import { Group, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three";
import * as CANNON from "cannon-es";
import { getEngine } from "~/space/engine";
import { GameObject } from "@ravespaceio/rave-engine/build/engine/src/engine/player/ObjectManagement/GameObject";
import Engine, { RaveNavigation, RavePhysics } from "@ravespaceio/rave-engine";
import { GuiHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiHelper";
import { getSpace } from "~/space/space";

const wireframeMaterial = new MeshBasicMaterial({ wireframe: true });
const colliders: Object3D[] = []



export function createColliderStaticMesh(mesh: Mesh, material: CANNON.Material) {
	const engine = getEngine();
	const ground = new GameObject(mesh);
	ground.add(new RavePhysics.Collider({ mass: 0, material })); // mass 0 is static
	ground.get<RavePhysics.Collider<any>>('collider').setCollidefromMesh(mesh);
	ground.transform.object3D.visible = false;
	mesh.material = wireframeMaterial;
	colliders.push(mesh)
	engine.playModeScene.add(ground);
	ground.name = mesh.userData.NavmeshID;
	return ground
}




/**
 * Configures an event to be executed on collision enter for zones transitionZone2 and platformSky zones
 * @param ground
 */
export function onColliderEventUpdateZone(ground: GameObject<any>) {
	const engine = getEngine();
	const playerCharNav = engine.player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter');
	if (ground.name === 'transitionZone2') {
		ground.onCollisionEnter = (collision) => {
			if (playerCharNav.isActive === false) {
				playerCharNav.setActive(true);
			}
			playerCharNav.switchZonebyPosition(engine.player.getPlayerPosition());
		}
	} else if (ground.name === 'main' || ground.name === 'platformSky') {
		ground.onCollisionStay = (collision) => {
			if (playerCharNav.isActive === false) {
				playerCharNav.switchZonebyPosition(engine.player.getPlayerPosition());
				playerCharNav.setActive(true);
			}
		}

	}
}



export function createKinematicColliderToMesh(mesh: Mesh, material: CANNON.Material, index: number, offsetIntensity: number) {
	const engine = getEngine();
	const movableGround = new GameObject(mesh.parent);
	movableGround.add(new RavePhysics.Collider({ mass: 1, material, type: CANNON.BODY_TYPES.KINEMATIC }));
	const collider = movableGround.get<RavePhysics.Collider<any>>('collider');
	collider.setCollidefromMesh(mesh);
	mesh.material = wireframeMaterial;
	colliders.push(mesh)
	mesh.visible = false;

	movableGround.preStepPhysics = (dt) => {
		const t = performance.now() / 1000
		collider.velocity.y = Math.sin(t + (index * Math.PI / 2)) * offsetIntensity
		mesh.parent.updateMatrix();
	}

	engine.playModeScene.add(movableGround);
	return movableGround

}

export function addCollidersToDebugGUI(engine: Engine) {
	const space = getSpace();
	const spaceFolder = space.gui.getFolderOrCreate('Space');
	GuiHelper.addButton(spaceFolder, () => {
		colliders.forEach(c => {
			c.visible = !c.visible
		})
	}).name('Toggle Colliders');

}






