import { getSpace } from "~/space/space"
import { Mesh, Material, MeshBasicMaterial, Scene, Texture, Vector2, Vector3, MeshStandardMaterial } from "three"
import * as THREE from "three"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures"
import { applyMaterials, setupAoMapLightMapQualityManager } from "~/space/_lib/mechanic/materials"
import { CircleShadow, RPMAvatar, ReflectionProbe } from "@ravespaceio/rave-engine"
import { getEngine } from "~/space/engine"
import { findAllMaterials } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter'


export function getTexBasePath(): string {
	const space = getSpace()
	return space.project.publicPath + "/textures" + (space.browser.isMobile ? "/low" : "/high") // always low on mobile
}

export function getProjectEnvMap(): Texture {
	const space = getSpace()
	return getMatMap(space.loader, `${space.project.publicPath}/textures/env_map.jpg`, { encoding: THREE.LinearEncoding, mapping: THREE.EquirectangularReflectionMapping, flipY: true })
}

export function createPlayerReflectionProbe(): Texture {
	const engine = getEngine()
	const space = getSpace()
	const refl = new ReflectionProbe()
	space.gui.addToSceneFolder(refl)
	engine.scene.add(refl)
	engine.loop.register(() => {
		refl.position.x = engine.player.getPlayerPosition().x
		refl.position.y = engine.player.getPlayerPosition().y - engine.player.camera.position.y // best for floor reflection
		refl.position.z = engine.player.getPlayerPosition().z
		refl.update(engine.renderer.renderer, engine.scene)
	})
	return refl.cubeRenderTarget.texture
}


//work only when character doesn't jump
export function setupAvatarEnvMapAndShadow(envMap: Texture) {
	const shadowPlane = new CircleShadow()
	RPMAvatar.onInstanceCreated = (avatar) => {
		const sp = shadowPlane.clone()
		sp.position.y += 0.01
		avatar.add(sp)
		findAllMaterials(avatar).forEach((m) => {
			const mat = m as THREE.MeshStandardMaterial
			mat.envMap = envMap
			mat.envMapIntensity = 1
		})
	}
}

export function setupAvatarShadow() {
	const engine = getEngine()
	const playerNavPos = engine.player.getNavigationComponent<PlayerCharacter>().getNavMeshPosition()
	const shadowPlane = new CircleShadow()
	engine.scene.add(shadowPlane)
	engine.loop.registerFixUpdate(() => {

		shadowPlane.position.set(playerNavPos.x, playerNavPos.y + 0.05, playerNavPos.z)

	})
}


export function setupEnvMapAvatar(envMap: Texture) {
	RPMAvatar.onInstanceCreated = (avatar) => {
		findAllMaterials(avatar).forEach((m) => {
			const mat = m as THREE.MeshStandardMaterial
			mat.envMap = envMap
			mat.envMapIntensity = 1
		})
	}
}



