
import { MyLoaders, PositionalHint } from "@ravespaceio/rave-engine"
import { getSpace } from '~/space/space';
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { useGameStore } from "~/store/game";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { getProjectEnvMap } from "~/space/_lib/mechanic/textures";
import { useSpaceStore } from "~/store/space";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';


export async function setupEnergyCells() {

	const engine = getEngine()
	const space = getSpace()
	const store = useGameStore()
	const spaceStore = useSpaceStore()

	const energycells = [
		{
			position: new THREE.Vector3(18, 2, 1.8),
			found: false,
			questId: 0,
			model: undefined
		},
		{
			position: new THREE.Vector3(2.752, 13.39, -0.45),
			found: false,
			questId: 1,
			model: undefined
		}
	]


	const energyGlb = await MyLoaders.gltfLoader.loadAsync('/dek/models/game/energiezelle.glb') as GLTF
	const energycellscene = energyGlb.scene

	const vendingCell = energycellscene.clone() as THREE.Group
	vendingCell.position.set(0.9, 3, -0.7)
	vendingCell.scale.set(3, 3, 3)
	space.gui.addToSceneFolder(vendingCell)
	if (!store.quests[6].done) {
		engine.scene.add(vendingCell)
		space.pciSystem.add({
			collider: new SphereCollider(vendingCell, 2),
			onColliderEnter: () => {
				if (!store.quests[6].done) {
					engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
					spaceStore.hint.title = "Denergy"
				}
			},
			onColliderLeave: () => {
				engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
				spaceStore.hint.title = undefined
			},
			onInteract: () => {
				if (!store.quests[6].done) {
					spaceStore.hint.title = undefined
					spaceStore.showDekergyModal = true
				}
			}
		})
	}

	space.eventbus.onAlready("dekergyFound", (q) => {
		vendingCell.visible = false
	})

	energycells.forEach(energyCell => {

		const model = energycellscene.clone() as THREE.Group
		energyCell.model = model
		model.rotation.x = -0.5
		model.position.copy(energyCell.position)
		model.position.y += .5
		model.scale.set(3, 3, 3)
		model.userData.baseY = model.position.y


		if (!store.quests[energyCell.questId].done) {
			engine.scene.add(model);
		}

		//@ts-ignore
		model.children[0].material.envMap = getProjectEnvMap()

		const pos = new THREE.Object3D()
		pos.position.copy(energyCell.position)
		space.pciSystem.add({
			collider: new SphereCollider(pos, 0.5),
			onColliderEnter: () => {
				if (!energyCell.found) {
					store.quests[energyCell.questId].done = true
					energyCell.found = true
					energyCell.model.visible = false
					store.newEnergycellFound = true
					if (energyCell.questId == 0) {
						space.eventbus.emit("newAnalytics", "energycellSpace")
					}
					if (energyCell.questId == 1) {
						space.eventbus.emit("newAnalytics", "energycellQuiz")
					}
				}
			},
		})
	});

	engine.loop.register((dt, time) => {
		for (const v of energycells) {
			vendingCell.rotation.y = vendingCell.rotation.y + 0.01
			// vendingCell.position.y = vendingCell.position.y + Math.sin(time / 1) / 1000
			v.model.position.y = v.model.userData.baseY + Math.sin(time / 1) / 10
			v.model.rotation.y = v.model.userData.baseY + (time / 2) / .5
		}
	})
}
