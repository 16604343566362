import { Logging, LoggingArea } from "@ravespaceio/rave-engine";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { useVoiceGroupStore } from "~/store/agora/voicegroups";
// import { useSpaceStore } from "~/store/space";
import { blockForFrontend, canAnswerVoiceChat, unblockFromFrontend } from "~/space/_lib/utils/frontend";
import { VoiceChatResponseTypes } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/VoiceChatTypes";
import { AgoraFallbackHandler } from "~/utils/agoraUtils";
import Client_AgoraVoiceGroupHandler from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/ClientStateHandlers/AgoraRoom/Client_AgoraVoiceGroup_RSH";

export function setupAgoraVoiceGroups(): Promise<any> {

	const engine = getEngine();

	if (!engine.config.multiplayer || !engine.config.voicechat) return;

	engine.multiplayer.clientAgoraRoom.voiceChatVgHandler.setAgoraHandler(undefined);
	const voiceGroupStateHandler = engine.multiplayer.clientAgoraRoom.getStateHandler<Client_AgoraVoiceGroupHandler>("VoiceGroupH");
	voiceGroupStateHandler.setAgoraClientHandler(engine.multiplayer.clientAgoraRoom.voiceChatVgHandler)

	const voiceGroupStore = useVoiceGroupStore();

	const getUserNameForPlayerId = (playerIdInAgoraRoom: string): string | undefined => {
		const playerIdInGlobalRoom = engine.multiplayer.clientAgoraRoom.room.state.vvPlayers.get(playerIdInAgoraRoom).globalRoomRef.globalClientId;
		if (playerIdInGlobalRoom) return engine.multiplayer.clientGlobalRoom.room.state.allPlayers.get(playerIdInGlobalRoom).userData.userName;
	}

	// --- ROOM EVENTS

	engine.multiplayer.clientAgoraRoom.onRoomLeftEvent.on(() => {
		voiceGroupStore.voiceGroupActive = false;
		voiceGroupStore.members.clear();
		voiceGroupStore.requestedName = "";
	})

	// --- VOICE GROUP EVENTS

	let alreadyJoined = false;

	voiceGroupStateHandler.onAgoraGroupJoin.on((channel) => {
		alreadyJoined = true;
		Logging.trace("You joined a voice group", LoggingArea.Agora);

		voiceGroupStore.voiceGroupActive = true;
		voiceGroupStore.showMessageForSomeTime("You joined the voicechat");
	})

	voiceGroupStateHandler.onAgoraGroupLeave.on((channel) => {
		Logging.trace("You left the voice group", LoggingArea.Agora);

		voiceGroupStore.members.clear();
		voiceGroupStore.voiceGroupActive = false;
		if (alreadyJoined) voiceGroupStore.showMessageForSomeTime(`You left the voicechat`);
	})

	voiceGroupStateHandler.onAgoraGroupMemberAdded.on((clientId) => {
		Logging.trace(`Member ${clientId} added to voice group`, LoggingArea.Agora);

		if (voiceGroupStateHandler.room.sessionId === clientId) return; // am i the user that was added ? -> return

		voiceGroupStore.members.set(clientId, getUserNameForPlayerId(clientId))

		// if remote user in my voice group changes name, react to it and change in interface
		const remoteNetPlayer = engine.multiplayer.netPlayerManager.getNetPlayerById(clientId, "agoraRoom");
		remoteNetPlayer?.globalNetplayer?.onUserNameChange.on((newName) => {
			voiceGroupStore.members.set(clientId, newName);
		})

	})

	voiceGroupStateHandler.onAgoraGroupMemberRemoved.on((clientId) => {
		Logging.trace(`Member ${clientId} removed from voice group`, LoggingArea.Agora);

		if (voiceGroupStateHandler.room.sessionId === clientId) return; // am i the user that was added ? -> return

		if (!voiceGroupStore.members.delete(clientId)) {
			Logging.error(`Could not find player "${getUserNameForPlayerId(clientId)}" in voiceGroupStore`, LoggingArea.Agora);
		}

		const remoteNetPlayer = engine.multiplayer.netPlayerManager.getNetPlayerById(clientId, "agoraRoom");
		remoteNetPlayer?.globalNetplayer?.onUserNameChange.clear();

	})

	voiceGroupStateHandler.onKickOut_LastUser.on((userId) => {
		Logging.trace('You were kicked out of the agora chat', LoggingArea.Agora);

		voiceGroupStore.members.clear();
		voiceGroupStore.voiceGroupActive = false;
		voiceGroupStore.showMessageForSomeTime("Everybody left the voicechat.");
	})

	// --- INVITATION EVENTS

	voiceGroupStateHandler.onBadInvitation.on((response) => {
		let message = 'Cannot Invite: ';

		const remotePlayerName = "the other player"

		switch (response) {
			case VoiceChatResponseTypes.groupErr:
				message += `You and ${remotePlayerName} are in a channel.`
				break;
			case VoiceChatResponseTypes.decline:
				message += `${remotePlayerName} has declined your invitation.`
				break;
			case VoiceChatResponseTypes.req_pend_local:
				message += 'You have already a pending invitation'
				break;
			case VoiceChatResponseTypes.req_pend_remote:
				message += `${remotePlayerName} has a pending invitation.`
				break;
			case VoiceChatResponseTypes.mic_permit_remote:
				message += `${remotePlayerName} did not allow microphone access`
				break;
			case VoiceChatResponseTypes.mic_permit_local:
				message += `Please allow microphone to use voice chat`
				break;
			case VoiceChatResponseTypes.server_Err:
				message += `Somthing went wrong on the server`
				break;
			case VoiceChatResponseTypes.cancel_local:
				message += `Invitation cancelled`
				break;
			case VoiceChatResponseTypes.cancel_remote:
				// removes ui element
				voiceGroupStore.requestedName = '';
				// removes keypress listener
				document.removeEventListener("keydown", voiceGroupStore.respondKeyListener);
				message += `${remotePlayerName} cancelled the invitation`
				break;
			case VoiceChatResponseTypes.channelFull:
				message += `Channel group is full`
				break;
			case VoiceChatResponseTypes.agora_Err:
				message += `Agora error: Players did not join`
				break;
			case VoiceChatResponseTypes.user_disconnect:
				message += `Invitation cancelled, ${remotePlayerName} got disconnected`
				break;
		}

		voiceGroupStore.showMessageForSomeTime(message);
	});

	// creating invite

	voiceGroupStateHandler.onInvitationCreate_Local.on((clientId) => {
		voiceGroupStore.showMessageForSomeTime(`You invited ${getUserNameForPlayerId(clientId)} to voicechat`);
	})


	voiceGroupStateHandler.onInvitationCreate_Net.on((clientId) => {
		voiceGroupStore.requestedName = getUserNameForPlayerId(clientId);

		document.addEventListener("keydown", voiceGroupStore.respondKeyListener);
	})

	// cancelling invite

	voiceGroupStateHandler.onInvitationCancel_Local.on(async (cancelInfo) => {
		const { clientId, cancelation } = cancelInfo

		if (cancelation === 'local')
			voiceGroupStore.showMessageForSomeTime("Invitation cancelled");
		else
			voiceGroupStore.showMessageForSomeTime(`${getUserNameForPlayerId(clientId)} has declined your invitation`);
	})

	voiceGroupStateHandler.onInvitationCancel_Net.on(async (cancelInfo) => {
		voiceGroupStore.requestedName = ""

		const { clientId, cancelation } = cancelInfo

		if (cancelation === 'local')
			voiceGroupStore.showMessageForSomeTime(`${getUserNameForPlayerId(clientId)} has withdrawn the invitation`)
		else
			voiceGroupStore.showMessageForSomeTime(`Invitation from ${getUserNameForPlayerId(clientId)} declined`)
	})

	// answering invite

	const localNetPlayer = engine.multiplayer.local_netPlayer;

	voiceGroupStore.respondKeyListener = async (e) => {
		voiceGroupStore.requestedName = "";

		if (!canAnswerVoiceChat()) return;

		switch (e.key) {
			case "y": case "Y":
				blockForFrontend();
				const permission = await localNetPlayer.getMicPermission();
				unblockFromFrontend();

				if (permission)
					localNetPlayer.joinVG();
				else
					voiceGroupStateHandler.onBadInvitation.trigger(VoiceChatResponseTypes.mic_permit_local)

				document.removeEventListener("keydown", voiceGroupStore.respondKeyListener); break;
			case "n": case "N":
				localNetPlayer.cancelVGInvite();

				document.removeEventListener("keydown", voiceGroupStore.respondKeyListener); break;
		}
	}

	// invite failed

	localNetPlayer.onRayCastFail.on(() => {
		voiceGroupStore.showMessageForSomeTime("Look at a player and press 'V'");
	})

}

export async function inviteRaycast() {
	const engine = getEngine();
	const voiceGroupStore = useVoiceGroupStore();

	const voiceGroupStateHandler = engine.multiplayer.clientAgoraRoom.getStateHandler<Client_AgoraVoiceGroupHandler>("VoiceGroupH");
	const localNetPlayer = engine.multiplayer.local_netPlayer;

	if (voiceGroupStateHandler.hasInvite()) {
		localNetPlayer.cancelVGInvite();
		return;
	}

	const permission = await localNetPlayer.getMicPermission();

	if (permission)
		localNetPlayer.inviteNetPlayerVG();
	else {
		Logging.warn("No microphone access", LoggingArea.Agora);
		voiceGroupStore.showMessageForSomeTime("Please enable microphone access");
	}
};
