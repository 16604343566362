<template>
	<div id="cookieHint" v-if="show">
		<div class="inner">
			<p class="bold">Cookie-Einwilligung</p>
			<p>Die Piwik PRO GmbH, Kurfürstendamm 21, 10719 Berlin, Deutschland, unterstützt uns als Auftragsverarbeiterin bei der Erhebung von Statistiken über die Nutzung der Deka Career Day 3D Welt (Reichweitenmessung), der Identifizierung von Fehlern und der Optimierung der 3D Welt.</p>
			<p class="link" v-if="!showMore" @click="showMore = true">Mehr Details anzeigen</p>
			<p v-if="showMore" style="margin-top: 12px;">Dafür werden Cookies gesetzt, über die wir die folgende personenbezogene Daten verarbeiten:
				IP-Adresse (IP-Adressen werden unmittelbar nach Erhebung gekürzt, Nutzungsdaten (Session, durchschnittliche Dauer des Besuchs der 3D Welt, angesehene Bereiche pro Besuch, Bounce Rate, Statistische Informationen zu bestimmten Events wie Nutzung der 3D Welt Features), zuerst und zuletzt besuchte Bereiche, User Journey, Benutzer-ID, Session-ID, Datum und Uhrzeit des Besuchs, Referrer URL, Informationen zu genutztem Gerät und Browser, Bildschirmauflösung, ungefährer geografischer Standort des Nutzers anhand der gekürzten IP-Adresse, User Agent, Cookie-ID.
				Rechtliche Grundlage für die Verarbeitung ist die Einwilligung, Art. 6 Abs. 1 s. 1 lit. a DS-GVO, § 25 Abs. 1 TTDSG
				Die Daten werden in der Europäischen Union verarbeitet und 25 Monate gespeichert.</p>
			<p class="link" v-if="showMore" @click="showMore = false">Einklappen</p>
			<div class="btn--wrapper">
				<div class="btn" @click="ablehnen()">Ablehnen</div>
				<div class="btn" @click="consent()">Akzeptieren</div>
				<div class="close" @click="show = false" v-show="optstate">
					<IconClose />
				</div>
			</div>
			<div class="wrap">
				<a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a>
				<a href="https://www.deka.de/privatkunden/impressum" target="_blank">Impressum</a>
			</div>
		</div>
	</div>
	<SpaceTrackingMatomo v-if="hasConsent" />
</template>

<script lang="ts">
import { enableTracking } from '@vue/reactivity';
import { useToast } from 'vue-toastification';
import { getSpace } from '~/space/space';


export default defineComponent({
	data() {
		return {
			optstate: false,
			show: false,
			hasConsent: false,
			showMore: false,
		}
	},
	mounted() {
		const isConsent = JSON.parse(localStorage.getItem("privacyConsent"));
		const isAnalytics = JSON.parse(localStorage.getItem("analyticsConsent"));
		const space = getSpace();
		if (isConsent) {
			if (isAnalytics == true) {
				this.enableTracking()
				this.optstate = true
			}
		} else {
			this.show = true
		}

		space.eventbus.onAlready("showCookieHint", () => {
			this.show = true
		});

		space.eventbus.on("optOutAnalytics", () => {
			useToast().success("Piwik Analytics wurde erfolgreich deaktiviert.")
		})

	},
	methods: {
		enableTracking() {
			const space = getSpace()
			this.hasConsent = true
			setTimeout(() => {
				localStorage.setItem("analyticsConsent", "true");
				space.eventbus.emit("analyticsConsent")
			},);
		},
		deleteTracking() {
			const space = getSpace()
			this.hasConsent = false
			setTimeout(() => {
				localStorage.setItem("analyticsConsent", "false");
				space.eventbus.emit("optOutAnalytics")
				this.optstate = false
			},);
		},
		consent() {
			localStorage.setItem("privacyConsent", "true");
			this.show = false
			this.enableTracking()
			this.optstate = true
		},
		ablehnen() {
			localStorage.setItem("privacyConsent", "true");
			this.show = false
			if (this.optstate) {
				this.deleteTracking()
			}
		}
	}
})

</script>
<style scoped lang="scss">
@import "assets/css/variables";

.link {
	margin-top: 12px;
	opacity: 0.5;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}

.btn--wrapper {
	@extend .flex-center;
	margin-top: 12px;

	.btn {
		margin: 10px;
	}
}

@media(max-width:800px) {
	.btn--wrapper {
		.btn {
			padding: 10px;
		}
	}
}

#cookieHint {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 20px;
	z-index: 9999999999;
	background: rgba(0, 0, 0, 0.8);
}

#cookieHint>.inner {
	width: 550px;
	max-width: calc(100% - 40px);
	height: auto;
	padding: 20px;
	border-radius: 20px;
	background: white;
	bottom: 20px;
	@extend .flex-center;
	flex-direction: column;

	p {
		font-size: 14px;
		text-align: center;
	}

	p.bold {
		font-weight: 700;
		margin-bottom: 8px;
	}

	.ga {
		margin: 20px 0;
		@extend .flex-center;
		flex-direction: column;

		p {
			font-size: 11px;
			font-weight: 700;
			margin-bottom: 12px;
		}
	}

	.wrap {
		@extend .flex-center;
		margin-top: 16px;

		a {
			color: black;
			opacity: 0.5;
			font-size: 12px;
			margin: 0 5px;
		}
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: $dark;
}

input:focus+.slider {
	box-shadow: 0 0 1px $secondary;
}

input:checked+.slider:before {
	-webkit-transform: translateX(36px);
	-ms-transform: translateX(36px);
	transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
