import { Role } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes';
import { defineStore } from 'pinia'
import { getEngine } from '~/space/engine';
import { ADMIN_CHANNEL_NAME, muteAdmin } from '~/space/_lib/mechanic/agora/adminChannel';

// export type ActiveChannelsInfo = { [key: string]: { muted: boolean, broadcasting: boolean } };

export const useVoiceRolesStore = defineStore({
	id: "voiceRolesStore",
	state: () => ({
		// own user states
		// userRole: Role.default,
		// activeHostChannelInfo: {} as ActiveChannelsInfo,
		// activeAudienceChannelInfo: {} as ActiveChannelsInfo,
		isJoined: false,
		isMuted: false,
		volumeMeter: 0,
	}),
	// getters: {
	// 	// combined states
	// 	activeHostChannels(): string[] {
	// 		return Object.keys(this.activeHostChannelInfo);
	// 	},
	// 	activeAudienceChannels(): string[] {
	// 		return Object.keys(this.activeAudienceChannelInfo);
	// 	},
	// 	activeHostChannelInfoLength(): number {
	// 		return Object.keys(this.activeHostChannelInfo).length
	// 	}
	// },
	actions: {
		muteUnmute() {
			this.isMuted = !this.isMuted;
			muteAdmin(this.isMuted);
		},
		joinLeave() {
			this.isJoined = !this.isJoined;

			const engine = getEngine();
			if (this.isJoined)
				engine.multiplayer.local_netPlayer.joinorCreateVoiceVideoAdminChannel(ADMIN_CHANNEL_NAME)
			else
				engine.multiplayer.local_netPlayer.leaveVoiceVideoAdminChannel(ADMIN_CHANNEL_NAME)
		},

	}
})

