<template>
	<div class="row controls-row">
		<div class="l">Maus</div>
		<div class="r">
			<div v-if="!MyEnv.$isMobile" class="wrap">
				<input id="check-jumpMode" v-model="spaceStore.usePointJumpMode" type="checkbox" name="check-jumpMode">
				<label for="check-jumpMode">Klick Steuerung</label>
			</div>
			<!-- <div v-if="!MyEnv.$isMobile" class="wrap">
				<input id="check-lockmouse" v-model="spaceStore.pointerlockMode" type="checkbox" name="check-lockmouse">
				<label for="check-lockmouse">Sperren</label>
			</div> -->
			<div class="wrap">
				<input id="check-invertmouse" v-model="invertMouse" type="checkbox" name="check-invertmouse">
				<label for="check-invertmouse">Invertieren</label>
			</div>
		</div>
	</div>

	<div class="row controls-row">
		<div class="l">Steuerung</div>
		<div class="r">
			<div class="wrap">
				<input id="check-sprint" v-model="isAutoSprint" type="checkbox" name="check-sprint">
				<label for="check-sprint">Auto Sprint</label>
			</div>
			<div class="wrap">
				<input id="check-perspective" v-model="isFirstPerson" type="checkbox" name="check-perspective">
				<label for="check-perspective">First Person</label>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CamControllersNames } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM';
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import PlayerObject from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject';
import { getEngine } from '~/space/engine';
import { useSpaceStore } from '~/store/space';


const isFirstPerson = ref(false)
const invertMouse = ref(false)
const isAutoSprint = ref()
const spaceStore = useSpaceStore()
const engine = getEngine();

getFirstPerson()
getInvertMouse()
// getPointerlock()
getAutoSprint()

watch(isFirstPerson, setFirstPerson)
watch(invertMouse, setInvertMouse)
watch(isAutoSprint, toggleAutoSprint)
// watch(() => spaceStore.pointerlockMode, setPointerLock)

function getFirstPerson() {
	isFirstPerson.value = engine.player.camControllerM.isFirstPerson()
}
function setFirstPerson(fp: boolean) {
	// engine naming bug: ist nicht nur only, aber für den use case hier passt das ja
	// engine.player.camControllerM.setFirstPersonOnly()
	if (fp) engine.player.camControllerM.changeController(CamControllersNames.FIRST_CAM);
	else engine.player.camControllerM.changeController(CamControllersNames.ORBIT_CAMERA);
	isFirstPerson.value = fp
}

function getInvertMouse() {
	invertMouse.value = engine.inputManager.mouseInputTracker.invertMouse;
}
function setInvertMouse(v: boolean) {
	invertMouse.value = v
	engine.inputManager.mouseInputTracker.invertMouse = v;
}

// function getPointerlock() {
// 	spaceStore.pointerlockMode = engine.inputManager.mouseInputTracker.usePointerLock
// }
// function setPointerLock(v: boolean) {
// 	engine.inputManager.mouseInputTracker.usePointerLock = v;
// 	spaceStore.pointerlockMode = v
// }

function getAutoSprint() {
	const playerInputController = engine.player.getInputController<PlayerCharacter>()
	if (playerInputController) isAutoSprint.value = playerInputController.emulatedRunning
}
function toggleAutoSprint(v: boolean) {
	const playerInputController = engine.player.getInputController<PlayerCharacter>()
	if (playerInputController) playerInputController.emulatedRunning = v
	isAutoSprint.value = v
}

</script>



