import { Logging, LoggingArea, MyLoaders, VideoScreenF } from '@ravespaceio/rave-engine';
import { Mesh, MeshBasicMaterial, VideoTexture, LinearFilter, sRGBEncoding, Texture, TextureLoader, } from 'three';
import { getEngine } from '~/space/engine';


type ScreenInfo = {
	screenMesh: Mesh,
	screenMat: MeshBasicMaterial,
	screenTextureUrl: string,
	videoElement: HTMLVideoElement,
}

export default class ScreenManager {

	private _allowedUids: number[] = [];
	get allowedUids() { return this._allowedUids };
	private _castToAllScreensId = 1000; // if this id is passed to the manager, the video will be cast to all screens
	private _debugUid = 999; // when an id is pass to the manager but no screen is found, instead to show an error a html will show the incoming video. better for debugging. with this we know that the video arrive so cloudplayer is fine but maybe the screen is missing or was not added to the manager.
	get debugUid() { return this._debugUid };
	public videoScreens: Map<number, Mesh> = new Map();
	public videoElements: Map<number, HTMLVideoElement> = new Map();
	public urlCMS: Map<number, string> = new Map();
	private screensIdsToFlip: number[] = [100, 102];
	private screensOnlyVideo: number[] = [100];
	public screenMaterials: Map<number, MeshBasicMaterial> = new Map();
	public debugElement: HTMLDivElement;
	// * notes* //
	/**
	 * I have seem through different projects and the way a texture its replaced differs a bit. Its almost the same but not quite, so I am leaving this function to be set up later by the engine.
	 * That is basically all that this class needs from the engine side.
	 */
	public replaceMaterial: ((screen: Mesh, screenTextureUrl: string) => void) | undefined;

	constructor() {
		this.videoElements.set(this._debugUid, ScreenManager.createVideoHTML());
		this.videoElements.set(this._castToAllScreensId, ScreenManager.createVideoHTML());
		this.screenMaterials.set(this._castToAllScreensId, new MeshBasicMaterial());
		this.debugElement = this._createDebugElement();
	}

	public addScreen(uid: number, screen: Mesh, screenMaterial: MeshBasicMaterial, screenTextureUrl: string) {
		if (!this._allowedUids.includes(uid)) {
			Logging.warn(`Screen ${uid} is not allowed. If you want to use this Id please add the id first to the manager.`, LoggingArea.Space);
			return;
		} else if (this.videoScreens.has(uid)) {
			Logging.warn(`Screen ${uid} is already in use`, LoggingArea.Space)
			return;
		}
		this.videoScreens.set(uid, screen);
		this.screenMaterials.set(uid, screenMaterial);
		this.urlCMS.set(uid, screenTextureUrl);


		if (screenTextureUrl !== 'none') {
			this._updateScreenTexturebyUrl(uid);
			// this.replaceMaterial(screen, screenTextureUrl);
		}
	}

	public setAllowedUids(uids: number[]) {
		this._allowedUids = uids;
		this._setVideoElements();
		Logging.info(`ScreenManager setup complete, screenIds: ${this._allowedUids}`, LoggingArea.Space);
	}

	private _setVideoElements() {
		this.allowedUids.forEach(uid => {
			this.videoElements.set(uid, ScreenManager.createVideoHTML());

			this.videoElements.get(uid).addEventListener('loadeddata', () => {
				const screenInfo = this._getScreenInfo(uid);
				const { screenMesh, screenMat, videoElement } = screenInfo;
				this._onVideoScreenTextureReplace(videoElement, screenMesh, screenMat);
			})


			this.videoElements.get(uid).addEventListener('ended', () => {
				const screenInfo = this._getScreenInfo(uid);
				const { screenMesh, screenMat, videoElement } = screenInfo;
				if (uid === 102) {
					this.updateScreenTexturebynewUrl(102, '/dek/img/masterclass.jpg');
				}

				if (uid === 103) {
					this.updateScreenTexturebynewUrl(103, 'none');
				}


			})

			this.videoElements.get(uid).addEventListener('error', (e) => {
				Logging.warn('Error: Video was not loaded' + JSON.stringify(e), LoggingArea.Space);
			})
		});

	}

	public replaceTexturebyId(videoData: MediaStream | undefined, uid: number) {
		if (this.allowedUids.length === 0) {
			Logging.warn(`Screen manager has no allowed uids, please set them up before trying to update the screen texture`, LoggingArea.Space)
			return;
		}

		if (this.videoScreens.size === 0) {
			return;
		}

		if (!this.allowedUids.includes(uid)) {
			Logging.warn(`Screen with id ${uid} was not found sending video to debugElement`, LoggingArea.Space);
			// create debug video element
			return;
		}
		const screenInfo = this._getScreenInfo(uid);
		const { screenMesh, screenMat, screenTextureUrl, videoElement } = screenInfo!;

		if (videoData === undefined) {

			if (uid === this._castToAllScreensId) {
				this.resetAllScreens();
				return;
			}

			this._updateScreenTexturebyUrl(uid);
			return;
		}

		// cast to all screens
		if (this._castToAllScreensId === uid) {
			this.castToAllScreens(videoData);
			return;
		}

		// cast to one screen
		this._updateScreenTexture(videoData, videoElement, screenMesh, screenMat);
	}

	public cleanAllScreensOnLeaving() {
		this.videoScreens.forEach((screen, uid) => {
			const screenTextureUrl = this.urlCMS.get(uid);
			if (this.replaceMaterial) this.replaceMaterial(screen, screenTextureUrl!);
		})
	}

	public resetAllScreens() {
		this.videoScreens.forEach((screen, uid) => {
			this.replaceTexturebyId(undefined, uid);
		})
	}

	public castToAllScreens(videoData: MediaStream) {
		this.videoScreens.forEach((screen) => {
			this._updateScreenTexture(videoData, this.videoElements.get(this._castToAllScreensId)!, screen, this.screenMaterials.get(this._castToAllScreensId)!);
		});
	}

	private _getScreenInfo(uid: number): ScreenInfo | undefined {
		const screenMesh = this.videoScreens.get(uid);
		const screenMat = this.screenMaterials.get(uid);
		const screenTextureUrl = this.urlCMS.get(uid);
		const videoElement = this.videoElements.get(uid);

		if (screenMat === undefined || screenMesh === undefined || screenTextureUrl === undefined || videoElement === undefined) {
			Logging.warn(`Something went wrong please check that the screen or material or placeholder url image exist`, LoggingArea.Space);
			return undefined;
		} else {
			return { screenMesh, screenMat, screenTextureUrl, videoElement }
		}
	}

	private _flipMapY(screenId: number | null, material: MeshBasicMaterial) {
		if (screenId === null) return;
		if (this.screensIdsToFlip.includes(screenId)) material.map.flipY = false;
	}

	public getScreenId(screen: Mesh): number | null {
		let foundScreenId = null
		this.videoScreens.forEach((screeIn, screenId) => {
			if (screen === screeIn) {
				foundScreenId = screenId
			}
		})

		return foundScreenId
	}

	private _updateScreenTexture(videoData: MediaStream, videoElement: HTMLVideoElement, screen: Mesh, screenMat: MeshBasicMaterial) {
		videoElement.srcObject = videoData;
	}

	private _onVideoScreenTextureReplace(videoElement: HTMLVideoElement, screen: Mesh, screenMat: MeshBasicMaterial,) {
		const videoTexture = ScreenManager.createVideoTexture(videoElement);
		videoTexture.needsUpdate = true;
		screen.visible = true;
		screenMat.map = videoTexture
		screenMat.needsUpdate = true
		screen.material = screenMat
		this._flipMapY(this.getScreenId(screen), screenMat);
		if (!this.screensOnlyVideo.includes(this.getScreenId(screen))) {
			videoElement.muted = false;
		}
		videoElement.play();

	}

	private _onImageScreenTextureReplace(imageElement: HTMLImageElement, screen: Mesh, screenMat: MeshBasicMaterial) {
		const texture = ScreenManager.createImageTexture(imageElement);
		texture.needsUpdate = true;
		screen.visible = true;
		screenMat.map = texture
		screenMat.needsUpdate = true
		screen.material = screenMat
		this._flipMapY(this.getScreenId(screen), screenMat);
	}

	public replaceTextureUrlbyId(screenId: number, newUrl: string) {
		this.urlCMS.set(screenId, newUrl);
		const screen = this.videoScreens.get(screenId);
		this.replaceMaterial(screen!, newUrl);
	}

	public updateScreenTexturebynewUrl(screenId: number, newUrl: string) {
		this.urlCMS.set(screenId, newUrl);
		this._updateScreenTexturebyUrl(screenId);
	}



	private _updateScreenTexturebyUrl(screenId: number) {
		if (this._useReservedURLStrings(screenId)) return;
		this._createTextureFromUrl(screenId);
	}

	private _useReservedURLStrings(screenId: number) {
		const screenInfo = this._getScreenInfo(screenId);
		const { screenMesh, screenMat, screenTextureUrl, videoElement } = screenInfo!;
		videoElement.pause(); // stops audio of videos
		switch (screenTextureUrl) {
			case 'none':
				Logging.trace(`Screen ${screenId} use screenTextureUrl ${screenTextureUrl} so it is set to invisible.`);
				screenMesh.visible = false;
				return true;

			default:
				return false;
		}

	}

	private _createTextureFromUrl(screenId: number) {
		const screenInfo = this._getScreenInfo(screenId);
		const { screenMesh, screenMat, screenTextureUrl, videoElement } = screenInfo!;

		if (this._urlIsImage(screenTextureUrl)) {

			const imgE = ScreenManager.createImageHTML();
			imgE.src = screenTextureUrl;
			imgE.onload = () => {
				this._onImageScreenTextureReplace(imgE, screenMesh, screenMat);
			}

			imgE.onerror = (e) => {
				Logging.warn('Error: Image was not loaded on screen' + screenId, LoggingArea.Space);
				Logging.warn('Error: Image was not loaded on screen' + screenId, LoggingArea.Space);
			}

		} else if (this._urlIsVideo(screenTextureUrl)) {
			videoElement.src = screenTextureUrl;


		} else {
			Logging.warn(`File extension not supported`);
		}
	}

	private _urlIsImage(url: string) {
		const extensions = ['.jpg', '.jpeg', '.png', '.webp'];
		const found = extensions.find(extension => url.includes(extension));
		if (found !== undefined) {
			return true;
		} else {
			return false;
		}
	}

	private _urlIsVideo(url: string) {
		const extensions = ['.mp4'];
		const found = extensions.find(extension => url.includes(extension));
		if (found !== undefined) {
			return true;
		} else {
			return false;
		}
	}

	// does the same as the one from the engine just makes sense that this class does it on its own instead of relying on the engine.
	static createVideoHTML() {
		const videoElement = document.createElement("video");
		// videoElement.style.position = 'absolute';
		videoElement.style.minWidth = '200px'
		videoElement.setAttribute("webkit-playsinline", "webkit-playsinline") // required for ios to not pop out after it started to play
		videoElement.playsInline = true
		videoElement.crossOrigin = "anonymous"
		videoElement.autoplay = false
		videoElement.loop = false
		videoElement.muted = true // on iOS Safari, video has to be muted
		videoElement.autoplay = false;
		// document.body.appendChild(videoElement)
		return videoElement
	}


	static createImageHTML() {
		const imageElement = document.createElement('img');
		imageElement.crossOrigin = ""
		return imageElement
	}

	static createVideoTexture(videoElement: HTMLVideoElement) {
		const videoTexture = new VideoTexture(videoElement)
		videoTexture.minFilter = LinearFilter;
		videoTexture.magFilter = LinearFilter;
		videoTexture.encoding = sRGBEncoding
		return videoTexture
	}

	static createImageTexture(videoElement: HTMLVideoElement | HTMLImageElement) {
		const imageTexture = new Texture(videoElement);
		imageTexture.minFilter = LinearFilter;
		imageTexture.magFilter = LinearFilter;
		imageTexture.encoding = sRGBEncoding
		return imageTexture
	}


	private _createDebugElement() {
		const div = document.createElement('div');
		div.style.position = 'absolute';
		div.style.display = 'flex';
		div.style.width = '100vw';
		div.style.top = '0';
		div.style.zIndex = '1000';
		return div
	}

	public showdebugVideoElements() {
		this.debugElement.append(...Array.from(this.videoElements.values()))
		document.body.appendChild(this.debugElement);
	}

	private _resetDebugElement() {
		this.debugElement.childNodes.forEach(child => child.remove())
	}

	public hideDebugVideoElemets() {
		this._resetDebugElement();
		this.debugElement.remove();
	}

}


