<template>
	<SpaceModal :show=show @closed="close()">
		<div class="content">
			<h1>Denergy Automat</h1>
			<p>Löse hier den Code aus der E-Mail deiner Anmeldung zum Deka Career Day ein.</p>
			<div class="code">
				<input type="text" v-model="code" maxlength="4" placeholder="0000">
				<div class="btn" @click="open()" :class="code.length < 4 ? 'disabled' : ''">Energiezelle entnehmen</div>
			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()
const { showDekergyModal } = storeToRefs(spaceStore)
const show = computed(() => !!showDekergyModal.value)
const code = ref("")

function close() {
	showDekergyModal.value = undefined
}

function open() {
	if (code.value == "1838") {
		space.eventbus.emit("dekergyFound")
		gameStore.quests[6].done = true
		gameStore.newEnergycellFound = true
		space.eventbus.emit("newAnalytics", "energycellDenergy")
		close()
	} else {
		toast.error("Falscher Code");
		code.value = ""
	}
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";


.content {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

p {
	text-align: center;
}

.code {
	@extend .flex-center;
	flex-direction: column;
	margin-top: 40px;
	background: #15171a;
	padding: 40px 70px;
	max-width: 100%;
	width: auto;
	border-radius: 20px;

	input {
		font-size: 24px;
		width: 140px;
		padding: 10px;
		padding-left: 28px;
		font-weight: 700;
		letter-spacing: 11px;
		border-radius: 8px;
		outline: none;
		border: none;
	}

	.btn {
		margin-top: 12px;
	}
}
</style>
