import { setupMultiplayer } from "../_lib/mechanic/multiplayer"
import { setupPlayer } from "../_lib/mechanic/_deprecated_player"
import { setupEnvironment } from "./environment"
import setupScene from "./scene"
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM"
import { getEngine } from "../engine"
import { setupEnergyCells } from "./game/energycells"
import { setupQuestGame } from "./game/questgame"
import setupElevator from "./scene/elevator"
import { setupBlockchainGame } from "./game/blockchain"
import setupMasterClass from "./scene/masterclass"
import { setupPlatformQuiz } from "./game/platformQuiz"
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player"
import { setupPlayerCharacter } from "../_lib/mechanic/playerCharacter"
import { setupLiveQuiz } from "./game/liveQuiz"
import { setupSkillMatching } from "./game/skillmatching"
import setupCMSScreens from "./scene/cmsScreens"
import setupCars from "./scene/cars"
import { getSpace } from "../space"

export function setupSpace() {

	setupPlayerCharacter();
	setupEnvironment()
	setupScene()
	setupMultiplayer()
	setupQuestGame()
	setupBlockchainGame()
	setupElevator()
	setupMasterClass()
	setupLiveQuiz()
	setupSkillMatching()
	setupCMSScreens()

	getSpace().browser.isVR.then(isVR => {
		if (!isVR) {
			setupCars()
		}
	})

	const engine = getEngine()
	engine.player.camControllerM.changeController(CamControllersNames.ORBIT_CAMERA)


}
