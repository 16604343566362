import { MyLoaders } from "@ravespaceio/rave-engine";
import { findMesh, testName } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import * as THREE from "three";
import { Object3D, Vector2, Vector3 } from "three";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter"

interface BPCConfig {
	src: string;
	width: number; // in cm
	height?: number // in cm
	maxWidth?: number // in cm
	maxHeight?: number // in cm
}

const createBetterPaintingCanvas = async (config: BPCConfig, onCreated?: (BPC: THREE.Object3D) => void) => {
	if (!config.height) {
		const img = document.createElement('img');
		img.src = config.src;
		await img.decode();
		const aspect = img.naturalHeight / img.naturalWidth;
		config.height = config.width * aspect;
	}

	config = calculateRestrictions(config);

	const BPC = new Object3D();
	const canvas = await _createCanvas(config);
	BPC.add(canvas);
	const frame = await _createFrame(config);
	BPC.add(frame);

	if (onCreated) onCreated(BPC);
	else return BPC
}

const _createCanvas = async (config: BPCConfig) => {
	const canvas = new Object3D();

	// load canvas model
	try {
		const gltf = await MyLoaders.gltfLoader.loadAsync('/SpaceShared/models/better_painting_canvas/BPC_canvas.glb');
		canvas.add(gltf.scene);
		console.log("loaded 'BPC_canvas.glb' and added to canvas object");
	} catch (error) {
		console.log("Error loading file 'BPC_canvas.glb': " + error);
	}

	// scale canvas model to size of painting
	canvas.scale.set(config.width / 100, config.height! / 100, 1); // (width, height, depth)

	// load painting texture
	const colMap = await MyLoaders.texLoader.loadAsync(config.src);
	colMap.anisotropy = 0;
	colMap.encoding = THREE.sRGBEncoding;
	colMap.magFilter = THREE.LinearFilter;
	colMap.minFilter = THREE.LinearMipMapLinearFilter;
	colMap.wrapS = THREE.RepeatWrapping;
	colMap.repeat.x = -1;

	const frontMat = findMesh(canvas, testName("canvasFront_MESH")).material as THREE.MeshStandardMaterial;
	frontMat.map = colMap;
	frontMat.normalScale.set(-0.3, 1)
	return canvas;
}

const _createFrame = async (config: BPCConfig) => {
	const frame = new Object3D();

	// load board model
	const gltf = await MyLoaders.gltfLoader.loadAsync('/SpaceShared/models/better_painting_canvas/BPC_board.glb');
	const board = gltf.scene;

	// adding boards to build up frame
	const boardThickness = 0.088;
	frame.add(_createBoard(board, new Vector2(0, -boardThickness / 2), 0, new Vector2(config.width / 100, 1))); // middle hor
	frame.add(_createBoard(board, new Vector2(boardThickness / 2, 0), Math.PI / 2, new Vector2(config.height! / 100, 0.7))); // middle vert
	frame.add(_createBoard(board, new Vector2(0, -config.height! / 100 / 2.01), 0, new Vector2(config.width / 100, 1))); // bottom
	frame.add(_createBoard(board, new Vector2(0, +config.height! / 100 / 2.01), Math.PI, new Vector2(config.width / 100, 1))); // top
	frame.add(_createBoard(board, new Vector2(config.width / 100 / 2.01, 0), Math.PI / 2, new Vector2(config.height! / 100, 0.9))); // left
	frame.add(_createBoard(board, new Vector2(-config.width / 100 / 2.01, 0), -Math.PI / 2, new Vector2(config.height! / 100, 0.9))); // right

	return frame;
}

const calculateRestrictions = (config: BPCConfig) => {
	// if too wide, reduce width
	if (config.maxWidth && config.width > config.maxWidth) {
		const scaleFactor = config.maxWidth / config.width;
		config.width *= scaleFactor;
		config.height! *= scaleFactor;
	}
	// if too tall, reduce height
	if (config.maxHeight && config.height! > config.maxHeight) {
		const scaleFactor = config.maxHeight / config.height!;
		config.width *= scaleFactor;
		config.height! *= scaleFactor;
	}

	return config
}

const _createBoard = (board: THREE.Object3D, pos: THREE.Vector2, rot: number, scale: THREE.Vector2) => {
	const newBoard = board.clone();
	newBoard.position.set(pos.x, pos.y, 0);
	newBoard.rotation.set(0, 0, rot);
	// @ts-ignore
	const mat = newBoard.children[0].material;
	mat.map.wrapS = THREE.RepeatWrapping; // enable wrapping to
	mat.map.repeat.y = scale.x;
	if (mat.normalMap) {
		mat.normalMap.wrapS = THREE.RepeatWrapping;
		mat.normalMap.repeat.y = scale.x;
	}
	if (mat.roughnessMap) {
		mat.roughnessMap.wrapS = THREE.RepeatWrapping;
		mat.roughnessMap.repeat.y = scale.x;
	}
	newBoard.children[0].scale.set(scale.x, 1, scale.y); // set scale in children to use local origin for scaling

	return newBoard;
}

export default createBetterPaintingCanvas;

// --------------------------------------

/**
 * Generates a url to a blob which stores the given THREE.Object3D
 * @param onResult
 */
export function generateRuntimeModelURL(model: THREE.Object3D, onResult: (url: string) => void) {
	const exporter = new GLTFExporter();
	exporter.parse(model, (glb) => {
		if (glb instanceof ArrayBuffer) {
			const blob = new Blob([glb], { type: 'model/gltf-binary' });
			onResult(URL.createObjectURL(blob));
		} else {
			console.log("Parsed model was not an ArrayBuffer, cannot create blob.");
		}
	}, (error) => {
		console.error("Could not parse model to glb. Error: " + error);
	}, { binary: true });
}
