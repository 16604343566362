export const skillmatchingData = [
	{
		"skillTextAlt": "akribisches Arbeiten",
		"skillText": "Sorgfältigkeits-Vorbild 🧐",
		"description": "Genaues und akribisches Arbeiten liegt dir im Blut.",
		"image": "sorgfaeltigkeits-vorbild.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": true,
			"Revision": true,
			"Recht": true,
			"InformationssicherheitDatenschutz": true,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": true,
			"Verwahrstelle": true,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": true,
			"ControllingTax": true
		}
	},
	{
		"skillTextAlt": "Analytisches Denken",
		"skillText": "Analytiker:in 🧠",
		"description": "Du durchdenkst Probleme sachlich und Schritt für Schritt und kannst das Wesentliche vom Unwesentlichen unterscheiden",
		"image": "analytikerin.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": true,
			"Finanzierungen": true,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": true
		}
	},
	{
		"skillTextAlt": "argumentationsfreudig",
		"skillText": "Argumentationsfreudig 💬",
		"description": "Diskutieren und Argumentieren ist deine Leidenschaft.",
		"image": "argumentationsfreudig.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": true,
			"Revision": true,
			"Recht": true,
			"InformationssicherheitDatenschutz": true,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Bühnenmensch / Rampensau",
		"skillText": "Bühnenmensch 🎤📣",
		"description": "Vor Menschen zu stehen und etwas zu präsentieren macht dir Spaß.",
		"image": "buehnenmensch.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Datenliebe",
		"skillText": "Datenliebe 📈",
		"description": "Du liebst es, dich mit Daten zu beschäftigen und diese zu analysieren.",
		"image": "datenliebe.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": true,
			"Finanzierungen": true,
			"IT": true,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": true,
			"Verwahrstelle": true,
			"Einkauf": true,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Finanzmärkte/Finanzprodukte/Kapitalmärkte",
		"skillText": "Hobbybanker:in 💸",
		"description": "Du interessierst dich für Aktien, Zinsen und alles, was mit Finanzmärkten zu tun hat.",
		"image": "hobbybanker.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Fokussiertes Arbeiten",
		"skillText": "Arbeiten mit Fokus 📔",
		"description": "Du arbeitest gerne über längere Zeit konzentriert an einem Thema, ohne gestört zu werden.",
		"image": "fokussiertesArbeiten.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": true,
			"Finanzierungen": false,
			"IT": true,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Gesetze & Regeln",
		"skillText": "Regelrockstar ⚖️",
		"description": "Regeln und Gesetze sind für dich etwas Gutes. Du magst es, Sachverhalte in Regeln zu gießen.",
		"image": "regelrockstar.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": true,
			"Revision": true,
			"Recht": true,
			"InformationssicherheitDatenschutz": true,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": true,
			"ControllingTax": true
		}
	},
	{
		"skillTextAlt": "kommunikativ / Plaudertasche",
		"skillText": "Plaudertasche 🗣️",
		"description": "Du bist wort- und redegewandt und kommunizieren ist deine Leidenschaft.",
		"image": "plaudertasche.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": true,
			"Personal": true,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": true,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "kreativ",
		"skillText": "Kreativer Kopf 🎨",
		"description": "Du bist ein kreativer Kopf, der/die am liebsten out-of-the-box denkt.",
		"image": "kreativerkopf.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": true,
			"Personal": false,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Mathe-Crack & Modellliebe",
		"skillText": "Mathecrack 🧮",
		"description": "Du liebst Mathe und mathematische Modelle.",
		"image": "mathecrack.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": true,
			"Finanzierungen": true,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Medien & Marketing",
		"skillText": "Medienmagier:in 💻",
		"description": "Du interessiert dich leidenschaftlich für alles, was mit Marketing und Medien zu tun hat.",
		"image": "medienmagier.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Menschenkenner",
		"skillText": "Menschenkenner:in 🤓",
		"description": "Du bist sehr empathisch und kannst auf die Emotionen deiner Mitmenschen gut eingehen",
		"image": "menschenkennerin.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": true,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": true,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Networker",
		"skillText": "Networker:in 🕸️",
		"description": "Auf der Party kennst du jeden persönlich und dein Wochenende reicht nicht aus, um all deine Freunde zu treffen.",
		"image": "networkerin.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": true,
			"Personal": true,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Programmieren",
		"skillText": "Codekünstler:in  👩‍💻",
		"description": "Programmieren ist deine Leidenschaft",
		"image": "codekuenstlerin.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": true,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "überrascht werden / Überraschungspartys",
		"skillText": "Überraschungsparty 🎉",
		"description": "Du bist spontan und liebst es, überrascht zu werden.",
		"image": "ueberraschungsparty.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Strukturliebend",
		"skillText": "Strukturliebe 📇",
		"description": "Du bist immer gut organisiert und arbeitest gerne strukturiert.",
		"image": "strukturliebe.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": true,
			"Finanzierungen": true,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": true,
			"Verwahrstelle": true,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": true,
			"ControllingTax": true
		}
	},
	{
		"skillTextAlt": "Technikliebe",
		"skillText": "Technikenthusiast:in 📱",
		"description": "Du bist jetzt schon Feuer und Flamme für den Verkaufsstart der neuesten Tech-Gadgets.",
		"image": "technikenthusiastin.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": true,
			"InnovationDigitalisierung": true,
			"Personal": false,
			"Unternehmenskommunikation": true,
			"Marketing": true,
			"Multikanalmanagement": true,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Gebrauchtwagenverkäufer:in",
		"skillText": "Verhandlungsmeister:in 🤝",
		"description": "Ob auf dem Flohmarkt, dem Wochenmarkt oder beim Autokauf - du verhandelst immer den besten Preis und hast dabei auch noch Spaß.",
		"image": "verhandlungsmeisterin.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": false,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": true,
			"PrivateBankingWealthManagement": true,
			"Vertrieb": true,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Ländern & Volkswirtschaften",
		"skillText": "BIP-Buddy 📰",
		"description": "Du interessierst dich für wirtschaftliche Zusammenhänge und die aktuellen Wirtschaftsnews sind dein täglich Brot.",
		"image": "bipbuddy.jpeg",
		"values": {
			"AssetManagement": false,
			"Treasury": false,
			"Kapitalmarkt": false,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": false,
			"Verwahrstelle": false,
			"Einkauf": false,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": false,
			"ControllingTax": false
		}
	},
	{
		"skillTextAlt": "Zahlenjongleur:in",
		"skillText": "Zahlenjongleur:in ➗",
		"description": "Zahlen sind deine Freunde.",
		"image": "zahlenjongleur.jpeg",
		"values": {
			"AssetManagement": true,
			"Treasury": true,
			"Kapitalmarkt": true,
			"Research": true,
			"Compliance": false,
			"Revision": false,
			"Recht": false,
			"InformationssicherheitDatenschutz": false,
			"Risikocontrolling": false,
			"Finanzierungen": false,
			"IT": false,
			"InnovationDigitalisierung": false,
			"Personal": false,
			"Unternehmenskommunikation": false,
			"Marketing": false,
			"Multikanalmanagement": false,
			"DepotWertpapierservice": true,
			"Verwahrstelle": true,
			"Einkauf": true,
			"PrivateBankingWealthManagement": false,
			"Vertrieb": false,
			"Finance": true,
			"ControllingTax": true
		}
	}
]


export const SkillmatchingCategories: {
	[name: string]: {
		title: string,
		text: string,
		points: number,
		neededPoints: number
	}
} = {
	"AssetManagement": {
		"title": "Asset Management",
		"text": "Im Bereich Asset Management bist du für die professionelle Verwaltung für Vermögenswerte, wie Geld, Immobilien und Wertpapiere verantwortlich, um langfristige finanzielle Ziele zu erreichen. Dies umfasst die Auswahl geeigneten und vielfältigen Portfolios. Damit können die Risiken minimiert werden und die laufende Überwachung der Anlagen wird sichergestellt. So werden die Renditen zu maximiert und Kundenwünsche erfüllt. Das Hauptziel besteht darin, finanzielle Stabilität und Wohlstand für die Anleger zu gewährleisten. Das Thema Nachhaltigkeit gewinnt hierbei eine immer größere Bedeutung und ist nun ein wichtiger Faktor bei der Analyse und Auswahl der Anlagen. Das Asset Management der Deka Gruppe ist die Deka Investment. Für das Management von Immobilieninvestitionen und -fonds ist die Deka Immobilien zuständig.",
		"points": 0,
		"neededPoints": 5
	},
	"Treasury": {
		"title": "Treasury",
		"text": "Der Bereich Treasury liegt im Zentrum der Bank. Hier kommen alle Investitionen (Aktiva) und Verbindlichkeiten (Passiva) zusammen und werden ausgesteuert. Die Hauptaufgaben umfassen die Verwaltung von Liquidität, das Risikomanagement und die Finanzplanung. Du beschäftigst dich mit unterschiedlichsten Produkten, Währungen und Laufzeiten und trägst deinen Teil dazu bei, dass diese Ressourcen möglichst effizient verwendet werden. Treasury-Expertinnen und Experten sind sowohl für eine strategiekonforme Bilanzstruktur als auch für die verursachungsgerechte Steuerung und Kalkulation von Geschäften verantwortlich.",
		"points": 0,
		"neededPoints": 5
	},
	"Kapitalmarkt": {
		"title": "Kapitalmarkt",
		"text": "In diesem Bereich gehört das Handeln mit verschiedenen Finanzinstrumenten auf den internationalen Kapitalmärkten zu deiner Aufgabe. Ziel ist es, den Sparkassen und anderen institutionellen Kundinnen und Kunden passende Finanzprodukte anzubieten. Das umfasst die Emissionen von Zertifikaten, sowie den Handel mit Aktien, Anleihen, Derivaten und anderen Wertpapieren. Im Kapitalmarktbereich spielt die Bewertung von Risiken und Chancen auf den Märkten eine entscheidende Rolle. Auch technisches Know-how ist hier gefragt, z.B. bei der Entwicklung unserer Plattform für institutionelle Kundinnen und Kunden oder in unseren Blockchain und KI-Projekten.",
		"points": 0,
		"neededPoints": 5
	},
	"Research": {
		"title": "Research",
		"text": "In der Research Abteilung unterstützt du unsere Kundinnen und Kunden mit der Analyse von Finanzmärkten, aktuellen Wirtschaftstrends und Unternehmen. Mit regelmäßigen Publikationen helfen wir uns und unseren Kundinnen und Kunden dabei, fundierte Investitionsentscheidungen zu treffen. Du erstellst diese Research-Berichte und bist Ansprechpartnerin bzw. Ansprechpartner bei Rückfragen. Die Abteilung spielt eine entscheidende Rolle bei der Bereitstellung von Marktinformationen und -analysen, um das Risikomanagement und die Kapitalallokation der Bank und der Sparkassen zu verbessern.",
		"points": 0,
		"neededPoints": 6
	},
	"Compliance": {
		"title": "Compliance",
		"text": "In der Compliance-Abteilung eines Unternehmens bist du verantwortlich für die Gewährleistung der Einhaltung gesetzlicher Vorschriften, regulatorischer Anforderungen und unternehmensinterner Richtlinien. Deine Aufgaben umfassen die Überwachung und Identifikation von Risiken, die Entwicklung und Umsetzung von Compliance-Programmen sowie die Schulung und Beratung von Mitarbeitenden und Führungskräften. Die Compliance-Abteilung arbeitet unabhängig, und sie spielt eine wesentliche Rolle bei der Minimierung von Rechtsrisiken und dem Schutz des Unternehmens vor rechtlichen Konsequenzen.",
		"points": 0,
		"neededPoints": 3
	},
	"Revision": {
		"title": "Revision",
		"text": "In der Internen Revision erfüllst du eine aufsichtsrechtlich vorgeschriebene Prüfungsfunktion und fungierst gleichzeitig als Beraterin bzw. Berater für die verschiedenen Geschäftsbereiche. Du bist verantwortlich für die unabhängige Prüfung und Überwachung der Geschäftsprozesse, Finanzen und Compliance, um sicherzustellen, dass sie den gesetzlichen Vorschriften, internen Richtlinien und besten Praktiken entsprechen. Angesichts sich ändernder Rahmenbedingungen, darunter Digitalisierung, Cyberrisiken, Compliance-Anforderungen und regulatorische Veränderungen, spielt die Interne Revision eine entscheidende Rolle für die Stabilität und Reputation der Deka-Gruppe.",
		"points": 0,
		"neededPoints": 3
	},
	"Recht": {
		"title": "Recht",
		"text": "In der Rechtsabteilung bist du verantwortlich für die rechtliche Beratung und Unterstützung der Facheinheiten und konzernzugehörigen Gesellschaften der Deka-Gruppe. Ziel ist es, hochwertige Rechtsberatung anzubieten, die praktische Anforderungen berücksichtigt und gleichzeitig vor Rechtsrisiken schützt. Du begleitest Regulierungsvorhaben von ihrer Entstehung bis zur Umsetzung in der gesamten Deka-Gruppe. Besonders spannend sind aktuelle Themen wie Blockchain, NFTs und die Digitalisierung von Wertpapieren, da sich der rechtliche Rahmen hier noch in der Entwicklung befindet.",
		"points": 0,
		"neededPoints": 3
	},
	"InformationssicherheitDatenschutz": {
		"title": "Informationssicherheit & Datenschutz",
		"text": "Informationssicherheit bezieht sich auf die Praktiken und Maßnahmen, die ergriffen werden, um sensible Informationen und Daten vor unbefugtem Zugriff, Diebstahl oder Verlust zu schützen. Du bist verantwortlich für die Festlegung von gruppenweiten Vorgaben und die Überwachung der Einhaltung in ihren Aufgabenfeldern. Das umfasst die Implementierung von Sicherheitsrichtlinien, Zugangskontrollen, Verschlüsselungstechnologien und Schulungsprogrammen, um Mitarbeitende für Sicherheitsrisiken zu sensibilisieren. Datenschutz bezieht sich auf die Einhaltung von Gesetzen und Vorschriften, die den Schutz personenbezogener Daten von Kundeinnen und Kunden, Mitarbeitenden und anderen Stakeholdern regeln. Hier stellst du sicher, dass Daten nur für legitime Zwecke gesammelt und gespeichert werden. Des Weiteren triffst du angemessene Sicherheitsvorkehrungen, um diese Daten vor unbefugtem Zugriff zu schützen. ",
		"points": 0,
		"neededPoints": 3
	},
	"Risikocontrolling": {
		"title": "Risikocontrolling",
		"text": "Im Bereich Risikocontrolling bist du für die Identifikation, Bewertung und Überwachung aller Risikoaspekte im Zusammenhang mit den Bankgeschäften verantwortlich. Du entwickelst Modelle und Methoden zur Messung von Marktrisiken, Liquiditätsrisiken, Kreditrisiken und operationellen Risiken und stellst sicher, dass die regulatorischen Anforderungen erfüllt werden. Darüber hinaus arbeitest du eng mit anderen Bereichen zusammen, um die jeweilige Risikosituation zu analysieren, Risikomanagementstrategien zu entwickeln und die Bank vor unerwarteten finanziellen Verlusten zu schützen.",
		"points": 0,
		"neededPoints": 4
	},
	"Finanzierungen": {
		"title": "Finanzierungen",
		"text": "Im Bereich Finanzierung bist du zuständig für die Akquisition und Durchführung von Finanzierungen in verschiedenen Bereichen, wie zum Beispiel in der Infrastruktur-, Export- und Transportfinanzierungen. Zudem arbeitest du mit unterschiedlichen Deal Teams im gesamten Neugeschäftsprozess und begleitest das Kreditgeschäft mit. Du überwachst auch das Risikomanagement in Bezug auf die Finanzierungsstruktur und trägst dazu bei, die finanzielle Stabilität und Nachhaltigkeit der DekaBank sicherzustellen.",
		"points": 0,
		"neededPoints": 4
	},
	"IT": {
		"title": "IT",
		"text": "In der IT bist du verantwortlich für die Planung, Implementierung, Wartung und Sicherheit der Informationstechnologie-Infrastruktur. Dies umfasst die Verwaltung von Computern, Netzwerken, Servern, Softwareanwendungen und Datenbanken. Damit spielt die IT-Abteilung eine entscheidende Rolle bei der Unterstützung der Geschäftsprozesse, der Datensicherheit und der Umsetzung digitaler Strategien, um die Effizienz und Wettbewerbsfähigkeit des Unternehmens sicherzustellen. Sie arbeitet eng mit anderen Abteilungen zusammen, damit die IT-Lösungen den geschäftlichen Anforderungen gerecht werden.",
		"points": 0,
		"neededPoints": 4
	},
	"InnovationDigitalisierung": {
		"title": "Innovation und Digitalisierung",
		"text": "Im Bereich Innovation und Digitalisierung konzentrierst du dich auf die Förderung neuer Technologien und digitaler Lösungen, um die Effizienz und Innovationskraft des Unternehmens zu steigern. Du arbeitest agil an der Entwicklung und Umsetzung von Projekten und Strategien, die den digitalen Wandel in der Bank vorantreiben. Es gilt, Trends zu identifizieren und neue Möglichkeiten und Technologien auf ihre Anwendbarkeit zu prüfen und in der Bank bekannt zu machen. Außerdem ist es deine Aufgabe, Ideen und Initiativen aus den Bereichen der Bank zu fördern, z.B. durch Projekte und Workshops, die den Rahmen bilden und dabei helfen, kreative Lösungsansätze zu erarbeiten. Dieser Bereich spielt eine entscheidende Rolle bei der Identifizierung von neuen Chancen in der Finanztechnologie und arbeitet daran, diese in die Geschäftsprozesse zu integrieren.",
		"points": 0,
		"neededPoints": 4
	},
	"Personal": {
		"title": "Personal",
		"text": "Die Personalabteilung ist die wichtigste Schnittstelle der Mitarbeitenden zur Deka-Gruppe. Hier bietest du den Arbeitnehmenden maßgeschneiderte Unterstützung, die sich an ihre unterschiedlichen Lebensabschnitte anpasst. Damit spielt die Personalabteilung eine Schlüsselrolle bei der Schaffung einer gesunden und produktiven Arbeitsumgebung, in der Mitarbeitende ihr volles Potenzial entfalten können und langfristig an die Deka-Gruppe gebunden werden. Dazu gehört auch Mitarbeitende persönlich und fachlich weiterzuentwickeln und somit Perspektiven zu bieten. Außerdem ist die Gewinnung neuer Mitarbeitenden eine wichtige Aufgabe. Der Bereich ist verantwortlich für Karriereseite der Deka-Gruppe und die Ansprache neuer Mitarbeitenden z.B. auf Messen. Auch unsere Einstiegsprogramme werden hier entwickelt und betreut. Nicht zuletzt hast du die Möglichkeit an spannenden Projekten, wie dem virtuellen Karrieretag mitzuarbeiten.",
		"points": 0,
		"neededPoints": 3
	},
	"Unternehmenskommunikation": {
		"title": "Unternehmenskommunikation",
		"text": "In der Unternehmenskommunikation bei der Deka spielst du eine wichtige Rolle in unserer Geschäftsstrategie. Deine Aufgabe ist es, sicherzustellen, dass unsere Kommunikation, sei es intern oder extern, konsistent, zielführend und ansprechend ist. Das umfasst Bereiche wie Kontakte zu Presse und Medien, unsere Social Media Auftritte sowie die Pflege unserer Unternehmensmarke. Du bist die Verbindung zwischen Deka und der Öffentlichkeit, und dabei ist die Pflege der Reputation des Unternehmens und der Marke dein zentrales Ziel. Auch die interne Kommunikation, z.B. über den unternehmenseigenen Intranetauftritt, fällt in deine Aufgaben. ",
		"points": 0,
		"neededPoints": 6
	},
	"Marketing": {
		"title": "Marketing",
		"text": "In der Marketingabteilung konzentrierst du dich darauf, Produkte oder Dienstleistungen erfolgreich auf dem Markt zu positionieren. Dabei entwickelst du Marketingstrategien, Werbekampagnen und Marktforschungsprojekte, um Kundenbedürfnisse zu verstehen und Produkte effektiv zu vermarkten. Durch gezielte Kommunikation und innovative Ansätze trägst du dazu bei, das Markenimage zu stärken und das Wachstum des Unternehmens zu fördern.",
		"points": 0,
		"neededPoints": 6
	},
	"Multikanalmanagement": {
		"title": "Multikanalmanagement",
		"text": "Im digitalen Multikanal Management (DMKM) sind deine Hauptaufgaben die Koordination und zentrale Steuerung verschiedener Geschäftsaspekte. Du bist auch für die Verwaltung von Anforderungen an Vertriebskanäle und Depotsysteme sowie die Planung und Überwachung von Budgets verantwortlich. Im DMKM spielst du auch eine entscheidende Rolle bei der strategischen Weiterentwicklung der Online-Vertriebskanäle der Deka und Sparkassen sowie bei der Unterstützung von Multikanallösungen und der Verwaltung von Domain und Newslettern.",
		"points": 0,
		"neededPoints": 6
	},
	"DepotWertpapierservice": {
		"title": "Depot- & Wertpapierservice",
		"text": "Im Bereich Depot- und Wertpapierservice bist du zuständig für die Abwicklung aller Kapitalmarktgeschäfte und das Management aller Zahlungen im ganzen Konzern. Du unterstützt die Einheit, die einen wesentlichen Vertriebskanal für Investmentfonds in der Sparkassenfinanzgruppe darstellt. Das Leistungsspektrum wird durch den Einkauf von Fremdfonds abgerundet. Des Weiteren hast du die Möglichkeit in diversen Projekten die digitale Transformation voranzutreiben. Somit spielst du eine essenzielle Rolle bei der Abwicklung von Wertpapiertransaktionen.",
		"points": 0,
		"neededPoints": 4
	},
	"Verwahrstelle": {
		"title": "Verwahrstelle",
		"text": "Die Verwahrstelle ist für wesentliche Aufgaben bei der Verwaltung von Investmentfonds und die sichere Verwahrung von Kundenvermögen verantwortlich. Dabei stellst du sicher, dass alle gehaltenen Vermögenswerte, einschließlich Investmentfonds und Wertpapiere, ordnungsgemäß bewertet und aufbewahrt werden. Außerdem überwachst du die Einhaltung von gesetzlichen sowie vertraglichen Richtlinien und berichtest über Abweichungen. Durch die von dir ausgeübte Überwachung des Fondsinventars und aller Geschäftsvorfälle sorgst du für Sicherheit der Anleger, Integrität der Investmentfonds und Vertrauen in den Finanzmarkt.",
		"points": 0,
		"neededPoints": 4
	},
	"Einkauf": {
		"title": "Einkauf",
		"text": "Der Einkauf besteht aus zwei Bereichen, dem strategischen und dem operativen Einkauf. Im strategischen Einkauf konzentrierst du dich auf langfristige Planung und strategische Partnerschaften mit Lieferanten. Ziel ist es Beschaffungsprozesse zu optimieren, Kosten zu reduzieren und die Qualität der gelieferten Waren und Dienstleistungen sicherzustellen. Du bist verantwortlich für die Auswahl von Schlüssellieferanten und die Gestaltung langfristiger Verträge, um die Unternehmensziele zu unterstützen. Im operativen Einkauf der Deka konzentrierst du dich auf die täglichen Beschaffungsaktivitäten und die Umsetzung der strategischen Vorgaben. Hier werden Bestellungen aufgegeben, Lieferungen überwacht, Lieferantenkontakte gepflegt und sicherheitsgestellt, dass die benötigten Ressourcen und Dienstleistungen zeitnah und effizient bereitgestellt werden. Dieser Bereich ist eng mit den internen Abteilungen und den Lieferantinnen und Lieferanten verbunden, um einen reibungslosen Ablauf der Beschaffungsprozesse sicherzustellen.",
		"points": 0,
		"neededPoints": 4
	},
	"PrivateBankingWealthManagement": {
		"title": "Private Banking & Wealth Management",
		"text": "In unserem Bereich dreht sich alles um exklusive Investmentkonzepte, für Unternehmer, Stiftungen und vermögende Privatpersonen. Wir setzen uns dafür ein, den Sparkassen ein maßgeschneidertes Angebot aus Produkten, Dienstleistungen und Services für jeden ihrer Kunden zu bieten.  Unser Aufgabenspektrum ist extrem vielfältig. Dabei bieten wir den Sparkassen vertriebliche Unterstützung in der strategischen Private Banking Betreuung und begleiten bei Bedarf auch in der Beratung durch unsere Wealth Management. Wir entwickeln erstklassige Produktlösungen und Anlagestrategien für die hochvermögende Kundschaft und konzipieren ganzheitliche Kommunikations- und Vermarktungskonzepte. ",
		"points": 0,
		"neededPoints": 6
	},
	"Vertrieb": {
		"title": "Vertrieb",
		"text": "Im Bereich Vertrieb unterstützt du die Sparkassen und institutionelle Kunden bei der Vermarktung und dem Vertrieb von Finanzprodukten und individuellen Anlagelösungen. Eine zentrale Aufgabe ist, die Sparkassen dabei zu unterstützen die passenden Deka Produkte für Ihre Kundinnen und Kunden zu finden. r. Auch die Pflege von Kundenbeziehungen ist dabei eine zentrale Aufgabe. Um das passende Produkt zu finden, sind außerdem umfassende Kenntnisse der Finanzprodukte und der aktuellen Situation an den Finanzmärkten nötig. ",
		"points": 0,
		"neededPoints": 6
	},
	"Finance": {
		"title": "Finance",
		"text": "Im Bereich Finance bist du verantwortlich für die finanzielle Steuerung und Berichterstattung des Unternehmens. Du kümmerst dich um die Budgetierung, Buchführung und Finanzplanung, um sicherzustellen, dass die finanziellen Ressourcen der Bank effizient genutzt werden. Dieser Bereich spielt eine entscheidende Rolle bei der Erstellung von Finanzberichten, die von Regulierungsbehörden und Investoren gefordert werden, und arbeitet eng mit anderen Abteilungen zusammen, um die finanzielle Gesundheit und Nachhaltigkeit der Deka sicherzustellen.",
		"points": 0,
		"neededPoints": 4
	},
	"ControllingTax": {
		"title": "Controlling & Tax",
		"text": "Im Bereich Controlling und Tax bist du für die umfassende Finanzkontrolle und Steuerplanung des Unternehmens zuständig. Du überwachst und bewertest finanzielle Kennzahlen, um sicherzustellen, dass Geschäftsziele erreicht und finanzielle Risiken minimiert werden. Darüber hinaus kümmerst du dich um die steuerlichen Angelegenheiten der Bank und entwickelst Strategien zur Optimierung der Steuerbelastung und Einhaltung der Steuergesetze.",
		"points": 0,
		"neededPoints": 4
	}
}
