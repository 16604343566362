import Engine, { BigGui, Logging, RaveNavigation, RavePhysics, RPMAvatar } from "@ravespaceio/rave-engine";
import MovementCharacter from "@ravespaceio/rave-engine/build/engine/src/engine/player/CharacterControllers/MovementCharacter";
import { Physics_Entity } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/Physics_Entity";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import PointClickController from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PointClickController';
import { PlayerCharacter_EntityM } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter_EntityM";
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper";
import { canUseActionKey } from "../utils/frontend";
import FirstPersonCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FirstPersonCameraController";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import FreeCamInputController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FreeCamInputController";
import { mapEmptyEntity } from "./_deprecated_player";
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player";
import { FOVEffector } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamManagerAddOns";
import { getSpace, Space } from "~/space/space";
import { getEngine } from "~/space/engine";
import { GuiHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiHelper";
import { Mesh, MeshPhongMaterial, TorusGeometry } from "three";
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useSpaceStore } from "~/store/space";

let playerChar: PlayerCharacter;

export function setupPlayerCharacter() {

	const space = getSpace()
	const spaceStore = useSpaceStore()
	const engine = getEngine()

	const camera = engine.player.camera
	camera.fov = 58
	camera.far = 500

	const playerCharacter = playerChar = new PlayerCharacter();
	playerCharacter.add(new MovementCharacter(engine.inputManager, engine.player.camera))
	playerCharacter.setAvatarByConfig(RPMAvatar.defaultMaleConfig)
	playerCharacter.stats.speed = 2.5
	if (!space.ENV.IS_PROD) playerCharacter.stats.speed *= 2
	playerCharacter.stats.maxAirJumps = 0
	engine.player.camControllerM.setPlayerCharacter_as_Target(playerCharacter)
	playerCharacter.add(new RaveNavigation.NavPlayerCharacter());

	// adding playerCharacter to scene and subscribing components
	engine.playModeScene.add(playerCharacter);
	engine.player.setLocalPlayer('playerCharacter', playerCharacter); //def
	playerCharacter.get<PlayerCharacter_EntityM>('entity_manager').changeEntity<Physics_Entity>('physics_entity');
	playerCharacter.get<PlayerCharacter_EntityM>('entity_manager').getEntity<Physics_Entity>('physics_entity').maxGroundAngle = 65;
	playerCharacter.get<PlayerCharacter_EntityM>('entity_manager').getEntity<Physics_Entity>('physics_entity').maxSnapSpeed = 80;


	setupAnimationInteractions(engine);
	setupCamControllers(engine);

	setTimeout(() => addPlayerCharacterToGUI(engine, space));
	// debugPhysicsEntity(engine);
}

function addPlayerCharacterToGUI(engine: Engine, space: Space) {
	const playerFolder = space.gui.getFolderOrCreate('Engine').addFolder('PlayerCharacter');
	const playerChar = engine.player.getLocalPlayer('playerCharacter');

	let enableInputTransform = false
	GuiHelper.addButton(playerFolder, () => {
		playerChar.transformInput(enableInputTransform)
		enableInputTransform = !enableInputTransform;
	}).name('Transform input with camera')


	let enableInput = false
	GuiHelper.addButton(playerFolder, () => {
		if (enableInput) playerChar.unblockInput()
		else playerChar.blockInput()
		enableInput = !enableInput;
	}).name('Block/Unblock input')

	const actionFolder = playerFolder.addFolder('Actions');
	actionFolder.add(playerChar.inputController.activeActions, 'jumping').name('Jumping')
	actionFolder.add(playerChar.inputController.activeActions, 'running').name('Running')

	GuiThreeHelper.addVector(playerFolder, playerChar.transform.position, { disable: true, listen: true }).title("world position")
	GuiThreeHelper.addVector(playerFolder, playerChar.transform.quaternion, { disable: true, listen: true }).title('world rotation')
	// adding player character stats
	Object.keys(playerChar.stats).forEach(playerStat => {
		if (typeof playerChar.stats[playerStat] !== 'object') {
			playerFolder.add(playerChar.stats, playerStat as any, 0, 10);
		}
	})

	const entitySelector = { currentEntity: 'simple_entity' };
	playerFolder.add(entitySelector, 'currentEntity', ['simple_entity', 'physics_entity', 'empty_entity']).onChange(e => {
		playerChar.get<PlayerCharacter_EntityM>('entity_manager')?.changeEntity(e)
	})

	let entityFolder: BigGui;
	playerChar.get<PlayerCharacter_EntityM>('entity_manager').onEntityChange.on(({ prevEntity, currentEntity }) => {
		entitySelector.currentEntity = currentEntity.name;
		if (currentEntity.name === 'physics_entity') {
			const physicsEntity = currentEntity as Physics_Entity;
			entityFolder = playerFolder.addFolder(physicsEntity.name);
			entityFolder.add(physicsEntity, 'maxGroundAngle', 0, 360);
			entityFolder.add(physicsEntity, 'maxSnapSpeed', 0, 100);

		}
		if (entityFolder && currentEntity.name !== 'physics_entity') {
			entityFolder.destroy();
		}
	})
}


function debugPhysicsEntity(engine: Engine) {
	const playerCharacter = engine.player.getLocalPlayer('playerCharacter');
	const entityM = playerCharacter.get<PlayerCharacter_EntityM>('entity_manager')

	engine.loop.register(dt => {
		if (engine.inputManager.getKeyboardButtonDown('p')) {
			if (entityM.currentEntity.name === 'simple_entity') {
				entityM.changeEntity('physics_entity');
			} else if (entityM.currentEntity.name === 'physics_entity') {
				entityM.changeEntity('simple_entity');
			}
		}
	})
}


function setupAnimationInteractions(engine: Engine) {
	engine.loop.register(dt => {
		if (engine.player.getInputController<PlayerCharacter>().isActive === false) return;
		if (engine.inputManager.getKeyboardButtonDown('1')) {
			engine.player.triggerPlayerAction('wave')
		} else if (engine.inputManager.getKeyboardButtonDown('2')) {
			engine.player.triggerPlayerAction('clapping', true)
		} else if (engine.inputManager.getKeyboardButtonDown('3')) {
			engine.player.triggerPlayerAction('cheering', true)
		} else if (engine.inputManager.getKeyboardButtonDown('4')) {
			engine.player.triggerPlayerAction('rave_dance_1', true)
		} else if (engine.inputManager.getKeyboardButtonDown('5')) {
			engine.player.triggerPlayerAction('rave_dance_4', true)
		} else if (engine.inputManager.getKeyboardButtonDown('6')) {
			engine.player.triggerPlayerAction('rave_dance_6', true)
		} else if (engine.inputManager.getKeyboardButtonDown('7')) {
			engine.player.triggerPlayerAction('rave_dance_3', true)
		} else if (engine.inputManager.getKeyboardButtonDown('8')) {
			engine.player.triggerPlayerAction('dance2', true)
		} else if (engine.inputManager.getKeyboardButtonDown('9')) {
			engine.player.triggerPlayerAction('joy_jump', true)
		}
		const playerCharacter = engine.player.getLocalPlayer('playerCharacter');
		// update entity_base to empty_entity
		if (playerCharacter.inputController.movingKeyPress() && engine.player.getCurrentEntity<PlayerCharacter>().name !== 'empty_entity') {
			engine.player.cancelCurrentAction();
		}
	})

	engine.inputManager.gamepad.on("buttondown", (btn) => {
		if (!canUseActionKey()) return;
		if (btn == "RB") { engine.player.triggerPlayerAction("dance1") }
		else if (btn == "LB") { engine.player.triggerPlayerAction("dance3") }
	})
}


function setupCamControllers(engine: Engine) {

	const space = getSpace()
	const spaceStore = useSpaceStore()


	const camera = engine.player.camera
	const fovAddOn = createFOVEffectorPlayerCharacter(engine);
	camera.fov = 58
	camera.far = 500


	// first person cam
	const first = engine.player.camControllerM.getController<FirstPersonCameraController>(CamControllersNames.FIRST_CAM)
	first.angularSpeed = 1.5
	first.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.2
		engine.player.camControllerM.offsetVector.y = 1.6
	})

	// third person cam
	const third = engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA)
	third.minVerticalAngle = -1
	third.maxVerticalAngle = 0.5
	third.desiredManualDistance = 3
	third.maxZoomOut = 3.5
	third.maxZoomIn = 0.5
	third.angularSpeed = 1.5
	third.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.3
		engine.player.camControllerM.offsetVector.y = 1.75
	})

	// free cam
	const freeCam = engine.player.camControllerM.getController<FreeCamInputController>(CamControllersNames.FREE_CAM)
	freeCam.speed = 2
	freeCam.angularSpeed = 0.2
	freeCam.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1
		engine.player.changeEntity<any>(mapEmptyEntity(engine.player.currentPlayerType));
	})
	camera.updateProjectionMatrix()
	engine.player.camControllerM.changeController(CamControllersNames.ORBIT_CAMERA)



	// point click controls
	const playerCharacter = getPlayerCharacter()
	const pointClick = new PointClickController(engine.inputManager, engine.player.camera, [])
	playerCharacter.add(pointClick)
	const pointGeo = new THREE.TorusGeometry(0.2, 0.01, 18, 18).rotateX(Math.PI / 2)
	const targetMarker = new THREE.Mesh(pointGeo, new THREE.MeshPhongMaterial({ color: "green" }))
	const hoverMarker = new THREE.Mesh(pointGeo, new THREE.MeshPhongMaterial({ color: "blue" }))
	space.loader.on("loaded", () => {
		const navmesh = space.loader.getLoaded("navmesh") as GLTF
		pointClick.intersectionMeshes = navmesh.scene.children
	})
	pointClick.whileOnPointClickBehaviour.on(() => {
		engine.player.camControllerM.goBehindPlayer(false);
	})

	watch(() => spaceStore.usePointJumpMode, (state) => {
		engine.inputManager.mouseInputTracker.usePointerLock = !state
		pointClick.isActive = state
		if (state) {
			pointClick.addTargetMarker(targetMarker)
			pointClick.addHoverMarker(hoverMarker)
			engine.inputManager.unBlockInput() // fix drag bug atfer used game pointerlock mvoe once
		} else {
			pointClick.removeMarkers()
		}
	}, { immediate: true })

	// spaceStore.usePointJumpMode = true

	/**
	 * PL Problem
	 * - wenn initial jumpPoint geht drag
	 * - sobald ich ein mal switche geht drag nimmer
	 */
}

function createFOVEffectorPlayerCharacter(engine: Engine) {
	const fovAddOn = new FOVEffector(engine.player.camera, engine.player.getLocalPlayer('playerCharacter'));
	engine.player.camControllerM.addCameraEffector(fovAddOn);
	fovAddOn.fovSpeed = 5;
	fovAddOn.fovRange = { minVal: 0.8, maxVal: 1.2 };
	engine.userData.fovAddOn = fovAddOn
	return fovAddOn
}


function getPlayerCharacter() {
	if (playerChar === undefined) {
		Logging.error('Player character is not setup')
	} else {
		return playerChar;
	}
}

export function getPointClickController() {
	return getPlayerCharacter().get<PointClickController>('player_pointClick');
}
