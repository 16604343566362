import { Object3D, Vector3 } from "three";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { materialObstacle } from "../../_lib/mechanic/navigation";
import Engine, { Logging, LoggingArea, RaveNavigation, RavePhysics } from "@ravespaceio/rave-engine";
import { GameObject } from "@ravespaceio/rave-engine/build/engine/src/engine/player/ObjectManagement/GameObject";
import { useGameStore } from "~/store/game";
import { setupPlatformQuiz } from "./platformQuiz";
import { lerp } from "three/src/math/MathUtils";

const minRespawnHeight = 5;

const initialPos = []
export const platforms: GameObject<any>[] = [];
export const platformIndexes: number[] = [];
export function setupPlatformMinigame(demoscene: Object3D) {

	const engine = getEngine();
	const space = getSpace();

	createPlatformsColliders(demoscene);
	createStaticColliders(demoscene);
	setupZoneInteraction();

	// move platforms according to the number fo questions answered
	trackQuestionState();
}




function createPlatformsColliders(scene: Object3D) {
	const platformMeshes = findObjects(scene, testUserData('ColID', 'platform'));
	platformMeshes.forEach((platformMesh, index) => {
		const platform = createKinematicColliderToMesh(platformMesh as THREE.Mesh, materialObstacle, index, 0.1);
		initialPos.push(platform.transform.object3D.position.clone());
		platforms.push(platform);
		platformIndexes.push(parseInt(platformMesh.userData.REID.split('platform')[1]) - 1);
	});

	// console.log(platformIndexes);

	setupPlatformQuiz()
}

function createStaticColliders(scene: Object3D) {
	const staticMeshes = findObjects(scene, testUserData('ColID', 'static'));
	staticMeshes.forEach((staticpMesh) => {
		createColliderStaticMesh(staticpMesh as THREE.Mesh, materialObstacle);
	});
}


function movePlatformsUp(index: number) {
	if (index > (platforms.length - 1)) return

	const remapIndex = platformIndexes.findIndex(value => value === index)

	platforms[remapIndex].get<RavePhysics.Collider<any>>('collider').collider.position.y = initialPos[remapIndex].y + 8;

}


function movePlatformToInitialPos(index: number) {
	if (index > (platforms.length - 1)) return
	// console.log(index);
	const remapIndices = [];

	const remapIndex = platformIndexes.findIndex(value => value === (index * 2))
	remapIndices.push(remapIndex);
	const remapIndex2 = platformIndexes.findIndex(value => value === (index * 2) + 1)
	remapIndices.push(remapIndex2);

	// console.log(remapIndices);

	const engine = getEngine();


	remapIndices.forEach(remapIndex => {
		const callbackId = engine.loop.register((dt) => {
			const platformYpos = platforms[remapIndex].get<RavePhysics.Collider<any>>('collider').collider.position.y;
			platforms[remapIndex].get<RavePhysics.Collider<any>>('collider').collider.position.y = lerp(platformYpos, initialPos[remapIndex].y, dt);
			if (platformYpos - initialPos[remapIndex].y <= 0.01) engine.loop.unregister(callbackId);
		})
	})
}


function trackQuestionState() {

	const gameStore = useGameStore();


	watch(() => gameStore.platformQuizState, (newVal, oldVal) => {
		for (let i = 0; i < newVal; i++) {
			movePlatformToInitialPos(i);
		}

		for (let i = platformIndexes.length - 1; i >= newVal * 2; i--) {
			movePlatformsUp(i);
		}



	}, { immediate: true })







}



function setupZoneInteraction() {
	const playerCharNav = getEngine().player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter');
	let callbacksIds = [];
	playerCharNav.events.on('switch', ({ prevZone, newZone }) => {
		let interactionId = '';
		Logging.trace(`Exiting: ${prevZone}, entering: ${newZone}`, LoggingArea.Space);


		if (newZone === 'transitionZone1' || newZone === 'transitionZone2') {
			disableConstrain(playerCharNav);

			callbacksIds.forEach(id => { clearRespawn(id) });
			callbacksIds = [];
			callbacksIds.push(miniGameLogic());

		} else if (prevZone === 'transitionZone1' || prevZone === 'transitionZone2') {
			enableNavConstrain(playerCharNav);
			callbacksIds.forEach(id => { clearRespawn(id) });
		}

	})
}


function disableConstrain(playerNav: RaveNavigation.NavPlayerCharacter) {
	playerNav.setActive(false);
	playerNav.constrainAxis = { x: undefined, y: undefined, z: undefined };
}

function enableNavConstrain(playerNav: RaveNavigation.NavPlayerCharacter) {
	playerNav.setActive(true);
	playerNav.constrainAxis = { x: 'x', y: undefined, z: 'z' };
}


function trackHeight(initialHeight: number) {
	const engine = getEngine();
	const currentHeight = engine.player.getPlayerPosition().y;
	const currentNavZone = engine.player.getNavigationComponent<any>().currentZone;
	let minRespawnH = 0;
	if (currentNavZone === 'transitionZone1') {
		minRespawnH = minRespawnHeight;
	} else if (currentNavZone === 'transitionZone2') {
		minRespawnH = minRespawnHeight + 5;
	}

	if (initialHeight - currentHeight >= minRespawnH) {
		respawnCharacter();
	}
}

function miniGameLogic() {
	const engine = getEngine();
	const initialHeight = engine.player.getPlayerPosition().y;
	const callbackId = engine.loop.register(() => {
		trackHeight(initialHeight);
	})
	return callbackId
}

export function respawnCharacter() {
	const space = getSpace();
	space.spawnManager.spawnAt('minigameRespawn');
}

function clearRespawn(id: string) {
	const engine = getEngine();
	engine.loop.unregister(id);
}


