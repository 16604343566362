import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import Engine, { HighlightCylinder, PlayerObject, RaveNavigation } from "@ravespaceio/rave-engine"
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useSpaceStore } from "~/store/space";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import * as THREE from "three"
import { clamp } from "three/src/math/MathUtils";
import { PlayerCharacter } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter";



export default function setupElevator() {

	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()


	// Doors Animation

	space.loader.onceAlready("loaded", () => {

		const obj = engine.scene.getObjectByName("Elevator")!
		obj.traverse((child) => { child.frustumCulled = false; child.matrixAutoUpdate = true })

		const gltf = space.loader.getLoaded("areas")
		const action = THREE.AnimationClip.findByName(gltf.animations, "ElevatorAction")

		const mixer = new THREE.AnimationMixer(obj)
		const clip = mixer.clipAction(action)
		clip.clampWhenFinished = true
		clip.reset().play()

		let t = 0
		engine.loop.register((dt) => {
			t = clamp(t + dt * (isInside ? 1 : -1), 0, action.duration - 0.001)
			mixer.setTime(t)
		})

		let isInside = false
		space.pciSystem.add({
			collider: new SphereCollider(obj, 6),
			onColliderEnter: () => { isInside = true },
			onColliderLeave: () => { isInside = false }
		})
	})

	// Screen Fade + TP
	function elevatorOnInteractPlayerObject(engine: Engine) {
		engine.player.getLocalPlayer("playerCharacter").get<RaveNavigation.NavPlayerCharacter>("navComponent_PlayerCharacter").jumpTo(new THREE.Vector3(-12.848, 11, -3.37))

	}
	let curtain = document.getElementById("spaceCanvas")

	engine.player.getNavigationComponent<PlayerCharacter>().events.on('switch', ({ prevZone, newZone }) => {
		if (newZone === 'elevator') {
			gsap.to(curtain, { opacity: 0, duration: 1, ease: Power2.easeInOut });
			gsap.delayedCall(1, elevatorOnInteractPlayerObject, [engine]);

		} else if (prevZone === 'elevator') {
			gsap.to(curtain, { opacity: 1, duration: 1, ease: Power2.easeInOut });
			gsap.killTweensOf(elevatorOnInteractPlayerObject);

		}
	})

}


