<template>
	<div class="row">
		<div class="l">Avatar</div>
		<div class="r">
			<div class="wrap">
				<div v-if="spaceStore.avatar.preview" class="avtarPreview" :style="{ backgroundImage: `url(${spaceStore.avatar.preview})` }">
					<div v-if="spaceStore.avatar.preview == 'loading'">
						<IconLoader />
					</div>
				</div>
				<div class="tgl auto" @click="spaceStore.showAvatarGenerator = true">Ändern</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts" setup>
import { useSpaceStore } from '~/store/space';
import { getSpace } from '~/space/space';

const spaceStore = useSpaceStore()
</script>

<style scoped lang="scss">
@import "assets/css/variables";


.avtarPreview {
	width: 40px;
	border-radius: $radius;
	height: 40px;
	border: 1px solid $main;
	background-size: 300%;
	background-position: 44% top;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
