<template>
	<div class="tutorial">
		<template v-if="tutorial == 0">
			<h1>Tutorial</h1>
			<h3>Einführung in das Mining</h3>
			<p>Beim Mining geht es darum, Transaktionen zu verarbeiten und sie zu Blöcken zusammenzufassen, die dann an die Blockchain angehängt werden. Die Blockchain ist eine Kette von bereits erstellten Blöcken, die alle Transaktionen speichert.</p>
			<h3>Ziel des Spiels</h3>
			<p>Deine Aufgabe in der Simulation ist es, in 90 Sekunden so viele Blöcke wie möglich zu erstellen. Du kannst dabei helfen, offene Transaktionen zu verarbeiten. Wenn du es schaffst, 3 Blöcke an die Blockchain anzuhängen, bekommst du als Belohnung eine Energiezelle.</p>
			<div class="btn--wrapper">
				<div class="btn" @click="tutorial = 0; gameStore.blockchainGameState = 0">Zurück</div>
				<div class="btn--dark" @click="tutorial = 1">Weiter</div>
			</div>

		</template>
		<template v-if="tutorial == 1">
			<div class="demo-nonce">
				<div class="l">
					<h1 style="text-align: left;">Demo</h1>
					<h3>Erklärung der Blockstruktur</h3>
					<p>Jeder Block hat einen einzigartigen Code – den sogenannten Blockhash. Dieser Blockhash wird durch die Kombination aller Elemente im jeweiligen Block generiert. Neben vielen anderen Informationen besteht ein Block aber im Wesentlichen aus drei wichtigen Elementen: dem Blockhash des vorherigen Blocks, den Transaktionen und einer geheimnisvollen Nummer namens "Nonce".
						Ändert man nur ein Zeichen innerhalb des Blocks, verändert sich der komplette Blockhash.<br>Probiere es gerne aus.</p>

					<div class="btn--wrapper">
						<div class="btn" @click="tutorial = 0;">Zurück</div>
						<div class="btn--dark" @click="tutorial = 2">Weiter</div>
					</div>

				</div>
				<div class="r">
					<div class="area">
						<h4>Blockhash vorheriger Block</h4>
						<input type="text" value="072XgG27dH" readonly>
						<h4>Transaktionen</h4>
						<textarea name="" id="" cols="20" rows="5" v-model="transaction"></textarea>
						<h4>Nonce (0-9)</h4>
						<input type="text" pattern="\d*" maxlength="1" minlength="1" v-model="nonce">
						<h4>Aktueller Blockhash</h4>
						<input type="text" readonly v-model="blockhash" :class="animate ? animate : ''">

						<div class="btn green" :class="isLoading ? 'disabled' : ''" @click="generate()">Generiere Blockhash
							<IconLoader v-if="isLoading" />
						</div>
					</div>
				</div>
			</div>

		</template>

		<template v-if="tutorial == 2">
			<h1>Willst du mehr über die Funktionsweise eines Hashes erfahren?</h1>
			<div class="btn--wrapper">
				<div class="btn" @click="tutorial = 3">Ja</div>
				<div class="btn" @click="tutorial = 5">Nein</div>
			</div>
			<div class="btn--back" @click="tutorial = 1;">Zurück</div>
		</template>

		<template v-if="tutorial == 3">
			<h3>Funktionsweise Hash</h3>
			<p>Um einen Block zu erstellen, muss der richtige Blockhash gefunden werden.</p>
			<p>Der Blockhash ist eine einzigartige Zeichenkette, welche nur durch die richtige Kombination aller Daten im Block generiert werden kann. Eine wesentliche Eigenschaft von Hashfunktionen ist, dass sie nicht rückwärts gerechnet werden können. Man kann also mit den Daten einen Hash ausrechnen. Man kann mit dem Hash aber nicht mehr die Daten rekonstruieren. Ein sehr einfaches Beispiel für eine Hashfunktion ist die Quersumme:</p>
			<p>Beispiel: Die Kombination der Zahlen 1, 2, 3 und 4 hat die Quersumme 10, da 1+2+3+4=10</p>
			<p>Beim Anhängen eines neuen Blocks muss nun ein passender Hash auf Basis der existierenden Chain gefunden werden. Während alle bisherigen Daten von der Chain vorgegeben sind, kannst du einen kleinen Teil des Inputs selbst bestimmen, die sogenannte Nonce. Durch Ändern dieser Nonce ergibt sich ein neuer Hash. In unserem Beispiel ist das so, also könntest du die letzte Ziffer der Zahl frei bestimmen. Wenn wir z.B. die 4 durch eine 5 ersetzen, erhalten wir die Quersumme 11.</p>
			<p>Beispiel: Die Zahl 1235 hat die Quersumme 11, da 1+2+3+5=11</p>
			<p>Damit dein neuer Block von anderen Minern akzeptiert wird, muss er immer bestimmte Vorgaben erfüllen. In der Praxis nutzt man komplizierte Hashverfahren, die eine Zeichenfolge aus Buchstaben und Zahlen ergeben. In unserem Spiel ist es, genau wie bei Bitcoin, das Ziel, einen Hash mit führenden Nullen zu finden. Der Einfachheit halber beschränken wir uns aber auf eine einzelne führende Null. Da unser Hash zu den Daten passen muss und wir eine Hashfunktion nicht rückwärts rechnen können, bleibt uns nichts anderes übrig, als den Hash durch Ausprobieren verschiedener Inputs zu finden. Wir variieren also die Nonce, da alles andere vorgegeben ist.</p>
			<div class="btn--wrapper">
				<div class="btn" @click="tutorial = 2;">Zurück</div>
				<div class="btn--dark" @click="tutorial = 5">Weiter</div>
			</div>

		</template>

		<template v-if="tutorial == 4">
			<h3>Bedeutung der Nonce</h3>
			<p>Vom Netzwerk werden nur Blockhashes akzeptiert, welche besonderen Anforderungen entsprechen. In unserem Fall werden nur Blöcke mit einer führenden Null akzeptiert. Stell es dir wie eine Art Rätsel vor.  Unser Ziel ist es, möglichst schnell den richtigen Blockhash zu finden und das Rätsel zu lösen.</p>
			<p>Da der Blockhash des vorherigen Blocks sowie die Transaktionen nicht manipuliert werden dürfen, kannst du <strong>lediglich die Nonce</strong> verändern, um den richtigen Blockhash zu finden.</p>
			<p>Um es zu lösen, musst du die richtige Nummer im Block, also die Nonce, finden, um so den Blockhash zu verändern. </p>
			<p>Normalerweise ist das ganze sehr komplex. Um es dir nicht ganz so schwer zu machen, brauchst du in unserer Simulation nur zwischen 1 bis 10 wählen.</p>
			<p>Probiere es gerne aus.</p>
			<div class="btn--wrapper">
				<div class="btn" @click="tutorial = 3;">Zurück</div>
				<div class="btn--dark" @click="tutorial = 5">Weiter</div>
			</div>
		</template>

		<template v-if="tutorial == 5">
			<div class="demo-nonce">
				<div class="l">
					<h3>Bedeutung der Nonce</h3>
					<p>Vom Netzwerk werden nur Blockhashes akzeptiert, welche besonderen Anforderungen entsprechen. <strong>In unserem Fall werden nur Blöcke mit einer führenden Null akzeptiert</strong>. Stell es dir wie eine Art Rätsel vor.  Unser Ziel ist es, möglichst schnell den richtigen Blockhash zu finden und das Rätsel zu lösen.</p>
					<p>Da der Blockhash des vorherigen Blocks sowie die Transaktionen nicht manipuliert werden dürfen, kannst du <strong>lediglich die Nonce</strong> verändern, um den richtigen Blockhash zu finden.</p>
					<p>Um es zu lösen, musst du die richtige Nummer im Block, also die Nonce, finden, um so den Blockhash zu verändern. </p>
					<p>Normalerweise ist das ganze sehr komplex. Um es dir nicht ganz so schwer zu machen, brauchst du in unserer Simulation nur zwischen 1 bis 10 wählen.</p>
					<p>Probiere es gerne aus.</p>
					<div class="btn--wrapper">
						<div class="btn" @click="tutorial = 4;">Zurück</div>
						<div class="btn--dark" @click="tutorial = 6">Weiter</div>
					</div>
				</div>
				<div class="r">
					<div class="area">
						<h4>Blockhash vorheriger Block</h4>
						<input type="text" value="072XgG27dH" readonly>
						<h4>Transaktionen</h4>
						<textarea name="" id="" cols="20" rows="5" :value="transaction" readonly></textarea>
						<h4>Nonce (0-9)</h4>
						<input type="text" pattern="\d*" maxlength="1" minlength="1" v-model="nonce2">
						<h4>Aktueller Blockhash</h4>

						<div class="wrap" :class="animate ? animate : ''">
							<input type="text" readonly v-model="blockhash2" :class="blockhash2Right ? 'green' : ''">
						</div>


						<div class="btn green" :class="isLoading ? 'disabled' : ''" @click="generate2()">Generiere Blockhash
							<IconLoader v-if="isLoading" />
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-if="tutorial == 6">
			<h1>Funktion Blockchain</h1>
			<p>Aber wozu jetzt überhaupt die Blockchain? Bei der Erstellung neuer Blöcke wird immer der Blockhash des vorherigen Blocks übertragen und wird somit Teil des neuen Blocks und dessen Code. Will ein Angreifer eine ältere Transaktion manipulieren, müsste er alle Blöcke bis zum aktuellen Block anpassen. Das macht das ganze System so sicher.</p>
			<div class="scroller">
				<img src="/dek/img/blocks.png">
			</div>
			<div class="btn--wrapper">
				<div class="btn" @click="tutorial = 5;">Zurück</div>
				<div class="btn--dark" @click="tutorial = 7">Weiter</div>
			</div>
		</template>

		<template v-if="tutorial == 7">
			<h1>Gewinnen des Spiels</h1>
			<p>Nun genug Theorie. Du hast genau eine Minute Zeit, um so viele Blöcke wie möglich zu erstellen, indem du die Nonce änderst und das Rätsel löst. Wenn du es schafft, 3 oder mehr Blöcke in einer Minute zu generieren, bekommst du als Belohnung eine Energiezelle.</p>
			<div class="btn--wrapper">
				<div class="btn--dark" @click="tutorial = undefined; gameStore.blockchainGameState = 2;">Zum Spiel</div>
			</div>
			<div class="btn--back" @click="tutorial = 6;">Zurück</div>
		</template>

	</div>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'

const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()

const hashes = ["01b2c3d4", "e5f6g7h8", "i9j0k1l2", "m3n4o5p6", "q7r8s9t0", "u1v2w3x4", "y5z6A7B8", "C9D0E1F2", "G3H4I5J6", "K7L8M9N0"];
const tutorial = ref(0)
const oldblock = ref("8qs9hd2")
const transaction = ref("A sendet 5 Coins an B\nB sendet 2 Coins an C\nC sendet 5 Coins an E")
const nonce = ref(0)
const nonce2 = ref(5)
const blockhash = ref("" as any)
const isLoading = ref(false)
const blockhash2 = ref(hashes[5])
const blockhash2Right = ref(undefined)
const animate = ref("")


generate()
function generate() {
	isLoading.value = true
	setTimeout(() => {
		const hash = hashCode(transaction.value + (nonce.value * 9))
		oldblock.value = blockhash.value
		blockhash.value = hash
		isLoading.value = false
		animate.value = "neutral"
		setTimeout(() => {
			animate.value = ""
		}, 1000);
	}, 1000);
}

function generate2() {
	isLoading.value = true
	setTimeout(() => {
		// const hash = hashes[nonce2.value]
		let hash = hashCode(transaction.value + (nonce2.value * 9))
		isLoading.value = false
		if (nonce2.value == 5) {
			hash = "0" + hash
			blockhash2Right.value = true
			animate.value = "right"
		} else {
			blockhash2Right.value = undefined
			animate.value = "wrong"
		}
		blockhash2.value = hash
		setTimeout(() => {
			animate.value = ""
		}, 1000);
	}, 1000);
}

function hashCode(str) {
	let hash1 = 0;
	let hash2 = 0;

	for (let i = 0, len = str.length; i < len; i++) {
		let chr = str.charCodeAt(i);
		hash1 = (hash1 << 5) - hash1 + chr;
		hash2 = (hash2 << 3) - hash2 + chr;
	}

	hash1 |= 0; // Convert to 32-bit integer
	hash2 |= 0; // Convert to 32-bit integer

	let combinedHash = (hash1 << 16) ^ hash2;

	let finalHash = '';

	for (let i = 0; i < 12; i++) {
		// Modifying the value to ensure it's within 0-35 (for base-36 encoding)
		let value = Math.abs(combinedHash % 36);
		let currentChar;

		if (value < 10) {
			// 0-9
			currentChar = String.fromCharCode(48 + value);
		} else {
			// a-z (lowercase)
			currentChar = String.fromCharCode(87 + value);
		}

		finalHash += currentChar;

		// Rotate bits of combinedHash to use different parts of the hash
		combinedHash = (combinedHash >> 1) | ((combinedHash & 1) << 31);
	}

	return finalHash;
}





</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.right {
	animation: pulse 0.5s ease 0s infinite normal forwards;
}

.wrong {
	animation: horizontal-shaking 0.5s ease 0s infinite normal forwards;

	input {
		background: $red;
	}
}

.tutorial {
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	h1 {
		text-align: center;
		max-width: 600px;
		margin-bottom: 12px;
	}

	h3 {
		margin-bottom: 12px;
		font-weight: 700;
	}

	p {
		max-width: 500px;
		margin-bottom: 30px;
		text-align: justify;

		strong {
			font-weight: 700
		}
	}

	img {
		max-width: 100%;
		width: 800px;
		margin: 30px 0
	}
}

.scroller {
	width: 100%;
	overflow-x: scroll;

	img {
		max-width: none;
		width: 1500px
	}
}

.btn--wrapper {
	@extend .flex-center;
	flex-wrap: wrap;
	margin: 20px 0;

	.btn {
		margin: 8px;
	}
}

.btn--back {
	font-size: 14px;
	font-weight: 600;
	text-decoration: underline;
	padding: 4px;
	text-align: center;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
}

.demo-nonce {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.l,
	.r {
		width: calc(50% - 10px);
	}

	.r {
		display: flex;
		justify-content: flex-end;
	}
}

.area {
	background: $blue;
	border-radius: $radius;
	padding: 20px 30px;
	@extend .flex-center;
	flex-direction: column;
	margin: 20px 0;
	width: 400px;
	max-width: 100%;

	.wrap {
		width: 100%;
	}

	input,
	textarea {
		padding: 5px 12px;
		width: 100%;
		margin-bottom: 20px;
		border: none;
		outline: none;
		border-radius: 2px;

		&:read-only {
			opacity: 0.5;
		}

		&.green {
			background: $green;
		}
	}

	h4,
	p {
		color: white;
		text-align: left;
		width: 100%;
		margin-bottom: 6px;
	}

	h4 {
		font-weight: 600;
	}

	.nonce,
	.blockhash {
		width: 100px;
		border-radius: $radius;
		border: 1px solid white;
		text-align: center;
		margin-top: 12px;
		padding: 4px;
	}

	.blockhash {
		width: 150px;
		font-weight: 700;

		p {
			font-weight: 700;
			letter-spacing: 1px;

			::v-deep(span) {
				font-weight: 700;
				color: $green;
			}

		}

		&.right {
			animation: pulse 0.5s ease 0s infinite normal forwards;
		}

		&.wrong {
			animation: horizontal-shaking 0.5s ease 0s infinite normal forwards;
		}
	}

	::v-deep(.wrong) {
		font-weight: 700;
		letter-spacing: 1px;
		color: $red !important;
	}


	.btn {
		margin: 20px 0;
		font-size: 14px;
		padding: 10px 20px;
		width: 100%
	}

	.numpad {
		@extend .flex-center;
		flex-wrap: wrap;
		width: 150px;

		span {
			width: 40px;
			height: 40px;
			border-radius: 4px;
			margin: 4px;
			background: white;
			@extend .flex-center;
			color: $blue;
			cursor: pointer;
			font-weight: 700;
			opacity: 0.8;
			transition: ease 0.2s;

			&:hover {
				opacity: 1;
				transform: scale(1.05)
			}

			&.active {
				background: $green;
			}
		}
	}
}

.neutral {
	animation: pulse 0.5s ease 0s infinite normal forwards;
}

.blocks {
	.wrapper {
		@extend .flex-center;

		span {
			width: 30px;
			height: 20px;
			background: white;
			border-radius: 2px;
			@extend .flex-center;
			margin: 3px;
			font-size: 12px;
			font-weight: 700;
			opacity: 0.5;

			&:first-child {
				opacity: 1;
			}
		}
	}
}

@media(max-width:800px) {
	.demo-nonce {
		width: 100%;
		display: flex;
		flex-direction: column;

		.l,
		.r {
			width: 100%;
		}

		.r {
			display: flex;
			justify-content: center;
		}
	}
}
</style>
