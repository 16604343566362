import Engine, { Logging, LoggingArea, RaveNavigation, RPMAvatar } from "@ravespaceio/rave-engine";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { Object3D, Mesh, Vector3, SphereGeometry, MeshBasicMaterial } from "three"
import SpawnManager, { NavigationControllerTypes } from "../manager/SpawnManager";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { getLsItem, setLsItem } from "../utils/storeHelper";
import { createNavLinkDebugSphere, debugNavMeshPerimeter, debugNavZoneNames } from "../utils/navigationDebug";
import * as CANNON from "cannon-es"
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player";
import { useGameStore } from "~/store/game";
import PointClickController from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PointClickController';


export const materialObstacle = new CANNON.Material("obstacle");


export function setupNavigation(scene: Object3D) {

	const space = getSpace()
	const engine = getEngine()
	// const pointClickController = engine.player.getLocalPlayer('playerCharacter').get<PointClickController>('player_pointClick');
	// setup zones
	const zones = findObjects(scene, testUserData("NavmeshID"))
	zones.forEach((zoneMesh) => {
		const geo = (zoneMesh as Mesh).geometry
		const zoneName = zoneMesh.userData.NavmeshID
		engine.navigation.navigationComponentM.addZone(geo, zoneName);
		// pointclick
		// pointClickController.intersectionMeshes.push(zoneMesh);
		// colliders
		const navGround = createColliderStaticMesh(zoneMesh.clone() as Mesh, materialObstacle);
		onColliderEventUpdateZone(navGround);
	})
	Logging.trace(`Navigation zones: ${engine.navigation.getActiveZones()}`, LoggingArea.Space)

	// navLinks - the setup has to be call after addZone() because it needs the zones to be created in order to work
	engine.navigation.navLinkManagerM.createNavLinks(0.05);
	// ------------------------------------------------- debug NavLinks Visuals and debugui -----------------------------------------
	navigationdebug_GUI();

	// camera nav
	const camNav = new RaveNavigation.NavCamComponent(engine.navigation, { constrainedObject: engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA) })
	engine.navigation.navigationComponentM.addNavigationComponent<NavigationControllerTypes>('camNav', camNav);
	engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, RaveNavigation.NavCamComponent>('camNav').setNavigationZones(engine.navigation.getActiveZones());

	camNav.setActive(false); // enable it when the error is fixed

	// spawns
	const spawnManager = space.spawnManager
	spawnManager.parseScene(scene)
	Logging.trace(`Spawn points: ${spawnManager.getSpawnList()}`, LoggingArea.Space)

	if (engine.player.currentPlayerType === 'playerObject') {
		const playerNav = new RaveNavigation.NavPlayeableObjComponent(engine.navigation, { constrainedObject: engine.player.getLocalPlayer("playerObject") });
		engine.navigation.navigationComponentM.addNavigationComponent<NavigationControllerTypes>('playerNav', playerNav);
		engine.navigation.navigationComponentM.getNavigationComponent<NavigationControllerTypes, RaveNavigation.NavPlayeableObjComponent>('playerNav').setNavigationZones(engine.navigation.getActiveZones());
		spawnManager.playerNavigationComponent = playerNav;
	} else if (engine.player.currentPlayerType === 'playerCharacter') {

		const playerCharNav = setupNavigationPlayerCharacter()
		spawnManager.playerNavigationComponent = playerCharNav;
		// define interaction
		const materialPlayer = engine.player.getLocalPlayer('playerCharacter').physicMaterial;
		const contactMaterial = new CANNON.ContactMaterial(materialPlayer, materialObstacle, { friction: 0.0, restitution: 0.0 });
		engine.physics.world.addContactMaterial(contactMaterial);
	}


	const urlSpawn = space.browser.urlParameter.get("spawnAt")
	if (urlSpawn && spawnManager.hasSpawn(urlSpawn)) {
		spawnManager.defaultSpawnName = urlSpawn
		Logging.trace(`Default spawn point set to ${urlSpawn}`, LoggingArea.Space)
	}
	space.spawnManager.spawnDefault(new Vector3((Math.random() - 0.5), 2, (Math.random() - 0.5)));

	// zones to store event
	configureZoneTracking();
	// spawn default in case of falling out of the map
	safeNet();
	setupVIPZone();

}


function setupNavigationPlayerCharacter() {
	const engine = getEngine();
	const playerCharNav = engine.player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter')
	playerCharNav.setNavigationZones(engine.navigation.getActiveZones());
	return playerCharNav
}



function navigationdebug_GUI() {
	const engine = getEngine();
	const space = getSpace();
	const navigationFolder = space.gui.getFolderOrCreate('Engine').getFolderOrCreate('Navigation');
	if (!space.ENV.IS_PROD) {
		debugNavMeshPerimeter(engine.navigation.pathfinder.navMeshPerimeter, 0.2, navigationFolder, engine.scene, true);
		createNavLinkDebugSphere(engine.navigation.navLinkManagerM.getAllNavLinks(), navigationFolder, engine.scene, true);
		debugNavZoneNames(navigationFolder, engine.navigation);
	}
}


function configureZoneTracking() {
	const engine = getEngine();
	const gameStore = useGameStore();
	const playerCharNav = engine.player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter');

	playerCharNav.events.on('switch', ({ prevZone, newZone }) => {
		gameStore.navZone = newZone;
	})


}


function safeNet() {
	const engine = getEngine();
	const space = getSpace();
	engine.loop.register(dt => {

		if (engine.player.getPlayerPosition().y < -5) {
			space.spawnManager.spawnDefault()
		}
	})

}

function setupVIPZone() {
	const engine = getEngine();
	const navPlayerChar = engine.player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter')

	const gameStore = useGameStore();

	const stageNavLink = getNavLinkByName("mainStage");
	stageNavLink.setActive(false);
	watch(() => gameStore.canEnterStage, (value) => {
		if (value === false) {
			navPlayerChar.prohibitedZones.push("mainStage")
		} else {
			navPlayerChar.prohibitedZones = [];
		}
		stageNavLink.setActive(value);
	}, { immediate: true })

}

function getNavLinkByName(name: string) {

	const engine = getEngine();
	const navLinks = engine.navigation.navLinkManagerM.getAllNavLinks();
	const navLink = navLinks.filter(navLink => navLink.zoneId.includes(name));
	if (navLink.length > 0) {
		return navLink[0];
	} else {
		Logging.warn(`NavLink with name ${name} not found`, LoggingArea.Space);
		return null;
	}
}


function setupLsPlayerReentry() {
	const space = getSpace()
	const engine = getEngine()
	const urlSpawn = space.browser.urlParameter.get("spawnAt")
	setInterval(() => {
		setLsItem("lougoutPosition__" + space.project.id, engine.player.getPlayerPosition())
		// setLsItem("lougoutDirection__" + space.project.spaceId, engine.player.getPlayer().getWorldDirection(tempVec))
	}, 5000)
	let outpos = getLsItem("lougoutPosition__" + space.project.id)
	let outdir = getLsItem("lougoutDirection__" + space.project.id)
	if (outpos /*&& outdir */ && !urlSpawn) {
		const spawn = new Object3D()
		spawn.position.copy(outpos)
		// spawn.lookAt(outdir) // to do falsch
		space.spawnManager.spawnAtObject(spawn)
	} else {
		space.spawnManager.spawnDefault(new Vector3((Math.random() - 0.5), 0, (Math.random() - 0.5)))
	}
}

