<template>
	<Transition name="fade">
		<div v-if="hintTop.title" @click="hintClicked()" class="hint-top">
			<p style="font-size: 18px;">{{ hintTop.title }}</p>
			<p v-if="hintTop.text" class="hintSubTex">{{ hintTop.text }}</p>
			<p v-else-if="MyEnv.$gamepadActive" class="hintSubTex btn-image">Press <img src="/SpaceShared/img/buttons/y.png">to {{ actionKeyworld }}</p>
			<p v-else-if="MyEnv.$isMobile" class="hintSubTex">Drücke zum {{ actionKeyworld }}</p>
			<p v-else class="hintSubTex"><span>LEERTASTE</span> zum {{ actionKeyworld }}</p>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';

const space = getSpace()
const spaceStore = useSpaceStore()

const { hintTop } = storeToRefs(spaceStore)
const actionKeyworld = ref(hintTop.value.actionKeyworld || "interagieren")

function hintClicked() {
	space.pciSystem.triggerNearestInteract()
}
</script>

<style scoped lang="scss">
@import "assets/css/variables";

.hintSubTex {
	font-size: 12px;
	margin-top: 6px;

	span {
		background: $main;
		color: $dark;
		font-weight: 700;
		padding: 2px 7px 2px 9px;
		margin-right: 1px;
		border-radius: 4px;
	}
}

.btn-image {
	@extend .flex-center;

	img {
		width: 20px;
		height: auto;
		margin: 0 4px;
		transform: translateY(-1px);
	}
}

.hint-top {
	position: fixed;
	z-index: 10;
	right: 20px;
	bottom: 90px;
	display: inline-block;
	background: $darkTransparent;
	border: $stroke solid $lineColor;
	border-radius: $radius;
	@extend .flex-center;
	padding: 14px 23px;

	.hintSubTex {
		margin: 0;
	}

	p {
		font-weight: 700;
		text-transform: uppercase;
		color: $main;

		span {
			margin-right: 3px;
		}
	}
}

@media(max-width:800px) {
	.hint-top {
		right: inherit;
		left: 30px;
		bottom: 165px;
		height: 26px;
		padding: 4px 8px;
		border: none;

		p {
			font-size: 12px;
			text-transform: none;
		}
	}
}
</style>
