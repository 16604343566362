<template>
	<div class="row">
		<div class="l">Name</div>
		<div class="r">
			<div class="wrap">
				<input v-model="userNameModel" maxlength="14" :class="['input', !isLegal && 'illegal']" type="text">
				<div :class="['tgl', 'auto', !isLegal && 'illegal']" @click="saveName()">Ändern</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts" setup>
import { onlyLetters, validateText } from '~/assets/js/text';
import { setName } from "~/space/_lib/mechanic/multiplayer";
import { useSpaceStore } from '~/store/space';

const playerName = useSpaceStore().playerName;
const userNameModel = ref(playerName as string);

const isLegal = computed(() => {
	let name = userNameModel.value
	name = name.trim();
	return (validateText(name) && onlyLetters(name));
})

function saveName() {
	useSpaceStore().playerName = userNameModel.value.trim()
}

</script>

<style lang="scss">
input.illegal {
	border-color: rgb(255, 75, 59);
}

div.tgl.illegal {
	border-color: rgb(255, 75, 59);
	opacity: 0.2;
	pointer-events: none;
}
</style>
