<template>
	<SpaceModal :show=show @closed="close()" :class="gameStore.blockchainGameState == 2 ? 'blockchain' : ''">

		<img src="/dek/img/bot/render.png" class="bot" v-if="gameStore.blockchainGameState == 0">
		<div class="content">


			<div class="intro" v-if="gameStore.blockchainGameState == 0">
				<h1>MasterMiner</h1>
				<p>Beim Mining geht es darum, Transaktionen zu verarbeiten und zu Blöcken zusammenzufassen. Diese Blöcke wiederum werden an eine Kette von bereits erstellten Blöcken angefügt. Das ist die sogenannte Blockchain. Dieser gesamte Prozess wird als Mining bezeichnet und durch diese Technik ist es unfassbar schwer, Transaktionen später zu manipulieren. 
					In der Simulation geht es darum, möglichst viele Blöcke in einer Minute zu erstellen.</p>
				<p>Kannst du dabei helfen, die offenen Transaktionen zu verarbeiten? Wenn du mindestens 3 Blöcke schaffst, bekommst du als Belohnung eine Energiezelle.</p>
				<div class="btn--wrapper">
					<div class="btn--dark" @click="gameStore.blockchainGameState = 1">Tutorial</div>
					<div class="btn" @click="gameStore.blockchainGameState = 2">Starten</div>
				</div>
			</div>

			<SpaceGameBlockainTutorial v-show="gameStore.blockchainGameState == 1" />

			<SpaceGameBlockchain v-if="gameStore.blockchainGameState == 2" />

			<SpaceGameBlockchainOutro v-if="gameStore.blockchainGameState == 3" />

		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()
const { showBlockchainModal } = storeToRefs(spaceStore)
const show = computed(() => !!showBlockchainModal.value)

const { blockchainGameState } = storeToRefs(gameStore) // 0 = intro, 1 = tutorial, 2 = game, 3 = endscreen


function close() {
	showBlockchainModal.value = undefined
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.bot {
	position: absolute;
	width: 210px;
	height: auto;
	bottom: 11px;
	right: 0px;
}

.content {
	height: auto;
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	.btn--wrapper {
		@extend .flex-center;
		flex-wrap: wrap;
		margin: 20px 0;

		.btn {
			margin: 8px;
		}
	}

	.intro {
		@extend .flex-center;
		flex-direction: column;

		p {
			text-align: center;
			margin-top: 12px;
			text-align: justify;
			max-width: 500px;
		}
	}
}

@media(max-width:900px) {
	.bot {
		display: none;
	}
}
</style>
