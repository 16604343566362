import Engine, { RPMAvatar } from "@ravespaceio/rave-engine";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import FirstPersonCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FirstPersonCameraController";
import FreeCamInputController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/FreeCamInputController";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import { FOVCamEffect } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamManagerAddOns";
import { getEngine } from "~/space/engine";
import PlayerObject, { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { canUseActionKey } from "../utils/frontend";
import { getSpace } from "~/space/space";
import { GuiEngine } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngine";
import { GuiPlayer } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiPlayer";
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player";


export function setupPlayer() {
	const space = getSpace()
	const engine = getEngine()



	// player object
	const playerObj = new PlayerObject(engine);
	engine.player.setLocalPlayer('playerObject', playerObj);
	engine.scene.add(playerObj);
	engine.loop.add(playerObj);
	engine.player.camControllerM.setTargetObject(playerObj);
	playerObj.setAvatarByConfig(RPMAvatar.defaultMaleConfig)
	playerObj.inputController.emulatedRunning = false
	playerObj.stats.speed = 13.2
	if (!space.ENV.IS_PROD) playerObj.stats.speed *= 2
	setTimeout(() => GuiPlayer.addPlayer(space.gui.getFolderOrCreate("Engine"), playerObj))

	// setupGestures
	setupAnimationInteractions(engine);
	// cam controllers initial values and events
	setupCamControllers(engine);

}
function setupCamControllers(engine: Engine) {
	const camera = engine.player.camera
	const fovAddOn = createFOVEffectorPlayerObj(engine);
	camera.fov = 68
	camera.far = 500

	// cam controllers

	// first person cam
	const first = engine.player.camControllerM.getController<FirstPersonCameraController>(CamControllersNames.FIRST_CAM)
	first.angularSpeed = 1.5

	first.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.2
		engine.player.camControllerM.offsetVector.y = 1.6
	})

	// third person cam
	const third = engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA)
	third.minVerticalAngle = -1
	third.maxVerticalAngle = 0.5
	third.desiredManualDistance = 3
	third.maxZoomOut = 3.5
	third.maxZoomIn = 0.5
	third.angularSpeed = 1.5
	third.camEvents.on("enter", () => {
		fovAddOn.desiredrunningMultiplier = 1.3
		engine.player.camControllerM.offsetVector.y = 1.75
	})

	// free cam

	const freeCam = engine.player.camControllerM.getController<FreeCamInputController>(CamControllersNames.FREE_CAM)
	freeCam.speed = 2
	freeCam.angularSpeed = 0.2

	freeCam.camEvents.on("enter", () => {

		fovAddOn.desiredrunningMultiplier = 1
		engine.player.changeEntity<any>(mapEmptyEntity(engine.player.currentPlayerType));
	})

	camera.updateProjectionMatrix()

	// default first person
	engine.player.camControllerM.changeController(CamControllersNames.FIRST_CAM)

}


function createFOVEffectorPlayerObj(engine: Engine) {
	const fovAddOn = new FOVCamEffect(engine.player.camera, engine.player.getLocalPlayer('playerObject'));
	engine.player.camControllerM.addCameraEffector(fovAddOn);
	fovAddOn.fovSpeed = 5;
	fovAddOn.fovRange = { minVal: 0.8, maxVal: 1.2 };
	engine.userData.fovAddOn = fovAddOn
	return fovAddOn
}




function setupAnimationInteractions(engine: Engine) {

	engine.loop.register(dt => {

		if (engine.inputManager.getKeyboardButtonDown('1')) {
			engine.player.triggerPlayerAction('wave')
		} else if (engine.inputManager.getKeyboardButtonDown('2')) {
			engine.player.triggerPlayerAction('clapping', true)
		} else if (engine.inputManager.getKeyboardButtonDown('3')) {
			engine.player.triggerPlayerAction('cheering', true)
		} else if (engine.inputManager.getKeyboardButtonDown('4')) {
			engine.player.triggerPlayerAction('rave_dance_1', true)
		} else if (engine.inputManager.getKeyboardButtonDown('5')) {
			engine.player.triggerPlayerAction('rave_dance_4', true)
		} else if (engine.inputManager.getKeyboardButtonDown('6')) {
			engine.player.triggerPlayerAction('rave_dance_6', true)
		} else if (engine.inputManager.getKeyboardButtonDown('7')) {
			engine.player.triggerPlayerAction('rave_dance_3', true)
		} else if (engine.inputManager.getKeyboardButtonDown('8')) {
			engine.player.triggerPlayerAction('dance2', true)
		} else if (engine.inputManager.getKeyboardButtonDown('9')) {
			engine.player.triggerPlayerAction('joy_jump', true)
		}

	})

	engine.inputManager.gamepad.on("buttondown", (btn) => {
		if (!canUseActionKey()) return;
		if (btn == "RB") { engine.player.triggerPlayerAction("dance1") }
		else if (btn == "LB") { engine.player.triggerPlayerAction("dance3") }
	})


}



export function mapEmptyEntity(currentPlayerType: string) {
	return currentPlayerType === 'playerObject' ? PlayerEntities.autoPlayer : 'empty_entity';
}

export function mapSimpleEntity(currentPlayerType: string) {
	return currentPlayerType === 'playerObject' ? PlayerEntities.playerPhysics : 'simple_entity';
}

export function mapPhysicsEntity(currentPlayerType: string) {
	return currentPlayerType === 'playerObject' ? PlayerEntities.playerPhysics : 'physics_entity';
}


