import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { Object3D, Vector3 } from "three";
import { getSpace } from "~/space/space";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useSpaceStore } from "~/store/space";
import { getEngine } from "~/space/engine";
import { PlayerObject, RaveNavigation } from "@ravespaceio/rave-engine";
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { isMovingKeyPressed } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import Player from "@ravespaceio/rave-engine/build/engine/src/engine/player/Player";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { PlayerAnimationState } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerAnimationState";




export default class InteractionExtension<T extends string> {

	private _playerOnAir: boolean = false
	private _lastNavMeshPosition: Vector3 = new Vector3()
	constructor(scene: Object3D, interactionType: { [SocketID: string]: "inside" | "outside" }) {

		const space = getSpace()
		const engine = getEngine()
		const spaceStore = useSpaceStore()
		engine.player.getLocalPlayer('playerCharacter').getPhysicState.on((states) => this._playerOnAir = states.isOnAir)
		const spots = findObjects(scene, testUserData("SocketID"))
		spots.forEach((spot) => {

			const type = interactionType[spot.userData.SocketID]
			if (!type) { console.error("No interactionType"); return }

			space.pciSystem.add({
				collider: new SphereCollider(spot, 1.2),
				onColliderEnter: () => {
					spaceStore.hintTop.title = "Hinsetzen"
					engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false;
					this._lastNavMeshPosition.copy(engine.player.getPlayerPosition());

				},
				onColliderLeave: () => {
					spaceStore.hintTop.title = undefined
					engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true;

				},
				onInteract: () => {
					const avatar = engine.player.getPlayerAvatar();
					if (!avatar) return

					if (this._playerOnAir) return;
					spaceStore.hintTop.title = undefined
					if (type === "inside") { offsetCamera(0.5, 1.3); }
					sitOutsideNavMesh(engine, 0.5, spot);
				},
				onExit: () => {
					exitSitting(engine, this._lastNavMeshPosition);
					engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true;

					if (type == "inside") { offsetCamera(3, 1.8); }
				}
			})
		})
	}
}


function sitOutsideNavMesh(engine, playerheight: number, spot: THREE.Object3D) {
	engine.player.currentPlayerType === 'playerObject' ? sitOutsideNavMeshPlayerObj(playerheight, spot) : sitOutsideNavMeshPlayerChar(playerheight, spot)
}

function exitSitting(engine, lastPos: Vector3) {
	engine.player.currentPlayerType === 'playerObject' ? exitSittingPlayerObj() : exitSittingPlayerChar(lastPos)
}

function sitOutsideNavMeshPlayerObj(playerheight: number, spot: THREE.Object3D) {
	const engine = getEngine()
	const player = engine.player.getLocalPlayer('playerObject')
	const avatar = player.avatar!
	if (!avatar) return
	avatar.triggerAction("sitting", 0.5, true)
	avatar.cancelWalk(1 / 5); // TODO: make a function that cancel walk but doesn't need parameter
	engine.navigation.enabled = false
	player.isPlaying = false;
	player.changeEntity(PlayerEntities.autoPlayer);
	player.position.copy(new Vector3(spot.position.x, spot.position.y - playerheight, spot.position.z));
	player.quaternion.copy(spot.quaternion);
	engine.player.camControllerM.goBehindPlayer(true);
}

function exitSittingPlayerObj() {
	const engine = getEngine()
	const player = engine.player.getLocalPlayer('playerObject')
	const avatar = player.avatar!
	if (!avatar) return
	player.isPlaying = true;
	getEngine().navigation.enabled = true;
	player.changeEntity(PlayerEntities.playerPhysics);
	avatar.cancelCurrentUserAction();
	engine.player.camControllerM.goBehindPlayer(true);
}

function sitOutsideNavMeshPlayerChar(playerheight: number, spot: THREE.Object3D) {
	const engine = getEngine()
	engine.navigation.enabled = false;

	engine.player.changeEntity<PlayerCharacter>('empty_entity');
	const animationStates = engine.player.getLocalPlayer('playerCharacter').get<PlayerAnimationState>('PlayerAnimationState').movementState;
	Object.keys(animationStates).forEach((key) => { animationStates[key] = false })
	engine.player.triggerPlayerAction("sitting", true);
	engine.player.getPlayerPosition().copy(new Vector3(spot.position.x, spot.position.y - playerheight, spot.position.z));
	engine.player.getPlayerQuaternion().copy(spot.quaternion);
	engine.player.camControllerM.goBehindPlayer(true);
}

function exitSittingPlayerChar(lastPos: Vector3) {
	const engine = getEngine()
	engine.navigation.enabled = true;
	engine.player.changeEntity<PlayerCharacter>('physics_entity');
	engine.player.getLocalPlayer('playerCharacter').get<RaveNavigation.NavPlayerCharacter>('navComponent_PlayerCharacter').jumpTo(lastPos);

	engine.player.camControllerM.goBehindPlayer(true);
}



function offsetCamera(maxOutDistance: number, height: number) {
	const engine = getEngine()
	const thrid = engine.player.camControllerM.getController<ThirdCameraController>(CamControllersNames.ORBIT_CAMERA);
	thrid.maxZoomOut = maxOutDistance;
	thrid.desiredManualDistance = maxOutDistance;
	engine.player.camControllerM.setCameraHeight(height)
}


