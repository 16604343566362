import { findObject, findObjects, findObjectsByProperty, findObjectsByUserData, testProperty, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { Object3D } from "three";
import { getSpace } from "../space";
import * as THREE from 'three';
import { getEngine } from "../engine";
import { extendToChromaKeyMaterial } from "@ravespaceio/rave-engine/build/engine/src/objects/shader/ChromaKeyMaterial"
import Engine from "@ravespaceio/rave-engine";
import { setupAlwaysPlayerFacing } from "../_lib/shader/billboard";





export function setupVideoScreens(scene: Object3D) {

	setupStageArtistScreen();
	setupMasterClassArtistScreen();
	setupmasterclassBigscreen(scene);

}

// 100
export function setupBigStageScreen(placeholder) {

	const space = getSpace();
	const engine = getEngine()
	const screen = findObject(engine.scene, testUserData('CMSR', 'stageBigscreen'));
	const screenMat = new THREE.MeshBasicMaterial();
	// TODO add placeholder image URL
	space.screenManager.addScreen(100, screen as THREE.Mesh, screenMat, placeholder)
}

// 101
function setupStageArtistScreen() {
	const space = getSpace();
	const engine = getEngine();
	const screenInfo = createScreen(8, new THREE.Vector3(-12, 1.5, 44), '101');
	screenInfo.screen.visible = false;
	screenInfo.screen.position.y = 3.9
	screenInfo.screen.scale.set(1.3, 1.15, 1)
	// chromakey
	const { params, updateParamsToUniforms } = extendToChromaKeyMaterial(screenInfo.screenMaterial)
	params.similarity = 0.47
	space.eventbus.onAlready("chromaKeyStudio", (state: any) => {
		params.chromaEnabled = state
		updateParamsToUniforms()
	});
	engine.scene.add(screenInfo.screen);
	space.screenManager.addScreen(101, screenInfo.screen as THREE.Mesh, screenInfo.screenMaterial, 'none');
	engine.loop.register(() => {
		screenInfo.screen.lookAt(engine.player.getPlayerPosition().x, screenInfo.screen.position.y, engine.player.getPlayerPosition().z);
	})

	space.eventbus.onAlready("scaleStageVideo", (state: any) => {
		if (isFinite(state.scale) && isFinite(state.x) && isFinite(state.y) && isFinite(state.z))
			screenInfo.screen.scale.set(state.scale, state.scale, state.scale)
		screenInfo.screen.position.set(state.x, state.y, state.z)
	});

	space.gui.addToSceneFolder(screenInfo.screen)


}

// 102
function setupmasterclassBigscreen(scene: Object3D) {
	const space = getSpace();
	const screen = findObject(scene, testUserData('CMSR', 'masterclassBigscreen'));
	const screenMat = new THREE.MeshBasicMaterial();

	// TODO add placeholder image URL
	space.screenManager.addScreen(102, screen as THREE.Mesh, screenMat, '/dek/img/masterclass.jpg')
}

// 103
function setupMasterClassArtistScreen() {
	const space = getSpace();
	const engine = getEngine();
	const screenInfo = createScreen(4, new THREE.Vector3(-16.5, 13, -0.5), '103');
	screenInfo.screen.visible = false;
	screenInfo.screen.scale.set(1.8, 1.5, 1)
	screenInfo.screen.position.set(-16.5, 13, -0.5)
	engine.scene.add(screenInfo.screen);
	// chromakey
	const { params, updateParamsToUniforms } = extendToChromaKeyMaterial(screenInfo.screenMaterial)
	params.similarity = 0.47
	space.eventbus.onAlready("chromaKeyStudio", (state: any) => {
		params.chromaEnabled = state
		updateParamsToUniforms()
	});

	space.screenManager.addScreen(103, screenInfo.screen as THREE.Mesh, screenInfo.screenMaterial, 'none');


	screenInfo.screen.name = "screen1"
	space.gui.addToSceneFolder(screenInfo.screen)
	engine.loop.register(() => {
		screenInfo.screen.lookAt(engine.player.getPlayerPosition().x, screenInfo.screen.position.y, engine.player.getPlayerPosition().z);
	})

}


function createScreen(scale: number, initialPos: THREE.Vector3, id: string) {
	const screenSize = new THREE.Vector2(scale, scale * 1080 / 1920);
	const screenMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide })
	const screen = new THREE.Mesh(new THREE.PlaneGeometry(screenSize.x, screenSize.y), screenMaterial);
	screen.position.set(initialPos.x, (screenSize.y / 2) + initialPos.y, initialPos.z);
	screen.name = 'screen_' + id;
	return { screen, screenMaterial }

}
