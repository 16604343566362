import { BigGui } from "@ravespaceio/rave-engine";
import { GuiEngine } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngine";
import { GuiThreeHelper } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiThreeHelper";
import { getEngine } from "./engine";
import { getSpace } from "./space";
import { useSpaceStore } from "~/store/space";

let gui: BigGui
export function getGui() {
	if (!gui) { console.error("Gui not setup"); }
	return gui
}
export function setupGui(): BigGui {
	const biggui = gui = new BigGui({ touchStyles: 0, title: "RaveEngine", autoPlace: true })
	setTimeout(() => {
		GuiEngine.fixFocus(biggui, getEngine())
	})
	return biggui

}
export function initGui() {
	const engine = getEngine()
	const space = getSpace()
	const gui = getGui()
	gui.add(gui, "refresh").name("listen to values")
	GuiThreeHelper.addScene(space.gui.getFolderOrCreate("Scene"), engine.scene).title("_Scene")
	GuiEngine.addEngine(gui, engine)
	gui.addToSceneFolder(engine.scene, GuiThreeHelper.addScene)


	if (space.ENV.IS_PROD) {
		gui.hide();
		gui.destroy();
	}

}
