import { RPMAvatar } from "@ravespaceio/rave-engine"
import { AvatarConfig_I } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes"


export function getAvatarPreviewImg(modelUrl: string, callback: (prev: string) => void) {
	const params = {
		model: modelUrl,
		scene: "fullbody-posture-v1-transparent",
	}
	const http = new XMLHttpRequest()
	http.open("POST", "https://render.readyplayer.me/render")
	http.setRequestHeader("Content-type", "application/json")
	http.send(JSON.stringify(params))
	http.onload = () => {
		const response = JSON.parse(http.responseText)
		if (response.renders) {
			callback(response.renders[0])
		} else {
			callback("")
		}
	}
}

export function rpmRndScale(): number {
	return 1 + (Math.random() - 0.5) / 10
}


export type AvatarConfigWithoutPreview = {
	model: string,
	gender: "masculine" | "feminine"
	scale: number
}
export type AvatarConfig = AvatarConfigWithoutPreview & {
	preview: "loading" | string
}

export function transformAvatarConfig(config: AvatarConfigWithoutPreview): AvatarConfig_I {
	return {
		modelPath: config.model,
		animationsPath: config.gender == "masculine" ? RPMAvatar.defaultMaleConfig.animationsPath : RPMAvatar.defaultFemaleConfig.animationsPath,
		scale: rpmRndScale(),
		img: ""
	}
}


// https://readyplayer.me/
// export const RPM_API_CONFIG = ".glb?textureSizeLimit=1024&meshLod=2&textureAtlas=1024&useDracoMeshCompression=true&morphTargets=none"
export const RPM_API_CONFIG = ".glb?quality=medium"
export const RPM_API_URL = "https://api.readyplayer.me/v1/avatars/"
export const RPM_PRE_AVATARS: AvatarConfig[] = [
	{ model: "/dek/avatars/models/5.glb", gender: "feminine", preview: "/dek/avatars/img/5.png", scale: 1 },
	{ model: "/dek/avatars/models/1.glb", gender: "masculine", preview: "/dek/avatars/img/1.png", scale: 1 },
	{ model: "/dek/avatars/models/4.glb", gender: "masculine", preview: "/dek/avatars/img/4.png", scale: 1 },
	{ model: "/dek/avatars/models/6.glb", gender: "feminine", preview: "/dek/avatars/img/6.png", scale: 1 },
	{ model: "/dek/avatars/models/2.glb", gender: "masculine", preview: "/dek/avatars/img/2.png", scale: 1 },
	{ model: "/dek/avatars/models/3.glb", gender: "masculine", preview: "/dek/avatars/img/3.png", scale: 1 },
	{ model: RPM_API_URL + "646f7b3e61130ed224d9c024" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/0tH2j0mOI.png", scale: 1 },
	{ model: RPM_API_URL + "646f7b6431cbbc83675effb9" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/LMhGcmvpp.png", scale: 1 },
	{ model: RPM_API_URL + "646f7ca1625b479a13870e88" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/Ly4NmAx11.png", scale: 1 },
	{ model: RPM_API_URL + "646f7ce361f4eb2f3456e69b" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/B8zoXR5I2.png", scale: 1 },
	{ model: RPM_API_URL + "63ee32a6d2a60a1bbcaffa1c" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/rUEzttIW0.png", scale: 1 },
	{ model: RPM_API_URL + "646f791b61f4eb2f3456da5b" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/pCHYlgO9j.png", scale: 1 },
	{ model: RPM_API_URL + "6400f314ce7f75d51cda4133" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/0jQRbI9eK.png", scale: 1 },
	{ model: RPM_API_URL + "6400ec676fad80b65b0f0628" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/099d2i1We.png", scale: 1 },
	{ model: RPM_API_URL + "630df3a5ce4e22ca81f47ed8" + RPM_API_CONFIG, gender: "masculine", preview: "https://renderapi.s3.amazonaws.com/T6KJ4oSvm.png", scale: 1 },
	{ model: RPM_API_URL + "645270530fc89d09fcdcd184" + RPM_API_CONFIG, gender: "feminine", preview: "https://renderapi.s3.amazonaws.com/iQR1ZxgBw.png", scale: 1 },
]



