<template>
	<Transition name="fade">
		<div v-show="showMenu" id="menu" :class="MyEnv.$isMobile ? 'mobile' : ''">
			<div class="bgg" @click.self="showMenu = false">

				<div class="menu" :class="highlightServer ? 'highlight' : ''">
					<IconCloseBlack @click="showMenu = false" />

					<SpaceMenuAudio></SpaceMenuAudio>

					<!--SpaceMenuVoiceChat v-if="multiplayerEnabled"></SpaceMenuVoiceChat-->

					<SpaceMenuControlls></SpaceMenuControlls>

					<SpaceMenuQuality></SpaceMenuQuality>

					<template v-if="multiplayerEnabled">
						<SpaceMenuName></SpaceMenuName>

						<SpaceMenuPlayerText v-if="!MyEnv.$isMobile"></SpaceMenuPlayerText>

						<SpaceMenuAvatar></SpaceMenuAvatar>

						<SpaceMenuMultiplayer :class="highlightServer ? 'mp-row' : ''"></SpaceMenuMultiplayer>
					</template>

					<div class="row" style="justify-content: flex-end">
						<div class="tgl auto" @click="clear()">
							Zurücksetzen
						</div>

						<div v-if="!MyEnv.$isMobile" class="tgl auto" @click="goFullscreen()">
							Vollbild
						</div>


						<div class="tgl auto" @click="respawn()">
							Respawn
						</div>
					</div>
					<div class="row">
						<div class="imprint-links">
							<a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a>
							<a href="https://www.deka.de/privatkunden/impressum" target="_blank">Impressum</a>
							<p @click="showCookieHint()">Cookie Einstellungen</p>
						</div>
					</div>
					<div id="versionText">
						<p>
							Powered by <a href="https://ravespace.io/engine" target="_blank">RaveEngine</a> version {{ engineVersion }}
						</p>
					</div>
				</div>

			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import Vue from "vue";
import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getSpace } from "~/space/space";
import versionsJSON from "@ravespaceio/rave-engine/package.json"
import { getEngine } from "~/space/engine";
import { useSpaceStore } from "~/store/space";
import { storeToRefs } from "pinia";
import { blockForFrontend, unblockFromFrontend } from "~/space/_lib/utils/frontend";


const space = getSpace();
const engine = getEngine()

const highlightServer = ref(false)
const engineVersion = versionsJSON.version
const multiplayerEnabled = engine.config.multiplayer;

const spaceStore = useSpaceStore()
const { showMenu } = storeToRefs(spaceStore)
watch(showMenu, (state) => { state ? blockForFrontend() : unblockFromFrontend() })


// Keys
space.eventbinder.bindDocument("keyup", (e) => {
	if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
	if (e.key == "Escape") {
		showMenu.value = false
	} else if (e.key == "m" || e.key == "M") {
		showMenu.value = !showMenu.value
		highlightServer.value = false
	} else if (e.key == "U" || e.key == "u") {
		showMenu.value = !showMenu.value
		if (showMenu.value) {
			highlightServer.value = true
		} else {
			highlightServer.value = false
		}
	}
});

function showCookieHint() {
	space.eventbus.emit("showCookieHint")
}
// Close automatically
engine.gui.on("canvasclick", () => {
	showMenu.value = false;
});

function respawn() {
	showMenu.value = false;
	space.spawnManager.spawnDefault()
}
function goFullscreen() {
	document.fullscreenElement ? document.exitFullscreen() : document.body.requestFullscreen();
}
function clear() {
	let text;
	if (confirm("Spielstand & Avatar zurücksetzen und neu laden?") == true) {
		localStorage.clear();
		window.location.reload()
	}

}
</script>


<style lang="scss">
@import "assets/css/variables";

.imprint-links {
	@extend .flex-center;
	width: 100%;

	a,
	p {
		font-size: 12px;
		padding: 4px;
		color: white;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

#menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	@extend .noselect;

	.bgg {
		position: absolute;
		background: rgba(8, 8, 8, 0.90);
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@extend .flex-center;

		.menu {
			position: absolute;
			width: 450px;
			background: $darkTransparent;
			border: 1px solid #ffffff17;
			border-radius: $radius;
			max-width: calc(90% - 20px);
			bottom: 30px;
			padding: 10px 0;

			.close {
				top: -60px;
				right: 0;
				width: 40px;
				height: 40px;
			}

			.row {
				border-bottom: 1px solid #ffffff17;
				padding: 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: $main;

				.l {
					width: 150px;
					color: $main;
					text-align: left;
					font-weight: 700;
				}

				.r {
					width: calc(100% - 110px);
					color: $main;

					.wrap {
						display: flex;
						justify-content: flex-end;
						align-items: center;

						label {
							color: $main;

						}

						.tag {
							color: $main;
							font-size: 12px;
							font-weight: 700;
						}
					}
				}


				&:last-child {
					border: none;
				}
			}

			&.highlight {
				.row {
					display: none;
				}

				.row.mp-row {
					display: flex;
					height: 200px;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.l,
					.r {
						width: 100%;
						margin: 0;
						text-align: center;

						.wrap {
							@extend .flex-center;
							margin-top: 12px;
						}
					}
				}
			}
		}
	}
}


.tgl {
	width: 50px;
	height: 40px;
	background: rgba(255, 255, 255, 0.1);
	border: $stroke solid $main;
	box-sizing: border-box;
	border-radius: $radius;
	@extend .flex-center;
	margin: 0 5px;
	padding: 0 5px;
	text-align: center;
	cursor: default;
	color: $main;
	font-weight: 700;
	font-size: 12px;

	svg {
		width: 20px;
		height: auto;

		* {
			fill: $main;
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: auto !important;
		cursor: pointer;

		&:hover {
			background: $dark;
			opacity: 1;
			color: $bright;

			svg {
				* {
					fill: $bright !important;
				}
			}
		}
	}

	&.auto {
		width: auto;
		cursor: pointer;
		padding: 0 10px;

		&:hover {
			background: $dark;
			color: $bright;
		}
	}
}


.input {
	width: 120px;
	height: 40px;
	background: transparent;
	outline: none;
	border: 1px solid $main;
	color: $main;
	font-size: 14px;
	text-align: center;
	border-radius: $radius;
	font-weight: 700;
}


// Mobile Menu
#menu--btn {
	position: fixed;
	bottom: 40px;
	right: 30px;
	height: 30px;
	@extend .btn--mini;
	transform: translateY(30px);
}

// Audio Range Sliders
input[type=range] {
	-webkit-appearance: none;
	margin: 18px 0;
	width: 100%;
	max-width: 160px;
	border-radius: $radius;
}

input[type=range]:focus {
	outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 5px;
	cursor: pointer;
	background: rgba(0, 0, 0, 0.9);
	border-radius: $radius;
	border: 1px solid $main;
}

input[type=range]::-webkit-slider-thumb {
	border: 1px solid $main;
	height: 20px;
	width: 30px;
	border-radius: 100px;
	background: $main;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -10px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
	background: #000;
}

input[type=range]::-moz-range-track {
	width: 100%;
	height: 5px;
	cursor: pointer;
	background: rgba(0, 0, 0, 0.9);
	border-radius: $radius;
	border: 1px solid $main;
}

input[type=range]::-moz-range-thumb {
	border: 2px solid $main;
	height: 20px;
	width: 30px;
	border-radius: 100px;
	background: $main;
	cursor: pointer;
}

input[type=range]::-ms-track {
	width: 100%;
	height: 5px;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	border-radius: $radius;
	border-width: 16px 0;
	color: transparent;
}

input[type=range]::-ms-fill-lower {
	background: #000;
	border: 1px solid $main;
	border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
	background: rgba(0, 0, 0, 0.9);
	border: 1px solid $main;
	border-radius: 10px;
}

input[type=range]::-ms-thumb {
	border: 2px solid $main;
	height: 20px;
	width: 30px;
	border-radius: 100px;
	background: $mainTransparent;
	cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
	background: rgba(0, 0, 0, 0.9);
}

input[type=range]:focus::-ms-fill-upper {
	background: #000;
}

@media(max-width:700px) {
	.tgl {
		height: 30px;
	}

	#menu {
		.bgg {
			.menu {
				.row {
					padding: 7px 12px;

					.l {
						font-size: 12px;
						width: 90px;
					}

					.tag {
						text-align: right;
						font-size: 12px;
					}
				}
			}
		}
	}
}


// Menu check boxes
.controls-row {
	.r {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.wrap {
			margin-left: 20px;

			input[type=checkbox]+label {
				cursor: pointer;
				font-size: 11px;
				display: block;
			}

			input[type=checkbox] {
				display: none;
			}

			input[type=checkbox]+label:before {
				content: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.59815 2.79847L5.59354 6.51385L3.99539 8L0 4.28462L1.59815 2.79847Z' fill='transparent'/%3E%3Cpath d='M2.53031 6.38996L9.40185 0L11 1.48615L4.12847 7.87611L2.53031 6.38996Z' fill='transparent'/%3E%3C/svg%3E%0A");
				border: 1px solid $main;
				border-radius: $radius;
				display: inline-block;
				width: 20px;
				height: 20px;
				display: inline-flex;
				margin-right: 5px;
				color: transparent;
				justify-content: center;
				align-items: center;
			}

			input[type=checkbox]+label:active:before {
				transform: scale(0);
			}

			input[type=checkbox]:checked+label:before {
				background-color: $main;
				border-color: $main;
				color: #fff;
				content: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.59815 2.79847L5.59354 6.51385L3.99539 8L0 4.28462L1.59815 2.79847Z' fill='white'/%3E%3Cpath d='M2.53031 6.38996L9.40185 0L11 1.48615L4.12847 7.87611L2.53031 6.38996Z' fill='white'/%3E%3C/svg%3E%0A");
			}
		}
	}
}

@media (max-width:1000px) and (orientation:landscape) {
	.menu {
		display: flex;
		flex-wrap: wrap;
		width: 100% !important;

		.row {
			width: 50%;
		}
	}
}


@media (max-height:800px) and (min-width:800px) {
	.menu {
		display: flex;
		flex-wrap: wrap;
		width: 100% !important;

		.row {
			width: 50%;
		}
	}
}



/* VERSION TEXT */
#versionText {
	display: flex;
	margin: 10px auto 0;
	align-items: center;
	justify-content: center;
	opacity: 0.5;

	* {
		font-size: 0.7rem;
		color: white;
	}

	p {
		display: inline-block
	}
}
</style>
