<template>
	<div id="avatar-generator" v-show="showAvatarGenerator">
		<div class="container">
			<div class="close" @click="showAvatarGenerator = false">
				<IconClose v-if="!isLoading" />
				<IconLoader v-else />
			</div>
			<div class="wrap">

				<div class="rpmblock" v-if="!acceptRPM">
					<h1>Erstelle deinen eigenen Avatar mit Ready Player Me</h1>
					<p>Wähle einen der vorgefertigten Avatare aus der Liste auf der rechten Seite oder nutze die Plattform Ready Player Me, um dir einen eigenen Avatar zu erstellen!<br><br>Mit Klick auf den „Weiter zu Ready Player Me“ Button wird eine Datenverbindung zu Ready Player Me aktiviert. Du kannst dir dann deinen Avatar erstellen. Ready Player Me setzt dabei aber auch Cookies auf deinem Gerät und erhält so Daten von dir (z. B. IP-Adresse). Zur Aktivierung von Ready Player Me findest du weitere Informationen in unseren Career Day <a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweisen</a>. Für die Datenverarbeitung durch Ready Player Me gelten die Datenschutzbestimmungen und Bedingungen von Ready Player Me, die dir Ready Player Me zur Verfügung stellt .</p>
					<div class="btn" @click="userpm()" style="margin-top: 22px;">Weiter zu Ready Player Me</div>
					<!-- <img src="/dek/img/rpm2.png"> -->
				</div>
				<UtilityRpmIframe v-else @avatar-chosen="config => { saveConfig(config); }" @avatar-preview="preview => savePreview(preview)"></UtilityRpmIframe>

				<div class="avatar-quickchooser">
					<div class="avatar-holder">
						<div class="avatar" v-for="avatar in RPM_PRE_AVATARS " :style="'background-image: url(' + avatar.preview + ');'" @click="() => savePredefinedAvatar(avatar)" :class="avatar.model == spaceStore.avatar.model ? 'active' : ''"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="imprint-links">
			<a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a>
			<a href="https://www.deka.de/privatkunden/impressum" target="_blank">Impressum</a>
			<p @click="showCookieHint()">Cookie Einstellungen</p>
		</div>
	</div>
</template>


<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useToast } from 'vue-toastification';
import { getEngine } from '~/space/engine';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { changeAvatar } from '~/space/_lib/mechanic/multiplayer';
import { RPMAvatar } from '@ravespaceio/rave-engine';
import { AvatarConfig, RPM_PRE_AVATARS } from '~/space/_lib/utils/rpm';


const space = getSpace()
const engine = getEngine()
const toast = useToast()
const spaceStore = useSpaceStore()
const isLoading = ref(false)

const { showAvatarGenerator } = storeToRefs(spaceStore)
const scale = ref(180)
const acceptRPM = ref(false)


function userpm() {
	acceptRPM.value = true
	localStorage.setItem("acceptReadyPlayerMe", "true")
}

// show/hide
watch(showAvatarGenerator, (to, from) => {
	if (to == true) {
		engine.renderer.paused = true;
		isLoading.value = false
	}
	else {
		isLoading.value = true
		engine.renderer.paused = false;
	}
})


// predefined
function savePredefinedAvatar(config: AvatarConfig) {
	changeAvatar(config)
	toast.success("Avatar changed.");
	spaceStore.avatar.preview = config.preview
	//show.value = false
}

// custom avatar
function saveConfig(config: AvatarConfig) {
	isLoading.value = true
	setTimeout(() => {
		changeAvatar(config)
		toast.success("Avatar changed.");
		spaceStore.avatar.preview = config.preview
		showAvatarGenerator.value = false
	}, 1);
}

//
function savePreview(preview: string) {
	if (spaceStore.avatar.preview) spaceStore.avatar.preview = preview
}

// scale
function changeScale() {
	// console.log(scale.value)
	// let rpmScale = scale.value / 180
	// rpmScale = Math.round(rpmScale * 100) / 100
	// if (rpmScale > 1.06 || rpmScale < 0.89) return
	// const a: AvatarConfig = {
	// 	modelPath: playerStore.avatar!.modelPath,
	// 	gender: playerStore.avatar!.gender,
	// 	scale: rpmScale,
	// 	preview: playerStore.avatar!.preview,
	// 	isCustom: playerStore.avatar!.isCustom
	// }
	// playerStore.avatar! = a
}


function showCookieHint() {
	space.eventbus.emit("showCookieHint")
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.imprint-links {
	position: fixed;
	bottom: 10px;
	left: 0;
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}
}

.close {
	position: relative;
	right: inherit;
	top: inherit;
	margin-bottom: 12px;

	div {
		filter: invert(1);
	}
}

#avatar-generator {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.97);
	z-index: 999999999;

	.container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.close {
			position: relative;
			left: initial;
			right: initial;
			top: initial;
			transform: translate(515px, -10px);
		}

		.wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: calc(100% - 50px);
			max-height: 80vh;

			iframe,
			.rpmblock {
				width: 770px;
				height: 600px;
				max-width: 100%;
				max-height: 100%;
				border: none;
				border-radius: $radius;
				border: 1px solid $main;
			}

			.rpmblock {
				@extend .flex-center;
				flex-direction: column;
				color: white;
				text-align: center;
				background-image: url('/dek/img/rpm.png');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				background-color: #0D0E11;
				padding: 20px;
				position: relative;

				img {
					position: absolute;
					bottom: 0;
					width: 350px;
					z-index: 0;
				}

				h1 {
					font-size: 25px;
					position: relative;
					z-index: 1;
					margin-bottom: 12px;
					margin-top: -20px;
				}

				p {
					font-size: 14px;
				}

				a {
					color: white;
					margin: 20px 0;
					position: relative;
					z-index: 1;
				}

				.btn {
					position: relative;
					z-index: 1;
				}
			}

			.avatar-quickchooser {
				width: 280px;
				height: 600px;
				max-height: 100%;
				margin-left: 20px;
				@extend .flex-center;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;

				.avatar-holder,
				.avatar-scaler {
					border: 1px solid $main;
					border-radius: $radius;
					padding: 10px;
				}

				.avatar-scaler {
					height: 70px;
					width: 280px;
					margin-bottom: 10px;
					@extend .flex-center;

					.range {
						width: 100px;
						padding: 0;
						margin-right: 10px;
					}

					.btn {
						padding: 4px;
						font-size: 10px;
						border-radius: 4px;
					}

					.scale {
						color: $main;
						font-size: 16px;
						font-weight: 700;
						margin-right: 12px;

						span {
							color: $main;
							font-size: 10px;
							margin-left: 2px;
							font-weight: 700;
						}
					}
				}

				.avatar-holder {
					overflow-y: auto;
					height: calc(100%);
					@extend .flex-center;
					flex-wrap: wrap;

					.avatar {
						margin: 10px;
						display: inline-block;
						width: 90px;
						height: 90px;
						border-radius: $radius;
						opacity: 0.6;
						cursor: pointer;
						transition: ease 0.2s;

						&:hover {
							transform: scale(1.1);
							opacity: 1;
							background-size: 144%;
							background-position: 37% 0%;
						}

						&.active {
							opacity: 1 !important;
							border: 1px solid $main;
						}
					}

					&::-webkit-scrollbar {
						width: 1px;
						height: 1px;
					}

					&::-webkit-scrollbar-thumb {
						background: transparent;
						border-radius: 4px;
					}

					&::-webkit-scrollbar-thumb:hover {
						background: transparent;
					}

					&::-webkit-scrollbar-track {
						background: transparent;
						border-radius: 4px;
					}
				}

			}
		}
	}
}

@media(max-width:1000px) {
	#avatar-generator {
		.container {
			.wrap {
				flex-direction: column;

				iframe,
				.rpmblock {
					width: 100%;
				}

				.rpmblock {
					justify-content: flex-start;
					overflow-y: auto;

					p {
						font-size: 12px;
					}

					h1 {
						margin: 0;
						font-size: 16px;
					}

					img {
						display: none;
					}
				}

				.avatar-quickchooser {
					width: 100%;
					height: 100px;
					margin: 20px 0 0 0;
					background: $darkTransparent;

					.avatar-holder {
						height: 100%;
						padding: 5px;

						.avatar {
							width: 75px;
							height: 75px;
							margin: 5px;
						}
					}

					.avatar-scaler {
						display: none !important;
					}
				}
			}
		}
	}
}

@media(max-width:1060px) {
	#avatar-generator {
		.container {
			.close {
				transform: translate(0) !important;
				position: absolute;
				right: 20px;
				top: 20px;
			}
		}
	}
}
</style>
