<template></template>

<script lang="ts">
import { enableTracking } from '@vue/reactivity';
import { useToast } from 'vue-toastification';
import { getSpace } from '~/space/space';

declare const gtag

export default defineComponent({
	data() {
		return {
			optstate: false
		}
	},
	mounted() {

		useHead({
			script: [{
				type: 'text/javascript', textContent: `var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/deka.matomo.cloud/container_u4AJwoBU.js'; s.parentNode.insertBefore(g,s);
  })();` },
			]
		})


		const space = getSpace()

		space.eventbus.on("optOutAnalytics", () => {
			this.optstate = false
			this.disableTracking()
		})

		space.eventbus.onAlready("analyticsConsent", () => {
			this.optstate = true
			this.checkIfMatomoLoaded()
		})

	},
	methods: {
		enableTracking() {
			// econda logic

			document.cookie = "analyticsEnabled=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			const space = getSpace()
			space.eventbus.emit("trackingEnabled")
			localStorage.setItem("analyticsConsent", "true");
		},

		disableTracking() {
			// econda logic

			const space = getSpace()
			localStorage.setItem("analyticsConsent", "false");
			space.eventbus.emit("trackingDisabled")
			const cookies = document.cookie.split(";");

			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i];
				const eqPos = cookie.indexOf("=");
				const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
			}
		},
		getCookie(name: string) {
			let cookie = {};
			document.cookie.split(';').forEach(function (el) {
				let [k, v] = el.split('=');
				cookie[k.trim()] = v;
			})
			return cookie[name];
		},
		checkIfMatomoLoaded() {
			const _this = this
			if (window["_mtm"] !== undefined) {
				this.setupMatomo()
			} else {
				setTimeout(function () {
					_this.checkIfMatomoLoaded()
				}, 1000)
			}
		},
		setupMatomo() {
			let opt = this.getCookie("analyticsEnabled")
			this.enableTracking()
			if (!opt) {
				opt = this.optstate
			}

			if (opt == true || opt == "true") {
				this.enableTracking()
				this.optstate = true
				console.log("Tracking Enabled")
			} else {
				this.optstate = false
				console.log("Tracking Disabled")
			}
			const space = getSpace()
			space.eventbus.emit("newAnalytics", "enterMetaverse")

			space.eventbus.onAlready("newAnalytics", (data) => {
				if (this.optstate == true) {
					if (space.ENV.IS_PROD) {
						window['_mtm'].push({ "event": data });
						//console.log("Tracked: " + data)
					} else {
						console.log("Not really Tracked: " + data)
					}
				}
			})
		}
	}



})

</script>
<style scoped lang="scss">
@import "assets/css/variables";

#cookieHint {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 20px;
	z-index: 999999;
	background: white;
}

#cookieHint>.inner {
	width: 550px;
	height: auto;
	padding: 20px;
	border-radius: 20px;
	background: white;
	bottom: 20px;
	@extend .flex-center;
	flex-direction: column;

	p {
		font-size: 14px;
		text-align: center;
	}

	p.bold {
		font-weight: 700;
		margin-bottom: 8px;
	}

	.ga {
		margin: 20px 0;
		@extend .flex-center;
		flex-direction: column;

		p {
			font-size: 11px;
			font-weight: 700;
			margin-bottom: 12px;
		}
	}

	.wrap {
		@extend .flex-center;
		margin-top: 16px;

		a {
			color: black;
			opacity: 0.5;
			font-size: 12px;
			margin: 0 5px;
		}
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: $secondary;
}

input:focus+.slider {
	box-shadow: 0 0 1px $secondary;
}

input:checked+.slider:before {
	-webkit-transform: translateX(36px);
	-ms-transform: translateX(36px);
	transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
