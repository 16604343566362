import { Mesh, Material, Object3D, MeshBasicMaterial, MeshStandardMaterial, MeshLambertMaterial } from "three"
import * as THREE from "three"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import { QualityLevelsType } from "./MeshQualityMaterialManager";
import { Loader } from "@ravespaceio/rave-engine";
import { findMeshs } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";


export type AoLightManagerSettings = {
	loader: Loader,
	texturepath: string,
	lightMapIntensity: number,
	aoMapIntensity: number,
	qualityLevels: { [qualityLevel: string]: number }
}


/**
 * @description handler for our baked light and ao map blender RaveEngine workflow
 * textures should be named "ao_X.jpg" and "lightmap_X.jpg" where X is the texture dimension linked to a qualityLevel
 * e.g. qualityLevels: { low: 512, mid: 1024, high: 2048 } would result in ao_2048.jpg for high

 * @param config.qualityLevels representing different resolutions of ao/ligtmap textures
 */
export default class AoLightManager {

	public readonly meshs: Mesh[] = []
	public readonly materials: { [uuidRid: string]: Material } = {}
	public static readonly udIdentifier = "RoomID"

	constructor(aoLightMeshs: Mesh[], private config: AoLightManagerSettings) {
		this.register(aoLightMeshs)
	}

	/**
	 * cloning a mat if it has ao/lightmap refece
	 * checks if a clone of the base mat with same RoomId already exists, when shared material
	 */
	public register(aoLightMeshs: Mesh[]) {
		for (const mesh of aoLightMeshs) {
			const roomId = mesh.userData[AoLightManager.udIdentifier]
			if (roomId === undefined) continue;
			this.meshs.push(mesh)

			const mat = mesh.material as Material
			const uuidRid = "" + mat.uuid + roomId

			const kbm = this.materials[uuidRid]
			if (kbm) {
				mesh.material = kbm
			} else {
				const newMat = mat.clone()
				newMat.userData = JSON.parse(JSON.stringify(mat.userData)) // clone also needs flags like __noQualityTransfer
				mesh.material = newMat
				this.materials[uuidRid] = newMat
			}
		}
	}

	/**
	 * changes ao and light map texture size for current material of registered meshs.
	 * should be execudet at least once in order to setup the textures
	 */
	public changeQuality(q: QualityLevelsType) {
		// lazy load new maps
		for (const mesh of this.meshs) {
			const res = this.config.qualityLevels[q]
			const roomId = mesh.userData[AoLightManager.udIdentifier]
			const mat = mesh.material as MeshStandardMaterial
			this._lazyLoadAoLight(mat, roomId, res)
			mat.lightMapIntensity = this.config.lightMapIntensity
			mat.aoMapIntensity = this.config.aoMapIntensity
		}
	}

	/**
	 * change lightMapIntensity of all registered materials
	 */
	public changeLightMapIntensity(i: number) {
		this.config.lightMapIntensity = i
		for (const m of Object.values(this.materials)) {
			const mat = (m as THREE.MeshStandardMaterial)
			mat.lightMapIntensity = this.config.lightMapIntensity
			mat.needsUpdate = true
		}
	}

	/**
	 * change aoMapIntensity of all registered materials
	 */
	public changeAoMapIntensity(i: number) {
		this.config.aoMapIntensity = i
		for (const m of Object.values(this.materials)) {
			const mat = (m as THREE.MeshStandardMaterial)
			mat.aoMapIntensity = this.config.aoMapIntensity
			mat.needsUpdate = true
		}
	}

	private _lazyLoadAoLight(mat: MeshStandardMaterial, roomID: string, res: number): void {
		// assign on loaded
		const suffix = res ? ("_" + res) : ""
		getMatMap(this.config.loader, `${this.config.texturepath}${roomID}/ao${suffix}.jpg`, {
			flipY: false,
			encoding: THREE.LinearEncoding,
			onLoad: (tex) => {
				mat.aoMap = tex
				mat.aoMapIntensity = this.config.aoMapIntensity
				mat.needsUpdate = true
			}
		})
		getMatMap(this.config.loader, `${this.config.texturepath}${roomID}/lightmap${suffix}.jpg`, {
			flipY: false,
			encoding: THREE.LinearEncoding,
			onLoad: (tex) => {
				mat.lightMap = tex
				mat.lightMapIntensity = this.config.lightMapIntensity
				mat.needsUpdate = true
			}
		})
	}

	/**
	 * returns list of unique materials following predefined contidiotns
	 */
	static findMeshs(group: Object3D): Mesh[] {
		const aoLightMeshs = findMeshs(group, (mesh) => {
			if (!mesh.material || mesh.material instanceof Array) return false;
			if (mesh.userData.CMSReference) return false
			if (mesh.userData[this.udIdentifier] === undefined) return false
			return true
		});
		return [...new Set(aoLightMeshs)]
	}
}

