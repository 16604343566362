<template>
<svg class="logo" width="71" height="18" viewBox="0 0 71 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.01 6.84C38.26 6.84 38.95 8.25 39.06 9.84H32.67C32.6837 9.42083 32.782 9.00875 32.959 8.62856C33.136 8.24836 33.3882 7.9079 33.7002 7.62765C34.0122 7.3474 34.3776 7.13315 34.7746 6.99779C35.1715 6.86244 35.5918 6.80876 36.01 6.84ZM41.45 14.2C40.024 14.9958 38.4228 15.4253 36.79 15.45C34.46 15.45 32.79 14.38 32.67 12.18H42.67C42.67 7.65 41.32 4.35 35.79 4.35C31.3 4.35 29.09 7.45 29.09 11.2C29.09 15.45 31.87 17.92 36.63 17.92C38.3656 17.94 40.0805 17.5421 41.63 16.76L41.45 14.2Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.9 11.27H47.95L52.95 17.57H57.55L51.63 10.41L56.88 4.6H52.71L47.94 9.79H47.9V0.0100002H44.14V17.57H47.9V11.27Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M65.98 14.25C65.6531 14.6259 65.2466 14.9242 64.7899 15.1231C64.3332 15.3221 63.8379 15.4167 63.34 15.4C61.99 15.4 60.97 14.81 60.96 13.82C60.96 11.82 63.45 11.58 65.14 11.58H66.76C66.8278 12.5347 66.551 13.482 65.98 14.25ZM70.2 14.54C70.2 13.54 70.2 12.73 70.2 11.43V9.77C70.2 5.94 68.37 4.3 63.97 4.3C62.2302 4.32368 60.509 4.66249 58.89 5.3L59.1 8.13C60.4045 7.25331 61.9383 6.78029 63.51 6.77C65.72 6.77 66.76 7.48 66.76 9.56H64.69C63.0359 9.4934 61.3848 9.7516 59.83 10.32C59.0827 10.5993 58.4426 11.1074 58.0009 11.7717C57.5592 12.4361 57.3384 13.2228 57.37 14.02C57.37 16.36 59.86 17.87 62.37 17.87C63.2398 17.889 64.101 17.6947 64.8784 17.3043C65.6558 16.9138 66.3259 16.339 66.83 15.63C66.8767 16.282 66.9669 16.9301 67.1 17.57H70.41C70.33 16.57 70.23 15.52 70.2 14.57" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.83 8.78C27.88 1.78 22.21 0.01 16.52 0H11.16V3.29H17.28C20.5 3.29 23.95 4.95 23.95 8.78C23.95 12.61 20.56 14.2 17.28 14.2H14.91V6.58H11.16V17.58H16.52C22.52 17.58 27.77 15.58 27.83 8.79" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.57999 17.57H9.29999V9.84H5.57999V17.57Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.57H3.72V13.1H0V17.57Z" fill="white"/>
</svg>

</template>
