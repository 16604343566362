<template>
	<svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M39.12 9C36.9109 9 35.12 10.7909 35.12 13V34.76C35.12 36.9691 36.9109 38.76 39.12 38.76H60.88C63.0891 38.76 64.88 36.9691 64.88 34.76V13C64.88 10.7909 63.0891 9 60.88 9H39.12ZM50.8742 19.2535C50.4932 18.5677 49.5069 18.5677 49.1259 19.2535L44.6254 27.3544C44.2551 28.0209 44.737 28.8401 45.4995 28.8401H54.5005C55.263 28.8401 55.7449 28.0209 55.3747 27.3544L50.8742 19.2535Z" fill="white" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M60.88 71C63.0891 71 64.88 69.2091 64.88 67L64.88 45.24C64.88 43.0309 63.0891 41.24 60.88 41.24L39.12 41.24C36.9109 41.24 35.12 43.0309 35.12 45.24L35.12 67C35.12 69.2091 36.9109 71 39.12 71L60.88 71ZM49.1258 60.7465C49.5068 61.4323 50.4931 61.4323 50.8742 60.7465L55.3746 52.6456C55.7449 51.9791 55.263 51.1599 54.5005 51.1599L45.4995 51.1599C44.737 51.1599 44.2551 51.9791 44.6254 52.6456L49.1258 60.7465Z" fill="white" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M97.12 45.24C97.12 43.0309 95.3292 41.24 93.12 41.24L71.36 41.24C69.1509 41.24 67.36 43.0308 67.36 45.24L67.36 67C67.36 69.2091 69.1509 71 71.36 71L93.12 71C95.3291 71 97.12 69.2091 97.12 67L97.12 45.24ZM86.8665 56.9942C87.5523 56.6132 87.5523 55.6269 86.8665 55.2459L78.7657 50.7454C78.0991 50.3751 77.28 50.8571 77.28 51.6196L77.28 60.6205C77.28 61.383 78.0991 61.865 78.7657 61.4947L86.8665 56.9942Z" fill="white" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M2.88 45.24C2.88 43.0309 4.67086 41.24 6.88 41.24L28.64 41.24C30.8491 41.24 32.64 43.0308 32.64 45.24L32.64 67C32.64 69.2091 30.8491 71 28.64 71L6.88 71C4.67087 71 2.88 69.2091 2.88 67L2.88 45.24ZM13.1335 56.9942C12.4477 56.6132 12.4477 55.6269 13.1335 55.2459L21.2344 50.7454C21.9009 50.3751 22.72 50.8571 22.72 51.6196L22.72 60.6205C22.72 61.383 21.9009 61.865 21.2344 61.4947L13.1335 56.9942Z" fill="white" />
	</svg>
</template>


