<template>
	<div>
		<div v-if="showRejoinButton && !mpStore.blocked">
			<p>{{ rejoinsTried }}
				<IconLoader v-if="isLoading" />
			</p>
		</div>

	</div>
</template>

<script setup lang="ts">
import { getEngine } from '~/space/engine';
import { getSpace } from '~/space/space';
import { useMultiplayerStore } from '~/store/multiplayer';

const space = getSpace()
const engine = getEngine()
const mpStore = useMultiplayerStore()

const showRejoinButton = ref(false)
const rejoinsTried = ref(0)
const isLoading = ref(false)

let buttonTimeoutId;

watch(() => mpStore.isConnected, (state) => {
	showRejoinButton.value = false
	clearTimeout(buttonTimeoutId)
	if (state) { isLoading.value = false }
	else {
		buttonTimeoutId = setTimeout(() => {
			showRejoinButton.value = true
		}, 4000 * rejoinsTried.value);
	}
})
</script>


<style scoped lang="scss">
p {
	display: flex;
	justify-content: center;
	align-items: center;
}

.multiplayer-status-hint {
	position: fixed;
	top: 10px;
	width: 300px;
	left: calc(50% - 150px);
	z-index: 99;
	color: white;
	background: rgba(0, 0, 0, 0.8);
	padding: 10px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-direction: column;

	.btn {
		padding: 9px 20px;
		margin-top: 12px;
		display: flex;
		justify-content: center;
		align-items: center;

		.loader {
			filter: invert(1);
			transform: translate(2px, 2px);
		}

	}


	p {
		font-size: 14px;
		text-align: center;
		color: white;

		a {
			color: white;
			font-weight: 700;
		}
	}
}

@media(max-width: 400px) {
	.multiplayer-status-hint {
		display: none;
	}
}
</style>
