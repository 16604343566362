import { Logging, LoggingArea } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import AoLightManager from "../manager/AoLightManager"
import MeshQualityMaterialManager from "../manager/MeshQualityMaterialManager"
import { findMaterialsByName, findMeshs, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { replaceMaterialInScene } from "@ravespaceio/rave-engine/build/engine/src/utils/materials"


/**
 *
 * @param scene
 * @param MATS key is mapped to material name
 * @param PER_MESH_SHADER key is mapped to userData[ShaderID] == userDataProperty
 * @param PER_SCENE_SHADER key is mapped to userData[ShaderID] == userDataProperty
 */
export function applyMaterials(scene: THREE.Object3D, MATS: { [matName: string]: THREE.Material }, PER_MESH_SHADER?: { [userDataProperty: string]: (mesh: THREE.Mesh) => any }, PER_SCENE_SHADER?: { [userDataProperty: string]: (mesh: THREE.Mesh[]) => any }) {
	Logging.trace("Applying Materials", LoggingArea.Space)
	for (const [name, mat] of Object.entries(MATS)) {
		replaceMaterialInScene(scene, findMaterialsByName(scene, name), mat, { dispose: true })
		mat.name = name
	}
	if (PER_MESH_SHADER) {
		Logging.trace("Applying per mesh shader", LoggingArea.Space)
		for (const [shaderId, shaderCb] of Object.entries(PER_MESH_SHADER)) {
			findMeshs(scene, testUserData("ShaderID", shaderId)).forEach((m) => { shaderCb(m) })
		}
	}
	if (PER_SCENE_SHADER) {
		Logging.trace("Applying per group shader", LoggingArea.Space)
		for (const [shaderId, shaderCb] of Object.entries(PER_SCENE_SHADER)) {
			shaderCb(findMeshs(scene, testUserData("ShaderID", shaderId)))
		}
	}
}



/**
 * Automated Material Quality AoLight Levels Workflow
 * 0. before: setup high materials used as base
 * 1. find and replace blender materials with shared material
 * 2. seperate/clone materials which need a ao/light map to set individuel maps (AoLightManager)
 * 3. create downgraded materials if material.userData.__noQualityTransfer is not true  (QualityManager)
 * 4. when material switched and has no use old one first and lazy load one with different size
 */
export function setupAoMapLightMapQualityManager(scene: THREE.Object3D, aoMapIntensity: number = 1, lightMapIntensity: number = 2) {

	const space = getSpace()
	const aoLightManger = new AoLightManager(AoLightManager.findMeshs(scene), {
		loader: space.loader,
		texturepath: `${space.project.publicPath}/models/`,
		aoMapIntensity,
		lightMapIntensity,
		qualityLevels: { low: 512, mid: 1024, high: 2048 }
	})
	Logging.trace(`AoLightManager: Managing ${aoLightManger.meshs.length} unique meshes with ${Object.keys(aoLightManger.materials).length} unique materials`, LoggingArea.Space)

	const qualityManager = new MeshQualityMaterialManager(MeshQualityMaterialManager.findMeshs(scene), {
		midRefMat: new THREE.MeshPhongMaterial(),
		lowRefMat: new THREE.MeshLambertMaterial(),
	})
	Logging.trace(`QualityManager: Managing ${Object.keys(qualityManager.meshs).length} unique meshes with ${Object.keys(qualityManager.materials).length} unique materials`, LoggingArea.Space)

	space.eventbus.onAlready("changeLightmapIntensity", (i: number) => { aoLightManger.changeLightMapIntensity(i) })
	space.eventbus.onAlready("qualitysetting", (q) => {
		qualityManager.changeQuality(q)
		aoLightManger.changeQuality(q)
	})
}
