import Engine, { EngineUtils, Logging, LoggingArea } from "@ravespaceio/rave-engine"
import { Mesh, MeshPhongMaterial, MeshPhysicalMaterial, MeshLambertMaterial, Object3D, MeshBasicMaterial, Scene, Texture, Vector2, Vector3, MeshStandardMaterial } from "three"
import seedrandom from "seedrandom"
import { findObject, findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getEngine } from "~/space/engine";


const rng = seedrandom("1");

const UPS = {
	"X": new Vector3(1, 0, 0),
	"Y": new Vector3(0, 1, 0),
	"Z": new Vector3(0, 0, 1)
}

export type AEAxis = "X" | "Y" | "Z"

export interface AETranslate {
	axis: AEAxis
	offset: number
	amplitude: number,
	speed: number
}

export interface AERotate {
	axis: AEAxis
	speed: number,
	offset: number
	space: "local" | "world"
}

export namespace AESequences {

	// public object: Object3D | undefined
	// update(dt: number) { }
	// set(obj: Object3D) { }
	// reset() { }

	export function translationPreset(params: AETranslate, obj: Object3D): void {
		const off = params.offset
		const initialY = obj.position.y
		const engine = getEngine()
		engine.loop.register((dt) => {
			const t = performance.now() / 1000
			obj.position.y = initialY + (Math.sin((t + off) * params.speed) + 1) * params.amplitude
		})
	}

	export function rotationPreset(params: AERotate, obj: Object3D): void {
		const engine = getEngine()
		if (params.space == "local") {
			const fnName = "rotate" + params.axis

			const off = params.offset
			// @ts-ignore
			obj[fnName](off);
			engine.loop.register((dt) => {
				// @ts-ignore
				obj[fnName](dt * params.speed)
			})
		} else {
			const UP = UPS[params.axis]
			const off = params.offset
			obj.rotateOnWorldAxis(UP, off)
			engine.loop.register((dt) => {
				obj.rotateOnWorldAxis(UP, dt * params.speed)
			})
		}
	}

	/**
	 * retuns a seeded number in range [0; 1] meaing its randome but consistent between sessions
	 */
	export function seedRndm(): number {
		return rng()
	}
}





export default class AnimationExtension {

	public readonly specProp: string = "AnimationID"
	// private readonly _animations: AESequenceType[] = []

	constructor(scene: Object3D, aniMap: { [LightID: string]: (obj: Object3D) => any }) {

		// const engine = getEngine()
		// engine.loop.register((dt) => {
		// 	for (const a of this._animations) a(dt)
		// })

		const aniObjs = findObjects(scene, testUserData(this.specProp))
		for (const aniObj of aniObjs) {
			const aniId = aniObj.userData[this.specProp]
			const aniSeq = aniMap[aniId]
			if (!aniSeq) { continue; }

			// make sure to have matrix update enabled
			aniObj.matrixAutoUpdate = true
			aniSeq(aniObj)


			// if (aniId.startsWith("rotateLocal")) {
			// 	setupRotation(aniObj, aniId[aniId.length - 1], true)
			// }
			// if (aniId.startsWith("rotateGlobal")) {
			// 	setupRotation(aniObj, aniId[aniId.length - 1], false)
			// }
		}

		// check for unused lights registrations
		// for (const lid of Object.keys(aniMap)) {
		// 	if (!aniObjs.find((s) => s.userData[this.specProp] === lid)) {
		// 		Logging.warn("No aniObj found for aniId " + lid)
		// 	}
		// }
	}
}
