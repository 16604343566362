import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { useSpaceStore } from "~/store/space";


export function blockForFrontend() {
	const engine = getEngine()
	engine.player.getInputController<PlayerCharacter>().blockInput();
	engine.inputManager.unLockCursor();
}

export function unblockFromFrontend() {
	const engine = getEngine()
	engine.player.getInputController<PlayerCharacter>().unblockInput();
	engine.inputManager.lockCursor();
}


/**
 * Action keys are keys that are used ingame to interact with the world or others. e.g. key to trigger dance
 */
export function canUseActionKey(): boolean {
	const space = getSpace();
	const engine = getEngine()
	const spaceStore = useSpaceStore()
	if (space.browser.isMobile) return false;
	var ok = !isAnyTextInputActive() && spaceStore.onboardingDone && !spaceStore.showMenu
	if (engine.inputManager.mouseInputTracker.usePointerLock) ok = ok && engine.gui.isPointerLocked()
	return ok
}

export function canAnswerVoiceChat(): boolean {
	const space = getSpace();
	const spaceStore = useSpaceStore()
	if (space.browser.isMobile) return false;
	var ok = !isAnyTextInputActive() && spaceStore.onboardingDone && !spaceStore.showMenu
	return ok
}
