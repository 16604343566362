<template>
	<div class="center">
		<div class="box">
			<div class="row">
				<p>Avatar</p>
				<div class="avatar" :style="'background-image: url(' + spaceStore.avatar.preview + ')'">
					<IconLoader v-if="spaceStore.avatar.preview == 'loading'">
					</IconLoader>
				</div>
				<div class="btn-avatar" @click="spaceStore.showAvatarGenerator = true">Avatar ändern</div>
			</div>
			<div class="row">
				<p>Username</p>
				<div class="input">
					<form autocomplete="off" method="post" action="">
						<input autocomplete="false" name="hidden" type="text" style="display:none;">
						<input id="username" type="text" :placeholder="playerName" v-model="playerName" maxlength="15" autocomplete="new-password">
					</form>
				</div>
				<p class="mini link" v-if="!showNameInfo" @click="showNameInfo = true">Mehr Infos zum Username</p>
				<p class="mini" v-if="showNameInfo">Du kannst dir einen eigenen Username geben, mit dem du für andere Teilnehmende sichtbar bist. Bitte nutze daher nicht deinen richtigen Namen, dein Geburtsdatum oder ähnliche Daten, die dich identifizieren können.</p>
				<p class="mini link" v-if="showNameInfo" @click="showNameInfo = false">Weniger anzeigen</p>
			</div>
			<div class="row">
				<p>Grafik</p>
				<div class="w">
					<div :class="quality == 'low' ? 'active' : ''" @click="quality = 'low'">Low</div>
					<div :class="quality == 'mid' ? 'active' : ''" @click="quality = 'mid'">Fair</div>
					<div :class="quality == 'high' ? 'active' : ''" @click="quality = 'high'">High</div>
				</div>
			</div>
			<div class="row" v-if="!MyEnv.$isMobile">
				<p>Steuerung</p>
				<div class="w2">
					<div :class="spaceStore.usePointJumpMode ? '' : 'active'" @click="spaceStore.usePointJumpMode = false">Gamer</div>
					<div :class="spaceStore.usePointJumpMode ? 'active' : ''" @click="spaceStore.usePointJumpMode = true">Point&Click</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { isMobile } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';

const spaceStore = useSpaceStore()
const { quality, playerName } = storeToRefs(spaceStore)

const showNameInfo = ref(false)

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.center {
	@extend .flex-center;
	flex-direction: column
}



.box {
	@extend .flex-center;
	flex-direction: column;
	color: $main;
	margin-top: 30px;
	width: 340px;
	max-width: 100%;
	background: rgba(8, 8, 8, 0.3);

	div {
		@extend .flex-center;
		flex-direction: column;
	}

	p {
		color: $main;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.input {
		width: calc(100% - 100px);
		border: none;
	}

	.row {
		border-bottom: 1px solid $lineColor;
		width: 100%;
		padding: 20px 0;

		&:last-child {
			border-bottom: none;
		}
	}

	.btn-avatar {
		width: auto;
		height: 30px;
		border: $stroke solid $main;
		background: transparent;
		color: $main;
		cursor: pointer;
		@extend .flex-center;
		border-radius: $radius;
		margin: 4px 8px;
		padding: 0 12px;
		font-size: 12px;
		font-weight: 600;

		&:hover {
			background: $main;
			color: $dark;
		}
	}

	.w,
	.w2 {
		@extend .flex-center;
		width: 100%;
		flex-direction: row;

		div {
			width: 50px;
			height: 30px;
			border: $stroke solid $main;
			background: transparent;
			color: $main;
			cursor: pointer;
			@extend .flex-center;
			border-radius: $radius;
			margin: 4px 8px;
			padding: 0 0 2px 0;
			font-size: 12px;
			font-weight: 600;

			&:hover {
				opacity: 0.7;
			}

			&.active {
				background: $main;
				color: $dark;
			}
		}
	}

	.w2 {
		div {
			width: auto !important;
			padding: 0 12px;
		}
	}
}

.mini {
	text-align: center;
	margin-top: 12px;
	padding: 0 6px;
}

.mini.link {
	text-decoration: underline;
	cursor: pointer;
}

@media(max-width:850px) {
	#settings {
		.box {
			width: 450px;

			.row {
				padding: 10px 0 15px;
			}
		}
	}
}

@media(max-width:700px) {
	#settings {
		.box {
			.avatar {
				width: 40px;
				height: 40px;
			}

			.btn-avatar {
				height: auto;
				padding: 5px 10px;
				font-size: 11px;
			}

			#username {
				font-size: 14px;
				padding: 6px 20px;
			}
		}
	}
}

@media(max-height:790px) {
	#settings {
		.box {
			.row {
				padding: 10px 0 15px;
			}
		}
	}
}

@media(max-height:700px) {
	#controls {
		.c {
			svg {
				width: 30px;
			}
		}
	}
}
</style>
