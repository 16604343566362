<template>
	<Transition name="fade">
		<div v-if="hint.title" id="hint--wrapper" :class="MyEnv.$isMobile ? 'mobile' : ''">
			<div id="hint" @click="hintClicked()">
				<p style="font-size: 18px; font-weight: 600;">{{ hint.title }}</p>
				<p v-if="hint.text" class="hintSubTex">{{ hint.text }}</p>
				<p v-else-if="MyEnv.$gamepadActive" class="hintSubTex btn-image">Drücke <img src="/SpaceShared/img/buttons/y.png">zum {{ actionKeyworld }}</p>
				<p v-else-if="MyEnv.$isMobile" class="hintSubTex">Drücke zum {{ actionKeyworld }}</p>
				<p v-else class="hintSubTex"><span>LEERTASTE</span> zum {{ actionKeyworld }}</p>
			</div>
		</div>
	</Transition>
</template>


<script setup lang="ts">
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';

const space = getSpace()
const spaceStore = useSpaceStore()

const { hint } = storeToRefs(spaceStore)
const actionKeyworld = ref(hint.value.actionKeyworld || "interagieren")

function hintClicked() {
	space.pciSystem.triggerNearestInteract()
}
</script>


<style scoped lang="scss">
@import "assets/css/variables";

.hintSubTex {
	font-size: 12px;
	margin-top: 6px;
	font-weight: 500;

	span {
		background: $main;
		color: $dark;
		font-weight: 700;
		padding: 2px 7px 2px 9px;
		margin-right: 4px;
		margin-left: 4px;
		border-radius: 4px;
	}
}

.btn-image {
	@extend .flex-center;

	img {
		width: 20px;
		height: auto;
		margin: 0 4px;
		transform: translateY(-1px);
	}
}

#hint--wrapper {
	position: fixed;
	width: 100vw;
	left: 0;
	bottom: 0;
	position: fixed;
	text-align: center;
	padding: 10px;
	flex-direction: row;
	z-index: 99;
}

#hint {
	display: inline-block;
	width: 200px;
	background: $mainTransparent;
	border-radius: $radius;
	@extend .flex-center;
	padding: 14px 23px;
	width: 250px !important;
	max-width: 280px;

	p {
		font-weight: 500;
		color: $main;
	}
}

#hint--wrapper.mobile {
	bottom: 20px;
}
</style>
