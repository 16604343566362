import { Logging, LoggingArea } from "@ravespaceio/rave-engine"
import { StoreState } from "pinia"


// local storage getter setter wrapper
const prefix = "__raveengine__" // + space.project.spaceId + "__"

export function getLsItem(key: string): any | undefined {
	const l = localStorage.getItem(prefix + key)
	if (!l) return undefined
	try { return JSON.parse(l) }
	catch { return l }
}


export function setLsItem(key: string, value: any): void {
	localStorage.setItem(prefix + key, JSON.stringify(value))
}


/**
 * for ref with object
 *
 * helper function that watches on state change and saves to localStorage
 * reads localStorage initially
 *
 * @param ref
 * @param key
 * @param check check that must pass before an item is loaded from or saved to localStorage
 */
export function setupReactiveLocalStorageRef<T extends { [key: string]: any }>(ref: Ref<T>, key: keyof T, check?: (x: any) => boolean) {
	setupReactiveLocalStorage(ref.value, key as string, check)
}

/**
 * for ref with value or store
 * (has no type support yet)
 */
export function setupReactiveLocalStorage<T extends Object>(ref: StoreState<T>, key: string, check?: (x: any) => boolean) {
	const k = key.toString()
	const lsValue = getLsItem(k)
	if (lsValue !== undefined) {
		if (!check) {
			ref[key] = lsValue
		} else {
			if (check(lsValue)) {
				ref[key] = lsValue
			} else {
				Logging.warn(`RLS ${k} read check failed`, LoggingArea.Space)
			}
		}
	}

	watch(() => ref[key], (state) => {
		const k = key.toString()
		if (!check) {
			setLsItem(k, state)
		} else {
			if (check(state)) {
				setLsItem(k, state)
			} else {
				Logging.warn(`RLS ${k} write check failed`, LoggingArea.Space)
			}
		}
	}, { deep: true, immediate: true })
}

