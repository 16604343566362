import Engine, { MyLoaders } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"
import { useGameStore } from "~/store/game"




export function setupAudio() {
	const engine = getEngine()
	const space = getSpace()
	const loader = space.loader

	const waterPath = "/dek/audio/waterfall.mp3"
	const birdPath = '/dek/audio/ambient.mp3'
	const hrPath = '/dek/audio/hr.mp3'
	loader.addFiles([waterPath, birdPath, hrPath])

	engine.audioManager.setupPromise.then(() => {
		let waterfallSound: THREE.PositionalAudio
		let birdSound: THREE.PositionalAudio
		let hrMusic: THREE.PositionalAudio

		waterfallSound = new THREE.PositionalAudio(engine.audioManager.listener!)
		loader.getLoadingPromise(waterPath).then((buffer) => {
			waterfallSound.setBuffer(buffer);
			waterfallSound.play()
			waterfallSound.setVolume(0)
			waterfallSound.setRefDistance(1);
			waterfallSound.setMaxDistance(6)
			waterfallSound.setLoop(true)
			waterfallSound.setRolloffFactor(1.5)
			waterfallSound.position.set(11.0, 0, 11.9)
			engine.scene.add(waterfallSound);
			setTimeout(() => { waterfallSound.setVolume(0.4) }, 1000) // warum ist der sound beim starten so laut? player, camera und auio listener sind eig korrekt
		})

		birdSound = new THREE.PositionalAudio(engine.audioManager.listener!)
		loader.getLoadingPromise(birdPath).then((buffer) => {
			birdSound.setBuffer(buffer);
			birdSound.setVolume(0)
			birdSound.play()
			birdSound.setRefDistance(10);
			birdSound.setMaxDistance(100)
			birdSound.setLoop(true)
			birdSound.setRolloffFactor(1)
			birdSound.position.set(3.68, 7, -9.136)
			engine.scene.add(birdSound);
			setTimeout(() => { birdSound.setVolume(0.2) }, 1000)
		})


		hrMusic = new THREE.PositionalAudio(engine.audioManager.listener!)
		loader.getLoadingPromise(hrPath).then((buffer) => {
			hrMusic.setBuffer(buffer);
			hrMusic.setVolume(0)
			hrMusic.play()
			hrMusic.setRefDistance(3);
			hrMusic.setMaxDistance(100)
			hrMusic.setLoop(true)
			hrMusic.setRolloffFactor(1)
			hrMusic.position.set(0, 4, -28.49)
			engine.scene.add(hrMusic);
		})

		const store = useGameStore()
		watch(() => store.navZone, (zone) => {
			if (zone == "hr") {
				hrMusic.setVolume(0.3)
			} else {
				hrMusic.setVolume(0)
			}

			if (zone == "stage" || zone == "q1" || zone == "q2" || zone == "q3") {
				birdSound.setVolume(0)
				waterfallSound.setVolume(0)
			} else {
				birdSound.setVolume(0.2)
				waterfallSound.setVolume(0.4)
			}

		}, { immediate: true })




	})
}

