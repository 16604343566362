import { isMobile, isTouchDevice } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import * as THREE from "three"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"

// exported stuff is unimported
// used for libraries, but also for global reactive variables?
export const MyEnv = {
	$isMobile: isMobile(),
	$isProd: process.env.NODE_ENV === 'production',
	$gamepadActive: false
}

window.addEventListener("gamepadconnected", function (e) {
	MyEnv.$gamepadActive = true
});
window.addEventListener("gamepaddisconnected", function (e) {
	MyEnv.$gamepadActive = false
});

export function isValidEmail(email) {
	const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	return emailPattern.test(email);
}


export function replaceMaterial(mesh, image) {
	const engine = getEngine()
	const space = getSpace()
	const mat = new THREE.MeshBasicMaterial()
	mesh.material = mat
	mat.map = getMatMap(space.loader, image, { encoding: THREE.sRGBEncoding });
	mat.color.setHex(0xFFFFFF);
	mat.needsUpdate = true;
}
