import { Mesh, Material, MeshBasicMaterial, Scene, Texture, Vector2, Vector3, MeshStandardMaterial } from "three"
import Engine, { EngineUtils, VertexWindDisplacer, Loader, Logging, LoggingArea, VolumetricLightMaterial } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { findAllMaterials, findMaterial, findMesh, findMeshs, testName, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings"
import { setupMaterialBlending } from "../_lib/shader/blend"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures"
import { getSpace } from "../space"
import { getEngine } from "../engine"
import { applyMaterials, setupAoMapLightMapQualityManager } from "../_lib/mechanic/materials"
import { setupAlwaysPlayerFacing } from "~/space/_lib/shader/billboard"
import { setupWaterMesh } from "~/space/_lib/shader/water"
import { getProjectEnvMap, getTexBasePath, setupAvatarEnvMapAndShadow, setupAvatarShadow, setupEnvMapAvatar } from "~/space/_lib/mechanic/textures"


export function setupMaterials(): (group: THREE.Object3D) => void {

	const space = getSpace()
	const engine = getEngine()

	const loader = space.loader
	const texBasePath = getTexBasePath()
	const envMap = getProjectEnvMap()

	// setupAvatarEnvMapAndShadow(envMap)
	setupEnvMapAvatar(envMap);
	setupAvatarShadow();
	const MATS = {
		Glass: new MeshStandardMaterial({
			color: 0x38475c,
			metalness: 1,
			roughness: 0,
			transparent: true,
			opacity: 0.6,
			depthTest: true,
			depthWrite: false,
			envMap: envMap,
		}),
		Glass_Dark: new MeshStandardMaterial({
			color: 0x5e5e5e,
			metalness: 1,
			roughness: .6,
			transparent: true,
			opacity: 0.8,
			depthTest: true,
			depthWrite: false,
		}),
		Metal: new MeshStandardMaterial({
			color: 0xFFFFFF,
			roughnessMap: getMatMap(loader, `${texBasePath}/metal_rough.jpg`, { encoding: THREE.sRGBEncoding }),
			metalness: 1,
			roughness: 1,
			envMap: envMap,
		}),
		Glossy_White: new MeshStandardMaterial({
			color: 0xFFFFFF,
			roughnessMap: getMatMap(loader, `${texBasePath}/surface_rough.jpg`, { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Black: new MeshStandardMaterial({
			color: 0x111111,
			roughnessMap: getMatMap(loader, `${texBasePath}/surface_rough.jpg`, { encoding: THREE.sRGBEncoding }),
			metalness: 0,
			roughness: 1,
		}),
		Env_Buildings: new MeshStandardMaterial({
			color: 0x4C8299,
			metalness: 1,
			roughness: .1,
			envMap: envMap,
			envMapIntensity: 1,
		}),
		Base: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/concrete_new_base.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/concrete_new_rough.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/concrete_new_normal.jpg`,),
			metalness: 0,
			roughness: 1,
		}),
		Concrete_Ground: new MeshStandardMaterial({
			color: 0xc2ebff,
			map: getMatMap(loader, `${texBasePath}/DefaultMaterial_Base_color.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/DefaultMaterial_Roughness.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/DefaultMaterial_Normal_OpenGL.jpg`,),
			metalness: 0,
			roughness: 1,
		}),
		Deka: new MeshStandardMaterial({
			color: 0xe00000,
			roughnessMap: getMatMap(loader, `${texBasePath}/concrete_new_rough.jpg`,),
			metalness: 0,
			roughness: 1,
		}),
		Grass: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/grass_base.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/grass_rough.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/grass_normal.jpg`,),
			metalness: 0,
			roughness: 1,
		}),
		Cloth: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/fabric_base.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/fabric_rough.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/fabric_normal.jpg`,),
			metalness: 0,
			roughness: 1,
			side: THREE.DoubleSide,
		}),
		Wood: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/Wood_Natural_Rough_BaseColor.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/Wood_Natural_Rough_Roughness.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/Wood_Natural_Rough_Normal.jpg`,),
			metalness: 0,
			roughness: 1,
		}),
		Water: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/water_base.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/water_rough.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/water_normal.jpg`,),
			metalness: 0,
			roughness: 1,
			envMap: envMap,
		}),
		Treepack_Classic: new MeshStandardMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/treepack_classic_base.jpg`, { encoding: THREE.sRGBEncoding }),
			roughnessMap: getMatMap(loader, `${texBasePath}/treepack_classic_rough.jpg`,),
			normalMap: getMatMap(loader, `${texBasePath}/treepack_classic_normal.jpg`,),
			alphaMap: getMatMap(loader, `${texBasePath}/treepack_classic_alpha.jpg`,),
			alphaTest: .8,
			metalness: 0,
			roughness: 1,
			side: THREE.DoubleSide,
			envMap: envMap,
			envMapIntensity: .4,
		}),
		Window: new MeshBasicMaterial({
			color: 0x444444,
			map: getMatMap(loader, `${texBasePath}/window.png`, { encoding: THREE.sRGBEncoding }),

		}),
		Screen: new MeshBasicMaterial({
			color: 0x12181c,
			// map: getMatMap(loader, `${space.project.publicPath}/textures/screen.jpg`, { encoding: THREE.sRGBEncoding }),
		}),
		Wall: new MeshBasicMaterial({
			color: 0xFFFFFF,
			map: getMatMap(loader, `${texBasePath}/wall.jpg`, { encoding: THREE.sRGBEncoding }),
		}),
		Emission: new MeshBasicMaterial({
			color: 0xFFFFFF,
		}),
		Gradient: new MeshBasicMaterial({
			color: 0xFFFFFF,
			alphaMap: getMatMap(loader, `${space.project.publicPath}/textures/gradient.jpg`, { encoding: THREE.sRGBEncoding }),
			transparent: true,
			side: THREE.DoubleSide,
		})
	}

	MATS.Metal.userData.__noQualityTransfer = true
	MATS.Water.userData.__noQualityTransfer = true;

	space.gui.addToSceneFolder(MATS.Water)
	space.gui.addToSceneFolder(MATS.Deka)
	space.gui.addToSceneFolder(MATS.Water)
	space.gui.addToSceneFolder(MATS.Glass)
	space.gui.addToSceneFolder(MATS.Glass_Dark)
	space.gui.addToSceneFolder(MATS.Concrete_Ground)



	engine.loop.register((dt, t) => {

		if (MATS.Gradient.alphaMap) {
			MATS.Gradient.alphaMap.offset.y += dt
		}

		if (MATS.Water.map) {
			MATS.Water.map.offset.y -= (dt / 10)
		}

	})

	const PER_MESH_SHADER = {
		water: (m: Mesh) => {
			setupWaterMesh(m)
		},
		bilboard: (m: Mesh) => {
			m.userData.__noQualityTransfer = true
			setupAlwaysPlayerFacing(m)
		},
		blend: (m: Mesh) => {
			if (m.userData.REID == "xxx") setupMaterialBlending(m, MATS.Water.clone(), MATS.Base.clone())
		},
		volumetric: (m: Mesh) => {
			if (m.userData.REID == "city") {
				const vMat = m.material = new VolumetricLightMaterial(m.getWorldPosition(new Vector3()), new THREE.Color(0xFF00FF))
				vMat.uniforms.attenuation.value = 10
				vMat.uniforms.anglePower.value = 2
				vMat.userData.__noQualityTransfer = true
				space.gui.addToSceneFolder(vMat)
			}
		},
	}
	const PER_SCENE_SHADER = {
		vertexWind: (m: Mesh[]) => {
			const vwd = new VertexWindDisplacer(m)
			engine.loop.registerI(vwd)
			VertexWindDisplacer.addToGui(space.gui, vwd)
			// vwd.intensity = 2 // TODO

		}
	}


	return (scene) => {
		applyMaterials(scene, MATS, PER_MESH_SHADER, PER_SCENE_SHADER)
		setupAoMapLightMapQualityManager(scene, 1, 3)
	}
}

