<template>
	<div id="chat-wrap" class="ui" :class="[isChattingDesktop ? 'isChatting' : '', MyEnv.$isMobile ? 'mobile' : '']" v-show="forceShow">

		<IconCloseBlack v-if="MyEnv.$isMobile" @click="spaceStore.showChatMobile = false" class="close" />

		<div id="chat" ref="chatEl">
			<p v-for="(chat, i) in chats" :key="i">
				<span :style="chat.senderClientId == mpStore.playerId ? 'color: white;' : chat.hrSupport === true ? 'color: yellow;' : ''">
					{{ chat.senderPlayerName }}
				</span>

				{{ chat.chatMessage.replaceAll(HR_MESSAGE_TOKEN, "") }}
			</p>
		</div>

		<div class="input-wrap" v-if="mpStore.isConnected">
			<p v-if="!MyEnv.$isMobile" :class="!mpStore.isConnected && 'offline'">Drücke Enter zum Chatten</p>
			<form autocomplete="off" method="post" action="">
				<input autocomplete="false" name="hidden" type="text" style="display:none;">
				<input id="chat-input" ref="chatInputEl" ype="text" role="presentation" autoComplete="off" maxlength="100">
			</form>
			<div class="online-wrap">
				{{ mpStore.isConnected ? "Online" : "Offline" }}<span :class="!mpStore.isConnected && 'offline'"></span>{{ mpStore.isConnected ? mpStore.playerAmountGlobalRoom : "" }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter'
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes";
import { getEngine } from '~/space/engine'
import { getSpace } from '~/space/space'
import { useMultiplayerStore } from '~/store/multiplayer'
import { useSpaceStore } from '~/store/space'
import { canUseActionKey } from '~/space/_lib/utils/frontend'
import { validateText } from '~/assets/js/text'
import { HR_MESSAGE_TOKEN } from '~/store/multiplayer';

const space = getSpace()
const engine = getEngine()
const toast = useToast()
const spaceStore = useSpaceStore()
const mpStore = useMultiplayerStore()

const { chats } = storeToRefs(mpStore)

const isChattingDesktop = ref(false) // for chat animation desktop
const forceShow = ref(true)

const chatInputEl = ref<HTMLInputElement>();
const chatEl = ref<HTMLDivElement>()

// open close with keyboard
space.eventbinder.bindDocument('keydown', (event) => {
	if (event.key == "Enter" && mpStore.isConnected) {
		if (space.browser.isMobile) {
			sendMsg()
		} else {
			if (!isChattingDesktop.value && canUseActionKey()) {
				isChattingDesktop.value = true
				engine.player.getInputController<PlayerCharacter>().blockInput();
				chatInputEl.value.focus()
			} else if (isChattingDesktop.value) {
				sendMsg()
				isChattingDesktop.value = false
				engine.player.getInputController<PlayerCharacter>().unblockInput();
				chatInputEl.value.blur();
			}
		}
	}
})

// send msg
let canSend = true

function sendMsg() {
	if (canSend) {
		let text = chatInputEl.value.value
		if (text.trim() != "") {
			canSend = false
			setTimeout(() => {
				canSend = true
			}, 5000);
			if (!validateText(text)) {
				toast.error("Please do not be that mean.")
			} else {
				if (mpStore.isHrSupport)
					text = HR_MESSAGE_TOKEN + text;

				engine.multiplayer.local_netPlayer.sendGlobaChatMessage(text);
			}
		}
	} else {
		const text = chatInputEl.value.value
		if (text.trim() != "") {
			useToast().error("Du kannst nur alle 5s eine Nachricht schicken")
		}
	}

	chatInputEl.value.value = ""
}

space.eventbus.onAlready("hideChat", (data: any) => {
	if (!data) {
		forceShow.value = true
	} else {
		forceShow.value = false
	}
})

</script>

<style scoped lang="scss">
@import "assets/css/variables";

#chat-wrap {
	position: fixed;
	left: 10px;
	bottom: 10px;
	z-index: 10;
	pointer-events: none;

	#chat {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-start;
		width: 350px;
		overflow: hidden;
		transition: ease 0.3s;
		height: 600px;

		p {
			background: $mainTransparent;
			margin-bottom: 6px;
			border-radius: $radius;
			padding: 8px 15px;
			color: white;
			font-size: 12px;
			display: none;
			word-break: break-all;

			span {
				color: white;
				font-weight: 600;
			}
		}

		p:nth-last-child(-n+7) {
			display: block;
		}

		p:nth-last-child(-n+7) {
			opacity: 0.2;
		}

		p:nth-last-child(-n+6) {
			opacity: 0.4;
		}

		p:nth-last-child(-n+5) {
			opacity: 0.6;
		}

		p:nth-last-child(-n+4) {
			opacity: 0.8;
		}

		p:nth-last-child(-n+3) {
			opacity: 1;
		}
	}

	.input-wrap {
		background: $mainTransparent;
		border-radius: $radius;
		color: white;
		font-size: 13px;
		position: relative;
		height: 40px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		p:first-child {
			position: absolute;
			left: 15px;
			text-align: left;
			font-size: 13px;
			font-weight: 600;
			color: white;
		}

		p.offline {
			opacity: 0.3;
			pointer-events: none;
		}

		form {
			height: 100%;
			width: calc(100% - 100px);
			position: absolute;
			left: 0;
			top: 0;

			input {
				position: absolute;
				appearance: none;
				border: none;
				outline: none;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				padding: 8px 15px;
				text-align: left;
				font-size: 14px;
				font-weight: 600;
				color: white;
				word-break: break-all;
				background: transparent;
			}
		}

		.online-wrap {
			position: absolute;
			right: 0;
			width: 100px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			color: white;
			font-weight: 600;
			border-left: 1px solid $lineColor;

			span {
				display: inline-block;
				background: $green;
				width: 5px;
				height: 5px;
				border-radius: 100%;
				margin: 0 4px;
			}

			span.offline {
				background-color: red;

			}
		}
	}

	&.isChatting {

		.input-wrap {
			p:first-child {
				display: none;
			}
		}
	}

	&.mobile {
		background: rgba(255, 255, 255, 0.95);
		width: 100%;
		left: 0;
		bottom: 0;
		height: 100%;
		padding-bottom: 50px;
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 50px;
		opacity: 1 !important;
		transform: translateY(0) !important;
		pointer-events: auto;

		#chat {
			height: calc(100vh - 200px);

			p {
				opacity: 1;
				display: block;
			}
		}
	}
}

.close {
	z-index: 99;
}
</style>
