<template>
	<SpaceModal :show=showApplicationModal @closed="showApplicationModal = false">
		<div class="content">
			<h1>
				<IconLogo />
				HR Kontakt
			</h1>
			<p>Du hast keine passende Stelle gefunden oder wünscht dir noch mehr Infos? 💡 Dann besuche jetzt unsere Karriereseiten oder schreibe unseren HR-Expert:innen eine E-Mail 📬 Wir helfen dir sehr gerne weiter 💕</p>
			<div class="btn--wrapper">
				<a href="mailto:recruiting@deka.de" class="btn">E-Mail schreiben</a>
				<a href="https://www.deka.de/deka-gruppe/deka-als-arbeitgeber/karriere-bei-uns" target="_blank" class="btn">Mehr Infos</a>
			</div>
			<!-- <template v-if="!showForm">
				<p>Du hast keine Stelle gefunden, die zu dir passt oder wünschst dir noch mehr Infos? Dann bist du hier genau richtig 🧑‍🎓💡Du hast die Möglichkeit, mit unserem Recruiting-Team in Kontakt zu bleiben, und erhältst dann nach dem Event eine Mail mit Infos zu unseren Einstiegsmöglichkeiten 📬</p>
				<p>Wenn du auf die Mail antwortest, kannst du dich direkt mit unseren HR-Expert:innen connecten, um alle Fragen zu stellen, die dir vielleicht noch eingefallen sind 🧐 Registriere dich jetzt, indem du auf den untenstehenden Button klickst und deine Kontaktdaten eingibst 💌</p>

				<div class="btn" @click="showForm = true;">Zur Anmeldung für die HR-Infomail</div>
			</template>
			<template v-else>
				<form class="form" action="">
					<p class="label">E-Mail</p>
					<input type="text" class="input" placeholder="Email" v-model="email">
					<p class="label">Vorname</p>
					<input type="text" class="input" placeholder="Vorname" v-model="firstName">
					<p class="label">Nachname</p>
					<input type="text" class="input" placeholder="Nachname" v-model="lastName">
					<p style="font-size: 12px;">Ja, ich möchte von der HR-Abteilung der DekaBank Deutsche Girozentrale per E-Mail Informationen zu beruflichen Einstiegschancen erhalten. Ich kann diese Einwilligung jederzeit für die Zukunft widerrufen.</p>
					<div class="btn--dark" @click="submitForm()" :class="loading ? 'disabled' : ''">Absenden</div>
					<p style="margin-top: 12px;color: black;">Es gilt unser <a style="margin-top: 12px;color: black;" href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a></p>
					<p style="font-size: 12px;">Wir verwenden deinen Namen nur, damit wir dich persönlich ansprechen können.</p>
				</form>
				<p class="disclaimer" @click="showForm = false">Zurück</p>
			</template> -->
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import HighlightCylinder from '@ravespaceio/rave-engine/build/engine/src/objects/other/HighlightCylinder';
import { getEngine } from '~/space/engine';
import { SphereCollider } from '@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem';
import { Static } from 'nuxt/dist/app/compat/capi';
import { createNPC } from '~/space/_lib/mechanic/npc';
import * as THREE from "three"
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
const space = getSpace()
const engine = getEngine()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showApplicationModal } = storeToRefs(spaceStore)

const showForm = ref(false)

const firstName = ref("")
const lastName = ref("")
const email = ref("")
const loading = ref(false)



const hcApplication = new HighlightCylinder()
engine.scene.add(hcApplication)
hcApplication.scale.set(0.7, 1, 0.7)
hcApplication.position.set(-9.5, 2, -21.4)
space.gui.addToSceneFolder(hcApplication)

space.pciSystem.add({
	collider: new SphereCollider(hcApplication, 2),
	onColliderEnter: () => {
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
		spaceStore.hint.title = "HR Kontakt"
	},
	onColliderLeave: () => {
		spaceStore.hint.title = undefined
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
	},
	onInteract: () => {
		spaceStore.showApplicationModal = true
		spaceStore.hint.title = undefined
		space.eventbus.emit("newAnalytics", "openBewerbung")
	}
})

const avatar = createNPC({
	name: "npc_hr",
	model: "/dek/avatars/models/5.glb",
	gender: "feminine",
	position: new THREE.Vector3(-10.7, 2, -20.2),
	rotation: 0.2,
	animation: "idle_dancing",
	scale: 1
}, true)
space.gui.addToSceneFolder(avatar)
engine.loop.register(() => {
	avatar.lookAt(engine.player.getPlayerPosition())
})


function submitForm() {

	if (!isValidEmail(email.value)) {
		alert("Deine E-Mail Adresse ist nicht korrekt.")
		return
	}

	if (firstName.value.length < 1 || lastName.value.length < 1) {
		alert("Bitte fülle alle Felder aus.")
		return
	}
	loading.value = true
	var requestData = {
		"firstName": firstName.value,
		"lastName": lastName.value,
		"email": email.value
	};

	var xhr = new XMLHttpRequest();
	var url = "https://dek-api.ravespace.io/create.php";

	xhr.open("POST", url, true);

	xhr.onload = function () {
		if (xhr.status === 200) {
			var response = JSON.parse(xhr.responseText);
			console.log("API Response:", response);
			addToList("2700240038")
		} else {
			console.error("API Error:", xhr.status, xhr.statusText);
			useToast().error("Es ist etwas schief gelaufen.")
		}
	};

	xhr.onerror = function () {
		console.error(xhr.statusText);
		useToast().error(xhr.statusText)
		loading.value = false
	};

	var requestDataJSON = JSON.stringify(requestData);
	xhr.send(requestDataJSON);
}


function addToList(id) {
	if (email.value) {
		const apiURL = "https://dek-api.ravespace.io/update.php";

		let optIn = "DOUBLE_OPT_IN"

		var requestData = {
			"groupId": id,
			"email": email.value,
			"optIn": optIn
		};

		var xhr = new XMLHttpRequest();
		xhr.open("POST", apiURL, true);
		xhr.onload = function () {
			console.log(xhr)
			if (xhr.status === 204) {
				useToast().success("Du hast dich erfolgreich eingetragen!")
			} else {
				useToast().success("Du hast dich erfolgreich eingetragen!")
			}
		};
		xhr.onerror = function () {
			console.error(xhr.statusText);
		};
		var requestDataJSON = JSON.stringify(requestData)
		xhr.send(requestDataJSON);

	} else {
		console.log("Not worked")
		loading.value = false
	}
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.btn--wrapper {
	margin: 20px 0;
	margin-top: 40px;

	.btn {
		margin: 10px;
	}
}

.content {
	height: auto;
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	h1 {
		margin-bottom: 12px;
		@extend .flex-center;

		svg {
			width: 100px;
			height: auto;
			margin-right: 12px;
			transform: scale(1);

			::v-deep(*) {
				fill: $red;
			}
		}
	}

	p {
		text-align: center;
		margin: 10px 0;
	}

	.btn {
		margin-top: 12px;
	}
}

p.label {
	text-align: left;
	width: 100%;
	margin-bottom: 4px;
	font-size: 12px;
}

input {
	text-align: left;
	padding: 0 12px;
}

.disclaimer {
	margin-top: 12px;
	opacity: 0.6;
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;

	&:hover {
		opacity: 1;
	}
}
</style>
