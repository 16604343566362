import * as THREE from "three"
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import VRManager, { VRConfig } from "@ravespaceio/rave-engine/build/engine/src/helper/vr/VRManager"
import { useVoiceRolesStore } from "store/agora/voiceroles";

import { PlayerObject } from "@ravespaceio/rave-engine";
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { useGameStore } from "~/store/game";



export async function setupVR() {

	const space = getSpace();
	const engine = getEngine()
	space.eventbus.emit("qualitysetting", "low")
	useGameStore().questStage = 1

	await new Promise(resolve => space.loader.once("loaded", () => resolve(true)));
	const navmeshGltf = space.loader.getLoaded("navmesh")

	const navigationMeshes = findObjects(navmeshGltf.scene, testUserData("NavmeshID")) as Array<THREE.Mesh>;
	const allowedNavMeshIDs = engine.navigation.getActiveZones()
	// VR Manager ------------------

	const wrapper = document.createElement("div");
	wrapper.id = "VRButtonWrapper";
	document.body.append(wrapper);

	const btn = document.createElement("button");
	btn.id = "ReplacementVRButton";
	btn.innerText = "Enter VR";
	document.body.append(btn);
	engine.gui.registerFirstInteractElements([btn]);
	wrapper.append(btn);

	const config: VRConfig = {
		replacementVRButton: btn,
		language: "DE",
		dominantHand: "right",
		handUIEnabled: true,
		handUIImageFile: "/dek/textures/teleport-inverted.png",
		handUIColor: new THREE.Color(0x000000),
		teleportReach: 8,
		navMeshes: navigationMeshes,
		allowedNavMeshIDs: allowedNavMeshIDs,
	}

	const vr = new VRManager(engine, config);

	let firstTime = true;
	let entered = false
	vr.enterVREvent.on(() => {
		engine.player.camControllerM.currentController.isActive = false

		//engine.player.getPlayer<PlayerObject>().changeEntity(PlayerEntities.autoPlayer)
		engine.player.changeEntity<PlayerCharacter>('empty_entity');
		if (firstTime) {
			setTimeout(() => {
				entered = true
				vr.teleportToArea("StartZone")
				firstTime = false;
			}, 1);
		}

	})
	vr.leaveVREvent.on(() => {
		entered = false
		//engine.player.getPlayer<PlayerObject>().changeEntity(PlayerEntities.orbit)
		engine.player.changeEntity<PlayerCharacter>('physics_entity');
	})

	vr.revealVRButton()

	// Spawn ------------------

	vr.addArea("Start", new THREE.Vector3(19.83, 6.62, -18.88))
	vr.addArea("Masterclass", new THREE.Vector3(-13.19, 10.629, -3.56))
	vr.addArea("Stage", new THREE.Vector3(-4.640, 1, 27.36))
	vr.addArea("HR Area", new THREE.Vector3(-0.066, 1.39, -20.39))



	const helper = new THREE.Mesh(new THREE.SphereGeometry(0.5), new THREE.MeshBasicMaterial({ wireframe: true }))
	if (!space.ENV.IS_PROD) engine.scene.add(helper)
	engine.loop.register(() => {
		helper.position.copy(vr.player.position)
		if (entered) engine.player.getPlayerPosition().copy(vr.player.position)
	})
}

