<template>
	<div>
		<div id="cinescope" :class="spaceStore.onboardingDone && gameStore.questStage > 0 ? '' : 'active'">
			<div></div>
			<div></div>
		</div>
		<div id="introState" v-if="spaceStore.onboardingDone && gameStore.questStage == 0 && canvasclicked" :class="spaceStore.showIntroState ? 'active' : ''" @click="gameStore.introState++">
			<div class="progress" :style="'width: ' + gameStore.introState / 6 * 100 + '%'"></div>
			<h2>Intro {{ gameStore.introState + 1 }} von 6</h2>
			<p v-if="!space.browser.isMobile">
				<span>LEERTASTE</span> weiter
			</p>
			<p v-else>
				<span>Weiter</span>
			</p>
		</div>
		<div id="menu-hint-onboarding" v-if="spaceStore.onboardingDone && gameStore.questStage == 0" @click="spaceStore.showMenu = true">
			<div class="box"> <span>M</span>
				<p>Menü</p>
			</div>
		</div>

		<SpaceInterfaceVoiceRequest v-if="multiplayerEnabled" />
		<SpaceInterfaceWorldPointer />
		<SpaceModalTeleport />
		<SpaceInterfaceInventar v-if="spaceStore.showInventar" />

		<!-- UI Desktop -->
		<template v-if="!MyEnv.$isMobile">
			<div id="clickAnywhere" v-show="!spaceStore.pointerlocked && spaceStore.isAnyModal && spaceStore.onboardingDone && spaceStore.pointerlockMode" :style="'left: ' + mousePosX + 'px;top: ' + mousePosY + 'px;'">Klicke zum Starten</div>
			<div id="unhide" class="ui box"> <span class="key">H</span>
				<p>Unhide</p>
			</div>

			<SpaceInterfaceChat v-if="multiplayerEnabled" />

			<div v-if="multiplayerEnabled" id="multiplayerWrapper">
				<SpaceInterfaceVoiceRoleHint />
				<SpaceInterfaceVoiceInfo />
				<SpaceInterfaceMultiplayer />
			</div>

			<div id="ui-keys" class="ui">
				<div class="box" v-if="spaceStore.pointerlocked">
					<span>ESC</span>
					<p>Exit</p>
				</div>
				<div class="box">
					<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M18 8C16.8954 8 16 8.89543 16 10V18C16 19.1046 16.8954 20 18 20H26C27.1046 20 28 19.1046 28 18V10C28 8.89543 27.1046 8 26 8H18ZM22.433 12.25C22.2406 11.9167 21.7594 11.9167 21.567 12.25L19.8349 15.25C19.6425 15.5833 19.8831 16 20.268 16H23.7321C24.117 16 24.3575 15.5833 24.1651 15.25L22.433 12.25Z" fill="#000" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M26 35C27.1046 35 28 34.1046 28 33L28 25C28 23.8954 27.1046 23 26 23L18 23C16.8954 23 16 23.8954 16 25L16 33C16 34.1046 16.8954 35 18 35H26ZM21.567 30.75C21.7594 31.0833 22.2406 31.0833 22.433 30.75L24.1651 27.75C24.3575 27.4167 24.1169 27 23.732 27H20.2679C19.883 27 19.6425 27.4167 19.8349 27.75L21.567 30.75Z" fill="#000" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M43 25C43 23.8954 42.1046 23 41 23H33C31.8954 23 31 23.8954 31 25V33C31 34.1046 31.8954 35 33 35H41C42.1046 35 43 34.1046 43 33V25ZM38.75 29.433C39.0833 29.2406 39.0833 28.7594 38.75 28.567L35.75 26.8349C35.4167 26.6425 35 26.883 35 27.2679V30.732C35 31.117 35.4167 31.3575 35.75 31.1651L38.75 29.433Z" fill="#000" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M1 33C1 34.1046 1.89543 35 3 35H11C12.1046 35 13 34.1046 13 33V25C13 23.8954 12.1046 23 11 23H3C1.89543 23 1 23.8954 1 25L1 33ZM5.25 28.567C4.91667 28.7594 4.91667 29.2406 5.25 29.433L8.25 31.1651C8.58333 31.3575 9 31.1169 9 30.732V27.268C9 26.883 8.58333 26.6425 8.25 26.8349L5.25 28.567Z" fill="#000" />
					</svg>
					<p>Laufen</p>
				</div>
				<div class="box">
					<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21.3736 5C15.6418 5 11 9.64176 11 15.3736V26.6264C11 32.3582 15.6418 37 21.3736 37C27.1055 37 31.7473 32.3582 31.7473 26.6264V15.3736C31.7473 9.64176 27.1055 5 21.3736 5ZM29.2857 26.6264C29.2857 30.9868 25.7341 34.5385 21.3736 34.5385C17.0132 34.5385 13.4615 30.9868 13.4615 26.6264V15.3736C13.4615 11.0132 17.0132 7.46154 21.3736 7.46154C25.7341 7.46154 29.2857 11.0132 29.2857 15.3736V26.6264Z" fill="#F4D4FB" />
						<path d="M21.3733 11.3297C20.7052 11.3297 20.1426 11.8923 20.1426 12.5605V16.0769C20.1426 16.7451 20.7052 17.3077 21.3733 17.3077C22.0415 17.3077 22.6041 16.7451 22.6041 16.0769V12.5605C22.6041 11.8923 22.0415 11.3297 21.3733 11.3297Z" fill="#F4D4FB" />
					</svg>
					<p>Schauen</p>
				</div>
				<div class="box space">
					<span>SPACE</span>
					<p>Springen</p>
				</div>
				<div class="box" v-if="multiplayerEnabled">
					<span>1-9</span>
					<p>Tanzen</p>
				</div>
				<div class="box">
					<span>T</span>
					<p>Teleport</p>
				</div>
				<div class="box" v-if="multiplayerEnabled && spaceStore.canUseVoicechat">
					<span>V</span>
					<p>Sprechen</p>
				</div>
				<div class="box">
					<span>M</span>
					<p>Menü</p>
				</div>
			</div>
		</template>

		<!-- UI Mobile -->
		<template v-else>

			<div id="jump" @pointerdown="jump" @pointerup="jumpEnd" class="ui"></div>
			<SpaceInterfaceChat v-if="multiplayerEnabled && spaceStore.showChatMobile" />

			<SpaceInterfaceVoiceInfo v-if="multiplayerEnabled" class="mobile" />
			<SpaceInterfaceVoiceRoleHint v-if="multiplayerEnabled" />


			<div id="mobile--buttons">
				<div @click="spaceStore.showTeleportModal = true">
					<IconTeleport />
				</div>
				<div @click="dance('wink')" v-if="multiplayerEnabled">
					<IconEmote />
				</div>

				<div @click="dance('dance')" v-if="multiplayerEnabled">
					<IconEmote />
				</div>

				<div @click="spaceStore.showChatMobile = true" v-if="multiplayerEnabled && mpStore.isConnected">
					<IconChat />
				</div>
				<div v-if="multiplayerEnabled && spaceStore.canUseVoicechat">
					<IconMicrophone />
				</div>
				<div @click="spaceStore.showMenu = true">
					<IconSettings />
				</div>
			</div>

		</template>


	</div>
</template>

<script setup lang="ts">
import Vue from "vue";
import { TimelineLite, Power3, } from "gsap";
import { useToast } from "vue-toastification";
import { getSpace } from "~/space/space";
import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getEngine } from "~/space/engine";
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { useSpaceStore } from "~/store/space";
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import { useGameStore } from "~/store/game";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { useMultiplayerStore } from "~/store/multiplayer";

const space = getSpace();
const engine = getEngine()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const multiplayerEnabled = !!engine.config.multiplayer;
const mpStore = useMultiplayerStore()

const mousePosX = ref()
const mousePosY = ref()

const canvasclicked = ref(false)

onMounted(() => {
	engine.gui.once("canvasclick", () => {
		canvasclicked.value = true;
	});

	window.addEventListener('mousemove', mouseMoveListener, false);

	watch(() => spaceStore.tutorialDone, () => {
		if (space.browser.isMobile) {
			if (gameStore.questStage > 0) { spaceStore.showInterface = true }
		} else if (canvasclicked.value) {
			if (gameStore.questStage > 0) { spaceStore.showInterface = true }
		} else {
			engine.gui.once("canvasclick", () => {
				if (gameStore.questStage > 0) { spaceStore.showInterface = true }
			});
		}
	})

	// Keys
	space.eventbinder.bindDocument("keyup", (e) => {
		if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
		else if (e.key == "h" || e.key == "H") {
			const ui = document.getElementsByClassName("ui")
			for (var i = 0; i < ui.length; i++) {
				ui[i].classList.toggle("hide")
			}
		}
	});

	/* Handle Pointer Lock UI */
	engine.loop.register(() => {
		spaceStore.pointerlocked = engine.gui.isPointerLocked()
	})

})


watch(() => spaceStore.showInterface, () => {
	if (space.browser.isMobile) {
		const tl = new TimelineLite({});
		tl.to(".joystickOuter", 1, { y: 0, opacity: 1 });
		tl.to("#mobile--buttons, #inventar, #jump", 0.7, { y: 0, opacity: 1, });
	} else {
		const tl = new TimelineLite({});
		tl.to(".ui", 0.4, {
			y: 0,
			opacity: 1,
			ease: Power3.easeOut,
		});
	}
})


function dance(id: string) {
	const engine = getEngine();
	const avatar = engine.player.getPlayerAvatar();
	if (id == "wink") {
		avatar?.triggerAction("wave", 1, false)
	} else if (id == "dance") {
		const randomDance = Math.floor(Math.random() * 4) + 1
		if (randomDance == 1) { avatar?.triggerAction("rave_dance_1", 1, true) }
		if (randomDance == 2) { avatar.triggerAction("dance_hiphop_1", 1, true) }
		if (randomDance == 3) { avatar?.triggerAction("rave_dance_5", 1, true) }
		if (randomDance == 4) { avatar?.triggerAction("dance_hiphop_1", 1, true) }
	}
}

function jump() {
	const engine = getEngine();
	engine.player.getInputController<PlayerCharacter>().keysSchema.touch.jumping = true;
}
function jumpEnd() {
	const engine = getEngine();
	engine.player.getInputController<PlayerCharacter>().keysSchema.touch.jumping = false;
}



var mouseMoveListener = (event: { clientX: number; clientY: number; }) => {
	mousePosX.value = event.clientX;
	mousePosY.value = event.clientY;
};

</script>



<style lang="scss">
@import "assets/css/variables";

#cinescope {
	pointer-events: none;

	div {
		width: 100%;
		height: 5vw;
		position: fixed;
		left: 0;
		background: black;
		z-index: 9;
		transition: ease 1s;
	}

	div:first-child {
		top: -7vw;
	}

	div:last-child {
		bottom: -7vw;
	}

	&.active {
		div:first-child {
			top: 0;
		}

		div:last-child {
			bottom: 0;
		}
	}
}

#multiplayerWrapper {
	z-index: 10;
	position: fixed;
	left: 10px;
	top: 10px;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
	justify-content: flex-start;
}

#clickAnywhere {
	position: fixed;
	@extend .box;
	padding: 6px 12px;
	color: $main;
	z-index: 99999;
	font-weight: 500;
	font-size: 12px;
	transform: translate(15px, -10px);
}

#jump {
	position: absolute;
	left: calc(50% - 115px);
	bottom: 40px;
	width: 50px;
	height: 50px;
	background: #ffffffba;
	box-shadow: inset 0px -1px 9px rgba(26, 27, 33, 0.3);
	z-index: 9;
	border-radius: 100%;
}


.key {
	background: white;
	color: dark;
	width: 20px;
	color: $dark;
	height: 20px;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	background: white;
	@extend .flex-center;
	border-radius: 3px;
	color: black;
	font-weight: 700;
}

.box {

	svg {
		width: 23px;
		height: auto;
		transform: scale(1.3);

		* {
			fill: white;
		}
	}
}


#ui-keys,
#menu-hint-onboarding {
	right: 10px;
	bottom: 10px;
	position: fixed;
	z-index: 9;
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.box {
		height: 55px;
		width: 60px;
		margin-left: 6px;
		@extend .flex-center;
		flex-direction: column;

		span {
			@extend .key;
			margin-bottom: 3px;
			width: 23px;
		}

		p {
			font-size: 11px;
			color: white;
			font-weight: 600;
		}
	}

}

#menu-hint-onboarding {
	position: fixed;
	right: 10px;
	bottom: 20px;
	z-index: 12 !important;
}

.space {
	span {
		width: 35px !important;
		height: 13px !important;
		font-size: 7px !important;
		line-height: 13px !important;
		margin-bottom: 11px !important;
		transform: translateY(6px) !important;
	}
}


#unhide {
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 9;
	transform: translateX(150px);
	@extend .flex-center;
	padding: 5px 5px;

	p {
		color: 13px;
		font-weight: 600;
		margin-left: 5px;
		font-size: 11px;
		color: white;
	}

	&.hide {
		transform: translateX(0) !important;
		opacity: 1 !important;
	}
}

#mobile--buttons {
	position: fixed;
	bottom: 20px;
	left: 0px;
	transform: translateY(300px);
	z-index: 3;
	align-items: center;
	display: flex;
	flex-direction: column;
	@extend .noselect;
	width: 40px;

	div {
		opacity: 1;
		width: 50px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0px 16px 16px 0px;
		background: #000000af;
		margin-top: 3px;

		p {
			color: white;
			font-size: 12px;
			font-weight: 600;
			margin: 0
		}

		svg {
			width: 16px;
			height: auto;

			* {
				fill: white;
			}
		}

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}
	}
}


.ui {
	transform: translateY(100px);
	opacity: 0;
	transition: ease 0.5s;

	&.hide {
		transform: translateY(300px) !important;
		opacity: 0 !important;
	}
}

.ui-top {
	transform: translateY(-100px);

	&.hide {
		transform: translateY(-200px) !important;
		opacity: 0 !important;
	}
}

#introState {
	position: fixed;
	bottom: calc(5vw + 20px);
	width: 500px;
	left: calc(50% - 250px);
	z-index: 10;
	@extend .box;
	background: rgba(0, 0, 0, 0.9);
	@extend .flex-center;
	flex-direction: column;
	padding: 40px 10px;
	color: white;
	overflow: hidden;
	opacity: 0;
	transform: translateY(100px);
	transition: ease 1s;

	.progress {
		position: absolute;
		left: 0;
		top: 0;
		height: 5px;
		background: white;
		transition: ease 0.5s;
	}

	p {
		font-weight: 600;
	}

	span {
		background: $main;
		color: $dark;
		font-weight: 700;
		padding: 2px 7px 2px 9px;
		margin-right: 1px;
		border-radius: 4px;
		font-size: 12px;
		margin: 0 3px;
	}

	&.active {
		opacity: 1;
		transform: translateY(0);
	}
}



@media(max-width:800px) {
	#cinescope {
		display: none;
	}

	#introState {
		width: 300px;
		left: calc(50% - 150px);

		p {
			margin-top: 12px;

			span {
				padding: 13px 30px;
				font-size: 14px;
			}
		}
	}
}
</style>
