<template>
	<Transition name="fade">
		<div v-if="show" class="modal">
			<div class="inner" @click.self="close()">
				<div v-if="MyEnv.$gamepadActive" class="close btn--controller-close" @click="close()">
					<img src="/SpaceShared/img/buttons/b.png"> Close
				</div>
				<div v-else class="close" @click="close()">
					<IconClose />
				</div>
				<div class=" box">
					<slot></slot>
				</div>
				<div class="imprint-links">
					<a href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a>
					<a href="https://www.deka.de/privatkunden/impressum" target="_blank">Impressum</a>
					<p @click="showCookieHint()">Cookie Einstellungen</p>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import Vue from 'vue'
import * as THREE from "three"
import { getSpace } from '~/space/space'
import { blockForFrontend, unblockFromFrontend } from '~/space/_lib/utils/frontend';


/**
 * Usage example
 * <SpaceModal :show=show @closed="show = false">
 * 	<p>my modal text</p>
 * </SpaceModal>
 * */

const props = defineProps({
	show: {
		type: Boolean,
		required: true
	},
})

const emits = defineEmits({
	closed() { return true }
})

watch(() => props.show, (state) => {
	if (!state) { close() } // damit zu geht egal wers zu gemacht hat (Modal, Modal Parent, Collider)
	state ? blockForFrontend() : unblockFromFrontend()
})

const space = getSpace()
function close() {
	space.pciSystem.triggerNearestInteract()
	emits("closed")
}
function showCookieHint() {
	space.eventbus.emit("showCookieHint")
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.imprint-links {
	position: fixed;
	bottom: 10px;
	left: 0;
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}
}

.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 11;

	.inner {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@extend .flex-center;
		background: rgba(0, 0, 0, 0.85);
		flex-direction: column;

		.close {
			position: relative;
			left: initial;
			right: initial;
			top: initial;
			transform: translate(457px, -10px);
		}

		.box {
			background: white;
			box-sizing: border-box;
			border-radius: $radius;
			@extend .flex-center;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 60px 30px 30px 30px;
			position: relative;
			width: 950px;
			max-width: 90%;
			height: auto;
			max-height: calc(100vh - 60px);

			.content {
				@extend .flex-center;
				flex-direction: column;
				height: 100%;
			}

			/* width */
			&::-webkit-scrollbar {
				width: 10px;

			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				border-radius: 20px;
				background: #d4d4d5;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}
		}
	}

	&.blockchain {
		.inner {
			.box {
				background-image: url(/dek/img/blockchaingame.jpg);
				background-size: cover;
				background-position: center;
			}
		}
	}

}

@media(max-width:1060px) {
	.modal {
		.inner {
			.box {
				padding: 30px 20px 20px 20px;
			}

			.close {
				transform: translate(0) !important;
				position: absolute;
				right: 20px;
				top: 20px;
			}
		}
	}
}

@media(max-height:800px) {
	.modal {
		.inner {
			.box {
				padding: 20px 20px 20px 20px;
			}
		}
	}
}
</style>
