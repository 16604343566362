<template>
	<div id="voicechat-wrap" v-if="vgStore.voiceGroupActive">
		<div class="top" v-if="!MyEnv.$isMobile">
			<span class="key" @click="leaveVoiceGroup()">L</span>
			<p>Voicechat verlassen - {{ vgStore.members.size + 1 }} Nutzer</p>
		</div>
		<div class="top" v-else>
			<span class="key" @click="leaveVoiceGroup()">Voicechat verlassen</span>
		</div>
		<div class="list">
			<div>
				<p>Du</p>
			</div>
			<template v-for="([id, name]) of vgStore.members">
				<p>{{ name }}</p>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { useVoiceGroupStore } from "~/store/agora/voicegroups.js";
import { canAnswerVoiceChat } from "~/space/_lib/utils/frontend";

const engine = getEngine();
const space = getSpace()
const vgStore = useVoiceGroupStore()

const leaveVoiceGroup = () => { engine.multiplayer.local_netPlayer.leaveVG() }

space.eventbinder.bindDocument("keydown", (e) => {
	if (canAnswerVoiceChat() && (e.key == "l" || e.key == "L"))
		leaveVoiceGroup();
});

</script>

<style scoped lang="scss">
@import "assets/css/variables";

#voicechat-wrap {
	@extend .box;
	// top: 56px;
	// left: 10px;
	// position: fixed;
	margin: 5px;

	z-index: 9;
	max-width: 300px;

	p {
		color: white;
		font-size: 13px;

		span {
			color: white;
		}
	}

	.top {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $lineColor;
		padding-bottom: 6px;
		margin-bottom: 6px;
		padding: 8px 15px;

		.key {
			height: 20px;
			background: white;
			@extend .flex-center;
			border-radius: 3px;
			width: 20px;
			font-size: 11px;
			color: black;
			font-weight: 600;
			margin-right: 6px;
		}
	}

	.list {
		padding: 8px 15px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;

		p {
			border: 1px solid $lineColor;
			display: inline-block;
			padding: 3px 6px;
			border-radius: 4px;
			margin: 0 3px 3px 0;
		}
	}

	&.mobile {
		top: inherit;
		bottom: 30px;
		right: 10px;
		left: inherit;
		width: 120px;

		.top {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 6px;

			.key {
				width: 100%;
				height: auto;
				font-size: 10px;
				margin: 0;
				padding: 6px 0;
			}
		}

		.list {
			padding: 6px;
			padding-top: 0;

			p {
				font-size: 10px;
			}
		}
	}
}
</style>
