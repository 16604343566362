<template>
	<SpaceModal :show=showEntryOpportunitiesModal @closed="showEntryOpportunitiesModal = false">
		<div class="content">
			<h1 v-if="!activeCompany">{{ modalData.Title }}</h1>

			<div class="splash" v-if="!activeCompany">

				<div class="company" v-for="company in modalData.Company" @click="activeCompany = company; activeTab = company.Item[0]">
					<div class="img" :style="'background-image:url(' + company.Logo.data.attributes.url + ')'"></div>
					<p>{{ company.Title }}</p>
				</div>
			</div>

			<template v-if="activeCompany">
				<div class="btn back" @click="activeCompany = undefined">
					<IconArrow />
				</div>
				<h2> {{ activeCompany.Title }}</h2>
				<div class="tabs">
					<div class="tab" v-for="item in activeCompany.Item" @click="activeTab = item" :class="item == activeTab ? 'active' : ''">
						{{ item.Name }}
					</div>
				</div>

				<div class="current" v-if="activeTab">
					<h2>{{ activeTab.Name }}</h2>
					<img :src="activeTab.Image.data.attributes.url" v-if="activeTab.Image.data">
					<div v-html="activeTab.Description" style="white-space: pre-wrap;word-wrap: break-word;"></div>
					<div class="btn--wrapper">
						<a class="btn" v-if="activeTab.Link1" :href="activeTab.Link1" target="_blank">{{ activeTab.Link1Text }}</a>
						<a class="btn" v-if="activeTab.Link2" :href="activeTab.Link2" target="_blank">{{ activeTab.Link2Text }}</a>
					</div>
				</div>
			</template>



		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import HighlightCylinder from '@ravespaceio/rave-engine/build/engine/src/objects/other/HighlightCylinder';
import { getEngine } from '~/space/engine';
import { SphereCollider } from '@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem';
import { Static } from 'nuxt/dist/app/compat/capi';
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
const space = getSpace()
const engine = getEngine()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showEntryOpportunitiesModal } = storeToRefs(spaceStore)
const apiurl = space.ENV.CMS_URI + "/api/einstiegsmoeglichkeiten?populate=*,Company.Logo,Company.Item,Company.Item.Image"
const modalData = ref()
const activeTab = ref()
const activeCompany = ref()


fetch(apiurl, {
	method: "GET",
}).then((response) => { return response.json(); }).then((data) => { continueSetup(data) }).catch(function (error) { console.log(error) });

function continueSetup(data) {
	modalData.value = data.data.attributes

	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(0.5, 1, 0.5)
	hc.position.set(7.839, 1.43, -26.11)

	space.pciSystem.add({
		collider: new SphereCollider(hc, 2),
		onColliderEnter: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			spaceStore.hint.title = data.data.attributes.Title
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {
			spaceStore.showEntryOpportunitiesModal = true
			spaceStore.hint.title = undefined
			space.eventbus.emit("newAnalytics", "openEinstiegsmoeglichkeiten")
		}
	})
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.splash {
	@extend .flex-center;

	.company {
		@extend .flex-center;
		flex-direction: column;
		margin: 10px;
		padding: 10px;
		border-radius: $radius;
		border: 1px solid $dark;
		cursor: pointer;
		transition: ease 0.2s;

		p {
			font-size: 14px;
			font-weight: 600;
			margin-top: 5px;
		}

		.img {
			width: 100px;
			height: 100px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&:hover {
			transform: scale(1.1);
		}
	}
}

.back {
	background: $dark;
	padding: 4px 20px;
	margin-bottom: 12px;

	svg {
		transform: rotate(-180deg);

	}

	&:hover {
		opacity: 0.6;
		border-color: $dark;
	}
}

.btn--wrapper {
	@extend .flex-center;
	flex-wrap: wrap;

	.btn {
		margin: 4px;
	}
}

.content {
	height: auto;
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	h1 {
		margin-bottom: 12px;

		svg {
			width: 100px;
			height: auto;
			margin: 0 4px;

			::v-deep(*) {
				fill: $red;
			}
		}
	}

	.tabs {
		@extend .flex-center;
		margin-bottom: 20px;
		border: 1px solid $lineColor;
		border-radius: $radius;
		overflow: hidden;
		flex-wrap: wrap;
		width: 100%;
		max-width: 800px;

		.tab {
			padding: 10px 20px;
			font-weight: 600;
			cursor: pointer;

			&:hover {
				background: $lineColor;
			}

			&.active {
				background: $red;
				color: white
			}
		}
	}

	.wrapper {
		@extend .flex-center;
		flex-wrap: wrap;
		margin-top: 30px;

		img {
			width: auto;
			height: 100px;
			margin: 5px;
			opacity: 0.6;
			transition: ease 0.4s;
			position: relative;

			&:hover {
				opacity: 1;
				transform: scale(1.2);
				z-index: 3;
			}
		}
	}

	.description {
		@extend .flex-center;
		flex-direction: column;
		text-align: center;
		text-align: justify;

		* {
			text-align: center;
		}
	}
}

.current {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: 800px;
	background: #fafafa;
	padding: 20px;
	border-radius: $radius;

	img {
		margin-bottom: 20px;
		width: 100%;
		border-radius: $radius;
		height: auto;
	}

	.btn {
		margin-top: 20px;
	}
}
</style>
