import { Host_or_AudienceAgoraClientHandler } from "@ravespaceio/rave-engine";
import Client_VoiceVideoAdmin_RSH from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/ClientStateHandlers/AgoraRoom/Client_VoiceVideoAdmin_RSH";
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes";
import { getEngine } from "~/space/engine"
import { useVoiceRolesStore } from "~/store/agora/voiceroles";

export const ADMIN_CHANNEL_NAME = "all";

let agoraHandlerAdminChannel: Host_or_AudienceAgoraClientHandler;

export function setupAdminChannel() {

	const voiceRolesStore = useVoiceRolesStore();

	const engine = getEngine();

	const agoraRole = engine.multiplayer.clientGlobalRoom.getAgoraRole();
	const handlerRole = agoraRole === Role.default ? "audience" : "host";

	agoraHandlerAdminChannel = new Host_or_AudienceAgoraClientHandler(engine.multiplayer.clientAgoraRoom, handlerRole);
	agoraHandlerAdminChannel.setAgoraHandler(undefined);

	const voiceVideoAdminStateHandler = engine.multiplayer.clientAgoraRoom.getStateHandler<Client_VoiceVideoAdmin_RSH<any>>('voiceVideoAdminStateH');

	voiceVideoAdminStateHandler.onAdminChannelCreated.on((channelName) => {
		agoraHandlerAdminChannel.joinAgoraChannel(channelName);
	});

	voiceVideoAdminStateHandler.onAdminChannelDestroy.on((channelName) => {
		agoraHandlerAdminChannel.leaveAgoraChannel();
	});

	agoraHandlerAdminChannel.enableAudioVolumenIndicator();

	agoraHandlerAdminChannel.volumenIndicatorEvent.on((results: Array<{ uid: number, level: number }>) => {
		if (!voiceRolesStore.isJoined) return;

		results.forEach(result => {
			if (result.uid === agoraHandlerAdminChannel.getAgoraOptions().uid)
				voiceRolesStore.volumeMeter = result.level
		})
	})

	return agoraHandlerAdminChannel;

}

export const muteAdmin = (muted: boolean) => {
	agoraHandlerAdminChannel?.muteLocalAudio(muted);
}
