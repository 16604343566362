<template>
	<!-- <SpaceInterfaceMobileBlocker v-if="MyEnv.$isMobile" /> -->
	<div>
		<div id="spaceWrapper" ref="spaceWrapper">
			<Transition name="fade">
				<canvas v-show="onboardingDone" id="spaceCanvas" ref="spaceCanvas" />
			</Transition>

			<Transition name="fade">
				<div v-if="!onboardingDone" id="bgLoader">
					<IconLoader />
				</div>
			</Transition>

			<SpaceOnboardingLoader />

			<template v-if="engineCreated">
				<SpaceInterface />
				<SpaceInterfaceDrinkProgress />
				<SpaceInterfaceHint />
				<SpaceInterfaceHintTop v-if="!MyEnv.$isMobile" />
				<!-- <SpaceInterfacePlacer /> -->
				<!-- <SpaceInterfaceTutorial /> -->
				<SpaceModalMasterclass />
				<SpaceModalPlatformquiz />
				<SpaceModalJobs />
				<SpaceModalArtwork />
				<SpaceModalQuest />
				<SpaceModalBlockchain />
				<SpaceModalEntryOpportunities />
				<SpaceModalApplication />
				<SpaceModalSkillMatching />
				<SpaceModalDekergy />
				<SpaceModalRaffle />
				<SpaceModalBenefits />
				<SpaceModalStage />

				<SpaceMenu />
				<SpaceMenuAvatarGenerator />
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toastification';
import { getEngine, setupEngine } from '~/space/engine';
import { setupSpace } from '~/space/space';
import { usePanelStore } from '~/store/panel';
import { useSpaceStore } from '~/store/space';

const space = setupSpace()
const toast = useToast()
const spaceWrapper = ref({} as HTMLDivElement)
const spaceCanvas = ref({} as HTMLCanvasElement)

const engineCreated = ref(false)
const { onboardingDone } = storeToRefs(useSpaceStore())

onMounted(async () => {
	const store = usePanelStore()
	store.init(space);

	try {
		const engine = setupEngine(spaceCanvas.value)
		engineCreated.value = true
		space.eventbus.onAlready("sendNotification", (data: any) => {
			const pastTime = new Date(data.timestamp);
			const now = new Date();
			const timeDiffInMs = now.getTime() - pastTime.getTime();
			if (timeDiffInMs >= 10000) {
				console.log('Last notification sent ' + pastTime);
			} else if (data.text) {
				toast.success(data.text);
				if (data.text == "You need to reload the experience") {
					if (timeDiffInMs <= 1000) {
						if (confirm("You need to reload the experience") == true) {
							location.reload()
						}
					}
				}
			}
		})
	} catch (e) {
		console.log(e);
	}

})



onUnmounted(() => {
	try {
		const engine = getEngine()
		engine.destroy()
	} catch (e) {
		console.log(e);
	}
})
</script>



<style scoped lang="scss">
@import "assets/css/variables";

#bgLoader {
	position: absolute;
	width: 100px;
	height: 100px;
	left: calc(50% - 50px);
	top: calc(50% - 50px);
	z-index: 0;
	@extend .flex-center;
	flex-direction: column;

	p {
		color: white;
		margin-top: 4px;
		font-size: 12px;
		text-align: center;
	}
}

#spaceCanvas {
	width: 100vw;
	height: 100vh;
	position: relative;
	z-index: 1;
}


#spaceWrapper {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;

	* {
		-webkit-touch-callout: none;
		-webkit-text-size-adjust: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-user-select: none;
	}

	#loader--museum {
		position: absolute;
		left: 50%;
		top: 50%;
	}
}
</style>
