<template>
	<div class="loader">
		<div />
		<div />
		<div />
		<div />
	</div>
</template>


<style scoped lang="scss">
.loader {
	display: flex;
	position: relative;
	width: 20px;
	height: 20px;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 20px;
	height: 20px;
}

.loader div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 14px;
	height: 14px;
	margin: 1px;
	border: 2px solid white;
	border-radius: 50%;
	animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: white transparent transparent transparent;
}

.loader div:nth-child(1) {
	animation-delay: -0.45s;
}

.loader div:nth-child(2) {
	animation-delay: -0.3s;
}

.loader div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
