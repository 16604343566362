import { useToast } from 'vue-toastification';
import { BigGui, Logging, LoggingArea } from "@ravespaceio/rave-engine"
import { Client_GlobalData_RSH } from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/ClientStateHandlers/GlobalRoom/Client_GlobalData_RSH";
import { addMultiplayer } from "@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiMultiplayer";
import { getSpace } from "~/space/space";
import { getEngine } from "~/space/engine";
import { useGameStore } from "~/store/game";
import { useSpaceStore } from "~/store/space";
import { useMultiplayerStore } from "~/store/multiplayer";
import { setupAgoraVoiceGroups } from "./agora/voiceGroups";
import { setupAdminChannel } from './agora/adminChannel';
import { setupAgoraCloudPlayer } from './agora/cloudPlayer';
import { AvatarConfig, AvatarConfigWithoutPreview, transformAvatarConfig } from "../utils/rpm";
import { getGui } from '~/space/gui';
import CloudPlayerManager from '../manager/CloudPlayerManager';
import { GuiEngine } from '@ravespaceio/rave-engine/build/engine/src/helper/gui/GuiEngine';
import ClientRoomHandler from '@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/core/ClientRoomHandler';



let cpManager: CloudPlayerManager;

export function getCPM() {
	if (cpManager === undefined) {
		return false
	} else {
		return cpManager
	}
}


export function setupMultiplayer() {
	const engine = getEngine()
	const space = getSpace()
	if (!engine.config.multiplayer) return;

	const roomGlobal = engine.multiplayer.clientGlobalRoom;
	const roomAgora = engine.multiplayer.clientAgoraRoom
	const room3d = engine.multiplayer.clientRoom3D;

	const toast = useToast()
	const spaceStore = useSpaceStore()
	const mpStore = useMultiplayerStore();
	const gameStore = useGameStore();

	// gui
	const gui = getGui();
	addMultiplayer(gui, engine, space.ENV.GAMESERVER_URI)
	if (space.browser.urlParameter.get("mpGui")) {
		const mpGui = new BigGui({ autoPlace: true })
		addMultiplayer(mpGui, engine, space.ENV.GAMESERVER_URI)
		GuiEngine.fixFocus(mpGui, getEngine())
	}

	// kick
	const kickDate = localStorage.getItem("RE_Report")
	if (kickDate) {
		const now = Date.now();
		const oneDay = 24 * 60 * 60 * 1000;
		const isMoreThanADay = (now - parseInt(kickDate)) > oneDay;
		if (isMoreThanADay) { localStorage.removeItem("RE_Report"); }
		else { toast.error("Du wurdest wegen Fehlverhaltens für 24 Stunden vom Mehrspielermodus getrennt.") }
	}
	const globalDataHandler = roomGlobal.getStateHandler<Client_GlobalData_RSH>("globalDataHandler");
	globalDataHandler.kick.on(() => {
		toast.error("Du wurdest wegen Fehlverhaltens für 24 Stunden vom Mehrspielermodus getrennt.");
		mpStore.blocked = true;
		engine.multiplayer.leaveMultiplayer();
		localStorage.setItem("RE_Report", "" + Date.now());
	})

	// role and badge
	engine.multiplayer.clientGlobalRoom.onRoomJoinEvent.on(() => {
		gameStore.userRole = engine.multiplayer.clientGlobalRoom.getRole();
		// Logging.trace("User role (global room): " + gameStore.userRole, LoggingArea.Space);
		if (mpStore.isHrSupport) engine.multiplayer.local_netPlayer.setBadge('/dek/img/admin.png');
	})

	// agora setup
	roomAgora.onRoomJoinEvent.once((room) => {
		const space = getSpace();
		setupAgoraVoiceGroups();
		const adminHandler = setupAdminChannel();
		const cloudPlayerHandler = setupAgoraCloudPlayer();
		cpManager = new CloudPlayerManager(engine, space.screenManager, cloudPlayerHandler, { prefix: 'DEK_CP_', stage: space.ENV.STAGE, onlyVideoScreens: [100] })
		// work around to set appId to agora handlers using the websocket callback
		room.state.listen('agoraAppId', (value, prevValue) => {
			engine.multiplayer.clientAgoraRoom.voiceChatVgHandler.getAgoraOptions().appId = value;
			adminHandler.getAgoraOptions().appId = value;
			cloudPlayerHandler.getAgoraOptions().appId = value
		})
	})

	engine.multiplayer.setupServerConnection(space.ENV.GAMESERVER_URI);

	watch(() => spaceStore.avatar, (state) => {
		setAvatar(state)
	}, { deep: true })
	watch(() => spaceStore.playerName, (state) => {
		setName(state, true)
	})

	// initial join
	watch(() => spaceStore.onboardingDone, async () => {
		await joinAllRooms()
	})
}

let joinAllCounter = 1
let joinAllRunning = false
let onceJoinAllRooms = false
async function joinAllRooms(): Promise<void> {
	const engine = getEngine();
	const spaceStore = useSpaceStore()
	const mpStore = useMultiplayerStore();
	if (mpStore.isConnected || joinAllRunning) return;
	joinAllRunning = true

	try {
		Logging.info("joinInitial", LoggingArea.Space)
		engine.multiplayer.clientRoom3D.roomType = "room3D"
		await engine.multiplayer.join({ playerName: spaceStore.playerName, avatarConfig: transformAvatarConfig(spaceStore.avatar), });
		Logging.info("joinInitial was successful", LoggingArea.Space)
		joinAllCounter = 1
	} catch (err) {
		Logging.error("joinInitial was not successful. Error: " + err, LoggingArea.Space);
		joinAllCounter += 1
	} finally {
		joinAllRunning = false
		if (!onceJoinAllRooms) {
			onceJoinAllRooms = true
			setupRoomConnectionsChecker()
		}
	}
}

function setupRoomConnectionsChecker() {
	const engine = getEngine()
	const mpStore = useMultiplayerStore()
	setInterval(async () => {
		checkRoomConnections()
		if (mpStore.isConnected || joinAllRunning) return;
		Logging.info("reconnecting all rooms attempt " + joinAllCounter, LoggingArea.Space)
		engine.multiplayer.leaveMultiplayer().then(() => { joinAllRooms() })
	}, 4000 * joinAllCounter)
}



export async function joinRoom3DById(roomId: string, roomtype = "room3D") {
	joinRoom3DWithRejoin(roomId, roomtype)
}
export async function joinRoom3DAny(roomtype = "room3D") {
	joinRoom3DWithRejoin(undefined, roomtype)
}

let join3DCounter = 1
let t
async function joinRoom3DWithRejoin(roomId: string | undefined, roomtype = "room3D") {
	checkRoomConnections()
	const engine = getEngine()
	const mpStore = useMultiplayerStore()
	if (!mpStore.isConnectedRoomAgora || !mpStore.isConnectedRoomGlobal) {
		Logging.warn("You must be in a gloobal and agora room", LoggingArea.Space);
		return;
	}
	if (engine.multiplayer.clientRoom3D.room) await engine.multiplayer.clientRoom3D.leaveRoom()
	const ok = await joinRoom3D(roomId, roomtype)
	if (ok) {
		join3DCounter = 1
	} else {
		join3DCounter += 1
		clearTimeout(t)
		setTimeout(() => {
			Logging.trace("rejoin room3d attempt " + join3DCounter, LoggingArea.Space)
			joinRoom3DAny()
		}, 3000 * join3DCounter)
	}
}

let joining3D = false
async function joinRoom3D(roomId: string | undefined, roomtype = "room3D"): Promise<boolean> {
	checkRoomConnections()
	const engine = getEngine()
	const mpStore = useMultiplayerStore()
	if (mpStore.isConnectedRoom3D) { Logging.info("You are already in a room", LoggingArea.Space); return; }
	if (joining3D) { Logging.info("Already joining", LoggingArea.Space); return; }
	joining3D = true
	Logging.info("joinRoom3D " + roomtype + " " + roomId, LoggingArea.Space)

	const room3dOptions = {
		globalRoomData: {
			globalClientId: engine.multiplayer.clientGlobalRoom.clientId,
			globalRoomId: engine.multiplayer.clientGlobalRoom.roomId
		},
		agoraRoomData: {
			agoraClientId: engine.multiplayer.clientAgoraRoom.clientId,
			agoraRoomId: engine.multiplayer.clientAgoraRoom.roomId
		}
	}
	engine.multiplayer.clientRoom3D.roomType = roomtype as "room3D"
	const ok = await (roomId === undefined ?
		engine.multiplayer.clientRoom3D.joinOrCreate(room3dOptions) :
		engine.multiplayer.clientRoom3D.joinById(roomId, room3dOptions)
	)
	joining3D = false
	checkRoomConnections()
	if (ok) { Logging.info("joinRoom3D succeeded", LoggingArea.Space); return true }
	else { Logging.warn("joinRoom3D failed", LoggingArea.Space); return false }
}


export function setName(name: string, displayToast: boolean) {
	const toast = useToast()
	const engine = getEngine()
	useSpaceStore().playerName = name;
	if (engine.multiplayer.clientGlobalRoom.roomId) {
		engine.multiplayer.local_netPlayer.setName(name)
		if (displayToast) toast.success("Name geändert zu " + name + ".");
	}
}

export function changeAvatar(avatar: AvatarConfig) {
	useSpaceStore().avatar = avatar
	setAvatar(avatar);
}

export function changeAvatarTemporarily(avatar: AvatarConfigWithoutPreview, durationInS: number) {
	setAvatar(avatar);
	setTimeout(() => setAvatar(useSpaceStore().avatar), durationInS);
}

function setAvatar(avatar: AvatarConfig | AvatarConfigWithoutPreview) {
	const engine = getEngine()
	if (engine.config.multiplayer) {
		if (engine.multiplayer.clientRoom3D.roomId)
			engine.multiplayer.local_netPlayer.setAvatar(transformAvatarConfig(avatar))
	} else {
		engine.player.getPlayer_setAvatarByConfig(transformAvatarConfig(avatar))
	}
}

// in addition to room join/leave events
function checkRoomConnections() {
	const mpStore = useMultiplayerStore();
	const engine = getEngine()
	mpStore.isConnectedRoom3D = isRoomConnectedSuccesfully(engine.multiplayer.clientRoom3D)
	mpStore.isConnectedRoomAgora = isRoomConnectedSuccesfully(engine.multiplayer.clientAgoraRoom)
	mpStore.isConnectedRoomGlobal = isRoomConnectedSuccesfully(engine.multiplayer.clientGlobalRoom)
}

function isRoomConnectedSuccesfully(rh: ClientRoomHandler<any>): boolean {
	// @ts-ignore // dont need ts ignore anymore once updated colyseus client version
	const readyState = rh.room?.connection?.transport?.ws?.readyState // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
	const isOpen = (readyState === 0) || (readyState === 1)
	return isOpen
}
