<template>
	<SpaceModal :show=show @closed="close()">
		<div v-if="artworkModal" class="content">
			<ArViewer3D v-if="artworkModal.img" :model-info="modelInfo" :standalone="false" class="ar-viewer" style="width:50%; height:400px;" />
			<video :src="artworkModal.video" v-if="artworkModal.video" class="video-preview" autoplay playsinline muted loop></video>
			<div class="details">
				<h2>{{ artworkModal.title }}</h2>
				<h3 v-if="artworkModal.artist">{{ artworkModal.artist }}</h3>
				<p>{{ artworkModal.text2 }}</p>
				<div class="width">
					<h6>WIDTH</h6>
					<h6>{{ artworkModal.width * 100 }} cm</h6>
				</div>
				<a :href="artworkModal.link" v-if="artworkModal.link" class="btn" target="_blank">More Info</a>
				<p class="disclaimer">{{ artworkModal.link }}</p>
			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';


// const props = defineProps<{
// 	data: ArtworkType
// }>()
// watch(() => props.data, (data) => {
// 	showData(data)
// })


const space = getSpace()
const spaceStore = useSpaceStore()

const { artworkModal } = storeToRefs(spaceStore)
const show = computed(() => !!artworkModal.value)

const modelInfo = computed(() => {
	const data = artworkModal.value
	if (data) {
		return {
			url: data.img,
			placement: "wall" as "wall",
			...(data.width && { widthInCm: data.width * 100 }),
			...(data.height && { heightInCm: data.height * 100 }),
		}
	}
})

function close() {
	artworkModal.value = undefined
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";


.content {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.video-preview {
		max-width: 50%;
		height: auto;
		padding-right: 20px;
	}

	.details {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		padding-left: 30px;

		h2 {
			font-size: 30px;
			text-align: left;
			font-weight: 700;
			margin-bottom: 6px;
		}

		h3 {}

		p {
			font-size: 15px;
			line-height: 140%;
			margin-bottom: 20px;
			margin-top: 20px;
		}

		.btn {
			margin-top: 12px;
		}

		p.disclaimer {
			font-size: 10px;
			opacity: 0.4;
			margin-top: 6px;
		}

		.width {
			h6:first-child {
				font-weight: 700;
			}
		}
	}
}

@media(max-width:900px) {
	.content {
		flex-direction: column;

		.details {
			width: 100%;
			padding: 0;
			margin-top: 20px;
		}

		.ar-viewer {
			width: 100% !important;
			max-height: 30vh !important;
		}
	}
}
</style>
