<template>
	<SpaceModal :show=showJobsModal @closed="showJobsModal = false">
		<div class="content">
			<h1>
				Stellenanzeigen
			</h1>
			<h5>Suche</h5>
			<input v-model="filterText" placeholder="Nach Stellen suchen" class="input" @input="filterJobs" />
			<div class="wrap">
				<div class="job" v-for="job in filteredJobs ">
					<p>{{ job.Title }}</p>
					<a class="btn" :href="job.Link" target="_blank">Zur Stelle
						<IconArrow />
					</a>
				</div>
			</div>
		</div>

	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/_lib/utils/frontend';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { dekaBotPositions, useGameStore } from '~/store/game';
import { getEngine } from '~/space/engine';
import HighlightCylinder from '@ravespaceio/rave-engine/build/engine/src/objects/other/HighlightCylinder';
import { SphereCollider } from '@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem';
import { resetDekaBot } from '~/space/dek/game/dekabot';
import { createNPC } from '~/space/_lib/mechanic/npc';
import * as THREE from "three"
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';

const space = getSpace()
const engine = getEngine()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showJobsModal } = storeToRefs(spaceStore)
const apiurl = space.ENV.CMS_URI + "/api/jobs?populate=*,job"
const modalData = ref()
const filterText = ref("")
const filteredJobs = ref()

fetch(apiurl, {
	method: "GET",
}).then((response) => { return response.json(); }).then((data) => { continueSetup(data) }).catch(function (error) { console.log(error) });

function continueSetup(data) {
	modalData.value = data.data.attributes

	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(0.5, 1, 0.5)
	hc.position.set(-10.9, 2, -24.5)


	const avatar = createNPC({
		name: "npc_hr",
		model: "/dek/avatars/models/4.glb",
		gender: "masculine",
		position: new THREE.Vector3(-12.6, 2, -25.1),
		rotation: 0.2,
		animation: "idle_dancing",
		scale: 1
	}, true)
	space.gui.addToSceneFolder(avatar)
	engine.loop.register(() => {
		avatar.lookAt(engine.player.getPlayerPosition())
	})

	space.pciSystem.add({
		collider: new SphereCollider(hc, 2),
		onColliderEnter: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			spaceStore.hint.title = "Stellenanzeigen"
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {
			spaceStore.showJobsModal = true
			spaceStore.hint.title = undefined
			space.eventbus.emit("newAnalytics", "openJobs")
		}
	})

	filterJobs()
}

function filterJobs() {
	if (filterText.value.trim() === "") {
		// If the input is empty or contains only whitespace, show all jobs
		filteredJobs.value = modalData.value.job;
	} else {
		// Otherwise, filter the jobs based on the input text
		filteredJobs.value = modalData.value.job.filter((job) =>
			job.Title.toLowerCase().includes(filterText.value.toLowerCase())
		);
	}
}




</script>

<style scoped lang="scss">
@import "assets/css/variables";

.input {
	border: 1px solid $dark;
	width: 300px;
	max-width: 100%;
	color: $dark;
	margin-bottom: 30px
}

.content {
	@extend .flex-center;
	flex-direction: column;
	width: 100%;

	h1 {
		margin-bottom: 12px;

		svg {
			width: 100px;
			height: auto;
			margin-right: 20px;

			::v-deep(*) {
				fill: $red;
			}
		}
	}

	.wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		width: 100%;
		margin: 20px 0;

		.job {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			max-width: 800px;
			border-bottom: 1px solid $lineColor;
			padding: 10px;
			border-radius: $radius;

			p {
				font-weight: 600;
				max-width: 500px;
			}

			.btn {
				margin: 0;
				padding: 9px 24px;
				font-size: 14px;

				svg {
					width: 20px;
					height: auto;
					margin-left: 4px;

					::v-deep(*) {
						fill: $dark;
					}
				}

				&:hover {
					svg {
						::v-deep(*) {
							fill: $main;
						}
					}
				}
			}

			&:hover {
				background: #f8f8f8;
			}

			&:last-child {
				border: none;
			}
		}
	}

}
</style>
