import { HighlightCylinder, MyLoaders, PlayerObject, PositionalHint } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { dekaBotPositions, useGameStore } from "~/store/game";
import { setupEnergyCells } from "./energycells";
import { create3DUi } from "~/space/_lib/utils/3dui";
import { useSpaceStore } from "~/store/space";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { blockForFrontend, unblockFromFrontend } from "~/space/_lib/utils/frontend";
import { setupAlwaysPlayerFacing } from "~/space/_lib/shader/billboard";
import { Autoplay } from "swiper";
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { resetDekaBot } from "./dekabot";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';

export async function setupBlockchainGame() {


	const engine = getEngine()
	const space = getSpace()
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()

	const hcBlockchaingame = new HighlightCylinder()
	engine.scene.add(hcBlockchaingame)
	hcBlockchaingame.scale.set(0.7, 1, 0.7)
	hcBlockchaingame.position.set(30.31, 5.2630, 6.121)

	space.pciSystem.add({
		collider: new SphereCollider(hcBlockchaingame, 3),
		onColliderEnter: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			spaceStore.hint.title = "Blockchaingame starten"
			gameStore.dekaBotText = "Lerne wie eine Blockchain funktioniert."
			if (gameStore.dekaBotPosition != dekaBotPositions.blockchain) {
				gameStore.dekaBotPosition = dekaBotPositions.blockchain
			}
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {
			spaceStore.hint.title = undefined
			spaceStore.showBlockchainModal = true
			gameStore.blockchainGameState = 0
			space.eventbus.emit("newAnalytics", "openBlockchain")
		}
	})


	const hcBlockchainRadius = new HighlightCylinder()
	engine.scene.add(hcBlockchainRadius)
	hcBlockchainRadius.position.set(26.69, 4.599, -1.8)
	hcBlockchainRadius.visible = false

	space.pciSystem.add({
		collider: new SphereCollider(hcBlockchainRadius, 4),
		onColliderEnter: () => {
			gameStore.dekaBotText = "Lerne wie eine Blockchain funktioniert."
			if (gameStore.dekaBotPosition != dekaBotPositions.blockchain) {
				gameStore.dekaBotPosition = dekaBotPositions.blockchain
			}
		}
	})


}
