<template>
	<SpaceModal :show=show @closed="close()">
		<div class="content">
			<svg class="smlogo" width="618" height="109" viewBox="0 0 618 109" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M579.635 107V57.308H597.108V65.691C599.397 59.9677 604.346 56.8367 611.955 56.298L616.904 55.894L617.914 71.044L608.117 71.953C604.548 72.2897 601.956 73.2997 600.34 74.983C598.724 76.599 597.916 79.023 597.916 82.255V107H579.635Z" fill="#E34132" />
				<path d="M550.679 108.111C544.619 108.111 539.367 107.067 534.923 104.98C530.547 102.825 527.18 99.7954 524.823 95.89C522.467 91.9847 521.288 87.3724 521.288 82.053C521.288 77.003 522.399 72.5254 524.621 68.62C526.911 64.7147 530.008 61.6847 533.913 59.53C537.886 57.308 542.397 56.197 547.447 56.197C552.43 56.197 556.739 57.2407 560.375 59.328C564.079 61.4154 566.907 64.4117 568.859 68.317C570.879 72.155 571.889 76.6664 571.889 81.851V85.79H538.458C539.064 89.022 540.411 91.345 542.498 92.759C544.586 94.173 547.481 94.88 551.184 94.88C553.743 94.88 556.403 94.5097 559.163 93.769C561.991 92.961 564.483 91.8164 566.637 90.335L571.081 102.152C568.523 103.97 565.392 105.418 561.688 106.495C557.985 107.572 554.315 108.111 550.679 108.111ZM548.255 67.812C545.562 67.812 543.34 68.62 541.589 70.236C539.906 71.852 538.829 74.2087 538.357 77.306H557.143C556.941 74.1414 556.066 71.7847 554.517 70.236C553.036 68.62 550.949 67.812 548.255 67.812Z" fill="#E34132" />
				<path d="M462.36 107V35.795H480.338V63.469C483.907 58.621 489.092 56.197 495.892 56.197C501.818 56.197 506.194 57.9477 509.022 61.449C511.918 64.9504 513.365 70.337 513.365 77.609V107H495.387V78.316C495.387 75.286 494.849 73.1314 493.771 71.852C492.761 70.5727 491.246 69.933 489.226 69.933C486.533 69.933 484.378 70.8084 482.762 72.559C481.146 74.2424 480.338 76.5317 480.338 79.427V107H462.36Z" fill="#E34132" />
				<path d="M438.79 108.111C433.201 108.111 428.319 107.067 424.145 104.98C420.037 102.893 416.839 99.8964 414.55 95.991C412.26 92.0857 411.116 87.4397 411.116 82.053C411.116 76.6664 412.26 72.054 414.55 68.216C416.839 64.3107 420.037 61.348 424.145 59.328C428.319 57.2407 433.201 56.197 438.79 56.197C442.089 56.197 445.355 56.6684 448.587 57.611C451.886 58.4864 454.579 59.732 456.667 61.348L451.92 73.67C450.371 72.458 448.553 71.5154 446.466 70.842C444.446 70.1014 442.527 69.731 440.709 69.731C437.14 69.731 434.379 70.8084 432.427 72.963C430.474 75.1177 429.498 78.1477 429.498 82.053C429.498 86.0257 430.474 89.123 432.427 91.345C434.379 93.4997 437.14 94.577 440.709 94.577C442.527 94.577 444.446 94.2404 446.466 93.567C448.553 92.8264 450.371 91.85 451.92 90.638L456.667 102.96C454.579 104.576 451.886 105.855 448.587 106.798C445.287 107.673 442.022 108.111 438.79 108.111Z" fill="#E34132" />
				<path d="M396.986 108.111C389.444 108.111 383.856 106.36 380.22 102.859C376.584 99.3577 374.766 94.072 374.766 87.002V70.539H365.474V57.308H374.766V42.865H392.744V57.308H406.884V70.539H392.744V86.396C392.744 91.244 395.201 93.668 400.117 93.668C400.925 93.668 401.8 93.6007 402.743 93.466C403.753 93.264 404.864 92.9947 406.076 92.658L408.5 105.586C406.951 106.394 405.133 107 403.046 107.404C400.958 107.875 398.938 108.111 396.986 108.111Z" fill="#E34132" />
				<path d="M330.794 108.111C326.956 108.111 323.556 107.404 320.593 105.99C317.698 104.576 315.409 102.657 313.725 100.233C312.109 97.7417 311.301 94.9137 311.301 91.749C311.301 88.1804 312.244 85.3524 314.129 83.265C316.015 81.1104 319.045 79.5954 323.219 78.72C327.394 77.7774 332.949 77.306 339.884 77.306H343.722V75.892C343.722 73.468 343.083 71.751 341.803 70.741C340.591 69.731 338.47 69.226 335.44 69.226C332.882 69.226 330.054 69.63 326.956 70.438C323.859 71.246 320.829 72.4917 317.866 74.175L313.321 62.358C315.005 61.146 317.159 60.0687 319.785 59.126C322.411 58.1834 325.172 57.4764 328.067 57.005C330.963 56.4664 333.69 56.197 336.248 56.197C344.53 56.197 350.658 57.9814 354.63 61.55C358.67 65.1187 360.69 70.741 360.69 78.417V107H343.924V99.93C343.049 102.489 341.467 104.509 339.177 105.99C336.888 107.404 334.094 108.111 330.794 108.111ZM334.834 96.395C337.326 96.395 339.413 95.587 341.096 93.971C342.847 92.355 343.722 90.234 343.722 87.608V85.79H339.884C335.912 85.79 332.983 86.2277 331.097 87.103C329.279 87.911 328.37 89.224 328.37 91.042C328.37 92.5907 328.909 93.87 329.986 94.88C331.131 95.89 332.747 96.395 334.834 96.395Z" fill="#E34132" />
				<path d="M225.675 107V35.795H241.128L263.853 76.599L286.578 35.795H301.627V107H284.861V68.317L268.6 96.496H258.5L242.441 68.721V107H225.675Z" fill="#E34132" />
				<path d="M208.742 108.111C201.874 108.111 196.891 106.394 193.794 102.96C190.696 99.4587 189.148 94.2067 189.148 87.204V35.795H207.126V86.598C207.126 91.3114 209.28 93.668 213.59 93.668C214.263 93.668 214.936 93.6344 215.61 93.567C216.35 93.4997 217.057 93.3987 217.731 93.264L217.529 107C214.835 107.741 211.906 108.111 208.742 108.111Z" fill="#E34132" />
				<path d="M173.628 108.111C166.76 108.111 161.778 106.394 158.68 102.96C155.583 99.4587 154.034 94.2067 154.034 87.204V35.795H172.012V86.598C172.012 91.3114 174.167 93.668 178.476 93.668C179.15 93.668 179.823 93.6344 180.496 93.567C181.237 93.4997 181.944 93.3987 182.617 93.264L182.415 107C179.722 107.741 176.793 108.111 173.628 108.111Z" fill="#E34132" />
				<path d="M125.628 107V57.308H143.606V107H125.628Z" fill="#E34132" />
				<path d="M67.0401 107V35.795H85.0181V77.306H85.2201L101.582 57.308H122.691L102.996 80.942L123.398 107H101.885L85.2201 86.295H85.0181V107H67.0401Z" fill="#E34132" />
				<path d="M28.926 108.111C23.3373 108.111 17.9843 107.438 12.867 106.091C7.74967 104.677 3.54134 102.825 0.242004 100.536L5.393 86.093C8.49034 88.1803 12.1263 89.83 16.301 91.042C20.4757 92.254 24.684 92.86 28.926 92.86C33.37 92.86 36.4673 92.2876 38.218 91.143C40.036 89.931 40.945 88.4496 40.945 86.699C40.945 85.1503 40.3053 83.9046 39.026 82.962C37.814 82.0193 35.6257 81.2113 32.461 80.538L20.644 78.013C7.85067 75.2523 1.454 68.5526 1.454 57.914C1.454 53.268 2.69967 49.1943 5.19101 45.693C7.74967 42.1916 11.2847 39.4983 15.796 37.613C20.3073 35.6603 25.5593 34.684 31.552 34.684C36.4673 34.684 41.1133 35.391 45.49 36.805C49.934 38.1516 53.5363 40.0033 56.297 42.36L51.146 55.894C48.5873 54.0086 45.6247 52.561 42.258 51.551C38.8913 50.4736 35.2553 49.935 31.35 49.935C27.5793 49.935 24.684 50.6083 22.664 51.955C20.644 53.2343 19.634 54.9176 19.634 57.005C19.634 58.4863 20.24 59.6983 21.452 60.641C22.664 61.5836 24.8187 62.3916 27.916 63.065L39.632 65.59C52.6273 68.3506 59.125 74.882 59.125 85.184C59.125 89.83 57.8793 93.87 55.388 97.304C52.8967 100.738 49.3953 103.398 44.884 105.283C40.3727 107.168 35.0533 108.111 28.926 108.111Z" fill="#E34132" />
				<path fill-rule="evenodd" clip-rule="evenodd" d="M144.985 15.2776C145.955 15.9824 148.006 17.4722 148.918 18.2347C152.813 21.5627 155 25.6533 155 31.2C155 32.1498 154.938 33.0844 154.817 34H152V42H152.034C148.443 48.001 141.942 52 134.5 52C123.157 52 114 42.7093 114 31.2C114 24.8213 115.367 20.8693 119.603 14.2133C120.013 13.5893 120.765 13.104 121.517 13.0347C122.952 12.896 124.182 13.936 124.318 15.392C124.455 17.4027 125.07 19.2747 126.163 20.9387C125.48 13.1733 128.897 4.43733 137.302 0.277333C137.848 6.62274e-09 138.463 -0.0693333 139.01 0.0693333C140.377 0.416 141.265 1.80267 140.923 3.18933C139.557 8.736 140.923 11.7173 144.477 14.9067C144.535 14.9513 144.72 15.0852 144.985 15.2776ZM119.125 31.3387C119.125 39.936 126.027 46.8693 134.5 46.8693H134.705C143.11 46.8693 149.943 39.8667 149.875 31.2693C149.875 27.3867 148.372 24.6827 145.57 22.256C145.093 21.8124 143.83 20.8763 142.749 20.0751C141.972 19.4988 141.289 18.9924 141.06 18.7893C137.712 15.6693 135.593 12.4107 135.32 8.04267C133.612 9.70667 129.717 14.976 131.903 25.4453C131.972 25.6533 131.972 25.792 131.972 26C131.972 27.3867 130.81 28.5653 129.375 28.5653C126.095 28.5653 122.883 26.0693 120.97 22.256C120.881 22.4786 120.786 22.7013 120.69 22.9296C119.974 24.6213 119.125 26.6267 119.125 31.2693V31.3387Z" fill="#E34132" />
			</svg>
			<SpaceGameSkillmatching />
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()
const { showSkillmatchingModal } = storeToRefs(spaceStore)
const show = computed(() => !!showSkillmatchingModal.value)


function close() {
	showSkillmatchingModal.value = undefined
}


</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.smlogo {
	width: 200px;
	height: auto;

	* {
		fill: #E34132 !important;
	}
}

.content {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
</style>
