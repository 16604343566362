import { Logging, LoggingArea, SimpleColliderSystem } from "@ravespaceio/rave-engine"
import { ColliderI, SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem"
import { Vector3 } from "three"
import { canUseActionKey } from "../utils/frontend"
import * as THREE from "three"
import { isMovingKeyPressed } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import { removeFromArray } from "@ravespaceio/rave-engine/build/engine/src/utils/js"
import { getEngine } from "~/space/engine"
import { getSpace } from "~/space/space"


export interface PCI_InteractionCollider {

	collider: ColliderI

	onColliderCanEnter?: () => boolean // may block onColliderEnter and onColliderLeave. checked every frame
	onColliderEnter?: () => void
	onColliderLeave?: () => void

	onCanInteract?: () => boolean, // may block onInteract and onExit. checked on interaction
	onInteract?: () => void,
	onExit?: () => void,

	// interactTrigger?: [] // what must happen to interact?
	// exitTrigger?: [] // whan must happen to exit interaction? should be called if player moved outside of collider

}

/**
 * Player Collision Interaction System
 *
 * Can have multiple colliding elements (triggering onColliderEnter and onColliderLeave if onColliderCanEnter is set and returns true)
 * Cam have one nearest colliding element
 * Can interacting only with its nearest colliding element
 */
export default class PCISystem {

	private readonly _colliderSys: SimpleColliderSystem
	private readonly _pcis: { [id: string]: PCI_InteractionCollider } = {}

	private readonly _entered: PCI_InteractionCollider[] = [] // list of pcis where onColliderEnter was triggered
	private _nPci: PCI_InteractionCollider | undefined // nearest pci
	private _nPciIsInteracting: boolean = false

	constructor() {
		this._colliderSys = new SimpleColliderSystem()
	}

	private _getPlayerPosition(): Vector3 {
		const engine = getEngine()
		if (engine.player.getPlayerPosition() === undefined) {
			return new Vector3();
		} else {
			return engine.player.getPlayerPosition()
		}
	}

	public setup() {
		const engine = getEngine()
		const space = getSpace()

		// trigger and exit logic (applied to nearest pci)
		// TODO implement trigger. trigger can be a key (new inputManager?), button (hint), event, canvas click,.. use trigger also for menu shortcuts?
		space.eventbinder.bindDocument("keyup", (e) => {
			if (!this._nPci) return;
			if (e.key === " " && canUseActionKey()) this.triggerNearestInteract()
			if (e.key == "Escape") this.triggerNearestExit() // not working for pl. watch on pl change instead
			if (canUseActionKey() && isMovingKeyPressed(e.key)) this.triggerNearestExit()
		})
		engine.inputManager.gamepad.on("buttonup", (btn) => {
			if (btn === "Y" && canUseActionKey()) this.triggerNearestInteract()
			if (btn == "B") this.triggerNearestExit()
		})
		engine.renderer.canvasElement.addEventListener("click", () => {
			this.triggerNearestExit()
		})
		engine.inputManager.joystick.inner.addEventListener("touchstart", () => {
			this.triggerNearestExit()
		})

		// track enter (for elements without onColliderCanEnter) and leave
		this._colliderSys.onColliderStateChanged = (collider, state) => {
			const pci = this._getPciByCollider(collider)
			if (!pci.onColliderCanEnter) { state ? this._enter(pci) : this._leave(pci) }
		}

		// update
		engine.loop.register(() => {
			// check colliding, will trigger onColliderStateChanged
			this._colliderSys.update(this._getPlayerPosition())

			// track enter and leave (for elements with onColliderCanEnter)
			let anyEnteredOrLeft = false
			for (const collider of this._colliderSys.getColliding()) {
				const pci = this._getPciByCollider(collider)
				if (!pci.onColliderCanEnter) continue;
				if (pci.onColliderCanEnter()) { anyEnteredOrLeft = anyEnteredOrLeft || this._enter(pci) }
				else { anyEnteredOrLeft = anyEnteredOrLeft || this._leave(pci) }
			}
			for (const pci of this._entered) { if (!this._colliderSys.getColliding().includes(pci.collider)) this._leave(pci) }

			// track nearest entered (save for interaction)
			const nearest = this._findNearestEntered()
			if (nearest !== this._nPci) {
				if (this._nPci && this._nPciIsInteracting) this.triggerNearestExit()
				this._nPci = nearest
				anyEnteredOrLeft = true
			}

			// fix design of having multiple "entered" and using them to display frontend data
			// when a collider was left or onColliderCanEnter (from above) turned false onLeave was called which may clear a state
			// e.g. hint state is set to undefined again even though player is still colliding with others
			if (anyEnteredOrLeft && nearest) { nearest.onColliderEnter?.() }
		})
	}

	public add(pci: PCI_InteractionCollider) {
		// if (pci.collider instanceof SphereCollider) {
		// 	const sphereHelper = new THREE.Mesh(new THREE.SphereGeometry(pci.collider.radius, 12, 12), new THREE.MeshBasicMaterial({ color: "white", wireframe: true }))
		// 	pci.collider.object.userData.helper = sphereHelper
		//  useEngine().scene.add(pci.collider.object.add(sphereHelper))
		// }
		this._colliderSys.add(pci.collider)
		this._pcis[pci.collider.object.uuid] = pci
	}

	private _getPciByCollider(collider: ColliderI): PCI_InteractionCollider {
		return this._pcis[collider.object.uuid]
	}

	private _enter(pci: PCI_InteractionCollider): boolean {
		if (this._entered.includes(pci)) return false;
		this._entered.push(pci)
		pci.onColliderEnter?.()
		return true
	}

	private _leave(pci: PCI_InteractionCollider): boolean {
		if (!this._entered.includes(pci)) return false;
		removeFromArray(this._entered, pci)
		pci.onColliderLeave?.()
		return true
	}

	private _findNearestEntered(): PCI_InteractionCollider | undefined {
		if (this._entered.length === 0) return undefined
		if (this._entered.length === 1) return this._entered[0]
		let closestCollider: PCI_InteractionCollider | undefined
		let closestDst: number = Number.MAX_SAFE_INTEGER
		for (const collider of this._entered) {
			const colliderDst = collider.collider.object.position.distanceTo(this._getPlayerPosition())
			if (colliderDst < closestDst) {
				closestCollider = collider
				closestDst = colliderDst
			}
		}
		return closestCollider
	}

	public triggerNearestInteract() {
		if (!this._nPci) return; // nothing to interact with
		if (this._nPciIsInteracting) return; // already interacting
		if (this._nPci.onCanInteract && !this._nPci.onCanInteract()) return; // pre conditon failed
		this._nPciIsInteracting = true
		this._nPci.onInteract?.()
	}

	public triggerNearestExit() {
		if (this._nPciIsInteracting && this._nPci) {
			this._nPciIsInteracting = false
			this._nPci.onExit?.()
		}
	}

	public isInteracting() {
		return !!this._nPciIsInteracting
	}
}
