<template>
	<div class="game">

		<div class="btn" @click="startCountdown()" v-if="!gameStarted" style="margin-bottom: 20px;">MasterMiner Starten</div>


		<div class="area top" :class="!gameStarted ? 'disabled' : ''">
			<div class="left">
				<h4>Countdown</h4>
				<p class="countdown">{{ countdown }}</p>
			</div>
			<div class="right">
				<h4>Score</h4>
				<p>{{ blocks }}</p>
			</div>
		</div>



		<div class="middle" :class="!gameStarted ? 'disabled' : ''">
			<div class="area">
				<div class="numpad" :class="numpadDisabled ? 'disabled' : ''">
					<span @click="chooseNonce(1)" :class="chosenNonce == 1 ? 'active' : ''">1</span>
					<span @click="chooseNonce(2)" :class="chosenNonce == 2 ? 'active' : ''">2</span>
					<span @click="chooseNonce(3)" :class="chosenNonce == 3 ? 'active' : ''">3</span>
					<span @click="chooseNonce(4)" :class="chosenNonce == 4 ? 'active' : ''">4</span>
					<span @click="chooseNonce(5)" :class="chosenNonce == 5 ? 'active' : ''">5</span>
					<span @click="chooseNonce(6)" :class="chosenNonce == 6 ? 'active' : ''">6</span>
					<span @click="chooseNonce(7)" :class="chosenNonce == 7 ? 'active' : ''">7</span>
					<span @click="chooseNonce(8)" :class="chosenNonce == 8 ? 'active' : ''">8</span>
					<span @click="chooseNonce(9)" :class="chosenNonce == 9 ? 'active' : ''">9</span>
					<span @click="chooseNonce(0)" :class="chosenNonce == 0 ? 'active' : ''">0</span>
				</div>
			</div>

			<div class="area" id="block-game">
				<h4>Aktuelle Nonce</h4>
				<p class="nonce">
					<template v-if="chosenNonce != undefined">{{ chosenNonce }}</template>
					<template v-else>---</template>
				</p>

				<div class="btn green" :class="disabled ? 'disabled' : ''" @click="generate()">Generiere Blockhash
					<IconLoader v-if="isLoading" />
				</div>
				<h4>Blockhash</h4>
				<div class="blockhash" :class="animate ? animate : ''">
					<p v-if="blockhash" v-html="blockhash"></p>
					<p v-else>---</p>
				</div>
			</div>
		</div>

		<div class="area blocks" :class="!gameStarted ? 'disabled' : ''">
			<h4>Blockchain</h4>
			<div class="wrapper" :class="'progress-' + blocks">
				<span v-for="( block, index ) in  maxBlocks " class="block">{{ index }}</span>
			</div>
		</div>

		<h5 class="nn">{{ rightNonce }}</h5>

	</div>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'

const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()

const numpadDisabled = ref(false)
const disabled = ref(true)
const countdown = ref(90)
const timer = ref()
const nonce = ref(0)
const chosenNonce = ref(undefined)
const isLoading = ref(undefined)
const blockhash = ref(undefined)
const hashes = ["a1b2c3d4", "e5f6g7h8", "i9j0k1l2", "m3n4o5p6", "q7r8s9t0", "u1v2w3x4", "y5z6A7B8", "C9D0E1F2", "G3H4I5J6", "K7L8M9N0", "O1P2Q3R4S5", "T6U7V8W9X0", "Y1Z2a3b4c5", "d6e7f8g9h0", "i1j2k3l4m5", "n6o7p8q9r0", "s1t2u3v4w5", "x6y7z8A9B0", "C1D2E3F4G5", "H6I7J8K9L0", "M1N2O3P4Q5", "R6S7T8U9V0", "W1X2Y3Z4a5", "b6c7d8e9f0", "g1h2i3j4k5", "l6m7n8o9p0", "q1r2s3t4u5", "v6w7x8y9z0", "A1B2C3D4E5", "F6G7H8I9J0", "K1L2M3N4O5", "P6Q7R8S9T0", "U1V2W3X4Y5", "Z6a7b8c9d0", "e1f2g3h4i5", "j6k7l8m9n0", "o1p2q3r4s5", "t6u7v8w9x0",];
const animate = ref('')
const maxBlocks = 11
const blocks = ref(0)
const rightNonce = ref(undefined)
const gameStarted = ref(false)


function chooseNonce(nonce) {
	chosenNonce.value = nonce
	disabled.value = false
}

function generate() {
	if (!rightNonce.value) {
		rightNonce.value = Math.floor(Math.random() * 10)
	}
	disabled.value = true
	numpadDisabled.value = true
	isLoading.value = true
	const randomBlockhash = Math.floor(Math.random() * hashes.length)


	setTimeout(() => {
		if (chosenNonce.value == rightNonce.value) {
			blockhash.value = '<span>0</span>' + hashes[randomBlockhash]
			reset()
		} else {
			blockhash.value = '<div class="wrong">' + hashes[randomBlockhash] + '</div>'
			animate.value = "wrong"
			isLoading.value = false
			setTimeout(() => {
				animate.value = ""
				chosenNonce.value = undefined
				blockhash.value = false
				disabled.value = false
				numpadDisabled.value = false
			}, 800);
		}
	}, 800);
}

watch(() => spaceStore.showBlockchainModal, () => {
	clearInterval(timer.value);
})


function startCountdown() {
	rightNonce.value = Math.floor(Math.random() * 10)
	gameStore.blockchainGameScore = 0
	gameStarted.value = true
	countdown.value = 90
	timer.value = setInterval(() => {
		if (countdown.value > 0) {
			countdown.value--;
		} else {
			clearInterval(timer.value);
			gameStore.blockchainGameState = 3
		}
	}, 1000);
}



function reset() {

	animate.value = "right"

	const tl = new TimelineLite();
	tl.to("#block-game", 0.5, { opacity: 0, y: 40 }, "+=0.7");
	tl.add(function () {
		useToast().success("Block hinzugefügt!")
		isLoading.value = false
		blocks.value++
		gameStore.blockchainGameScore = blocks.value
		animate.value = ""
	})
	tl.to("#block-game", 0.2, { y: 0, x: 40 });
	tl.add(function () {
		rightNonce.value = Math.floor(Math.random() * 10)
		blockhash.value = false
		chosenNonce.value = undefined
		disabled.value = false
		numpadDisabled.value = false
	})
	tl.to("#block-game", 0.5, { opacity: 1, x: 0 });


}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.nn {
	opacity: 0;
	display: inline-block;

	&:hover {
		opacity: 1;
	}
}

.game {
	width: 100%;
	max-width: 600px;
}

.middle {
	display: flex;
	align-items: initial;
	justify-content: space-between;

	.area {
		width: calc(50% - 6px);
		height: auto;
	}
}

.area {
	background: $blue;
	border-radius: $radius;
	padding: 20px 30px;
	@extend .flex-center;
	flex-direction: column;
	width: 100%;
	margin-bottom: 12px;

	h4,
	p {
		color: white;
	}

	h4 {
		font-weight: 600;
	}

	.nonce,
	.blockhash {
		width: 100px;
		border-radius: $radius;
		border: 1px solid white;
		text-align: center;
		margin-top: 12px;
		padding: 4px;
	}

	.blockhash {
		width: 150px;
		font-weight: 700;

		p {
			font-weight: 700;
			letter-spacing: 1px;

			::v-deep(span) {
				font-weight: 700;
				color: $green;
			}

		}

		&.right {
			animation: pulse 0.5s ease 0s infinite normal forwards;
		}

		&.wrong {
			animation: horizontal-shaking 0.5s ease 0s infinite normal forwards;
		}
	}

	::v-deep(.wrong) {
		font-weight: 700;
		letter-spacing: 1px;
		color: $red !important;
	}


	.btn {
		margin: 20px 0;
		font-size: 14px;
		padding: 10px 20px;
		width: 100%
	}

	.numpad {
		@extend .flex-center;
		flex-wrap: wrap;
		width: 150px;

		span {
			width: 40px;
			height: 40px;
			border-radius: 4px;
			margin: 4px;
			background: white;
			@extend .flex-center;
			color: $blue;
			cursor: pointer;
			font-weight: 700;
			opacity: 0.8;
			transition: ease 0.2s;

			&:hover {
				opacity: 1;
				transform: scale(1.05)
			}

			&.active {
				background: $green;
			}
		}
	}

	&.top {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: initial;

		.left,
		.right {
			width: 50%;
			@extend .flex-center;
			flex-direction: column;
			justify-content: flex-start;

			p {
				border: 1px solid white;
				border-radius: 2px;
				padding: 2px 10px;
				margin-top: 3px;
				font-weight: 700;
				font-size: 16px;
			}
		}
	}
}

.blocks {
	.wrapper {
		@extend .flex-center;

		span {
			width: 40px;
			height: 30px;
			background: transparent;
			border-radius: 2px;
			@extend .flex-center;
			margin: 3px;
			font-size: 12px;
			font-weight: 700;
			opacity: 0;
			position: relative;
			border: 3px solid $green;
			color: $green;

			&:after {
				content: "";
				position: absolute;
				width: 10px;
				height: 3px;
				background: $green;
				left: -10px;
			}

			&:first-child {
				opacity: 1;

				&:after {
					display: none;
				}
			}
		}
	}
}

.wrapper {

	&.progress-1 {
		span:nth-child(-n+2) {
			opacity: 1;
		}
	}

	&.progress-2 {
		span:nth-child(-n+3) {
			opacity: 1;
		}

	}

	&.progress-3 {
		span:nth-child(-n+4) {
			opacity: 1;
		}

	}

	&.progress-4 {
		span:nth-child(-n+5) {
			opacity: 1;
		}

	}

	&.progress-5 {
		span:nth-child(-n+6) {
			opacity: 1;
		}

	}

	&.progress-6 {
		span:nth-child(-n+7) {
			opacity: 1;
		}

	}

	&.progress-7 {
		span:nth-child(-n+8) {
			opacity: 1;
		}

	}

	&.progress-8 {
		span:nth-child(-n+9) {
			opacity: 1;
		}

	}

	&.progress-9 {
		span:nth-child(-n+10) {
			opacity: 1;
		}

	}

	&.progress-10 {
		span:nth-child(-n+11) {
			opacity: 1;
		}
	}

}

.interaction {
	padding: 40px 30px;
}

@media(max-width: 700px) {
	.game {
		.middle {
			flex-direction: column;

			.area {
				width: 100% !important;
			}
		}
	}
}
</style>
