import Engine, { Water } from "@ravespaceio/rave-engine";
import GUI from "lil-gui";
import { Mesh, MeshStandardMaterial, Vector3 } from "three";
import * as THREE from "three"
import { getSpace } from "../../space";
import { getEngine } from "../../engine";
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";


export function setupWaterMesh(waterBaseMesh: Mesh): Water {

	const space = getSpace()
	const engine = getEngine()


	waterBaseMesh.name = "WaterMeshBase"

	const waterParams = {
		textureWidth: 512,
		textureHeight: 512,
		waterNormals: getMatMap(space.loader, "/RaveEngine/textures/water/waternormals.jpg"),
		sunDirection: new THREE.Vector3(),
		sunColor: new THREE.Color(0xFFFFFF),
		waterColor: new THREE.Color(0x00203d),
		distortionScale: 1,
		normal: new Vector3(0, 1, 0),
		fog: true
	}

	const realWater = new Water(waterBaseMesh.geometry, waterParams)
	realWater.applyMatrix4(waterBaseMesh.matrix);

	(waterBaseMesh.material as THREE.Material).userData.__noQualityTransfer = true
	realWater.material.userData.__noQualityTransfer = true
	space.gui.addToSceneFolder(waterBaseMesh);
	space.gui.addToSceneFolder(realWater, Water.addToGui).title(waterBaseMesh.name)

	engine.scene.add(realWater)
	engine.loop.register((dt) => {
		realWater.material.uniforms.time.value += dt / 5
	})

	space.eventbus.onAlready("qualitysetting", (q) => {
		realWater.visible = q === "high"
		waterBaseMesh.visible = !realWater.visible
	})

	return realWater
}
