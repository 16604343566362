
import { EngineUtils, HighlightCylinder, MyLoaders, PositionalHint } from "@ravespaceio/rave-engine"
import { getSpace } from '~/space/space';
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { useGameStore } from "~/store/game";
import { Text } from 'troika-three-text'
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useToast } from "vue-toastification";


export async function setupLiveQuiz() {
	const space = getSpace()
	const gameStore = useGameStore()
	initCountdown()


	let platform, q1, q2, q3
	space.loader.getLoadingPromise("areas").then((gltf) => {
		const objects = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
		objects.forEach((object) => {
			if (object.userData.CMSR == "liveQuiz") {
				platform = object
			}
			if (object.userData.CMSR == "q1") {
				q1 = object
			}
			if (object.userData.CMSR == "q2") {
				q2 = object
			}
			if (object.userData.CMSR == "q3") {
				q3 = object
			}
		})
		initPlatform(platform, q1, q2, q3)
	})


}

function initPlatform(platform, q1, q2, q3) {
	const space = getSpace()
	const engine = getEngine()
	const white = new THREE.Color(0x000000)
	const red = new THREE.Color(0xFFFF00)

	let curr = 0

	q1.material = new THREE.MeshBasicMaterial()
	q2.material = new THREE.MeshBasicMaterial()
	q3.material = new THREE.MeshBasicMaterial()
	q1.material.color = white
	q2.material.color = white
	q3.material.color = white

	platform.visible = false
	space.eventbus.onAlready("showPlatforms", (value) => {
		useGameStore().liveQuizStarted = value
		platform.visible = value
	});

	space.eventbus.on("spotlightCountdown", (value) => {
		resetMaterial()
		if (curr == 0) {
			q1.material.color = red
		}
		if (curr == 1) {
			q2.material.color = red
		}
		if (curr == 2) {
			q3.material.color = red
		}
		curr++
		if (curr == 3) { curr = 0 }
	})

	function resetMaterial() {
		q1.material.color = white
		q2.material.color = white
		q3.material.color = white
	}


	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(2, 10, 2)
	hc.name = "hclive"
	hc.visible = false
	space.gui.addToSceneFolder(hc)

	space.eventbus.on("quizRoundOver", (value) => {
		resetMaterial()
		if (value == 1) {
			hc.position.set(-5.4, 1, 39.2)
		}
		if (value == 2) {
			hc.position.set(-9, 1, 36.7)
		}
		if (value == 3) {
			hc.position.set(-13.4, 1, 35.6)
		}
		hc.visible = true
		setTimeout(() => {
			hc.visible = false
		}, 5000);
	})

}


function initCountdown() {
	/* COUNTDOWN */
	const engine = getEngine()
	const space = getSpace()
	const cTextObj = new Text()
	cTextObj.text = ""
	cTextObj.fontSize = 3
	cTextObj.position.set(-7.7, 4.6, 40.5)
	cTextObj.rotation.y = 2.66
	cTextObj.color = 0xED7703
	cTextObj.textAlign = "center"
	cTextObj.overflowWrap = "break-word"
	engine.scene.add(cTextObj)

	space.gui.addToSceneFolder(cTextObj)

	space.eventbus.on("liveQuizCountdown", (timestamp) => {
		const pastTime = new Date(timestamp);
		const now = new Date();
		const timeDiffInMs = now.getTime() - pastTime.getTime();
		if (timeDiffInMs >= 1000) {
			console.log('Last quiz started ' + pastTime);
			cTextObj!.text = ""
		} else {
			startCountdown(cTextObj, timestamp)
		}
	})
}


function startCountdown(cTextObj: { text: string; sync: () => void; } | undefined, timestamp: any) {
	const engine = getEngine()
	const space = getSpace()
	const startTime = timestamp
	const countdownTime = 15
	var cInterval: string | number | NodeJS.Timer | undefined
	cTextObj!.text = ""

	space.eventbus.emit("startSound")


	var showStartTime = new Date(startTime).getTime()
	var countdownDestinationTime = new Date(showStartTime + countdownTime * 1000).getTime();



	cInterval = setInterval(() => {
		var now = Date.now()
		var distance = countdownDestinationTime - now;
		var seconds = Math.floor((distance % (1000 * 60)) / 1000);
		var currentCountdownTime = "0:" + seconds;

		cTextObj!.text = currentCountdownTime
		cTextObj!.sync()

		space.eventbus.emit("spotlightCountdown")

		if (distance < 0) {
			clearInterval(cInterval)
			cTextObj!.text = ""
			checkResult()
		}
	}, 300);
}

function checkResult() {
	const engine = getEngine()
	const space = getSpace()
	const gameStore = useGameStore()
	const toast = useToast()

	let correctAnswer
	space.eventbus.onAlready("correctAnswer", (value) => {
		correctAnswer = value
	});
	space.eventbus.emit("quizRoundOver", correctAnswer)

	let navzone = gameStore.navZone.replace("q", "")
	const choice = parseInt(navzone)


	if (choice == correctAnswer) {
		toast.success("Richtige Antwort!")
		gameStore.liveQuizRightAnswers++
		if (gameStore.liveQuizRightAnswers > 1) {
			gameStore.quests[3].done = true
			gameStore.newEnergycellFound = true
			space.eventbus.emit("newAnalytics", "energycellLiveQuiz")
		}
	} else {
		toast.success("Falsche Antwort.")
	}
}
