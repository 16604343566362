import { defineStore } from 'pinia'
import { RoomAvailable } from 'colyseus.js';
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes";
import { Chatmessage_I } from '@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes';
import { Client_GlobalChat_RSH } from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/ClientStateHandlers/GlobalRoom/Client_GlobalChat_RSH";
import Engine, { Logging, LoggingArea } from '@ravespaceio/rave-engine';
import { getEngine } from '../space/engine';
import { getSpace } from '~/space/space';
import { useSpaceStore } from './space';
import { useToast } from 'vue-toastification';

type ChatMessageWithHrSupport = Chatmessage_I & { hrSupport: boolean };

export const HR_MESSAGE_TOKEN = "4zdzl9B4";

export const useMultiplayerStore = defineStore({
	id: "multiplayer",
	state: () => ({
		isEnabled: undefined as undefined | boolean,
		isConnectedRoomGlobal: undefined as undefined | boolean,
		isConnectedRoomAgora: undefined as undefined | boolean,
		isConnectedRoom3D: undefined as undefined | boolean,
		blocked: false,
		playerId: "",
		room3dId: "",
		room3dName: "",
		playerAmountGlobalRoom: 0,
		playersAmountRoom3d: 0,
		rooms: [] as RoomAvailable[],
		rooms3D: [] as RoomAvailable[],
		chats: [] as ChatMessageWithHrSupport[],
		isHrSupport: false as boolean
	}),
	getters: {
		isConnected: (state) => {
			return state.isConnectedRoomAgora && state.isConnectedRoomGlobal // && state.isConnectedRoom3D
		}
	},
	actions: {
		init(engine: Engine) {

			if (!engine.config.multiplayer) return;

			const space = getSpace();
			const toast = useToast()

			const roomGlobal = engine.multiplayer.clientGlobalRoom;
			const roomAgora = engine.multiplayer.clientAgoraRoom;
			const room3D = engine.multiplayer.clientRoom3D;

			if (space.browser.urlParameter.has('hrSupport') && space.browser.urlParameter.get('hrSupport') === "Lncd818nd29wnIDH61FH28")
				this.isHrSupport = true;


			// connections
			roomGlobal.onRoomJoinEvent.on(() => this.isConnectedRoomGlobal = true);
			roomGlobal.onRoomLeftEvent.on(() => this.isConnectedRoomGlobal = false);
			roomAgora.onRoomJoinEvent.on(() => this.isConnectedRoomAgora = true);
			roomAgora.onRoomLeftEvent.on(() => this.isConnectedRoomAgora = false);
			room3D.onRoomJoinEvent.on(() => this.isConnectedRoom3D = true);
			room3D.onRoomLeftEvent.on(() => this.isConnectedRoom3D = false);


			// chats
			const getMessages = (): void => {
				this.chats = engine.multiplayer.clientGlobalRoom.room.state.lastChatMessages.map((message) => {
					const hrSupport = message.chatMessage.startsWith(HR_MESSAGE_TOKEN)

					return {
						...message,
						hrSupport: hrSupport
					} as ChatMessageWithHrSupport
				});
			}

			roomGlobal.onRoomJoinEvent.on(() => getMessages());
			roomGlobal.getStateHandler<Client_GlobalChat_RSH>('globalChatHandler').onGlobalMessageReceived.on(() => getMessages());

			// update player info

			roomGlobal.onRoomJoinEvent.on(() => this.playerId = roomGlobal.clientId);
			roomGlobal.onRoomLeftEvent.on(() => this.playerId = "");

			room3D.onRoomJoinEvent.on((room) => {
				this.room3dId = room3D.roomId;
				this.room3dName = room3D.room.state.serverName;
				if (room3D.roomType === "HR1" as "room3D") this.room3dName = "HR-1"
				if (room3D.roomType === "HR2" as "room3D") this.room3dName = "HR-2"
				if (room3D.roomType === "HR3" as "room3D") this.room3dName = "HR-3"
				// toast.success("Gruppe " + this.room3dName + " betreten.");
			})
			room3D.onRoomLeftEvent.on(() => {
				this.room3dId = "";
				this.room3dName = "";
				engine.multiplayer.local_netPlayer.leaveVG();
			})

			// update multiplayer info

			roomGlobal.onRemoteClientJoinEvent.on(() => this.playerAmountGlobalRoom = roomGlobal.room.state.allPlayers.size);
			roomGlobal.onRemoteClientLeaveEvent.on(() => this.playerAmountGlobalRoom = roomGlobal.room.state.allPlayers.size);

			room3D.onRemoteClientJoinEvent.on(() => this.playersAmountRoom3d = room3D.room.state.players.size);
			room3D.onRemoteClientLeaveEvent.on(() => this.playersAmountRoom3d = room3D.room.state.players.size);

			room3D.onRemoteClientJoinEvent.once(() => this.fetchRooms3d())

			// periodically fetch room3D data when menu shown
			getSpace().eventbinder.bindInterval(() => {
				if (useSpaceStore().showMenu) this.fetchRooms3d();
			}, 1000)

		},
		fetchRooms3d() {
			Logging.trace("fetching rooms3D", LoggingArea.Api)
			const space = getSpace();
			const url = space.ENV.GAMESERVER_URI + "/api/rooms/all";

			fetch(url)
				.then(res => res.json())
				.then((roomList) => {
					const list = roomList as RoomAvailable[]
					this.rooms = list
					this.rooms3D = list.filter((room) => room.name === "room3D")
				})
				.catch(e => this.rooms3D = [])
		}
	}
})
