import { HighlightCylinder, MyLoaders, PlayerObject, PositionalHint } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { getEngine } from "~/space/engine";
import { dekaBotPositions, useGameStore } from "~/store/game";
import { useSpaceStore } from "~/store/space";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { resetDekaBot } from "./dekabot";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';

export async function setupPlatformQuiz() {


	const engine = getEngine()
	const space = getSpace()
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()


	const platforms = [
		{
			id: 0,
			position: new THREE.Vector3(-0.3, 8.6, -21.8),
		},
		{
			id: 1,
			position: new THREE.Vector3(-0.4046, 9.33, -18.57),
		},
		{
			id: 2,
			position: new THREE.Vector3(-0.7, 10.2, -14.6)
		},
		{
			id: 3,
			position: new THREE.Vector3(1.9, 11, -10.6)
		},
		{
			id: 4,
			position: new THREE.Vector3(6.7, 11.8, -10.7)
		}
	]

	for (let index = 0; index < platforms.length; index++) {
		const platform = platforms[index];

		// let xxxxxx = new HighlightCylinder()
		// engine.scene.add(xxxxxx)
		// xxxxxx.scale.set(0.7, 1, 0.7)
		// xxxxxx.position.copy(platform.position)
		// space.gui.addToSceneFolder(xxxxxx)


		let hcPlatformQuiz
		if (index == 0) {
			hcPlatformQuiz = new HighlightCylinder()
			engine.scene.add(hcPlatformQuiz)
			hcPlatformQuiz.scale.set(0.7, 1, 0.7)
			hcPlatformQuiz.position.copy(platform.position)
			watch(() => gameStore.platformQuizState, (state) => {
				if (state > 0) {
					hcPlatformQuiz.visible = false
				}
			}, { immediate: true })
		}


		let posOb = new THREE.Object3D()
		posOb.position.copy(platform.position)
		posOb.position.y -= 0.9
		space.pciSystem.add({
			collider: new SphereCollider(posOb, 1.6),
			onColliderEnter: () => {

				if (platform.id == 0) {
					gameStore.dekaBotText = "Löse unser Plattform-Quiz und hol dir die Energiezelle"
					if (gameStore.dekaBotPosition != dekaBotPositions.platformQuiz) {
						gameStore.dekaBotPosition = dekaBotPositions.platformQuiz
					}
				}

				if (gameStore.platformQuizState == platform.id) {
					spaceStore.showPlatformquizModal = true
					spaceStore.hint.title = undefined
				}

				if (gameStore.platformQuizState == platform.id) {
					engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping

					//spaceStore.hint.title = "Quiz " + platform.id + "/5"
				}
			},
			onColliderLeave: () => {
				engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true;


				spaceStore.hint.title = undefined
			},
			onInteract: () => {
				if (gameStore.platformQuizState == platform.id) {
					spaceStore.showPlatformquizModal = true
					spaceStore.hint.title = undefined
				}
				if (platform.id == 0) {
					space.eventbus.emit("newAnalytics", "startQuiz")
				}
			}
		})

	}



}
