import Engine, { InstancedGroupMesh, EngineUtils, MyLoaders, Logging, LoggingArea } from "@ravespaceio/rave-engine"
import { AmbientLight, MeshStandardMaterial, Group, Material, Matrix4, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three"
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three"
import { findObjects, testUserData } from "@ravespaceio/rave-engine/build/engine/src/utils/findings";
import { getSpace } from "~/space/space";


export default class InstancedMeshExtension {

	public readonly specProp = "ObjectID"
	public readonly isLoaded: Promise<any>
	public readonly group: Group = new Group()
	public readonly groupMeshNamePrefix: string = "Instanced_"
	private readonly _mappedEmptieSpots: { [name: string]: Object3D[] } = {}
	private readonly _instancedMeshs: { [name: string]: InstancedGroupMesh } = {}

	constructor(scene: Object3D) {
		const space = getSpace()
		const allSpots = findObjects(scene, testUserData(this.specProp))
		if (allSpots.length == 0) { Logging.warn("No spots found for " + [this.specProp], LoggingArea.Space); return; }

		scene.add(this.group)

		allSpots.forEach((spot: THREE.Object3D) => {
			const objName = spot.userData[this.specProp]
			this._mappedEmptieSpots[objName] ? this._mappedEmptieSpots[objName].push(spot) : this._mappedEmptieSpots[objName] = [spot]
		});

		const proms: Promise<any>[] = []
		for (const [objName, spots] of Object.entries(this._mappedEmptieSpots)) {
			const path = `${space.project.publicPath}/models/instanced/${objName}.glb`

			const loadingProm = space.loader.getLoadingPromise(path) || MyLoaders.gltfLoader.loadAsync(path)
			loadingProm.then((gltf: GLTF) => {
				// Logging.trace("Instancing " + path, LoggingArea.Space);
				const gi = this._createInstances(spots, gltf.scene)
				gi.name = this.groupMeshNamePrefix + objName
				this._instancedMeshs[objName] = gi
				this.group.add(gi)
			}).catch((e) => {
				// Logging.error("Instancing " + path + " failed", LoggingArea.Space);
			})
			proms.push(loadingProm)
		}
		this.isLoaded = Promise.all(proms)
	}

	private _createInstances(spots: Object3D[], model: Object3D): InstancedGroupMesh {
		const instancedGroupMesh = new InstancedGroupMesh(model, spots.length);
		// copy special properties for stuff liek ShaderID vertexWind
		const p1 = model.children[0]
		const p2 = instancedGroupMesh.children[0]
		if (p1 && p2) p2.userData = p1.userData
		this._updateEmptieTransformsToMatrix(spots, instancedGroupMesh)
		return instancedGroupMesh
	}

	private _updateEmptieTransformsToMatrix(spots: Object3D[], instancedGroupMesh: InstancedGroupMesh): void {
		spots.forEach((spot: Object3D, i: number) => {
			const m = new Matrix4()
			m.makeRotationFromQuaternion(spot.quaternion)
			m.setPosition(spot.position)
			m.scale(spot.scale)
			instancedGroupMesh.setMatrixAt(i, m)
		})
	}

	public updateEmptieTransformsToMatrix(instancedGroupMeshName: string) {
		const im = this._instancedMeshs[instancedGroupMeshName]
		const spots = this._mappedEmptieSpots[instancedGroupMeshName]
		if (!im || !spots) {
			Logging.warn("Cannot find instace " + instancedGroupMeshName, LoggingArea.Space)
			return;
		}
		this._updateEmptieTransformsToMatrix(spots, im)
	}
}



