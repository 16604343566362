<template>
	<div>
		<div v-if="gameStore.userRole === Role.superAdmin" id="adminHint">
			<h4>Admin Access: {{ gameStore.userRole }}</h4>
			<p> You are a host of this space, everyone can hear you speak. </p>
			<div @click="voiceRoleStore.joinLeave()" class="btn" :class="voiceRoleStore.isJoined ? 'red' : ''">
				{{ `${voiceRoleStore.isJoined ? "Leave" : "Join"} Master Voicechat` }}
			</div>

			<div v-if="voiceRoleStore.isJoined" class="broadcast-hint">
				<div class="mute" @click="voiceRoleStore.muteUnmute()">
					<div class="key">P</div>{{ voiceRoleStore.isMuted ? "Unmute" : "Mute" }}
				</div>
				<div class="volumeLevel">
					<p>Microphone Volume Level</p>
					<div class="progress-bar">
						<div class="progress" :style="'width: ' + voiceRoleStore.volumeMeter + '%'" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup lang="ts">

import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { Role } from "@ravespaceio/rave-engine/build/engineserver-common/src/SharedDataTypes/PlayerTypes";
import { getSpace } from "~/space/space";
import { useSpaceStore } from "~/store/space";
import { useGameStore } from "~/store/game";
import { useVoiceRolesStore } from "~/store/agora/voiceroles";

const gameStore = useGameStore();
const voiceRoleStore = useVoiceRolesStore();
// const screenStore = useScreenStore();
const spaceStore = useSpaceStore()

const space = getSpace();

space.eventbinder.bindDocument("keyup", (e) => {
	if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
	if (e.key == "p" || e.key == "P") voiceRoleStore.muteUnmute();
});

</script>

<style lang="scss" scoped>
@import "assets/css/variables";

#adminHint {
	// position: fixed;
	// left: 10px;
	// top: 60px;
	margin: 5px;
	z-index: 10;
	@extend .box;
	padding: 8px 13px;
	max-width: 240px;
	background: rgba(0, 0, 0, 0.8);

	h4 {
		font-size: 14px;
		color: white;
		font-weight: 600;
	}

	p {
		color: white;
	}

	.btn {
		padding: 6px 17px;
		margin-top: 9px;
		margin-bottom: 6px;
		font-size: 12px;

		&.red {
			background: #ec4959;
			border-color: #ec4959;

			&:hover {
				color: white;
			}
		}
	}

	p:first-child {
		font-weight: 700;
	}

	p {
		font-size: 12px;
	}

	.progress-bar {
		width: 100%;
		margin-bottom: 12px;

		.progress {
			transition: ease 0.1s !important;
		}
	}

	.volumeLevel {
		p {
			text-align: center;
			margin-bottom: 5px;
			font-size: 11px;
			background: transparent !important;
		}
	}

	.broadcast-hint {
		p {
			background: #5bad5b;
			color: white;
			margin: 12px 0 6px 0;
			padding: 4px 10px;
			border-radius: 4px;
			text-align: center;
			font-weight: 700;
			font-size: 11px;

			&.muted {
				background: #da6262;
				color: white;
			}
		}
	}
}

.mute {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12px 0;
	font-weight: 700;
	font-size: 13px;
	color: white;

	.key {
		width: 20px;
		height: 20px;
		background: white;
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 13px;
		border-radius: 4px;
		margin-right: 4px;

		&.muted {
			background: #5bad5b;
			color: white;
		}
	}
}

.screenshare {
	border-top: 1px solid white;

	p {
		background: transparent !important;
		margin-bottom: 0
	}

	.wrap {
		@extend .flex-center;
		flex-wrap: wrap;

		.btn {
			margin: 4px;
			margin-top: 0;
		}
	}
}
</style>
