import { Material, Shader } from "three"
import * as THREE from "three"
import * as SceneUtils from "three/examples/jsm/utils/SceneUtils"



/**
 * based on mesh or geometry
 * blending two materials using one geometry/mesh based on vertex color attribute
 * m2blend lays over m1 and i am controlling m1 alpha
 *
 * returns a group with two meshs sharing the same geometry
 */
export function setupMaterialBlending(mesh: THREE.Mesh, m1: Material, m2blend: Material): THREE.Group {
	m2blend.transparent = true
	const geo = mesh.geometry
	const blendGroup = SceneUtils.createMultiMaterialObject(geo, [m1, m2blend]) // creates a group with two mesh with the same geo but different mat

	const parent = mesh.parent
	if (!parent) {
		console.error("material blending mesh requires a parent")
		return blendGroup;
	}

	mesh.removeFromParent()
	parent.add(blendGroup)

	blendGroup.position.copy(mesh.position)
	blendGroup.rotation.copy(mesh.rotation)
	blendGroup.scale.copy(mesh.scale)

	// important in order to make ao/light map blender export working
	// muss strigify/parse machen sonst bug dass mesh2 komplett invisible
	// blendGroup.children[0].userData = JSON.parse(JSON.stringify(mesh.userData))
	// blendGroup.children[1].userData = JSON.parse(JSON.stringify(mesh.userData))

	// wenn ich keine quality level materials (QLM) verwende klappts mit auskommentiertem userData code
	// wenn mit QLM dann ists broken. kanns testen in warehouse und spaceDemo

	m2blend.onBeforeCompile = (shader: Shader) => {
		shader.vertexShader = shader.vertexShader
			.replace('#include <common>', `
			#include <common>
			attribute vec4 color;
			varying vec3 vPos;
			varying float vMix;
		`)
			.replace('#include <begin_vertex>', `
			#include <begin_vertex>
			vPos = position;
			vMix = color.x;
		`)

		shader.fragmentShader = shader.fragmentShader
			.replace("#include <common>", `
			#include <common>
			varying vec3 vPos;
			varying float vMix;
		`)
			.replace('#include <output_fragment>', `
			#include <output_fragment>
			gl_FragColor.a = vMix;
		`)
	}

	return blendGroup
}


