<template>
	<div class="outro">
		<h2>Du hast {{ gameStore.blockchainGameScore }} Punkte erreicht.</h2>
		<div class="card" v-if="gameStore.blockchainGameScore > 2">
			<img src="/dek/img/bot/energy.png" v-if="gameStore.quests[5].done == false">
			<p>Super, du hast genug Blöcke erstellt. Du bist ein echter MasterMiner<template v-if="gameStore.quests[5].done == false"> und hast dir eine Energiezelle verdient!</template></p>
			<p>Versuche es gerne nochmal und knacke den Highscore.</p>
			<div class="btn green" v-if="gameStore.quests[5].done == false" @click="getCell()">Energiezelle einsammeln</div>
		</div>

		<div class="card" v-else>
			<img src="/dek/img/bot/render.png">
			<p>Du hast es leider nicht geschafft.</p>
			<p>Versuch es doch einfach nochmal. Ich bin mir sicher, dass du es schaffst!</p>
		</div>

		<div class="btn--wrapper" v-if="gameStore.blockchainGameScore < 3">
			<div class="btn" @click="spaceStore.showBlockchainModal = false">Beenden</div>
			<div class="btn--dark" @click="gameStore.blockchainGameState = 2">Neu starten</div>
		</div>

	</div>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'

const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()

function getCell() {
	gameStore.quests[5].done = true;
	gameStore.newEnergycellFound = true;
	spaceStore.showBlockchainModal = false;
	space.eventbus.emit("newAnalytics", "energycellMasterminer")
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";

.outro {
	@extend .flex-center;
	flex-direction: column;

	.card {
		@extend .flex-center;
		flex-direction: column;
		padding: 40px 20px;
		max-width: 350px;
		width: 100%;
		border: 1px solid black;
		border-radius: $radius;

		img {
			width: 100px;
			height: auto;
			margin-bottom: 20px;
		}

		.btn {
			margin-top: 20px;
		}

		p {
			text-align: center
		}
	}
}

.btn--wrapper {
	@extend .flex-center;
	flex-wrap: wrap;
	margin: 20px 0;

	.btn {
		margin: 8px;
	}
}
</style>
