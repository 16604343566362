import { isAnyTextInputActive } from "@ravespaceio/rave-engine/build/engine/src/utils/browser";
import { getLsItem, setupReactiveLocalStorage } from "~/space/_lib/utils/storeHelper";
import { ArtworkType } from "~/space/_lib/gltfExtensions/ArtworkExtension";
import { QualityEnum, QualityLevelsType } from "~/space/_lib/manager/MeshQualityMaterialManager";
import { Space, getSpace } from "~/space/space";
import { AvatarConfig, RPM_PRE_AVATARS } from "~/space/_lib/utils/rpm";
import { Vector3 } from "three";
import { RPMAvatar } from "@ravespaceio/rave-engine";


var tid: any

/**
 *
 * there are way more states that define the project (zb chatActive, ingameControlsEnabled)
 * but if they are only used in one components we dont need to add them to the central store
 */
export const useSpaceStore = defineStore({
	id: "space",
	state: () => ({
		// editor
		editorMode: false,

		// onboarding states
		onboardingDone: false,
		tutorialDone: false,
		showInterface: false,
		showIntroState: false,

		// state to allow voicechat only in HR game rooms
		canUseVoicechat: false,

		// interface states
		showMenu: false,
		showAvatarGenerator: false,
		showPointer: false,
		showChatMobile: false,
		showBigChat: false,
		showInventar: true,
		showTutorial: false,
		stagePlaceholder: undefined,

		// controls
		usePointJumpMode: false,
		pointerlockMode: false,
		pointerlocked: false,

		// modals
		showBarModal: false,
		showJobsModal: false,
		showArtworkInfoModal: false,
		showPlatformquizModal: false,
		showTeleportModal: false,
		showQuestModal: false,
		showBlockchainModal: false,
		showBenefitsModal: false,
		showSkillmatchingModal: false,
		showApplicationModal: false,
		showEntryOpportunitiesModal: false,
		showMasterclassModal: false,
		showDekergyModal: false,
		showRaffleModal: false,
		showStageModal: false,
		artworkModal: undefined as ArtworkType | undefined,

		// interface data
		hint: {
			title: undefined as string | undefined,
			text: undefined as string | undefined,
			actionKeyworld: undefined as string | undefined,
		},
		hintTop: {
			title: undefined as string | undefined,
			text: undefined as string | undefined,
			actionKeyworld: undefined as string | undefined,
		},
		drink: {
			drinkId: "",
			name: "",
			img: "",
			duration: 0,
		},

		// player data for frontend. cached. multiplayer is watching and updating itself
		playerName: undefined as string,
		avatar: {
			model: undefined as string,
			gender: undefined as "masculine" | "feminine",
			scale: 1 as number,
			preview: undefined as "loading" | string,
		},
		quality: undefined as undefined | QualityLevelsType,
	}),
	actions: {
		init(space: Space) {
			// default name
			setupReactiveLocalStorage(this, "playerName")
			if (!this.playerName) {
				const randomNumber = Math.floor(Math.random() * 999) + 1;
				this.playerName = "Gast" + randomNumber
			}
			RPMAvatar.defaultFemaleConfig.modelPath = "/SpaceShared/models/avatar/onart_girl.glb"
			RPMAvatar.defaultMaleConfig.modelPath = "/SpaceShared/models/avatar/onart_boy.glb"

			// default avatar
			setupReactiveLocalStorage(this, "avatar", legitCheckAvatar)
			const rpm = RPM_PRE_AVATARS[Math.floor(RPM_PRE_AVATARS.length * Math.random())]
			if (!this.avatar.model) this.avatar.model = rpm.model
			if (!this.avatar.gender) this.avatar.gender = rpm.gender
			if (!this.avatar.preview) this.avatar.preview = rpm.preview

			// default quality
			setupReactiveLocalStorage(this, "quality", legiCheckQuality)
			if (this.quality === undefined) this.quality = space.browser.info.os == "iOS" ? "low" : "high"
			watch(() => this.quality, (state) => { if (state) space.eventbus.emit("qualitysetting", state) }, { immediate: true })
		},
		showPointerForFiveSec() {
			this.showPointer = true
			clearTimeout(tid)
			tid = setTimeout(() => {
				this.showPointer = false
			}, 5000)
		}
	},
	getters: {
		isAnyModal(): boolean {
			return (this.artworkModal == undefined) && !this.showBarModal && !this.showTeleportModal && !this.showMenu && !this.showQuestModal && !this.showJobsModal && !this.showPlatformquizModal && !this.showSkillmatchingModal && !this.showEntryOpportunitiesModal && !this.showBlockchainModal && !this.showBenefitsModal && !this.showMasterclassModal && !this.showApplicationModal && !this.showDekergyModal && !this.showRaffleModal && !this.showStageModal
		},
	}
})


export function legitCheckAvatar(config: AvatarConfig): boolean {
	if (!config) return false;
	if (config.scale === undefined) return false
	if (config.scale > 1.06 || config.scale < 0.89) config.scale = 1
	const modelPath = config.model
	const pattern = /^((http|https|ftp):\/\/)/;
	if (pattern.test(modelPath)) {
		return !!(modelPath.includes("readyplayer.me") || modelPath.includes("cloud.ravespace"))
	} else {
		return true
	}
}

export function legiCheckQuality(qual: string): boolean {
	if (!qual) return false
	return qual == "low" || qual == "mid" || qual == "high"
}
