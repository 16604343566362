<template>
	<div class="row">
		<div class="l">Musik</div>
		<div class="r">
			<div class="wrap">
				<input v-model.number="audioState.volume" type="range" min="0" max="1" step="0.01">
				<div v-if="!audioState.muted" class="tgl" style="cursor: pointer" @click="audioState.muted = true">
					<svg width="12" heixght="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M3.81804 3.77298L5.99892 2.68254V8.55356L3.81804 7.46312V3.77298ZM3.14278 2.89099L7.08979 0.91748V10.3186L3.14321 8.34533H1.09087C0.488398 8.34533 0 7.85693 0 7.25446V3.98185C0 3.37938 0.488398 2.89099 1.09087 2.89099H3.14278ZM2.72762 3.98178H1.09132V7.25438H2.72762V3.98178ZM10.1891 0.95813C11.2988 2.0621 12.0005 3.74605 12.0005 5.61793C12.0005 7.4898 11.2988 9.17375 10.1891 10.2777L9.32957 9.59006C10.2817 8.70161 10.9096 7.26087 10.9096 5.61793C10.9096 3.97498 10.2817 2.53424 9.32957 1.64579L10.1891 0.95813ZM9.81797 5.61796C9.81797 4.30311 9.30794 3.11572 8.49516 2.31274L7.63475 3.00107C8.29468 3.59534 8.72711 4.5411 8.72711 5.61796C8.72711 6.69483 8.29468 7.64059 7.63475 8.23486L8.49516 8.92319C9.30794 8.12021 9.81797 6.93282 9.81797 5.61796Z" fill="#000000" />
					</svg>
				</div>
				<div v-else class="tgl" style="cursor: pointer" @click="audioState.muted = false">
					<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M4.06125 3.03784L6.38194 1.8775V8.1225L4.06125 6.96216V3.03784ZM3.34393 2.09918L7.54229 0V10L3.34423 7.90097H1.16036C0.51951 7.90097 0 7.38146 0 6.74061V3.25954C0 2.61869 0.51951 2.09918 1.16036 2.09918H3.34393ZM2.90089 3.2596H1.16036V6.74067H2.90089V3.2596ZM8.29248 3.66983L9.11297 2.84933L10.4433 4.17962L11.7735 2.84933L12.594 3.66983L11.2638 5.00012L12.594 6.33041L11.7735 7.1509L10.4433 5.82062L9.11297 7.1509L8.29248 6.33041L9.62276 5.00012L8.29248 3.66983Z" fill="#000000" />
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts" setup>
import { getEngine } from '~/space/engine';
import { setupReactiveLocalStorageRef } from '~/space/_lib/utils/storeHelper';

const engine = getEngine();
const audioState = ref({
	volume: 1,
	muted: false,
})

setupReactiveLocalStorageRef(audioState, "volume")
setupReactiveLocalStorageRef(audioState, "muted")


engine.audioManager.setupPromise.then(() => {
	setVolume(audioState.value.volume, true)
	setMute(audioState.value.muted)
	watch(() => audioState.value.volume, (s) => setVolume(s))
	watch(() => audioState.value.muted, (s) => setMute(s))
})

function setVolume(v: number, firstTime: boolean = false) {
	engine.audioManager.volume = v
	audioState.value.volume = v
	if (!firstTime) audioState.value.muted = engine.audioManager.isMuted(); // reminder: a volume change will unmute the audiomanager
}

function setMute(v: boolean) {
	audioState.value.muted = v
	v ? engine.audioManager.mute() : engine.audioManager.unmute();
}
</script>

<style lang="scss" scoped>
input {
	border: none;
}
</style>
