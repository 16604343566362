import { Loader, Eventbus, Logging, LogLevel, EventbusStore, LoggingArea, BigGui } from "@ravespaceio/rave-engine"
import EventBinder from "@ravespaceio/rave-engine/build/engine/src/helper/data/EventBinder"
import { isMobile, isVRSupportedAndNotMobile } from "@ravespaceio/rave-engine/build/engine/src/utils/browser"
import PCISystem from "./_lib/manager/PCISystem"
import SpawnManager from "./_lib/manager/SpawnManager"
import { BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from 'detect-browser'
import ScreenManager from "./_lib/manager/ScreenManager"
import { useSpaceStore } from "~/store/space"
import { useMultiplayerStore } from "~/store/multiplayer"
import ArtworkExtension from "./_lib/gltfExtensions/ArtworkExtension"
import { useGameStore } from "~/store/game"
import { setupGui } from "./gui"
import AgoraRTC from 'agora-rtc-sdk-ng'


AgoraRTC.setLogLevel(4);

export type StageType = "dev" | "stage" | "prod"

export type SpaceEnv = {
	IS_PROD: boolean
	STAGE: StageType
	GAMESERVER_HOST: string,
	GAMESERVER_URI: string,
	CMS_URI: string,
	STORE_WSS_URI: string
}


var space: Space
export function getSpace(): Space {
	if (!space) { console.error("Space not setup") }
	return space
}

export type Space = {
	ENV: SpaceEnv
	project: {
		id: string
		publicPath: string
	},
	browser: {
		urlParameter: URLSearchParams,
		info: BrowserInfo,
		isMobile: boolean
		isSafari: boolean
		isIos: boolean,
		isVR: Promise<boolean>
	},
	//spaceScriptSys: SpaceScriptSystem,
	loader: Loader,
	eventbinder: EventBinder,
	eventbus: Eventbus<any> // <"firstinteract" | "onboardingDone" | "qualitysetting" | "respawn">,
	spawnManager: SpawnManager<any>, // <"spawn">
	screenManager: ScreenManager,
	pciSystem: PCISystem
	artworkExtension?: ArtworkExtension
	gui: BigGui
}



export function setupSpace(): Space {

	const urlParameter = new URLSearchParams(window.location.search)
	// logger
	const ENV = useRuntimeConfig().public as SpaceEnv
	if (ENV.STAGE == "prod") {
		Logging.setupLogger(Logging.buildDefaultBrowserLogger(LogLevel.trace));
		if (urlParameter.get("enableLogs") == null) Logging.enableExlusivLogging(LoggingArea.Nothing)
	} else {
		Logging.setupLogger(Logging.buildDefaultBrowserLogger(LogLevel.trace));
		// Logging.enableExlusivLogging(LoggingArea.Multiplayer)
		//Logging.enableExlusivLogging(LoggingArea.Space)
		// Logging.enableExlusivLogging(LoggingArea.Agora)

	}
	Logging.info("setupSpace", LoggingArea.Space)
	Logging.info("env: " + JSON.stringify(ENV), LoggingArea.Space)

	// space
	const bInfo = detect() as BrowserInfo
	const SPACE = space = {
		ENV,
		project: {
			id: "dek",
			publicPath: "/dek"
		},
		browser: {
			info: bInfo,
			urlParameter,
			isMobile: isMobile(),
			isSafari: bInfo.name == "safari",
			isIos: bInfo.os == "iOS",
			isVR: isVRSupportedAndNotMobile()
		},
		// spaceScriptSys: new SpaceScriptSystem(),
		loader: new Loader(),
		eventbus: new Eventbus(),
		eventbinder: new EventBinder(),
		spawnManager: new SpawnManager(),
		screenManager: new ScreenManager(),
		pciSystem: new PCISystem(),
		gui: setupGui()
	}

	SPACE.screenManager.replaceMaterial = replaceMaterial

	const spaceStore = useSpaceStore()
	spaceStore.$reset()
	spaceStore.init(space)
	useGameStore().init()
	space.screenManager.setAllowedUids([100, 101, 102, 103]);

	// ENGINE.multiplayer?.agoraSdk.setAgoraLogLevel(0)
	EventbusStore.customPrefix = space.project.id

	return SPACE
}
