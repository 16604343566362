<template>
	<div style="height: 100%; width: 100%">
		<model-viewer id="modelViewer" :src="modelViewerUrl" ar ar-modes="webxr quick-look" camera-controls touch-action="pan-y pan-x" xr-environment disable-tap disable-pan interaction-prompt-threshold="100" interpolation-decay="40" orbit-sensitivity="0.8" :ar-placement="info!.placement">
			<div v-show="!modelLoaded" class="loaderWrapper">
				<ArLoader />
			</div>
			<div slot="progress-bar" style="display: none" />
			<!-- This is the default AR button that gets created by modelViewer. When a device does not support AR, it does not get rendered -->

			<button v-show="modelLoaded" id="originalARButton" slot="ar-button" class="arButton" @click="startAR">
				<IconActivateAR />
				<p>AR</p>
			</button>
			<!-- This is the same button as above, but created by hand in case a device does not support AR. In this case clicking the buttons displays the QR code -->
			<button v-show="!canActivateAR && modelLoaded" id="replacementARButton" class="arButton" @click="() => { showQRCode = true; }">
				<IconActivateAR />
				<p>AR</p>
			</button>
		</model-viewer>
		<div v-show="showQRCode" id="qr">
			<div id="overlay" />
			<div class="wrapper">
				<div id="qrClose" @click="showQRCode = false">
					<svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.207 13.549L10.983 8.328L16.203 3.104C16.805 2.504 16.805 1.539 16.205 0.937L15.72 0.451C15.435 0.159 15.045 0 14.635 0C14.633 0 14.633 0 14.633 0C14.223 0 13.838 0.161 13.55 0.45L8.328 5.676L3.104 0.456C2.505 -0.144 1.541 -0.147 0.939 0.453L0.453 0.934C0.16 1.221 0 1.611 0 2.02C0 2.431 0.161 2.818 0.45 3.106L5.676 8.328L0.455 13.552C-0.147 14.152 -0.147 15.117 0.453 15.721L0.938 16.206C1.225 16.498 1.614 16.657 2.024 16.657C2.432 16.657 2.822 16.494 3.109 16.207L8.33 10.982L13.552 16.201C13.848 16.5 14.242 16.651 14.637 16.651C15.028 16.651 15.42 16.502 15.719 16.204L16.204 15.72C16.498 15.435 16.657 15.045 16.657 14.635C16.659 14.225 16.498 13.838 16.209 13.549H16.207Z" />
					</svg>
				</div>
				<qrcode-vue id="code" :value="qrCodeUrl" :size="250" background="transparent" level="H" render-as="svg" />
				<h2>
					{{ scanningMessage[0] }}
				</h2>
				<h4>
					{{ scanningMessage[1] }}
				</h4>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">

import { getEngine } from '~/space/engine';
import QrcodeVue from "qrcode.vue";
import createBetterPaintingCanvas, { generateRuntimeModelURL } from "~/space/_lib/ar/betterPaintingCanvas";

export type ModelInfo = {
	url: string,
	placement?: "wall" | "floor",
	widthInCm?: number,
	heightInCm?: number,
	[key: string]: any;
}

useHead({
	script: [{
		type: "module",
		src: "https://ajax.googleapis.com/ajax/libs/model-viewer/3.0.1/model-viewer.min.js"
	}],
});

const props = defineProps({
	standalone: {
		type: Boolean,
		default: false,
		required: true
	},
	modelInfo: {
		type: Object as PropType<ModelInfo>,
		required: false,
	},
	scanningMessage: {
		type: Array,
		required: false,
		default: () => ["Scan with your mobile device", "Your current device doesn't support Augmented Reality."]
	}
})

const info = computed(() => {
	const ret = props.modelInfo ?? extractModelInfoFromUrl(window.location)

	if (!ret) console.error("ModelInfo could neither be found in props, nor be extracted from browser url.");

	qrCodeUrl.value = generateUrlFromModelInfo(window.location.origin + "/ar/", ret!);
	console.log("QR-Code URL: " + qrCodeUrl.value)

	computeModelViewerUrl(ret!);

	return ret;
});

const showQRCode = ref(false as boolean);
const canActivateAR = ref(true as boolean);
const qrCodeUrl = ref("" as string);
const modelViewerUrl = ref("" as string);
const modelLoaded = ref(false as boolean);

onMounted(async () => {
	checkCanActivateAR();
});

onUnmounted(() => {
	if (!info.value!.url.endsWith(".glb"))
		URL.revokeObjectURL(modelViewerUrl.value); // release blob by destroying url

	if (!props.standalone) {
		const engine = getEngine();
		engine.renderer.paused = false; // resume engine in case it was paused by entering AR
	}
});

const checkCanActivateAR = () => {
	const mv = document.querySelector("#modelViewer");

	mv?.addEventListener("load", () => {
		setTimeout(() => {
			// @ts-ignore
			canActivateAR.value = mv.canActivateAR;
			modelLoaded.value = true;
		}, 100)
	})
}

const computeModelViewerUrl = async (modelInfo: ModelInfo) => {
	const urlPromise = new Promise<string>(resolve => {
		if (modelInfo.url.endsWith(".glb")) {
			resolve(modelInfo.url);
		}
		else {
			createBetterPaintingCanvas({
				src: modelInfo.url,
				...(modelInfo.widthInCm ? { width: modelInfo.widthInCm } : { width: 150 }),
				...(modelInfo.heightInCm && { height: modelInfo.heightInCm }),
			}, (BPC) => {
				generateRuntimeModelURL(BPC, (url) => {
					resolve(url);
				})
			});
		}
	});

	modelViewerUrl.value = await urlPromise
}

const startAR = () => {
	if (!props.standalone) getEngine().renderer.paused = true;

	const mv = document.querySelector("#modelViewer");
	// @ts-ignore
	mv.resetTurntableRotation(0);
}

/**
 * Generates a URL with a ModelInfo object encoded in the search params
 */
const generateUrlFromModelInfo = (baseUrl: string, modelInfo: ModelInfo): string => {
	const url = new URL(baseUrl);

	for (const [key, value] of Object.entries(modelInfo)) {
		url.searchParams.append(key, value.toString());
	}

	return url.href;
}

/**
 * Extracts a ModelInfo object from a URLs search params
 */
const extractModelInfoFromUrl = (location: Location): ModelInfo | undefined => {
	const params = new URLSearchParams(location.search);

	if (!params.get("url")) {
		console.error("Property 'url' not found in search params!");
		return undefined;
	}

	const modelInfo: ModelInfo = {
		url: decodeURIComponent(params.get("url")!),
		...(params.get("placement") && { placement: params.get("placement") as "floor" | "wall" }),
		...(params.get("heightInCm") && { heightInCm: parseFloat(params.get("heightInCm") as string) }),
		...(params.get("widthInCm") && { widthInCm: parseFloat(params.get("widthInCm") as string) })
		// add more properties here if needed in usecase
	}

	return modelInfo;
}

</script>

<style scoped lang="scss">
$green: #fff;

#qr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	backdrop-filter: blur(20px);
	background-color: hsl(0, 0%, 0%, 0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 100;

	.wrapper {
		margin: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: hsl(0, 0%, 100%, 1);
		border-radius: 38px;
		z-index: 10;
		color: black;
		box-sizing: content-box;
		padding: 40px;
		position: relative;
		text-align: center;

		#code {
			padding: 24px;
		}

		h2 {
			margin-top: 10px;
			font-size: 2em;
			font-weight: 600;
		}

		h4 {
			font-size: 1em;
			color: rgb(144, 144, 144);
			margin-top: 10px;
		}

		#qrClose {
			position: absolute;
			right: 20px;
			top: 20px;
			z-index: 10;
			padding: 12px;
			display: inline-flex;
			background-color: black;
			border-radius: 100px;
			cursor: pointer;

			svg {
				fill: white;
			}
		}
	}
}

model-viewer {
	width: 100%;
	height: 100%;
	border-radius: 16px;

	.loaderWrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		top: 0;
	}

	.arButton {
		background-color: rgba(0, 0, 0, 0.8);
		border: none;
		position: absolute;
		bottom: -10px;
		right: calc(50% - 50px);
		width: 100px;
		margin: 10px;
		height: 50px;
		padding: 4px;
		border-radius: 10px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		p {
			margin: 0 10px 0 0;
			font-size: 1.6em;
			color: $green;
		}

		svg {
			width: 30px;
			margin-left: 10px;
			margin-right: 7px;
			stroke: $green;
			fill: none;
		}

		&:hover {
			filter: invert(1);
			border: 1px solid white;
		}
	}

	#originalARButton {
		z-index: 7;
	}

	#replacementARButton {
		z-index: 6;
	}

}
</style>
