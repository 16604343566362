<template>
	<div class="voicechat-prompt">
		<Transition name="fade">
			<div v-show="vgStore.message && !vgStore.requestedName" class="inner">
				<p class="title">Voicechat</p>
				<p>{{ vgStore.message }}</p>
			</div>
		</Transition>

		<Transition name="fade">
			<div v-show="vgStore.requestedName" class="inner">
				<p>{{ vgStore.requestedName }} möchte deiner Sprachchat-Gruppe beitreten.</p>
				<div class="wrap">
					<div @click="respondVoiceRequest(true)"><span class="key" v-show="!MyEnv.$isMobile">Y</span>Ja</div>
					<div @click="respondVoiceRequest(false)"><span class="key" v-show="!MyEnv.$isMobile">N</span>Nein</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script setup lang="ts">

import { getSpace } from "~/space/space";
import { useVoiceGroupStore } from "~/store/agora/voicegroups.js";
import { canUseActionKey } from "~/space/_lib/utils/frontend";
import { inviteRaycast } from "~/space/_lib/mechanic/agora/voiceGroups";
import { useSpaceStore } from "~/store/space";

const space = getSpace()
const spaceStore = useSpaceStore()
const vgStore = useVoiceGroupStore();

const voiceMobilebtn = document.getElementById("voicechat--btn");
voiceMobilebtn?.addEventListener("click", () => inviteRaycast());

space.eventbinder.bindDocument("keyup", (e) => {
	if ((e.key == "v" || e.key == "V") && canUseActionKey() && spaceStore.canUseVoicechat) {
		inviteRaycast();
	}
})

function respondVoiceRequest(yes: boolean) {
	console.log("respondVoiceRequest", yes);
	vgStore.respondKeyListener({ key: yes ? "y" : "n" });
}

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.voicechat-prompt {
	.inner {
		position: absolute;
		bottom: 20px;
		padding: 20px;
		@extend .box;
		width: 250px;
		left: calc(50% - 125px);
		z-index: 4;

		p {
			text-align: center;
			color: $main;
		}

		p.title {
			font-weight: 700;

		}

		.wrap {
			@extend .flex-center;
			margin-top: 10px;
			color: $main;

			div {
				@extend .flex-center;

				&:last-child {
					margin-left: 12px;
				}
			}

			.key {
				margin: 5px;
				font-size: 14px;
				font-weight: 700;

				span {
					width: 20px;
					margin-bottom: 4px;
					color: $dark;
					height: 20px;
					background: $main;
					@extend .flex-center;
					font-size: 14px;
					font-weight: 700;
					border-radius: 5px;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.voicechat-prompt {
		.inner {
			bottom: 140px;
		}
	}
}
</style>
