<template>
	<div class="wrap ui ui-top" v-show="!mpStore.blocked && mpStore.isConnected">
		<div v-if="mpStore.isConnectedRoom3D" class="box">
			<div class="group">
				<p>Gruppe</p>
				<p>{{ mpStore.room3dName }}</p>
			</div>
			<div class="online">
				<span></span>
				<p>{{ mpStore.playersAmountRoom3d }}/{{ mpStore.rooms3D[0]?.maxClients || 20 }}</p>
			</div>
		</div>
		<div v-else class="box">
			<p class="group">Keine Gruppe</p>
		</div>
		<div class="box switch">
			<div class="key">U</div>
			<p>Wechseln</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useMultiplayerStore } from '~/store/multiplayer';
const mpStore = useMultiplayerStore()

</script>

<style scoped lang="scss">
@import "assets/css/variables";

.wrap {
	// top: 10px;
	// left: 10px;
	// position: fixed;
	margin: 5px;
	z-index: 9;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 40px;

	.box {
		margin-right: 6px;
		@extend .flex-center;
		height: 100%;

		p {
			display: inline-block;
			font-size: 13px;
			color: white;
		}

		.group {
			padding: 8px 15px;
			border-right: 1px solid $lineColor;

			p:first-child {
				font-weight: 600;
				margin-right: 3px;
			}
		}

		.online {
			padding: 8px 15px;
			@extend .flex-center;

			p {
				font-weight: 600;
			}

			span {
				display: inline-block;
				background: $green;
				width: 5px;
				height: 5px;
				border-radius: 100%;
				margin-right: 6px;
			}
		}
	}

	.switch {
		padding: 8px 15px;

		.key {
			margin-right: 6px;
		}

		p {
			font-weight: 600;
		}
	}
}
</style>
