<template>
	<SpaceModal :show=showTeleportModal @closed="showTeleportModal = false">
		<div class="content">
			<h4>Teleportieren</h4>
			<div class="btn--wrapper">
				<template v-for="spawn in  spawns " :key="spawn">
					<div class="btn" @click="spawnAt(spawn)" v-if="spawn != 'minigameRespawn' && spawn != 'masterclass_entrance'">{{ spawn }}</div>
				</template>

			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/_lib/utils/frontend';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';

const space = getSpace()
const spaceStore = useSpaceStore()
const { showTeleportModal } = storeToRefs(spaceStore)

const spawns = ref([] as string[])
space.loader.on("loaded", () => {
	setTimeout(() => {
		spawns.value = space.spawnManager.getSpawnList()
	}, 1000)
})

space.eventbinder.bindDocument("keyup", (e) => {
	if (isAnyTextInputActive() || !spaceStore.onboardingDone) return;
	if ((e.key == "t" || e.key == "T") && canUseActionKey()) {
		showTeleportModal.value = !showTeleportModal.value
	}
});

function spawnAt(spawn: string) {
	space.spawnManager.spawnAt(spawn);
	showTeleportModal.value = false
}
</script>

<style scoped lang="scss">
.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;

		h4 {
			color: black;
			margin-bottom: 12px;
			font-weight: 600;
			font-size: 20px;
			text-align: center;
		}

		.btn--wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 20px;

			.btn {
				margin: 6px;
				padding: 8px 16px;
				text-transform: capitalize;
			}
		}
	}
}

.disabled {
	color: grey
}
</style>
