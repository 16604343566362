<template>
	<SpaceModal :show=showRaffleModal @closed="showRaffleModal = false">

		<div class="content">
			<h3>Das Gewinnspiel ist vorbei</h3>
			<!-- <form class="form" action="" v-if="gameStore.canEnterRaffle">
				<h3>Am Gewinnspiel teilnehmen (ab 18)</h3>
				<p style="margin-bottom: 24px;">Trage dich hier für das Gewinnspiel ein. Wenn du dich jetzt einträgst, berücksichtigen wir alle Energiezellen, die du bisher gesammelt hast. Wenn du im Laufe des Events noch mehr Energiezellen sammeln solltest, dann kannst du wieder kommen und erneut mit Tally interagieren, um die Energiezellen abzugeben und deine Gewinnchance zu erhöhen. Wir verwenden deinen Namen, um dich zu identifizieren, deine Emailadresse, um dich zu benachrichtigen und dein Geburtsdatum, um zu überprüfen, dass du mindestens 18 Jahre alt bist.</p>
				<p class="label">E-Mail</p>
				<input type="text" class="input" placeholder="Email" v-model="email" tabindex="1">
				<p class="label">Vorname</p>
				<input type="text" class="input" placeholder="Vorname" v-model="firstName" tabindex="2">
				<p class="label">Nachname</p>
				<input type="text" class="input" placeholder="Nachname" v-model="lastName" tabindex="3">
				<p class="label">Geburtstatum</p>
				<input type="date" class="date input" v-model="dateOfBirth" tabindex="4">
				<p style="margin-top: 12px;color: black; font-size: 12px;">Mit Klick auf ‚Absenden‘ bestätigst du, dass deine oben gemachten Angaben richtig sind und stimmst den <a style="color: black;" href="https://careerday.deka.de/pdfs/Teilnahmebedingungen_Deka_Career_Day_Gewinnspiel.pdf" target="_blank">Teilnahmebedingungen</a> für das Gewinnspiel zu. Es gilt unser <a style="color: black;" href="https://careerday.deka.de/pdfs/Datenschutzhinweise_Deka_Career_Day.pdf" target="_blank">Datenschutzhinweis</a></p>
				<div class="btn--dark" @click="enterRaffle()" :class="loading ? 'disabled' : ''">Absenden</div>
				<a href="https://careerday.deka.de/pdfs/Teilnahmebedingungen_Deka_Career_Day_Gewinnspiel.pdf" target="_blank" class="btnl">Teilnahmebedingungen</a>
			</form>
			<template v-else>
				<h3>Super!</h3>
				<p>Du hast dich erfolgreich beim Gewinnspiel eingetragen. Um teilzunehmen, musst du nun nur noch deine E-Mailadresse bestätigen. Dazu senden wir dir eine Mail mit einem Bestätigungslink.<br><br>Wenn du noch nicht alle Energiezellen gesammelt haben solltest, kannst du dich nun auf die Suche nach den weiteren Energiezellen machen. Komm dann hierher zurück, um die Tally deine Energiezellen zu übergeben und deine Gewinnchancen zu erhöhen.</p>
				<div class="btn--dark" @click="spaceStore.showRaffleModal = false;">Schließen</div>
			</template> -->
		</div>


	</SpaceModal>
</template>

<script setup lang="ts">
import { isAnyTextInputActive } from '@ravespaceio/rave-engine/build/engine/src/utils/browser';
import { storeToRefs } from 'pinia';
import { canUseActionKey } from '~/space/_lib/utils/frontend';
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification';

const space = getSpace()
const spaceStore = useSpaceStore()
const gameStore = useGameStore()
const { showRaffleModal } = storeToRefs(spaceStore)

const firstName = ref("")
const lastName = ref("")
const dateOfBirth = ref("")
const email = ref("")
const loading = ref(false)


space.eventbus.on("subscribeToGroup", () => {
	if (!gameStore.canEnterRaffle) {
		if (gameStore.questsFinished == 1) {
			updateRaffle("2700240050")
		}
		if (gameStore.questsFinished == 2) {
			updateRaffle("2700240051")
		}
		if (gameStore.questsFinished == 3) {
			updateRaffle("2700240052")
		}
		if (gameStore.questsFinished == 4) {
			updateRaffle("2700240053")
		}
		if (gameStore.questsFinished == 5) {
			updateRaffle("2700240054")
		}
		if (gameStore.questsFinished == 6) {
			updateRaffle("2700240055")
		}
		if (gameStore.questsFinished == 7) {
			updateRaffle("2700240056")
		}
	}
})


function enterRaffle() {

	if (!checkAge()) {
		alert("Aus rechtlichen Gründen können leider nur Personen über 18 Jahre teilnehmen.")
		return
	}

	if (!isValidEmail(email.value)) {
		alert("Deine E-Mail Adresse ist nicht korrekt.")
		return
	}

	if (firstName.value.length < 1 || lastName.value.length < 1 || dateOfBirth.value == '') {
		alert("Bitte fülle alle Felder aus.")
		return
	}

	if (gameStore.canEnterRaffle) {
		loading.value = true
		var requestData = {
			"firstName": firstName.value,
			"lastName": lastName.value,
			"dateOfBirth": dateOfBirth.value,
			"email": email.value
		};

		var xhr = new XMLHttpRequest();
		var url = "https://dek-api.ravespace.io/create.php";

		xhr.open("POST", url, true);

		xhr.onload = function () {
			var response = JSON.parse(xhr.responseText);
			console.log("API Response:", response);
			localStorage.setItem("raffleEmail", email.value)
			gameStore.canEnterRaffle = false
			useToast().success("Du hast dich erfolgreich eingetragen!")
			updateRaffle("2700240049")
			if (gameStore.questStage > 0 && gameStore.newEnergycellFound) {
				space.eventbus.emit("enterEnergyCell")
			}
		};

		xhr.onerror = function () {
			console.error(xhr.statusText);
			useToast().error(xhr.statusText)
			loading.value = false
		};

		var requestDataJSON = JSON.stringify(requestData);
		xhr.send(requestDataJSON);


	}
}

function updateRaffle(id) {
	const email = localStorage.getItem("raffleEmail")
	if (email) {
		const apiURL = "https://dek-api.ravespace.io/update.php";

		let optIn
		if (id == "2700240049") {
			optIn = "DOUBLE_OPT_IN"
		} else {
			optIn = "OPT_IN"
		}

		var requestData = {
			"groupId": id,
			"email": email,
			"optIn": optIn
		};

		var xhr = new XMLHttpRequest();
		xhr.open("POST", apiURL, true);
		xhr.onload = function () {
			console.log(xhr)
			localStorage.setItem("raffleEmail", email)
			gameStore.canEnterRaffle = false
			if (id != "2700240049") {
				useToast().success("Du hast deine Gewinnchancen erhöht!")
			}
			loading.value = false
		};
		xhr.onerror = function () {
			console.error(xhr.statusText);
			loading.value = false
		};
		var requestDataJSON = JSON.stringify(requestData)
		xhr.send(requestDataJSON);

	} else {
		console.log("Not worked")
		loading.value = false
	}
}

function checkAge() {
	const selectedDate = new Date(dateOfBirth.value);
	const currentDate = new Date();
	const ageDiff = currentDate.getFullYear() - selectedDate.getFullYear();
	return ageDiff >= 18;
}


</script>

<style scoped lang="scss">
@import "assets/css/variables";

.btnl {
	margin-top: 22px;
	color: black;
}

.content {
	@extend .flex-center;
	flex-direction: column;
	width: 100%;

	h3 {
		text-align: center;
		font-weight: 600;
		font-size: 25px;
	}

	p {
		text-align: center;
		font-size: 14px;
	}

	.btn {
		margin-top: 20px;
	}

	form {
		border: none;
		text-align: center;
		margin: 0;
		padding: 0;
		width: 750px;
		max-width: 100%;

		input {
			padding: 0 12px;
			text-align: left;
		}

		.btn {
			margin-top: 20px;
		}


		input,
		p.label {
			width: 100%;
			max-width: 350px;
		}

		p.label {
			text-align: left;
			width: 100%;
			margin-bottom: 4px;
			font-size: 12px;
		}
	}
}
</style>
