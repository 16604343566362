import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import Engine, { EngineUtils, HighlightCylinder, PlayerObject, RaveNavigation } from "@ravespaceio/rave-engine"
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useSpaceStore } from "~/store/space";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import { setupBigStageScreen } from "../setupScreens";




export default function setupCMSScreens() {

	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()

	const apiurl = space.ENV.CMS_URI + "/api/Billboards?populate=*,InfoScreen,StagePlaceholder"

	fetch(apiurl, {
		method: "GET",
	}).then((response) => { return response.json(); }).then((data) => {

		let imgs = data.data.attributes


		space.loader.getLoadingPromise("env").then((gltf) => {
			const objects = EngineUtils.Findings.findObjectsByUserData(gltf.scene, "CMSR")
			objects.forEach((object) => {
				if (object.userData.CMSR == "infoscreen") {
					replaceMaterial(object, imgs.InfoScreen.data.attributes.formats.large.url)
				}
			})
		})

		space.loader.on("loaded", async () => {
			if (imgs.StagePlaceholder.data?.attributes?.formats?.large?.url) {
				setupBigStageScreen(imgs.StagePlaceholder.data.attributes.formats.large.url)
				spaceStore.stagePlaceholder = imgs.StagePlaceholder.data.attributes.formats.large.url
			} else {
				setupBigStageScreen('/dek/img/placeholder.jpg')
			}
		})



	}).catch(function (error) { console.log(error) });




}

