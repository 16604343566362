import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import Client_AgoraCloudPlayer_RSH from "@ravespaceio/rave-engine/build/engine/src/engine/multiplayer/ClientStateHandlers/AgoraRoom/Client_AgoraCloudPlayer_RSH";
import { CloudPlayerAgoraHandler, Logging, LoggingArea, VideoScreenF } from "@ravespaceio/rave-engine/build/engine/src";
import { getEngine } from "~/space/engine";
import { getSpace } from '~/space/space';
import { getCPM } from '../multiplayer';
import CloudPlayerManager from '../../manager/CloudPlayerManager';

export function setupAgoraCloudPlayer() {

	const engine = getEngine();
	const agoraRoom = engine.multiplayer.clientAgoraRoom;

	const cloudPlayerStateHandler = agoraRoom.getStateHandler<Client_AgoraCloudPlayer_RSH>('CP_StateHandler');

	const cloudPlayerHandler = new CloudPlayerAgoraHandler(agoraRoom);
	cloudPlayerHandler.setAgoraHandler(undefined)

	cloudPlayerStateHandler.onCloudPlayerPublish.on(payload => {
		const { channelName, screenId } = payload;
		cloudPlayerHandler.joinAgoraChannel(channelName);
	})

	cloudPlayerStateHandler.onCloudPlayerUnPublish.on(payload => {
		const { channelName, screenId } = payload;
		cloudPlayerHandler.leaveAgoraChannel();
	})

	// fallback

	const agoraClient_CloudPlayer = cloudPlayerHandler.getAgoraClient() as IAgoraRTCClient;

	agoraClient_CloudPlayer.on('stream-fallback', (uid: any, isFallbackOrRecover: 'fallback' | 'recover') => {
		if (isFallbackOrRecover === 'fallback') {
			Logging.debug('only audio fallback', LoggingArea.Agora);
		} else if (isFallbackOrRecover === 'recover') {
			Logging.debug('recover using video');
		}
	})

	agoraClient_CloudPlayer.on('media-reconnect-end', (uid: any) => {
		Logging.debug('reconnect ends', LoggingArea.Agora);

		const space = getSpace()

		const agoraStreamTrack = cloudPlayerHandler.getRemoteVideoTracks().find((track) => track.getUserId() === uid)
		if (agoraStreamTrack === undefined) return;

		const streamTrack = agoraStreamTrack.getMediaStreamTrack()
		const stream = new MediaStream();
		stream.addTrack(streamTrack);
		space.screenManager.replaceTexturebyId(stream, uid);

		const screen = space.screenManager.videoScreens.get(uid);
		const cpManager = getCPM();

		if (cpManager instanceof CloudPlayerManager) {
			const agoraMediaTrack = cloudPlayerHandler.getRemoteAudioTracks().find((track) => track.getUserId() === uid);
			if (agoraMediaTrack === undefined) return;

			const mediaTrack = agoraMediaTrack.getMediaStreamTrack()
			const mediaStream = new MediaStream();
			mediaStream.addTrack(mediaTrack);
			cpManager.agoraAudioTracks.set(uid as number, cloudPlayerHandler.getRemoteAudioTracks()[0]);
			cpManager.mediaStreams.set(uid as number, mediaStream);
			cpManager.addPosAudio(uid as number, mediaStream, screen);
		}


		Logging.debug(uid, LoggingArea.Agora);
	})

	agoraClient_CloudPlayer.on('live-streaming-error', (url: any, err: any) => {
		Logging.debug(err, LoggingArea.Agora);

	})

	function getCPstate() {
		const engine = getEngine();
		if (!engine.multiplayer) return false;

		return engine.multiplayer.clientAgoraRoom.room.state.cloudPlayers.size > 0;
	}

	setInterval(() => getCPstate(), 1000);

	agoraClient_CloudPlayer.on('connection-state-change', (state) => {

		if (state === 'DISCONNECTED') {
			const leavingChannel = cloudPlayerHandler.controller.agoraOptions.channel;
			setTimeout(() => {
				if (getCPstate()) {
					cloudPlayerHandler.joinAgoraChannel(leavingChannel);
				}
			}, 1000);

			setTimeout(() => {
				if (getCPstate()) {
					cloudPlayerHandler.joinAgoraChannel(leavingChannel);
				}
			}, 10000);
		}
	})

	agoraClient_CloudPlayer.on('exception', (event: any) => {
		Logging.debug('exception', LoggingArea.Agora);
		Logging.debug(event, LoggingArea.Agora);

	})

	return cloudPlayerHandler

}
