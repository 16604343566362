<template>
	<div id="drink-progress" :class="`is-${drinkActive}`">
		<svg class="progress-ring" width="80" height="80">
			<circle ref="circle" class="progress-ring__circle" stroke="white" stroke-width="5" fill="transparent" r="35" cx="40" cy="40" />
		</svg>
		<div class="avatarPreview" :style="{ backgroundImage: `url(${drink.img ? drink.img : ''})` }" />
		<p v-if="drink.name">{{ drink.name }}</p>
	</div>
</template>

<script setup lang="ts">
import Vue from 'vue'
import { getSpace } from '~/space/space'
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';


const spaceStore = useSpaceStore()
const { drink } = storeToRefs(spaceStore)
const drinkActive = computed(() => !!drink.value.drinkId)
const circle = ref({} as SVGCircleElement)

var circumference: number
onMounted(() => {
	const radius = circle.value.r.baseVal.value;
	circumference = radius * 2 * Math.PI;
	circle.value.style.strokeDasharray = `${circumference} ${circumference}`;
	circle.value.style.strokeDashoffset = `${circumference}`;
	setProgress(0)
})

function resetCircle() {
	circle.value.style.transition = "0.1s linear stroke-dashoffset"
	setProgress(0)
}
function setProgress(percent: number) {
	const offset = circumference - percent / 100 * circumference;
	circle.value.style.strokeDashoffset = offset + "";
}

watch(() => spaceStore.drink, (state) => {
	if (drinkActive.value) {
		resetCircle()
		circle.value.style.transition = state.duration + "s linear stroke-dashoffset"
		setProgress(100) // why?
	} else {
		resetCircle()
	}
}, { deep: true })

</script>



<style scoped lang="scss">
@import "assets/css/variables";

#drink-progress {
	width: 80px;
	height: 100px;
	position: fixed;
	left: calc(50% - 40px);
	top: 20px;
	z-index: 10;
	transition: transform 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
	pointer-events: none;

	.avatarPreview {
		width: 74px;
		height: 74px;
		position: absolute;
		left: 3px;
		top: 3px;
		border-radius: 100%;
		background-size: 23%;
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.5);
	}

	svg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 4;
	}

	p {
		position: absolute;
		left: 0;
		width: 100%;
		text-transform: capitalize;
		font-size: 15px;
		top: 82px;
		color: white;
		text-align: center;
	}

	&.is-false {
		opacity: 0;
		transform: scale(0.9);
	}

	&.is-true {
		opacity: 1;
		transform: scale(1);
	}
}

.progress-ring__circle {
	transition: 0.1s stroke-dashoffset;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}
</style>
