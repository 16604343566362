
import { HighlightCylinder, MyLoaders, PositionalHint } from "@ravespaceio/rave-engine"
import { getSpace } from '~/space/space';
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { useGameStore } from "~/store/game";
import { Text } from 'troika-three-text'
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useToast } from "vue-toastification";
import { useSpaceStore } from "~/store/space";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';


export async function setupSkillMatching() {

	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()
	const engine = getEngine()
	const space = getSpace()

	const hcSkillMatching = new HighlightCylinder()
	engine.scene.add(hcSkillMatching)
	hcSkillMatching.scale.set(0.7, 1, 0.7)
	hcSkillMatching.position.set(0, 2.18, -26.980)

	space.pciSystem.add({
		collider: new SphereCollider(hcSkillMatching, 2),
		onColliderEnter: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = false; // avoid jumping
			spaceStore.hint.title = "Skillmatching starten"
		},
		onColliderLeave: () => {
			engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true; // avoid jumping
			spaceStore.hint.title = undefined
		},
		onInteract: () => {
			spaceStore.hint.title = undefined
			spaceStore.showSkillmatchingModal = true
			space.eventbus.emit("newAnalytics", "openSkillmatching")
		}
	})

}
