import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import * as THREE from "three"
import ThreeMeshUI, { BlockOptions, update } from 'three-mesh-ui';
import PlayerObject from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject";
import { setupAlwaysPlayerFacing } from "../shader/billboard";
import { HighlightCylinder } from "@ravespaceio/rave-engine";
import { useGameStore } from "~/store/game";


export function create3DUi(id, pos: { x: number; y: number; z: number; }, boxText: any, width?: number, height?: number) {
	const space = getSpace()
	const engine = getEngine()
	const gameStore = useGameStore()


	const container = new ThreeMeshUI.Block({
		width: width ? width : 2.3,
		height: height ? height : 1,
		padding: 0.2,
		backgroundOpacity: 0.9,
		backgroundColor: new THREE.Color(0x111223),
		alignItems: "center",
		justifyContent: "center",
		contentDirection: 'column',
		borderRadius: 0.1,
		fontColor: new THREE.Color(0xffffff),
		fontFamily: '/dek/fonts/Roboto-msdf.json',
		fontTexture: '/dek/fonts/Roboto-msdf.png',
		bestFit: "auto"
	});

	/* Text */
	const textBlock = new ThreeMeshUI.Block({
		width: width ? width : 2.3,
		height: height ? height : 1,
		backgroundOpacity: 0,
		bestFit: "auto",
		padding: 0.15
	});
	const text = new ThreeMeshUI.Text({
		content: boxText,
		fontSize: 0.1
	});
	textBlock.add(text);

	if (id == "dekabot") {
		watch(() => gameStore.dekaBotText, (dekaBotText) => {
			//@ts-ignore
			text.set({ content: dekaBotText })
			ThreeMeshUI.update();
		}, { immediate: true })
	} else {
		setupAlwaysPlayerFacing(container)
	}

	container.add(textBlock);

	container.position.set(pos.x, pos.y, pos.z)

	engine.loop.register((dt) => {
		ThreeMeshUI.update();
		const t = performance.now() / 1000
		container.position.y = pos.y + (Math.sin((t + 1) * 1) + 1) * 0.1
	})




	return container

}

