import { getEngine } from "~/space/engine";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import Engine, { HighlightCylinder, MyLoaders, PlayerObject, RaveNavigation } from "@ravespaceio/rave-engine"
import { PlayerEntities } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerObject"
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { useSpaceStore } from "~/store/space";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { CamControllersNames } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/CamControllerM";
import ThirdCameraController from "@ravespaceio/rave-engine/build/engine/src/engine/player/Camera/ThirdPersonCameraController";
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { getProjectEnvMap } from "~/space/_lib/mechanic/textures";



export default async function setupCars() {

	const space = getSpace()
	const engine = getEngine()
	const spaceStore = useSpaceStore()


	// DEKABOT YOUNG
	const car_glb = await MyLoaders.gltfLoader.loadAsync('/dek/models/car.glb') as GLTF
	const car = car_glb.scene
	car.name = "CAR"

	engine.scene.add(car)
	car.scale.set(0.2, 0.2, 0.2)
	car.position.set(-17, 40, 200)
	//@ts-ignore
	car.children[0].material.envMap = getProjectEnvMap()


	const car2 = car.clone()
	car2.position.set(-10, 36, 190)
	engine.scene.add(car2)

	const car3 = car.clone()
	car3.position.set(-130, 53, 70)
	car3.rotation.y = -1.5
	engine.scene.add(car3)
	space.gui.addToSceneFolder(car3)


	const tl = new TimelineLite({ onComplete: () => { tl.restart() } });
	tl.to(car.position, 10, { z: -90, ease: Linear.easeNone });
	tl.to(car.rotation, 4, { y: -3.14 });
	tl.to(car.position, 10, { z: 200, ease: Linear.easeNone });
	tl.to(car.rotation, 4, { y: 0 });


	const tl2 = new TimelineLite({ onComplete: () => { tl2.restart() } });
	tl2.to(car2.position, 12, { z: -90, ease: Linear.easeNone });
	tl2.to(car2.rotation, 4, { y: -3.14 });
	tl2.to(car2.position, 12, { z: 190, ease: Linear.easeNone });
	tl2.to(car2.rotation, 4, { y: 0 });


	const tl3 = new TimelineLite({ onComplete: () => { tl3.restart() } });
	tl3.to(car3.position, 13, { x: 150, ease: Linear.easeNone });
	tl3.to(car3.rotation, 4, { y: -4.7 });
	tl3.to(car3.position, 12, { x: -130, ease: Linear.easeNone });
	tl3.to(car3.rotation, 4, { y: 1.3 });

}
