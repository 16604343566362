import Engine, { CircleShadow, EngineUtils, Logging, LoggingArea, MyLoaders, ParticlePresets, RPMAvatar } from "@ravespaceio/rave-engine"
import { AmbientLight, MeshStandardMaterial, Group, Material, Matrix4, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three"
import { getMatMap } from "@ravespaceio/rave-engine/build/engine/src/utils/textures";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { getSpace } from "../space";
import { getEngine } from "../engine";
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';



export function setupEnvironment() {

	const space = getSpace()
	const engine = getEngine()

	// renderer
	engine.renderer.renderer.toneMapping = THREE.ACESFilmicToneMapping
	engine.renderer.renderer.toneMappingExposure = 1
	engine.renderer.renderer.physicallyCorrectLights = true
	engine.renderer.renderer.outputEncoding = THREE.sRGBEncoding;

	// bg/env
	const scene = engine.scene
	scene.fog = new THREE.Fog(0x7e9aae, 0, 180);
	scene.background = new THREE.Color(0xA0A0A0);
	// engine.scene.overrideMaterial = new THREE.MeshPhysicalMaterial()

	space.eventbus.onAlready("qualitysetting", (q) => {
		const mapRes = space.browser.isMobile ? 2 : q == "low" ? 2 : q == "mid" ? 4 : q == "high" ? 8 : 2
		getMatMap(space.loader, `${space.project.publicPath}/textures/sky_${mapRes}k.jpg`, {
			encoding: THREE.sRGBEncoding, mapping: THREE.EquirectangularReflectionMapping, flipY: true, onLoad: (tex) => {
				if (space.eventbus.getEventValue("qualitysetting") != q) return;
				scene.background = tex
			}
		})
	})

	const ambiLight = new AmbientLight(0xff9500);
	const ambiLightIntensity = .2
	ambiLight.intensity = ambiLightIntensity;
	engine.scene.add(ambiLight);
	space.gui.addToSceneFolder(ambiLight);

	const sunLight = new THREE.DirectionalLight(0xadf1ff);
	const sunLightIntensity = .8
	sunLight.position.set(-300, 300, 600)
	sunLight.intensity = sunLightIntensity;
	sunLight.castShadow = false;
	engine.scene.add(sunLight);
	space.gui.addToSceneFolder(sunLight)

	const directionalLight = new THREE.DirectionalLight(0x0579f5);
	const directionalLightIntensity = 0.4
	directionalLight.position.set(300, -80, -200)
	directionalLight.intensity = directionalLightIntensity;
	directionalLight.castShadow = false;
	engine.scene.add(directionalLight);
	space.gui.addToSceneFolder(directionalLight)

	// const idleParticles = ParticlePresets.createGlow(engine.player.camera)
	// engine.scene.add(idleParticles)
	// engine.loop.register((dt) => {
	// 	idleParticles.spawnParticles(dt)
	// 	idleParticles.update(dt)
	// 	idleParticles.spawnRange.set(80, 10, 80)
	// 	idleParticles.amount = 8;
	// 	idleParticles.size = .05;
	// 	idleParticles.lifetime = 3 * (Math.random() * 2);
	// 	// (idleParticles.material as MeshBasicMaterial).transparent = false;
	// 	// (idleParticles.material as MeshBasicMaterial).color.set(0xffffff);
	// 	(idleParticles.material as MeshBasicMaterial).side = THREE.FrontSide;
	// 	// @ts-ignore
	// 	// idleParticles.material.uniforms.diffuseTexture.value = null
	// })
	// space.gui.addToSceneFolder(idleParticles)


	engine.player.getNavigationComponent<PlayerCharacter>().events.on('switch', ({ prevZone, newZone }) => {
		if (newZone === 'masterclass') {
			gsap.to(ambiLight, { intensity: 0, duration: 1, ease: Power2.easeInOut });
			gsap.to(sunLight, { intensity: 0, duration: 1, ease: Power2.easeInOut });
			gsap.to(directionalLight, { intensity: 0, duration: 1, ease: Power2.easeInOut });
		} else if (prevZone === 'masterclass') {
			gsap.to(ambiLight, { intensity: ambiLightIntensity, duration: 1, ease: Power2.easeInOut });
			gsap.to(sunLight, { intensity: sunLightIntensity, duration: 1, ease: Power2.easeInOut });
			gsap.to(directionalLight, { intensity: directionalLightIntensity, duration: 1, ease: Power2.easeInOut });
		}
		let interactionId = '';
	})
}


