import { BigGui, RaveNavigation } from "@ravespaceio/rave-engine";
import { Segment } from "@ravespaceio/rave-engine/build/engine/vendor/three-pathfinding/src/Pathfinding";
import * as THREE from "three";

export function debugNavMeshPerimeter(segments: Segment[], offsetY: number, parentFolder: BigGui, scene: THREE.Scene, visible: boolean = false) {

	const segmentGroup = new THREE.Group();
	segmentGroup.name = 'NavMeshPerimeter'
	const segmentFolder = parentFolder.addFolder("NavMesh Perimeter");
	segments.forEach(segment => {
		const material = new THREE.LineBasicMaterial();
		const geometry = new THREE.BufferGeometry();
		const start = segment.start.clone();
		const end = segment.end.clone();
		start.y += offsetY;
		end.y += offsetY;
		const points = [start, end];
		geometry.setFromPoints(points);
		const line = new THREE.Line(geometry, material);
		segmentGroup.add(line);
	})

	scene.add(segmentGroup);
	segmentGroup.visible = visible;

	segmentFolder.add(segmentGroup, "visible").onChange((value) => {
		segmentGroup.visible = value;
	});

}

export function createNavLinkDebugSphere(navLinks: RaveNavigation.NavMeshLink[], parentFolder: BigGui, scene: THREE.Scene, visible: boolean = false) {
	const navLinksGroup = new THREE.Group();
	navLinksGroup.name = 'navLinks_group'
	const navLinksFolder = parentFolder.addFolder("NavMeshLinks");

	navLinks.forEach((navlink, i) => {
		const sphere = new THREE.Mesh(new THREE.SphereGeometry(0.1, 6, 6), new THREE.MeshBasicMaterial({ color: 0x0000ff, wireframe: true }));
		const sphere2 = new THREE.Mesh(new THREE.SphereGeometry(0.1, 6, 6), new THREE.MeshBasicMaterial({ color: 0x0000ff, wireframe: true }));

		sphere.name = `1_${navlink.zoneId}_${i}`
		sphere2.name = `2_${navlink.zoneId}_${i}`
		sphere.position.copy(navlink.refA);
		sphere2.position.copy(navlink.refB);
		navLinksGroup.add(...[sphere, sphere2])

		navLinksFolder.add({ [`${navlink.zoneId}_${i}`]: navlink.isActive }, `${navlink.zoneId}_${i}`).onChange(e => {
			navlink.setActive(e);
			if (e !== true) {
				sphere.material.color = new THREE.Color(0x808080)
				sphere2.material.color = new THREE.Color(0x808080)
				sphere.material.needsUpdate = true;
			} else {
				sphere.material.color = new THREE.Color(0x0000ff)
				sphere2.material.color = new THREE.Color(0x0000ff)
				sphere.material.needsUpdate = true;

			}
		})

		scene.add(navLinksGroup);


	})
	navLinksGroup.visible = visible;

	navLinksFolder.add(navLinksGroup, 'visible').onChange(e => {
		navLinksGroup.visible = e;
	})

}


export function debugNavZoneNames(navFolder: BigGui, navigation: RaveNavigation.NavigationSystem) {

	const zonesFolder = navFolder.addFolder('NavZones');
	navigation.getActiveZones().forEach(zone => {
		zonesFolder.add({ name: zone }, 'name');
	})


}
