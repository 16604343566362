export const dictionary = ["hitler", "heil", "nazi", "holocaust", "hakenkreuz", "nigger", "neger", "nigga"];


/**
 *
 * @returns true if valid
 */
export function validateText(text: string): boolean {
	if (typeof text !== "string") return false;
	text = text.toLowerCase().trim();
	if (text.length < 1) return false;
	return !dictionary.some(v => text.includes(v.toLowerCase().trim()))
}


export function onlyLetters(text: string): boolean {
	var letters = /^[a-zA-ZäöüÄÖÜ0-9]+$/;
	return letters.test(text);
}
