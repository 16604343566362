
import { MyLoaders, PositionalHint } from "@ravespaceio/rave-engine"
import { getSpace } from '~/space/space';
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { useGameStore } from "~/store/game";
import { setupEnergyCells } from "./energycells";
import { setupDekaBot } from "./dekabot";
import { setupDekaBotOld } from "./dekabotOld";


export async function setupQuestGame() {

	const gameStore = useGameStore()

	/* Setup Energy Cell Models to find only when not found already */
	setupEnergyCells()

	setupDekaBot()
	setupDekaBotOld()

}
