<template>
	<div class="row">
		<div class="l">Grafik</div>
		<div class="r">
			<div class="wrap">
				<div style="margin-right: 20px;text-align:center;" class="tag">FPS: {{ fps }}</div>
				<div class="tgl" :class="quality == 'low' ? '' : 'disabled'" @click="quality = 'low'">Low</div>
				<div class="tgl" :class="quality == 'mid' ? '' : 'disabled'" @click="quality = 'mid'">Fair</div>
				<div class="tgl" :class="quality == 'high' ? '' : 'disabled'" @click="quality = 'high'">High</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getEngine } from '~/space/engine';
import { useSpaceStore } from '~/store/space';
import { getSpace } from '~/space/space';
import { storeToRefs } from 'pinia';

const space = getSpace()
const engine = getEngine()
const { quality } = storeToRefs(useSpaceStore())

const fps = ref(0)
space.eventbinder.bindInterval(() => {
	fps.value = engine.loop.getFPS();
}, 1000)



</script>
