import { defineStore } from 'pinia'
import { getEngine } from '../../space/engine';

let timeoutId;

export const useVoiceGroupStore = defineStore({
	id: "voicegroup",
	state: () => ({
		message: '' as string,
		voiceGroupActive: false,
		members: new Map<string, string>() as Map<string, string>, // string, string -> clientID (in agora room), name
		requestedName: '' as string,
		respondKeyListener: (e: any) => { },
	}),
	actions: {
		showMessageForSomeTime(msg: string) {
			this.message = msg;
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				if (this.message === msg) this.message = '';
			}, 5000);
		}
	}
})
