<template>
	<SpaceModal :show=show @closed="close()">
		<div class="content">
			<h1>Frage {{ gameStore.platformQuizState + 1 }} von 5</h1>

			<h3 v-if="!currentCat">Wähle eine Kategorie</h3>
			<div class="category-wrapper" v-if="!currentCat" :class="'round-' + gameStore.platformQuizState">
				<div class="btn cat-investieren" @click="chooseCategory('Investieren')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4506 1.40266C16.5126 1.09921 17.6196 1.7142 17.923 2.77627C17.9741 2.95495 18 3.13988 18 3.32571V4.99998H20C21.1046 4.99998 22 5.89541 22 6.99998V19C22 20.1045 21.1046 21 20 21H4C2.89543 21 2 20.1045 2 19H2.0267C2.00895 18.8924 2 18.7835 2 18.6742V6.75428C2 5.86132 2.59195 5.07655 3.45056 4.83123L15.4506 1.40266ZM10.1401 19H20V11H18V15.2457C18 16.1386 17.408 16.9234 16.5494 17.1687L10.1401 19ZM20 6.99998V8.99998H18V6.99998H20ZM4 6.75426V18.6742L16 15.2457V3.32568L4 6.75426ZM14 8.99998C14 9.55227 13.5523 9.99998 13 9.99998C12.4477 9.99998 12 9.55227 12 8.99998C12 8.4477 12.4477 7.99998 13 7.99998C13.5523 7.99998 14 8.4477 14 8.99998Z" fill="black" />
					</svg>
					Investieren</div>
				<div class="btn cat-allgemein" @click="chooseCategory('Allgemeinwissen')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM3.06565 10.9074C3.35719 11.2805 4.16782 11.758 5.40826 12.1475C5.89668 12.3009 6.4316 12.4357 7.00555 12.5502C7.00186 12.3679 7 12.1844 7 12C7 8.67236 7.60556 5.6673 8.65455 3.64231C5.66412 4.84042 3.46628 7.59699 3.06565 10.9074ZM7.12914 14.6108C5.52334 14.3317 4.14644 13.9093 3.10296 13.3658C3.58837 16.5542 5.74677 19.1927 8.65455 20.3577C7.88867 18.8792 7.35916 16.8783 7.12914 14.6108ZM9.17891 14.8773C10.076 14.9581 11.0209 15 12 15C12.9633 15 13.9124 14.9454 14.8253 14.8441C14.3742 18.4417 13.127 21 12 21C10.8765 21 9.63347 18.4574 9.17891 14.8773ZM14.9863 12.8045C14.0367 12.9275 13.028 13 12 13C10.9558 13 9.95341 12.9483 9.01531 12.8502C9.00522 12.5706 9 12.287 9 12C9 6.98399 10.5936 3 12 3C13.4064 3 15 6.98399 15 12C15 12.2713 14.9953 12.5397 14.9863 12.8045ZM16.8792 14.5269C16.6539 16.8289 16.1208 18.861 15.3454 20.3577C18.3046 19.1721 20.4876 16.4606 20.9212 13.1964C19.861 13.7479 18.4647 14.209 16.8792 14.5269ZM20.9285 10.8601C20.458 11.3883 18.9737 12.0157 16.9962 12.4541C16.9987 12.3035 17 12.1521 17 12C17 8.67236 16.3944 5.6673 15.3454 3.64231C18.3216 4.83471 20.5128 7.57077 20.9285 10.8601Z" fill="black" />
					</svg>
					Allgemeinwissen</div>
				<div class="btn cat-serien" @click="chooseCategory('Serien')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M10 11.8472L20 10.1805V14.4666C19.408 14.1687 18.7232 14 18 14C15.8271 14 14 15.5226 14 17.5C14 19.4775 15.8271 21 18 21C20.1729 21 22 19.4775 22 17.5V1.81958L8 4.15291V15.4666C7.40804 15.1687 6.72317 15 6 15C3.82708 15 2 16.5226 2 18.5C2 20.4775 3.82708 22 6 22C8.17292 22 10 20.4775 10 18.5V11.8472ZM8 18.5C8 19.284 7.14079 20 6 20C4.85921 20 4 19.284 4 18.5C4 17.7161 4.85921 17 6 17C7.14079 17 8 17.7161 8 18.5ZM18 19C19.1408 19 20 18.284 20 17.5C20 16.7161 19.1408 16 18 16C16.8592 16 16 16.7161 16 17.5C16 18.284 16.8592 19 18 19ZM10 9.81958V5.84717L20 4.1805V8.15291L10 9.81958Z" fill="black" />
					</svg>
					Serien & Popkultur</div>
				<div class="btn cat-finanzen" @click="chooseCategory('Finanzen')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M2.81818 3H21.1818C22.186 3 23 3.89543 23 5V17C23 18.1046 22.186 19 21.1818 19H2.81818C1.81403 19 1 18.1046 1 17V5C1 3.89543 1.81403 3 2.81818 3ZM3 10V17H21V10H3ZM3 8H21V5H3V8Z" fill="black" />
					</svg>
					Finanzen</div>
				<div class="btn cat-krypto" @click="chooseCategory('Krypto')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M13 4.06438C15.4137 4.38772 17 5.92172 17 8.5C17 9.80666 16.5926 10.8451 15.8765 11.5937C17.2002 12.3022 18 13.6304 18 15.5C18 18.4023 16.0725 20 13.25 20H13V22H11V20H9V22H7V20H5V18H7V6H5V4H7V2H9V4H11V2H13V4.06438V4.06438ZM12 6H9V11H12C13.9562 11 15 10.1889 15 8.5C15 6.81114 13.9562 6 12 6ZM9 13V18H13.25C15.0422 18 16 17.2061 16 15.5C16 13.7939 15.0422 13 13.25 13H9Z" fill="black" />
					</svg>
					Krypto & Blockchain</div>
			</div>

			<div class="question" v-if="currentQuestion">
				<h3>{{ currentQuestion.question }}</h3>
				<div class="btn--wrapper" id="btncontainer">
					<div class="btn" @click="answer(1)">{{ currentQuestion["1"] }}</div>
					<div class="btn" @click="answer(2)">{{ currentQuestion["2"] }}</div>
					<div class="btn" @click="answer(3)">{{ currentQuestion["3"] }}</div>
					<div class="btn" @click="answer(4)">{{ currentQuestion["4"] }}</div>
				</div>

			</div>
		</div>
	</SpaceModal>
</template>

<script setup lang="ts">
import { getSpace } from '~/space/space';
import { useSpaceStore } from '~/store/space';
import { storeToRefs } from 'pinia';
import { useGameStore } from '~/store/game';
import { useToast } from 'vue-toastification'
import { getEngine } from '~/space/engine';
import { PlayerCharacter } from '@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter';
import { respawnCharacter } from '~/space/dek/game/minigamesetup';
const space = getSpace()
const gameStore = useGameStore()
const spaceStore = useSpaceStore()
const toast = useToast()
const { showPlatformquizModal } = storeToRefs(spaceStore)
const show = computed(() => !!showPlatformquizModal.value)

const currentCat = ref(undefined) as any
const currentQuestion = ref(undefined) as any

const questions = [
	{
		"1": "Das Konzentrieren aller Investitionen auf eine einzige Aktie.",
		"2": "Das Anlegen des gesamten Kapitals in riskante Derivate.",
		"3": "Das Ausschließen von Aktien aus dem Portfolio.",
		"4": "Das Verteilen des Anlagekapitals auf verschiedene Anlageklassen.",
		"category": "Investieren",
		"question": "Was versteht man unter dem Begriff \"Diversifikation\" im Kontext der Anlagestrategie?",
		"done": false
	},
	{
		"1": "Beraten Unternehmen",
		"2": "Schützen Anleger",
		"3": "Kontrollieren Banken",
		"4": "Schätzen Bonitäten",
		"category": "Investieren",
		"question": "Was machen Ratingagenturen?",
		"done": false
	},
	{
		"1": "Fix",
		"2": "Zero",
		"3": "Basket",
		"4": "Floater",
		"category": "Investieren",
		"question": "Wie bezeichnet man eine Anleihe mit variablem Zins?",
		"done": false
	},
	{
		"1": "Der Kurs steigt",
		"2": "Keine Auswirkung",
		"3": "Höhere Rendite",
		"4": "Der Kurs sinkt",
		"category": "Investieren",
		"question": "Wie wirkt sich ein steigendes Zinsniveau auf eine festverzinsliche Anleihe aus?",
		"done": false
	},
	{
		"1": 10,
		"2": 250,
		"3": 100,
		"4": 40,
		"category": "Investieren",
		"question": "Wie viele Unternehmen bildet der DAX ab?",
		"done": false
	},
	{
		"1": "European Trust Fund",
		"2": "European Trade Finance",
		"3": "Excess Trade Flow",
		"4": "Exchange Traded Fund",
		"category": "Investieren",
		"question": "Was bedeutet die Abkürzung \"ETF\"?",
		"done": false
	},
	{
		"1": "Behörde",
		"2": "Art von ETF",
		"3": "Abk. für IP-Adresse",
		"4": "Börsengang",
		"category": "Investieren",
		"question": "Was ist ein IPO?",
		"done": false
	},
	{
		"1": "Ein Film",
		"2": "Ein Wertpapier",
		"3": "Eine Anleitung",
		"4": "Ein sofortiger Ver/-kauf",
		"category": "Investieren",
		"question": "Was ist eine Market Order?",
		"done": false
	},
	{
		"1": "Eine Marke",
		"2": "Eine Geldmünze",
		"3": "Eine teure Anleihe",
		"4": "Eine billige Aktie",
		"category": "Investieren",
		"question": "Was ist ein \"Pennystock\"?",
		"done": false
	},
	{
		"1": "Eine Maschine",
		"2": "Mittelstandsunternehmen",
		"3": "Schuldverschreibung",
		"4": "Großunternehmen",
		"category": "Investieren",
		"question": "Was ist ein Blue Chip?",
		"done": false
	},
	{
		"1": "Angela Merkel",
		"2": "Mark Zuckerberg",
		"3": "Jeff Bezos",
		"4": "Satoshi Nakamoto",
		"category": "Investieren",
		"question": "Wer ist der vermutliche Schöpfer von Bitcoin?",
		"done": false
	},
	{
		"1": "Inflation",
		"2": "Schutzengel",
		"3": "Wahrzeichen",
		"4": "Fallende Kurse",
		"category": "Investieren",
		"question": "Was repräsentiert der Bär an der Börse?",
		"done": false
	},
	{
		"1": "Schutzengel",
		"2": "Rezession",
		"3": "Wahrzeichen",
		"4": "Steigende Kurse",
		"category": "Investieren",
		"question": "Was repräsentiert der Bulle an der Börse?",
		"done": false
	},
	{
		"1": "Trankilität",
		"2": "Schalilität",
		"3": "Airotät",
		"4": "Volatilität",
		"category": "Investieren",
		"question": "Wie lautet der Fachbegriff für stark schwankende Kurse?",
		"done": false
	},
	{
		"1": 1894,
		"2": 2008,
		"3": 2010,
		"4": 1998,
		"category": "Finanzen",
		"question": "In welchem Jahr wurde die Europäische Zentralbank (EZB) gegründet?",
		"done": false
	},
	{
		"1": "USA",
		"2": "Niederlande",
		"3": "China",
		"4": "Belgien",
		"category": "Finanzen",
		"question": "Welches Land war das erste, das den Euro eingeführt hat?",
		"done": false
	},
	{
		"1": "Südafrika",
		"2": "Deutschland",
		"3": "Ukraine",
		"4": "USA",
		"category": "Finanzen",
		"question": "Welches Land ist der größte Ölproduzent der Welt?",
		"done": false
	},
	{
		"1": "Schweiz",
		"2": "Südkorea",
		"3": "Ägypten",
		"4": "USA",
		"category": "Finanzen",
		"question": "Welches Land ist der größte Importeur weltweit?",
		"done": false
	},
	{
		"1": "Nachfrageeinbruch",
		"2": "Börsencrash",
		"3": "Finanzkrise",
		"4": "Kriegsschulden",
		"category": "Finanzen",
		"question": "Was löste die Hyperinflation in Deutschland aus?",
		"done": false
	},
	{
		"1": "Natron",
		"2": "Argon",
		"3": "Dampf",
		"4": "Stickstoff",
		"category": "Allgemeinwissen",
		"question": "Welches ist das am häufigsten vorkommende Gas in der Erdatmosphäre?",
		"done": false
	},
	{
		"1": "Blau",
		"2": "Gelb",
		"3": "Grün",
		"4": "Rot",
		"category": "Allgemeinwissen",
		"question": "Welche Farbe ist die häufigste auf den Nationalflaggen der Welt?",
		"done": false
	},
	{
		"1": "Südafrika",
		"2": "USA",
		"3": "Türkei",
		"4": "Brasilien",
		"category": "Allgemeinwissen",
		"question": "Welches ist die einzige Nation, die an jeder einzelnen FIFA Fussball-Weltmeisterschaft teilgenommen hat?",
		"done": false
	},
	{
		"1": "Hellofresh",
		"2": "flixbus",
		"3": "Zalando",
		"4": "Netflix",
		"category": "Allgemeinwissen",
		"question": "Welches dieser Unternehmen wurde zuerst gegründet?",
		"done": false
	},
	{
		"1": 1999,
		"2": 2020,
		"3": 1990,
		"4": 2007,
		"category": "Allgemeinwissen",
		"question": "In welchem Jahr wurde das allererste Modell des iPhone veröffentlicht?",
		"done": false
	},
	{
		"1": "Türkisch",
		"2": "Hindi",
		"3": "Deutsch",
		"4": "Chinesisch",
		"category": "Allgemeinwissen",
		"question": "Welche Sprache hat die meisten Muttersprachler:innen?",
		"done": false
	},
	{
		"1": "Nicky Minaj",
		"2": "50 Cent",
		"3": "Snoop Dog",
		"4": "Drake",
		"category": "Allgemeinwissen",
		"question": "Welche Künstler:in hat die meisten Streams bei Spotify?",
		"done": false
	},
	{
		"1": 412,
		"2": 23,
		"3": 5,
		"4": 118,
		"category": "Allgemeinwissen",
		"question": "Wie viele Elemente gibt es im Periodensystem?",
		"done": false
	},
	{
		"1": 90,
		"2": 12,
		"3": 55,
		"4": 21,
		"category": "Allgemeinwissen",
		"question": "Wie viele Punkte hat ein Würfel insgesamt?",
		"done": false
	},
	{
		"1": 0,
		"2": 18,
		"3": 1,
		"4": 3,
		"category": "Allgemeinwissen",
		"question": "Wie viele Herzen hat ein Tintenfisch?",
		"done": false
	},
	{
		"1": "Keine Ahnung",
		"2": "Keine Lust",
		"3": "Keine Zeit",
		"4": "Keine Probleme",
		"category": "Serien",
		"question": "Was bedeutet \"Hakuna Matata\"?",
		"done": false
	},
	{
		"1": "Schint",
		"2": "Bungalow",
		"3": "Ravenclaw",
		"4": "Burrow",
		"category": "Serien",
		"question": "Wie lautet der Name des Hauses der Weasleys in Harry Potter?",
		"done": false
	},
	{
		"1": "Snoopy",
		"2": "Homer Simpson",
		"3": "Stitch",
		"4": "Mickey Mouse",
		"category": "Serien",
		"question": "Wer war die erste Zeichentrickfigur, die mit einem Stern auf dem Hollywood Walk of Fame ausgezeichnet wurde?",
		"done": false
	},
	{
		"1": "Stranger Things",
		"2": "Wednesday",
		"3": "Haus des Geldes",
		"4": "Squid Game",
		"category": "Serien",
		"question": "Welche Netflix-Sendung hat die meisten Streaming-Aufrufe?",
		"done": false
	},
	{
		"1": "Naga",
		"2": "Fang",
		"3": "Toph",
		"4": "Appa",
		"category": "Serien",
		"question": "Wie heißt Aangs (Avatar) fliegendes Tier?",
		"done": false
	},
	{
		"1": 3,
		"2": 25,
		"3": 1,
		"4": 12,
		"category": "Serien",
		"question": "Wie viele Staffeln hat Big Bang Theory",
		"done": false
	},
	{
		"1": "Star Wars",
		"2": "Titanic",
		"3": "Avengers",
		"4": "Avatar",
		"category": "Serien",
		"question": "Was ist bis heute der erfolgreichste Film?",
		"done": false
	},
	{
		"1": 7,
		"2": 2,
		"3": 99,
		"4": 37,
		"category": "Serien",
		"question": "Wie viele Barbie Animationsfilme gibt es?",
		"done": false
	},
	{
		"1": "Death Note",
		"2": "Demon Slayer",
		"3": "My Hero Academy",
		"4": "Parasite",
		"category": "Serien",
		"question": "Welches davon stellt keine Anime Serie dar?",
		"done": false
	},
	{
		"1": "Victoria",
		"2": "Nora",
		"3": "Chloe",
		"4": "Tracy",
		"category": "Serien",
		"question": "Wie heißt die Mutter bei \"How I Met Your Mother",
		"done": false
	},
	{
		"1": "Eine Währung, die von der Regierung eines Landes herausgegeben wird.",
		"2": "Eine Währung, die nur in physischer Form existiert.",
		"3": "Eine Währung, die nur für Online-Einkäufe verwendet werden kann.",
		"4": "Eine Währung, die nur in digitaler Form existiert und auf Kryptographie basiert.",
		"category": "Krypto",
		"question": "Was ist eine Kryptowährung?",
		"done": false
	},
	{
		"1": "Ein Netzwerk, das das Online Banking ermöglicht.",
		"2": "Ein Netzwerk, das nur von Börsen verwendet wird.",
		"3": "Ein Netzwerk, das nur von ausgewählten verwendet wird.",
		"4": "Ein dezentrales Netzwerk, das Transaktionen in Kryptowährungen verifiziert und speichert.",
		"category": "Krypto",
		"question": "Was ist ein Blockchain-Netzwerk?",
		"done": false
	},
	{
		"1": "Das Schürfen nach physischen Münzen und Scheinen einer Kryptowährung.",
		"2": "Das Speichern von Kryptowährungen in einer Geldbörse.",
		"3": "Der Kauf und Verkauf von Gegenständen im Netz.",
		"4": "Der Prozess, bei dem neue Kryptowährungseinheiten generiert werden, indem komplexe mathematische Probleme gelöst werden.",
		"category": "Krypto",
		"question": "Was ist das Mining von Kryptowährungen?",
		"done": false
	},
	{
		"1": "Eine Brieftasche, in der physische Münzen und Scheine einer Kryptowährung aufbewahrt werden.",
		"2": "Eine Plattform, auf der Kryptowährungen gehandelt werden.",
		"3": "Eine Börse, auf der Kryptowährungen gekauft und verkauft werden können.",
		"4": "Eine Software oder Hardware, die den Besitz und die Verwaltung von Kryptowährungen ermöglicht.",
		"category": "Krypto",
		"question": "Was ist ein Wallet in Bezug auf Kryptowährungen?",
		"done": false
	},
	{
		"1": "Ein regulatorisches Dokument.",
		"2": "Ein Vertrag, der von einer Regierung herausgegeben wird.",
		"3": "Ein Dokument, das digital eingescannt wurde.",
		"4": "Ein Vertrag, der auf einer Blockchain-Plattform automatisch ausgeführt wird, sobald bestimmte Bedingungen erfüllt sind.",
		"category": "Krypto",
		"question": "Was ist ein Smart Contract?",
		"done": false
	},
	{
		"1": "Eine staatliche Behörde, die den Handel mit Kryptowährungen reguliert.",
		"2": "Ein börsennotiertes Unternehmen, das Kryptowährungen entwickelt und handelt.",
		"3": "Eine Art von Wallet zur Aufbewahrung von Kryptowährungen.",
		"4": "Eine Methode zur Finanzierung von Kryptowährungsprojekten, bei der neue Tokens an Investoren verkauft werden.",
		"category": "Krypto",
		"question": "Was ist ein ICO?",
		"done": false
	},
	{
		"1": "Eine Software zur Verwaltung von Kryptowährungen.",
		"2": "Ein privates Bankkonto, auf dem Kryptowährungen gehalten werden können.",
		"3": "Zufällige binäre Nummern.",
		"4": "Der geheime Code, der den Zugriff auf eine Wallet ermöglicht.",
		"category": "Krypto",
		"question": "Was ist eine private Key in Bezug auf Kryptowährungen?",
		"done": false
	},
	{
		"1": "Ripple",
		"2": "Xcoin",
		"3": "Litecoin",
		"4": "Bitcoin",
		"category": "Krypto",
		"question": "Welche Kryptowährung war die erste, die eingeführt wurde?",
		"done": false
	}
]


function close() {
	showPlatformquizModal.value = undefined
}

function answer(id) {
	const engine = getEngine()
	if (id == 4) {
		toast.success("Richtig!")
		engine.player.getInputController<PlayerCharacter>().activeActions.jumping = true;
		gameStore.platformQuizState++
	} else {
		toast.error("Falsch.")
		respawnCharacter()
	}
	currentQuestion.value = undefined
	currentCat.value = undefined
	close()
}

function chooseCategory(cat) {

	const filteredQuestions = questions.filter(question => question.category === cat && !question.done);
	if (filteredQuestions.length > 0) {
		const randomIndex = Math.floor(Math.random() * filteredQuestions.length);
		const randomFinanzenQuestion = filteredQuestions[randomIndex];
		currentQuestion.value = randomFinanzenQuestion
	} else {
		currentQuestion.value = questions[0]
	}
	currentCat.value = cat
	setTimeout(() => {
		shuffleChildren()
	}, 1);
}

function shuffleChildren() {
	const container = document.getElementById('btncontainer');
	const children = Array.from(container.children);

	for (let i = children.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[children[i], children[j]] = [children[j], children[i]];
	}

	for (const child of children) {
		container.appendChild(child);
	}
}

</script>

<style lang="scss" scoped>
@import "assets/css/variables";


.content {
	height: auto;
	width: 100%;
	@extend .flex-center;
	flex-direction: column;

	.question {
		@extend .flex-center;
		flex-direction: column;
	}

	p.disclaimer {
		margin-top: 20px;
		text-decoration: underline;
	}

	h3 {
		margin-top: 12px;
		text-align: center;
	}

	.category-wrapper {
		@extend .flex-center;
		flex-wrap: wrap;
		margin-top: 20px;

		.btn {
			margin: 5px;
			@extend .flex-center;
			padding: 10px 20px;
			display: none;

			svg {
				width: 20px;
				margin-right: 6px;
				height: auto;
				filter: invert(1);
			}

			&:hover {
				svg {
					filter: invert(0);
				}
			}
		}
	}

	.btn--wrapper {
		width: 100%;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		flex-wrap: wrap;
		margin-top: 12px;

		.btn {
			font-size: 14px;
			margin: 5px;


		}
	}
}




.round-0 {

	.cat-investieren,
	.cat-serien {
		display: flex !important;
	}
}

.round-1 {

	.cat-allgemein,
	.cat-krypto {
		display: flex !important;
	}
}

.round-2 {

	.cat-finanzen,
	.cat-investieren {
		display: flex !important;
	}
}

.round-3 {

	.cat-serien,
	.cat-allgemein {
		display: flex !important;
	}
}

.round-4 {

	.cat-krypto,
	.cat-finanzen {
		display: flex !important;
	}
}
</style>
